import { formatarValor, getStatusPagamentoLabel } from "./pedidoUtils";
import Tooltip from "@mui/material/Tooltip";

export const transformPedidosData = (pedidos, showClienteFilter, formatDate, readOnly) => {
    return pedidos.map((pedido) => ({
        ID: pedido._id,
        Status: pedido.status,
        ...(showClienteFilter ? {
            Cliente: pedido.clienteId ? (
                <Tooltip title={pedido.clienteId.observacoes || "Sem observações"}>
                    <span>{pedido.clienteId.nome}</span>
                </Tooltip>
            ) : "Cliente não encontrado"
        } : {}),
        DataCadastro: formatDate(pedido.createdAt),
        DataEntrega: formatDate(pedido.dataEntrega),
        Retirada: pedido.retirada,
        Total: formatarValor(pedido.total),
        "Valor a Pagar": formatarValor(pedido.valorPagar),
        Pagamento: getStatusPagamentoLabel(pedido.statusPagamento, pedido.valorPagar, pedido.asaas?.paymentStatus),
        asaas: pedido.asaas,
        clienteId: pedido.clienteId, // Incluindo o objeto completo do cliente
        statusPagamento: pedido.statusPagamento,
        valorPagar: pedido.valorPagar,
        pagamento: pedido.pagamento,
        ...(readOnly ? {} : { Telefone: pedido.clienteId?.telefone || "" }),
        Arquivos: pedido._id, // Mudamos de DriveLink para Arquivos e passamos o ID do pedido
        boolean: pedido.isUrgent,
        _raw: pedido // Mantendo os dados originais do pedido
    }));
};

export const getCabecalhos = (readOnly, showClienteFilter) => {
    const baseCabecalhos = [
        "ID",
        "Status",
        ...(showClienteFilter ? ["Cliente"] : []),
        "Data Cadastro",
        "Data Entrega",
        "Retirada",
        "Detalhes",
        "Total",
        "Valor a Pagar",
        "Pagamento",
        "Ações de Pagamento"
    ];

    if (!readOnly) {
        baseCabecalhos.push("WhatsApp");
    }

    return [
        ...baseCabecalhos,
        "Arquivos", // Mudamos de "Drive" para "Arquivos"
        "Urgente"
    ];
};
