import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    CircularProgress,
    useTheme,
    alpha,
    IconButton,
    Stack,
    Divider
} from '@mui/material';
import {
    Close as CloseIcon,
    OpenInNew as OpenInNewIcon,
    ReceiptLong as ReceiptIcon,
    PictureAsPdf as PdfIcon,
    Code as XmlIcon
} from '@mui/icons-material';
import { formatarDataComFuso } from '../../utils/dateUtils';

const InvoiceDetailsDialog = ({ open, onClose, invoice, loading }) => {
    const theme = useTheme();

    if (loading) {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    backgroundColor: theme.palette.background.paper
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 1
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ReceiptIcon color="primary" />
                    <Typography variant="h6" component="span">
                        Detalhes da Nota Fiscal
                    </Typography>
                </Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ pt: 2 }}>
                {invoice ? (
                    <Stack spacing={2}>
                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Status
                            </Typography>
                            <Typography variant="body1">
                                {invoice.status}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Valor
                            </Typography>
                            <Typography variant="body1">
                                {new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                }).format(invoice.value)}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Data de Emissão
                            </Typography>
                            <Typography variant="body1">
                                {formatarDataComFuso(invoice.effectiveDate)}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Descrição do Serviço
                            </Typography>
                            <Typography variant="body1">
                                {invoice.serviceDescription || 'Não especificado'}
                            </Typography>
                        </Box>

                        {invoice.observations && (
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Observações
                                </Typography>
                                <Typography variant="body1">
                                    {invoice.observations}
                                </Typography>
                            </Box>
                        )}

                        <Stack spacing={2}>
                            {invoice.invoiceUrl && (
                                <Box sx={{
                                    p: 2,
                                    bgcolor: alpha(theme.palette.primary.main, 0.05),
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Visualizar nota fiscal no navegador
                                    </Typography>
                                    <IconButton
                                        color="primary"
                                        href={invoice.invoiceUrl}
                                        target="_blank"
                                        size="small"
                                    >
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Box>
                            )}
                            {invoice.pdfUrl && (
                                <Box sx={{
                                    p: 2,
                                    bgcolor: alpha(theme.palette.success.main, 0.05),
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Download do PDF da nota fiscal
                                    </Typography>
                                    <IconButton
                                        color="success"
                                        href={invoice.pdfUrl}
                                        target="_blank"
                                        size="small"
                                    >
                                        <PdfIcon />
                                    </IconButton>
                                </Box>
                            )}
                            {invoice.xmlUrl && (
                                <Box sx={{
                                    p: 2,
                                    bgcolor: alpha(theme.palette.info.main, 0.05),
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Download do XML da nota fiscal
                                    </Typography>
                                    <IconButton
                                        color="info"
                                        href={invoice.xmlUrl}
                                        target="_blank"
                                        size="small"
                                    >
                                        <XmlIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                ) : (
                    <Typography color="text.secondary" align="center">
                        Não foi possível carregar os detalhes da nota fiscal.
                    </Typography>
                )}
            </DialogContent>

            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button onClick={onClose} variant="outlined">
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceDetailsDialog;
