import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Alert,
    Snackbar,
} from '@mui/material';
import { useArquivos } from '../../hooks/useArquivos';
import PedidoArquivosGrid from './PedidoArquivosGrid';
import PedidoArquivosList from './PedidoArquivosList';

const PedidoArquivos = ({ pedidoId, isAdmin = false, viewMode = 'grid', selectedCategory = 'all' }) => {
    const {
        arquivos,
        pedido,
        loading,
        uploading,
        downloadingAll,
        uploadProgress,
        currentPath,
        arquivoDuplicado,
        isSelectionFolder,
        totalSelecionados,
        fetchArquivos,
        uploadArquivo,
        uploadPasta,
        excluirArquivo,
        excluirArquivos,
        downloadArquivo,
        downloadTodosArquivos,
        navegarPara,
        voltarDiretorio,
        confirmarSubstituicao,
        cancelarSubstituicao,
        marcarParaEntrega,
        toggleSelectionFolder
    } = useArquivos(pedidoId);

    const [notification, setNotification] = React.useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        if (pedidoId) {
            fetchArquivos();
        }
    }, [pedidoId, fetchArquivos]);

    // Calcula o progresso total dos uploads ativos
    const totalProgress = React.useMemo(() => {
        const progressEntries = Object.entries(uploadProgress);
        if (progressEntries.length === 0) return null;

        const completed = progressEntries.filter(([_, info]) => info.progress === 100).length;
        const total = progressEntries.length;

        // Calcula o progresso médio ponderado pelo tamanho dos arquivos
        const totalSize = progressEntries.reduce((sum, [_, info]) => sum + info.size, 0);
        const weightedProgress = progressEntries.reduce((sum, [_, info]) => {
            return sum + (info.progress * info.size / totalSize);
        }, 0);

        return {
            completed,
            total,
            progress: Math.round(weightedProgress),
            files: progressEntries.map(([id, info]) => ({
                id,
                name: info.fileName,
                progress: info.progress
            }))
        };
    }, [uploadProgress]);

    const handleUploadSuccess = () => {
        setNotification({
            open: true,
            message: 'Arquivo(s) enviado(s) com sucesso!',
            severity: 'success'
        });
    };

    const handleUploadError = (error) => {
        setNotification({
            open: true,
            message: `Erro ao enviar arquivo(s): ${error.message}`,
            severity: 'error'
        });
    };

    const handleCloseNotification = () => {
        setNotification(prev => ({ ...prev, open: false }));
    };

    const filteredArquivos = React.useMemo(() => {
        if (selectedCategory === 'all') return arquivos;

        const categoryFilters = {
            images: (arquivo) => arquivo.tipo?.startsWith('image/'),
            documents: (arquivo) => arquivo.tipo === 'application/pdf',
            videos: (arquivo) => arquivo.tipo?.startsWith('video/'),
            audio: (arquivo) => arquivo.tipo?.startsWith('audio/'),
            archives: (arquivo) => arquivo.tipo?.includes('zip') || arquivo.tipo?.includes('rar')
        };

        return arquivos.filter(arquivo => {
            if (arquivo.isFolder) return true;
            return categoryFilters[selectedCategory]?.(arquivo) || false;
        });
    }, [arquivos, selectedCategory]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            {viewMode === 'grid' ? (
                <PedidoArquivosGrid
                    arquivos={filteredArquivos}
                    pedido={pedido}
                    isAdmin={isAdmin}
                    uploading={uploading}
                    downloadingAll={downloadingAll}
                    uploadProgress={totalProgress}
                    currentPath={currentPath}
                    onUpload={uploadArquivo}
                    uploadPasta={uploadPasta}
                    onDelete={excluirArquivo}
                    onDeleteMany={excluirArquivos}
                    onDownload={downloadArquivo}
                    onDownloadAll={downloadTodosArquivos}
                    onNavigate={navegarPara}
                    onVoltarDiretorio={voltarDiretorio}
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={handleUploadError}
                    isSelectionFolder={isSelectionFolder}
                    totalSelecionados={totalSelecionados}
                    onSelectForDelivery={marcarParaEntrega}
                    onToggleSelectionFolder={toggleSelectionFolder}
                />
            ) : (
                <PedidoArquivosList
                    arquivos={filteredArquivos}
                    pedido={pedido}
                    isAdmin={isAdmin}
                    uploading={uploading}
                    downloadingAll={downloadingAll}
                    uploadProgress={totalProgress}
                    currentPath={currentPath}
                    onUpload={uploadArquivo}
                    uploadPasta={uploadPasta}
                    onDelete={excluirArquivo}
                    onDeleteMany={excluirArquivos}
                    onDownload={downloadArquivo}
                    onDownloadAll={downloadTodosArquivos}
                    onNavigate={navegarPara}
                    onVoltarDiretorio={voltarDiretorio}
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={handleUploadError}
                    isSelectionFolder={isSelectionFolder}
                    totalSelecionados={totalSelecionados}
                    onSelectForDelivery={marcarParaEntrega}
                    onToggleSelectionFolder={toggleSelectionFolder}
                />
            )}

            <Dialog
                open={!!arquivoDuplicado}
                onClose={cancelarSubstituicao}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                    }
                }}
            >
                <DialogTitle>
                    <Typography variant="h6">Arquivo Duplicado</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Já existe um arquivo com o nome "{arquivoDuplicado?.file?.name}" neste diretório.
                        Deseja substituir o arquivo existente?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button
                        onClick={cancelarSubstituicao}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={confirmarSubstituicao}
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: 2 }}
                    >
                        Substituir
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification.severity}
                    variant="filled"
                    sx={{ borderRadius: 2 }}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PedidoArquivos;
