import React from 'react';
import { Box, Button, Typography, useTheme, alpha } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const PageHeader = ({
    title,
    actionButton = null,
    children
}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                mb: 4,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                p: 3,
                boxShadow: theme.shadows[1]
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography
                    variant="h5"
                    component="h1"
                    sx={{
                        fontWeight: 600,
                        color: 'text.primary',
                        position: 'relative',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: -8,
                            left: 0,
                            width: 80,
                            height: 3,
                            borderRadius: '2px',
                            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0)})`
                        }
                    }}
                >
                    {title}
                </Typography>

                {actionButton && (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={actionButton.onClick}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                            }
                        }}
                    >
                        {actionButton.label}
                    </Button>
                )}
            </Box>

            {children}
        </Box>
    );
};

export default PageHeader;
