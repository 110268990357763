import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Stack,
  Alert,
  AlertTitle
} from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title
} from 'chart.js';
import { Pie, Line, Bar } from "react-chartjs-2";
import api from "../api";
import DashboardStats from "../components/DashboardStats";
import PageHeader from "../components/shared/PageHeader";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title
);

const initialFilters = {
  startDate: "",
  endDate: "",
  status: "",
  clientType: ""
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

const Dashboard = () => {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [topClients, setTopClients] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  const fetchDashboardData = async (retryAttempt = 0) => {
    try {
      setError(null);
      setLoading(true);
      const [statsResponse, chartResponse, clientsResponse] = await Promise.all([
        api.get("/dashboard/statistics", { params: filters }),
        api.get("/dashboard/categories", { params: filters }),
        api.get("/dashboard/top-clients", { params: filters })
      ]);

      setStatistics(statsResponse.data);
      setChartData(chartResponse.data);
      setTopClients(clientsResponse.data);
      setRetryCount(0); // Reset retry count on success
    } catch (error) {
      console.error("Erro ao carregar dados do dashboard:", error);

      // Handle specific error cases
      if (error.response) {
        if (error.response.status === 503) {
          setError({
            title: "Erro de Conexão com o Banco de Dados",
            message: "O servidor está temporariamente indisponível. Tentando reconectar..."
          });

          // Retry logic for database connection errors
          if (retryAttempt < MAX_RETRIES) {
            setTimeout(() => {
              setRetryCount(retryAttempt + 1);
              fetchDashboardData(retryAttempt + 1);
            }, RETRY_DELAY);
            return;
          }
        } else if (error.response.status === 401) {
          setError({
            title: "Erro de Autenticação",
            message: "Sua sessão expirou. Por favor, faça login novamente."
          });
        } else {
          setError({
            title: "Erro ao Carregar Dados",
            message: error.response.data.error || "Ocorreu um erro ao carregar os dados do dashboard."
          });
        }
      } else if (error.request) {
        setError({
          title: "Erro de Conexão",
          message: "Não foi possível conectar ao servidor. Verifique sua conexão com a internet."
        });
      } else {
        setError({
          title: "Erro Inesperado",
          message: "Ocorreu um erro inesperado. Por favor, tente novamente."
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleApplyFilter = () => {
    fetchDashboardData();
  };

  const handleClearFilters = () => {
    setFilters(initialFilters);
    fetchDashboardData();
  };

  const handleRetry = () => {
    setRetryCount(0);
    fetchDashboardData();
  };

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        {retryCount > 0 && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            Tentativa {retryCount} de {MAX_RETRIES}...
          </Typography>
        )}
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={handleRetry}>
              Tentar Novamente
            </Button>
          }
        >
          <AlertTitle>{error.title}</AlertTitle>
          {error.message}
        </Alert>
      </Box>
    );
  }

  if (!statistics || !chartData || !topClients) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert severity="info">
          <AlertTitle>Sem Dados</AlertTitle>
          Não há dados disponíveis para exibição no momento.
        </Alert>
      </Box>
    );
  }

  const pieChartData = {
    labels: chartData.labels || [],
    datasets: [
      {
        data: chartData.values || [],
        backgroundColor: chartData.colors || [],
        hoverBackgroundColor: chartData.colors || [],
        borderWidth: 0,
      },
    ],
  };

  const lineChartData = {
    labels: statistics.monthlyGrowth?.map(item => item.date) || [],
    datasets: [
      {
        label: 'Faturamento Mensal',
        data: statistics.monthlyGrowth?.map(item => item.revenue) || [],
        borderColor: '#4CAF50',
        tension: 0.1,
        fill: false
      },
      {
        label: 'Quantidade de Pedidos',
        data: statistics.monthlyGrowth?.map(item => item.count) || [],
        borderColor: '#2196F3',
        tension: 0.1,
        fill: false
      }
    ]
  };

  const barChartData = {
    labels: topClients?.map(client => client.nome) || [],
    datasets: [
      {
        label: 'Total Gasto',
        data: topClients?.map(client => client.totalGasto) || [],
        backgroundColor: topClients?.map(client =>
          client.isZoomPlus ? '#4CAF50' : '#2196F3'
        ) || [],
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Estatísticas'
      }
    }
  };

  return (
    <PageHeader
      title="Dashboard"
      subtitle="Visão geral do seu negócio"
    >
      <Box>

        {/* Filters */}
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Filtros</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  label="Data Início"
                  type="date"
                  name="startDate"
                  value={filters.startDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  label="Data Fim"
                  type="date"
                  name="endDate"
                  value={filters.endDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                    label="Status"
                  >
                    <MenuItem value="">Todos</MenuItem>
                    <MenuItem value="pendente">Pendente</MenuItem>
                    <MenuItem value="em_andamento">Em Andamento</MenuItem>
                    <MenuItem value="concluido">Concluído</MenuItem>
                    <MenuItem value="cancelado">Cancelado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Cliente</InputLabel>
                  <Select
                    name="clientType"
                    value={filters.clientType}
                    onChange={handleFilterChange}
                    label="Tipo de Cliente"
                  >
                    <MenuItem value="">Todos</MenuItem>
                    <MenuItem value="zoomPlus">Zoom+</MenuItem>
                    <MenuItem value="regular">Regular</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction="row" spacing={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleApplyFilter}
                  >
                    Aplicar Filtros
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={handleClearFilters}
                  >
                    Limpar Filtros
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Statistics Cards */}
        <DashboardStats statistics={statistics} />

        {/* Charts */}
        <Grid container spacing={3} mt={3}>
          {/* Monthly Growth Chart */}
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Evolução Mensal</Typography>
                <Box height={300}>
                  <Line data={lineChartData} options={chartOptions} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Services Distribution */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Distribuição de Serviços</Typography>
                <Box height={300}>
                  <Pie data={pieChartData} options={chartOptions} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Top Clients */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Top 10 Clientes</Typography>
                <Box height={300}>
                  <Bar data={barChartData} options={chartOptions} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Performance Metrics */}
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Métricas de Desempenho</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" color="textSecondary">
                  Taxa de Sucesso dos Pedidos
                </Typography>
                <Typography variant="h5">
                  {(statistics.successRate || 0).toFixed(1)}%
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" color="textSecondary">
                  Taxa de Retenção de Clientes
                </Typography>
                <Typography variant="h5">
                  {(statistics.retentionRate || 0).toFixed(1)}%
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" color="textSecondary">
                  Tempo Médio de Conclusão
                </Typography>
                <Typography variant="h5">
                  {(statistics.avgCompletionTime || 0).toFixed(1)} horas
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </PageHeader>
  );
};

export default Dashboard;
