import React from 'react';
import {
    Box,
    Typography,
    LinearProgress,
    Paper,
    Stack,
    Chip,
    useTheme,
} from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

const FileProgress = ({ file }) => (
    <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
            <Typography variant="body2" noWrap sx={{ maxWidth: '80%' }}>
                {file.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {file.progress}%
            </Typography>
        </Box>
        <LinearProgress
            variant="determinate"
            value={file.progress}
            sx={{
                height: 4,
                borderRadius: 2,
                bgcolor: 'action.hover',
                '& .MuiLinearProgress-bar': {
                    borderRadius: 2,
                    bgcolor: file.progress === 100 ? 'success.main' : 'primary.main',
                }
            }}
        />
    </Box>
);

const UploadProgressBar = ({ progress }) => {
    const theme = useTheme();

    if (!progress) return null;

    return (
        <Paper
            elevation={0}
            sx={{
                p: 2,
                mb: 3,
                borderRadius: 3,
                bgcolor: 'background.default',
                border: '1px solid',
                borderColor: theme.palette.divider,
            }}
        >
            <Stack spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" fontWeight={500}>
                        Progresso do Upload
                    </Typography>
                    <Chip
                        label={`${progress.completed} de ${progress.total} arquivos`}
                        color="primary"
                        size="small"
                        sx={{ borderRadius: 2 }}
                    />
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                        <Typography variant="body2" color="textSecondary">
                            {progress.progress}% concluído
                        </Typography>
                        {progress.progress === 100 && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <CheckCircleOutline color="success" fontSize="small" />
                                <Typography variant="body2" color="success.main">
                                    Upload concluído
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <LinearProgress
                        variant="determinate"
                        value={progress.progress}
                        sx={{
                            height: 8,
                            borderRadius: 4,
                            bgcolor: 'action.hover',
                            '& .MuiLinearProgress-bar': {
                                borderRadius: 4,
                                bgcolor: progress.progress === 100 ? 'success.main' : 'primary.main',
                            }
                        }}
                    />
                </Box>

                {progress.files?.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                            Arquivos em processamento:
                        </Typography>
                        <Stack spacing={1}>
                            {progress.files.map(file => (
                                <FileProgress key={file.id} file={file} />
                            ))}
                        </Stack>
                    </Box>
                )}
            </Stack>
        </Paper>
    );
};

export default UploadProgressBar;
