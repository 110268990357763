import React, { useState } from 'react';
import {
    Paper,
    TextField,
    Button,
    Box,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import axios from '../../api';

const CreateListContainer = styled(Paper)(({ theme }) => ({
    width: 280,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(1)
}));

const CreateList = ({ boardId, onCancel, onSuccess }) => {
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title.trim()) {
            setError('Título é obrigatório');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`/boards/${boardId}/lists`, {
                titulo: title.trim()
            });

            setTitle('');
            setError('');
            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (err) {
            console.error('Error creating list:', err);
            setError('Erro ao criar lista');
        } finally {
            setLoading(false);
        }
    };

    return (
        <CreateListContainer>
            <form onSubmit={handleSubmit}>
                <Box mb={1}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Digite o título da lista..."
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        error={Boolean(error)}
                        helperText={error}
                        autoFocus
                        disabled={loading}
                    />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        disabled={loading}
                    >
                        Adicionar Lista
                    </Button>
                    <IconButton
                        size="small"
                        onClick={onCancel}
                        disabled={loading}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </form>
        </CreateListContainer>
    );
};

export default CreateList;
