import axios from "axios";

const getBaseURL = () => {
  if (process.env.NODE_ENV === 'production') {
    return window.location.origin + '/api';
  }
  return 'http://localhost:5000/api';
};

const api = axios.create({
  baseURL: getBaseURL(),
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to clear all auth data
const clearAuthData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("userId");
  window.location.href = "/";
};

// Adicionar token JWT a cada requisição
api.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Server responded with error status
      if (error.response.status === 503) {
        // Database connection error
        console.error("Database connection error:", error.response.data);
      } else if (error.response.status === 401) {
        // Authentication error - clear all auth data and redirect to login
        clearAuthData();
      }
    } else if (error.request) {
      // Request made but no response received
      console.error("No response received:", error.request);
    } else {
      // Error in request setup
      console.error("Request setup error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
