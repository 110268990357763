import { useState, useCallback } from 'react';

const INITIAL_ALERTA = {
    mensagem: "",
    tipo: "info",
    aberto: false
};

export const useAlerta = () => {
    const [alerta, setAlerta] = useState(INITIAL_ALERTA);

    const showAlerta = useCallback((mensagem, tipo = "info") => {
        setAlerta({
            mensagem,
            tipo,
            aberto: true
        });
    }, []);

    const hideAlerta = useCallback(() => {
        setAlerta(prev => ({
            ...prev,
            aberto: false
        }));
    }, []);

    const showError = useCallback((error) => {
        const mensagem = error.response?.data?.message || error.message || "Ocorreu um erro";
        showAlerta(mensagem, "error");
    }, [showAlerta]);

    const showSuccess = useCallback((mensagem) => {
        showAlerta(mensagem, "success");
    }, [showAlerta]);

    return {
        alerta,
        setAlerta,
        showAlerta,
        hideAlerta,
        showError,
        showSuccess
    };
};
