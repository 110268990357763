import React, { useEffect, useRef } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArchiveIcon from '@mui/icons-material/Archive';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const getFileIcon = (arquivo, theme, isMobile) => {
    const iconSize = isMobile ? 36 : 48;
    if (arquivo.isFolder) return <FolderIcon sx={{ fontSize: iconSize, color: theme.palette.warning.main }} />;
    if (arquivo.tipo?.startsWith('image/')) {
        if (arquivo.tipo === 'image/gif') {
            return <PhotoLibraryIcon sx={{ fontSize: iconSize, color: theme.palette.warning.main }} />;
        }
        return <ImageIcon sx={{ fontSize: iconSize, color: theme.palette.primary.main }} />;
    }
    if (arquivo.tipo?.startsWith('video/')) return <VideoFileIcon sx={{ fontSize: iconSize, color: theme.palette.success.main }} />;
    if (arquivo.tipo?.startsWith('audio/')) return <AudioFileIcon sx={{ fontSize: iconSize, color: theme.palette.info.main }} />;
    if (arquivo.tipo === 'application/pdf') return <PictureAsPdfIcon sx={{ fontSize: iconSize, color: theme.palette.error.main }} />;
    if (arquivo.tipo?.includes('zip') || arquivo.tipo?.includes('rar')) return <ArchiveIcon sx={{ fontSize: iconSize, color: theme.palette.warning.main }} />;
    return <InsertDriveFileIcon sx={{ fontSize: iconSize, color: theme.palette.action.active }} />;
};

const ProtectedImage = ({ src, alt, isPendente, showPreviewText }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas || !src) return;

        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = () => {
            // Define um tamanho mínimo para o canvas
            canvas.width = Math.max(img.width, 1);
            canvas.height = Math.max(img.height, 1);

            // Ajusta o canvas para manter a proporção da imagem
            const containerWidth = canvas.parentElement.clientWidth || 100;
            const containerHeight = canvas.parentElement.clientHeight || 100;
            const scale = Math.min(containerWidth / img.width, containerHeight / img.height);

            canvas.width = Math.max(img.width * scale, 1);
            canvas.height = Math.max(img.height * scale, 1);

            // Desenha a imagem em baixa qualidade
            ctx.imageSmoothingEnabled = false;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            if (isPendente) {
                // Aplica efeito de blur
                ctx.filter = 'blur(1px)';
                ctx.drawImage(canvas, 0, 0);

                // Reduz a qualidade
                canvas.style.imageRendering = 'pixelated';
            }
        };

        img.src = src;

        // Previne comportamentos padrão apenas no canvas
        const preventDefault = (e) => e.preventDefault();
        canvas.addEventListener('contextmenu', preventDefault);
        canvas.addEventListener('dragstart', preventDefault);
        canvas.addEventListener('selectstart', preventDefault);
        canvas.addEventListener('copy', preventDefault);

        return () => {
            canvas.removeEventListener('contextmenu', preventDefault);
            canvas.removeEventListener('dragstart', preventDefault);
            canvas.removeEventListener('selectstart', preventDefault);
            canvas.removeEventListener('copy', preventDefault);
        };
    }, [src, isPendente]);

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <canvas
                ref={canvasRef}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                }}
            />
            {isPendente && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: isMobile ? 0.5 : 1,
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        px: isMobile ? 1.5 : 2,
                        py: isMobile ? 0.5 : 1,
                        borderRadius: 1,
                        pointerEvents: 'none',
                        zIndex: 1,
                    }}
                >
                    <LockOutlinedIcon fontSize={isMobile ? "small" : "medium"} />
                    {showPreviewText && (
                        <Box component="span" sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                            Prévia
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

const FilePreview = ({ arquivo, pedido, onImageError, onPreviewClick, isAdmin, showPreviewText = false }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPreviewable = arquivo.tipo?.startsWith('image/') || arquivo.tipo?.startsWith('video/') || arquivo.tipo === 'application/pdf';
    const isPendente = pedido?.statusPagamento === 'pendente' && !isAdmin;

    if (!arquivo.isFolder && arquivo.tipo?.startsWith('image/')) {
        const thumbnailUrl = `/storage/pedidos/${arquivo.pedidoId}/thumbnails/${arquivo.path}.jpg`;

        return (
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    pt: '100%',
                    overflow: 'hidden',
                    borderRadius: 2,
                    bgcolor: theme.palette.background.default,
                    cursor: isPreviewable ? 'pointer' : 'default',
                    transition: 'transform 0.2s',
                    '&:hover': {
                        transform: isPreviewable && !isMobile ? 'scale(1.02)' : 'none',
                    },
                    '&:active': {
                        transform: isPreviewable && isMobile ? 'scale(0.98)' : 'none',
                    }
                }}
                onClick={() => isPreviewable && onPreviewClick(arquivo)}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                    }}
                >
                    <ProtectedImage
                        src={thumbnailUrl}
                        alt={arquivo.nome}
                        isPendente={isPendente}
                        showPreviewText={showPreviewText}
                    />
                </Box>
            </Box>
        );
    }

    if (!arquivo.isFolder && arquivo.tipo?.startsWith('video/')) {
        const thumbnailUrl = `/storage/pedidos/${arquivo.pedidoId}/thumbnails/${arquivo.path.split('/').pop()}.jpg`;

        return (
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    pt: '100%',
                    overflow: 'hidden',
                    borderRadius: 2,
                    bgcolor: theme.palette.background.default,
                    cursor: isPreviewable ? 'pointer' : 'default',
                    transition: 'transform 0.2s',
                    '&:hover': {
                        transform: isPreviewable && !isMobile ? 'scale(1.02)' : 'none',
                    },
                    '&:active': {
                        transform: isPreviewable && isMobile ? 'scale(0.98)' : 'none',
                    }
                }}
                onClick={() => isPreviewable && onPreviewClick(arquivo)}
            >
                <Box
                    component="img"
                    src={thumbnailUrl}
                    alt={arquivo.nome}
                    onError={() => onImageError(arquivo._id)}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        filter: isPendente ? 'blur(1px)' : 'none',
                        zIndex: 1,
                    }}
                />
                {isPendente && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: isMobile ? 0.5 : 1,
                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            px: isMobile ? 1.5 : 2,
                            py: isMobile ? 0.5 : 1,
                            borderRadius: 1,
                            pointerEvents: 'none',
                            zIndex: 1,
                        }}
                    >
                        <LockOutlinedIcon fontSize={isMobile ? "small" : "medium"} />
                        {showPreviewText && (
                            <Box component="span" sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                                Prévia
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        );
    }

    if (!arquivo.isFolder && arquivo.tipo === 'application/pdf') {
        const thumbnailUrl = process.env.NODE_ENV === 'production'
            ? `/storage/pedidos/${arquivo.pedidoId}/thumbnails/${arquivo.path.split('/').pop()}.jpg`
            : `http://localhost:5000/api/storage/pedidos/${arquivo.pedidoId}/thumbnails/${arquivo.path.split('/').pop()}.jpg`;

        return (
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    pt: '100%',
                    overflow: 'hidden',
                    borderRadius: 2,
                    bgcolor: theme.palette.background.default,
                    cursor: isPreviewable ? 'pointer' : 'default',
                    transition: 'transform 0.2s',
                    '&:hover': {
                        transform: isPreviewable && !isMobile ? 'scale(1.02)' : 'none',
                    },
                    '&:active': {
                        transform: isPreviewable && isMobile ? 'scale(0.98)' : 'none',
                    }
                }}
                onClick={() => isPreviewable && onPreviewClick(arquivo)}
            >
                <Box
                    component="img"
                    src={thumbnailUrl}
                    alt={arquivo.nome}
                    onError={() => onImageError(arquivo._id)}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        filter: isPendente ? 'blur(2px)' : 'none',
                        zIndex: 1,
                    }}
                />
                {isPendente && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: isMobile ? 0.5 : 1,
                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            px: isMobile ? 1.5 : 2,
                            py: isMobile ? 0.5 : 1,
                            borderRadius: 1,
                            pointerEvents: 'none',
                            zIndex: 1,
                        }}
                    >
                        <LockOutlinedIcon fontSize={isMobile ? "small" : "medium"} />
                        {showPreviewText && (
                            <Box component="span" sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                                Prévia
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                pt: '100%',
                borderRadius: 2,
                bgcolor: theme.palette.background.default,
                cursor: arquivo.isFolder ? 'pointer' : 'default',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: arquivo.isFolder && !isMobile ? 'scale(1.02)' : 'none',
                },
                '&:active': {
                    transform: arquivo.isFolder && isMobile ? 'scale(0.98)' : 'none',
                }
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                }}
            >
                {getFileIcon(arquivo, theme, isMobile)}
                {!arquivo.isFolder && isPendente && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: isMobile ? 0.5 : 1,
                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            px: isMobile ? 1.5 : 2,
                            py: isMobile ? 0.5 : 1,
                            borderRadius: 1,
                            pointerEvents: 'none',
                            zIndex: 1,
                        }}
                    >
                        <LockOutlinedIcon fontSize={isMobile ? "small" : "medium"} />
                        {showPreviewText && (
                            <Box component="span" sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                                Prévia
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default FilePreview;
