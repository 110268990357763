import React, { useMemo, useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import { Box, Snackbar, Alert } from '@mui/material';
import PaymentsFilterBar from './PaymentsFilterBar';
import TabelaReutilizavel from './shared/table/TabelaReutilizavel';
import TableSkeleton from './shared/TableSkeleton';
import PaymentDetailsDialog from './PaymentDetailsDialog';
import PaginationControls from './shared/PaginationControls';
import { getStatusPagamentoLabel } from '../utils/pedidoUtils';
import { usePaymentHandling } from '../hooks/usePaymentHandling';
import { formatarDataComFuso } from '../utils/dateUtils';

const PaymentsTable = ({
    data = [],
    loading = false,
    page = 1,
    limit = 10,
    totalCount = 0,
    filters = {},
    onFilterChange,
    onPageChange,
    onLimitChange,
    showFilters = true,
    showPagination = true,
    filterOptions,
    readOnly = false,
}) => {
    const [alerta, setAlerta] = useState({ mensagem: '', tipo: 'info', aberto: false });

    const {
        paymentDetails,
        showPaymentDetails,
        handlePaymentAction: originalHandlePaymentAction,
        setShowPaymentDetails,
    } = usePaymentHandling(() => { }, setAlerta);

    // Wrap handlePaymentAction to include the data array
    const handlePaymentAction = useCallback((paymentId, action) => {
        if (!data) return;
        originalHandlePaymentAction(paymentId, action, data);
    }, [originalHandlePaymentAction, data]);

    const handleDetalhes = useCallback((payment) => {
        if (!payment) return;
        const paymentId = payment.asaas?.paymentId || payment.ID;
        handlePaymentAction(paymentId, 'view');
    }, [handlePaymentAction]);

    const handleCloseAlert = useCallback(() => {
        setAlerta(prev => ({ ...prev, aberto: false }));
    }, []);

    const cabecalhos = [
        'ID',
        'Cliente',
        'Origem',
        'Data Cadastro',
        'Data Vencimento',
        'Total',
        'Pagamento',
        'Ações de Pagamento'
    ];

    const getPaymentOrigin = (payment) => {
        if (!payment) return '-';

        if (payment.subscription || payment.externalReference?.startsWith('subscription-')) {
            return 'Assinatura';
        }
        if (payment.description?.toLowerCase().includes('pedido')) {
            const orderId = payment.externalReference || '';
            return (
                <Box component={Link} to={`/visualizar-pedido/${orderId}`} color="primary.main">
                    Pedido
                </Box>
            );
        }
        return '-';
    };

    const transformedData = useMemo(() => {
        if (!Array.isArray(data)) {
            return [];
        }

        return data.map(payment => {
            if (!payment) return null;

            return {
                ID: payment.externalReference?.slice(-4).toUpperCase() || '',
                Cliente: payment.customerDetails?.name || payment.customer?.name || '',
                Origem: getPaymentOrigin(payment),
                DataCadastro: formatarDataComFuso(payment.dateCreated),
                DataVencimento: formatarDataComFuso(payment.dueDate),
                Total: payment.value ? `R$ ${Number(payment.value).toFixed(2)}` : 'R$ 0,00',
                Pagamento: getStatusPagamentoLabel(
                    payment.status || payment.asaas?.paymentStatus,
                    payment.value,
                    payment.status || payment.asaas?.paymentStatus
                ),
                asaas: {
                    paymentId: payment.id || payment._id || payment.asaas?.paymentId,
                    paymentStatus: payment.status || payment.asaas?.paymentStatus,
                    customerId: payment.customer?.id || payment.asaas?.customerId,
                    notaFiscalId: payment.asaas?.notaFiscalId || payment.notaFiscalId
                },
                _raw: {
                    ...payment,
                    asaas: {
                        ...payment.asaas,
                        notaFiscalId: payment.asaas?.notaFiscalId || payment.notaFiscalId,
                        paymentStatus: payment.status || payment.asaas?.paymentStatus
                    }
                }
            };
        }).filter(Boolean);
    }, [data]);

    return (
        <Box sx={{ position: 'relative', minHeight: '200px' }}>
            {showFilters && filterOptions && (
                <Box sx={{ mb: 3 }}>
                    <PaymentsFilterBar
                        filters={filters}
                        onFilterChange={onFilterChange}
                        filterOptions={filterOptions}
                    />
                </Box>
            )}

            <Box sx={{ position: 'relative' }}>
                <Box sx={{ mt: 3 }}>
                    <TabelaReutilizavel
                        cabecalhos={cabecalhos}
                        dados={transformedData}
                        handleDetalhes={handleDetalhes}
                        handlePaymentAction={handlePaymentAction}
                        isLoading={loading}
                        skeletonComponent={<TableSkeleton columns={cabecalhos.length} />}
                        readOnly={readOnly}
                    />
                </Box>

                {showPagination && transformedData.length > 0 && (
                    <PaginationControls
                        page={Math.max(1, Number(page))}
                        limit={Number(limit)}
                        total={Number(totalCount)}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                        loading={loading}
                        itemsPerPageOptions={[5, 10, 25, 50]}
                    />
                )}
            </Box>

            <PaymentDetailsDialog
                open={showPaymentDetails}
                onClose={() => setShowPaymentDetails(false)}
                payment={paymentDetails}
            />

            <Snackbar
                open={alerta.aberto}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseAlert} severity={alerta.tipo} sx={{ width: '100%' }}>
                    {alerta.mensagem}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default React.memo(PaymentsTable);
