import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';

const ManipulacaoSection = () => (
    <>
        <Grid item xs={12} md={6}>
            <Paper sx={{
                p: 4,
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{
                        color: '#eb5b25',
                        mb: 1,
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        Troca de cor
                    </Typography>
                    <Typography variant="h4" sx={{
                        color: 'white',
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        R$ 60,00
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h6" sx={{
                        color: '#eb5b25',
                        mb: 1,
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        Remoção
                    </Typography>
                    <Typography variant="h4" sx={{
                        color: 'white',
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        R$ 60,00
                    </Typography>
                </Box>
            </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box sx={{
                p: 4,
                height: '100%',
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <Edit sx={{
                    fontSize: 60,
                    color: '#eb5b25',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    }
                }} />
            </Box>
        </Grid>
    </>
);

export default ManipulacaoSection;
