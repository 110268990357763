import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    CircularProgress,
    useTheme,
    Paper
} from '@mui/material';
import UserOrders from '../../components/user/UserOrders';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { getUserId } from '../../utils/auth';
import PaginationControls from '../../components/shared/PaginationControls';
import SectionTitle from '../../components/SectionTitle';

const UserOrdersPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [pedidos, setPedidos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);

    const fetchPedidos = async (currentPage, itemsPerPage) => {
        try {
            setLoading(true);
            const userId = getUserId();
            const response = await api.get('/pedidos', {
                params: {
                    clienteId: userId,
                    page: currentPage,
                    limit: itemsPerPage,
                    orderBy: 'createdAt',
                    orderDirection: 'desc'
                }
            });

            // Update state with the response data
            setPedidos(response.data?.pedidos || []);
            setTotal(response.data?.pagination?.total || 0);
        } catch (error) {
            console.error("Erro ao carregar pedidos:", error);
            setError("Erro ao carregar pedidos. Por favor, tente novamente.");
            setPedidos([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPedidos(page, limit);
    }, [page, limit]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(1); // Reset to first page when changing items per page
    };

    const handleViewOrder = (orderId) => {
        navigate(`/pedido/${orderId}`);
    };

    if (loading && pedidos.length === 0) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                bgcolor: theme.palette.background.default
            }}>
                <CircularProgress sx={{ color: theme.palette.primary.main }} />
            </Box>
        );
    }

    return (
        <Box sx={{
            minHeight: "100vh",
            bgcolor: theme.palette.background.default,
            display: 'flex',
            position: 'relative',
        }}>
            <Box sx={{
                flexGrow: 1,
                minHeight: '100vh',
                p: 3,
                bgcolor: theme.palette.background.default
            }}>
                <Container maxWidth="lg">
                    <SectionTitle
                        variant="h4"
                        mb={6}
                        width={230}
                    >
                        Meus Pedidos
                    </SectionTitle>

                    <Paper
                        elevation={1}
                        sx={{
                            borderRadius: 2,
                            overflow: 'hidden',
                            bgcolor: theme.palette.background.paper
                        }}
                    >
                        {error ? (
                            <Typography
                                sx={{
                                    p: 3,
                                    color: theme.palette.error.main,
                                    fontFamily: theme.typography.fontFamily
                                }}
                            >
                                {error}
                            </Typography>
                        ) : (
                            <>
                                <UserOrders
                                    pedidos={pedidos}
                                    onViewOrder={handleViewOrder}
                                />
                                <Box sx={{ p: 2 }}>
                                    <PaginationControls
                                        page={page}
                                        limit={limit}
                                        total={total}
                                        onPageChange={handlePageChange}
                                        onLimitChange={handleLimitChange}
                                        loading={loading}
                                    />
                                </Box>
                            </>
                        )}
                    </Paper>
                </Container>
            </Box>
        </Box>
    );
};

export default UserOrdersPage;
