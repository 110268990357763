import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Collapse,
  CircularProgress,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import api from "../api";
import ListaClientes from "../components/ListaClientes";
import BasicInfoForm from "../components/cliente/BasicInfoForm";
import PlanConfigForm from "../components/cliente/PlanConfigForm";
import useClienteForm from "../hooks/useClienteForm";
import AlertaReutilizavel from "../components/shared/AlertaReutilizavel";
import PageHeader from "../components/shared/PageHeader";

const CadastroClientes = () => {
  const [planos, setPlanos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
  const [loading, setLoading] = useState(false);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // Add refresh key for ListaClientes

  const {
    cliente,
    formErrors,
    selectedPlano,
    handleFieldChange,
    validateForm,
    resetForm
  } = useClienteForm();

  // Função para buscar clientes
  const fetchClientes = async () => {
    try {
      setLoadingClientes(true);
      const response = await api.get("/clientes");
      setClientes(response.data);
    } catch (error) {
      console.error("Erro ao carregar clientes:", error);
      setAlerta({
        mensagem: "Erro ao carregar clientes: " + (error.response?.data?.error || error.message),
        tipo: "error",
        aberto: true
      });
    } finally {
      setLoadingClientes(false);
    }
  };

  // Função para buscar planos
  const fetchPlanos = async () => {
    try {
      const response = await api.get("/planos");
      setPlanos(response.data);
    } catch (error) {
      console.error("Erro ao carregar planos:", error);
      setAlerta({
        mensagem: "Erro ao carregar planos: " + (error.response?.data?.error || error.message),
        tipo: "error",
        aberto: true
      });
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoadingClientes(true);
      try {
        await Promise.all([fetchClientes(), fetchPlanos()]);
      } finally {
        setLoadingClientes(false);
      }
    };

    loadInitialData();
  }, []);

  // Função para deletar cliente
  const handleDelete = async (id) => {
    try {
      await api.delete(`/clientes/${id}`);
      setAlerta({ mensagem: "Cliente excluído com sucesso!", tipo: "success", aberto: true });
      setRefreshKey(prev => prev + 1); // Trigger refresh after deletion
    } catch (error) {
      console.error("Erro ao excluir cliente:", error);
      setAlerta({
        mensagem: "Erro ao excluir cliente: " + (error.response?.data?.error || error.message),
        tipo: "error",
        aberto: true
      });
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setAlerta({
        mensagem: "Por favor, corrija os erros no formulário antes de continuar.",
        tipo: "error",
        aberto: true,
      });
      return;
    }

    try {
      setLoading(true);
      const clienteData = { ...cliente };

      // Remove campos relacionados ao plano se não for assinante Zoom+
      if (!cliente.isZoomPlus) {
        delete clienteData.planoId;
        delete clienteData.dataInicioPlano;
        delete clienteData.dataFinalPlano;
        delete clienteData.saldo;
        delete clienteData.taxasUrgenciaDisponiveis;
        delete clienteData.usoModelosDisponiveis;
        delete clienteData.diaVencimento;
        delete clienteData.valorMensalidade;
      } else {
        // Calcula informações adicionais do plano Zoom+
        const planoSelecionado = planos.find((plano) => plano._id === cliente.planoId);
        if (!planoSelecionado) {
          setAlerta({
            mensagem: "Plano selecionado não encontrado.",
            tipo: "error",
            aberto: true,
          });
          return;
        }

        const dataInicio = new Date(cliente.dataInicioPlano);
        const dataFinal = new Date(dataInicio);
        dataFinal.setMonth(dataFinal.getMonth() + 6);

        clienteData.saldo = planoSelecionado.saldoBase;
        clienteData.taxasUrgenciaDisponiveis = planoSelecionado.taxasUrgenciasGratis;
        clienteData.usoModelosDisponiveis = planoSelecionado.modeloCompleta;
        clienteData.dataFinalPlano = dataFinal.toISOString().split("T")[0];
        clienteData.diaVencimento = dataInicio.getUTCDate();
        clienteData.planoNome = planoSelecionado.nome;
        clienteData.valorMensalidade = planoSelecionado.valorMensalidade;
      }

      await api.post("/clientes", clienteData);

      setAlerta({
        mensagem: "Cliente cadastrado com sucesso!",
        tipo: "success",
        aberto: true,
      });

      resetForm();
      setRefreshKey(prev => prev + 1); // Trigger refresh after successful creation
      setShowForm(false);
    } catch (error) {
      setAlerta({
        mensagem: "Erro ao cadastrar cliente: " + (error.response?.data?.error || error.message),
        tipo: "error",
        aberto: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelForm = () => {
    setShowForm(false);
    resetForm();
    setAlerta({ mensagem: "", tipo: "info", aberto: false });
  };

  const fecharAlerta = () => {
    setAlerta(prev => ({ ...prev, aberto: false }));
  };

  // Handle plan selection to auto-populate fields
  const handlePlanChange = (field, value) => {
    handleFieldChange(field, value);

    if (field === 'planoId' && value) {
      const selectedPlan = planos.find(plano => plano._id === value);
      if (selectedPlan) {
        // Auto-populate fields from selected plan
        handleFieldChange('saldo', selectedPlan.saldoBase);
        handleFieldChange('taxasUrgenciaDisponiveis', selectedPlan.taxasUrgenciasGratis);
        handleFieldChange('usoModelosDisponiveis', selectedPlan.modeloCompleta);
        handleFieldChange('valorMensalidade', selectedPlan.valorMensalidade);
      }
    }
  };

  return (
    <PageHeader
      title="Cadastro de Clientes"
    >
      <Box sx={{ width: '100%' }}>
        <Box p={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
          >
            <Button
              variant="contained"
              color={showForm ? "error" : "primary"}
              startIcon={showForm ? <CloseIcon /> : <AddIcon />}
              onClick={() => showForm ? handleCancelForm() : setShowForm(true)}
              disabled={loading}
            >
              {showForm ? "Cancelar Cadastro" : "Novo Cliente"}
            </Button>
          </Box>

          <AlertaReutilizavel
            mensagem={alerta.mensagem}
            tipo={alerta.tipo}
            aberto={alerta.aberto}
            onFechar={fecharAlerta}
          />

          <Collapse in={showForm}>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Cadastro de Novo Cliente
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {/* Informações Básicas */}
                  <Grid item xs={12}>
                    <BasicInfoForm
                      cliente={cliente}
                      onChange={handleFieldChange}
                      formErrors={formErrors}
                    />
                  </Grid>

                  {/* Configurações do Plano */}
                  <Grid item xs={12}>
                    <PlanConfigForm
                      cliente={cliente}
                      onChange={handlePlanChange}
                      planos={planos}
                      formErrors={formErrors}
                      selectedPlano={selectedPlano}
                    />
                  </Grid>

                  {/* Botões de Ação */}
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      gap={2}
                      sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                    >
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={handleCancelForm}
                        disabled={loading}
                        fullWidth={false}
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        fullWidth={false}
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Cadastrar Cliente"
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Collapse>

          <ListaClientes
            clientes={clientes}
            onDelete={handleDelete}
            loading={loadingClientes}
            key={refreshKey} // Add key prop to force re-render
          />
        </Box>
      </Box>
    </PageHeader>
  );
};

export default CadastroClientes;
