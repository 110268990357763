import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Chip,
    useTheme,
    Skeleton,
    Alert,
    Button,
} from '@mui/material';
import {
    AutorenewRounded,
    CheckCircleOutline,
    CalendarToday,
    AttachMoney,
    CreditCard,
    Receipt,
} from '@mui/icons-material';
import { formatarDataComFuso } from '../../utils/dateUtils';

const SubscriptionInfo = ({ subscription, loading, error }) => {
    const theme = useTheme();

    if (error) {
        return (
            <Alert
                severity="error"
                sx={{
                    mb: 4,
                    borderRadius: 2,
                    backgroundColor: theme.palette.error.lighter,
                    color: theme.palette.error.darker
                }}
            >
                {error}
            </Alert>
        );
    }

    if (loading) {
        return (
            <Card
                elevation={0}
                sx={{
                    mb: 4,
                    borderRadius: 2,
                    border: `1px solid ${theme.palette.divider}`,
                    bgcolor: theme.palette.background.paper,
                }}
            >
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Skeleton variant="text" width="60%" height={32} />
                        <Skeleton variant="text" width="40%" height={24} />
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Skeleton variant="rounded" width={120} height={32} />
                            <Skeleton variant="rounded" width={120} height={32} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    }

    if (!subscription) {
        return (
            <Card
                elevation={0}
                sx={{
                    mb: 4,
                    borderRadius: 2,
                    border: `1px dashed ${theme.palette.divider}`,
                    bgcolor: theme.palette.background.paper,
                }}
            >
                <CardContent>
                    <Box sx={{ textAlign: 'center', py: 2 }}>
                        <Typography color="text.secondary">
                            Nenhuma assinatura ativa encontrada
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        );
    }

    // Usar dueDate se nextDueDate não estiver disponível
    const nextDueDate = (subscription.nextDueDate || subscription.dueDate)
        ? formatarDataComFuso(subscription.nextDueDate || subscription.dueDate)
        : 'Não definida';

    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(subscription.value || 0);

    return (
        <Card
            elevation={0}
            sx={{
                mb: 4,
                borderRadius: 2,
                border: `1px solid ${theme.palette.divider}`,
                bgcolor: theme.palette.background.paper,
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: theme.shadows[2]
                }
            }}
        >
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
                    <Box>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 600,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                            }}
                        >
                            <AutorenewRounded color="primary" />
                            Assinatura Ativa
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {subscription.description || subscription.planoInfo?.nome || 'Plano Padrão'}
                        </Typography>
                    </Box>
                    <Chip
                        icon={<CheckCircleOutline />}
                        label="Ativo"
                        color="success"
                        sx={{ fontWeight: 500 }}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' },
                        gap: 3,
                        mb: 3
                    }}
                >
                    <Box>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                            }}
                        >
                            <AttachMoney fontSize="small" />
                            Valor Mensal
                        </Typography>
                        <Typography variant="h6" color="success.main" fontWeight={600}>
                            {formattedValue}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                            }}
                        >
                            <CalendarToday fontSize="small" />
                            Próximo Vencimento
                        </Typography>
                        <Typography variant="h6" fontWeight={500}>
                            {nextDueDate}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                            }}
                        >
                            <CreditCard fontSize="small" />
                            Forma de Pagamento
                        </Typography>
                        <Typography variant="h6" fontWeight={500}>
                            {subscription.billingType === 'CREDIT_CARD'
                                ? 'Cartão de Crédito'
                                : subscription.billingType === 'BOLETO'
                                    ? 'Boleto'
                                    : subscription.billingType === 'PIX'
                                        ? 'PIX'
                                        : 'Não definida'
                            }
                        </Typography>
                    </Box>
                </Box>

                {subscription.invoiceUrl && (
                    <Button
                        variant="outlined"
                        size="medium"
                        startIcon={<Receipt />}
                        href={subscription.invoiceUrl}
                        target="_blank"
                        fullWidth
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 500
                        }}
                    >
                        Ver Última Fatura
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default React.memo(SubscriptionInfo);
