
import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { Movie } from '@mui/icons-material';

const ClipsSection = () => (
    <>
        <Grid item xs={12} md={6}>
            <Paper sx={{
                p: 4,
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <Typography variant="h6" sx={{
                    color: 'white',
                    mb: 3,
                    fontFamily: '"Poppins", sans-serif'
                }}>
                    Benefícios:
                </Typography>
                <Box component="ul" sx={{
                    color: 'rgba(255,255,255,0.7)',
                    fontFamily: '"Inter", sans-serif'
                }}>
                    <Typography component="li" sx={{ mb: 2 }}>
                        Aumente em 5x as visitas aos seus anúncios
                    </Typography>
                    <Typography component="li" sx={{ mb: 2 }}>
                        Atrai novos compradores
                    </Typography>
                    <Typography component="li" sx={{ mb: 2 }}>
                        Alcance direto aos clientes potenciais
                    </Typography>
                    <Typography component="li">
                        Ferramenta 100% gratuita para vendedores
                    </Typography>
                </Box>
            </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box sx={{
                p: 4,
                height: '100%',
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <Movie sx={{
                    fontSize: 60,
                    color: '#eb5b25',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    }
                }} />
            </Box>
        </Grid>
    </>
);

export default ClipsSection;
