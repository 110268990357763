// calculations.js
export const calcularTotais = (pedido, clienteZoomPlus, isEdit = true) => {
  if (!pedido || !pedido.servicos) return totaisIniciais;

  // Cálculo do total base dos serviços
  const total = pedido.servicos.reduce((acc, servico) => acc + servico.subtotal, 0);

  // Verifica se taxa de urgência grátis está sendo utilizada
  const taxaUrgenciaGratis = pedido.zoomPlus?.beneficiosUtilizados?.taxaUrgenciaGratis || false;

  // Cálculo da taxa de urgência
  const taxaUrgencia = pedido.isUrgent && !taxaUrgenciaGratis ? total * 0.4 : 0;

  // Total com urgência
  const totalComUrgencia = total + taxaUrgencia;

  // Cálculo do desconto do plano
  let desconto = 0;
  if (clienteZoomPlus?.isZoomPlus && clienteZoomPlus?.plano?.porcentagemDesconto) {
    desconto = totalComUrgencia * (clienteZoomPlus.plano.porcentagemDesconto / 100);
  }

  // Total após descontos do plano
  const totalFinal = totalComUrgencia - desconto;

  // Cálculos relacionados ao saldo
  let saldoUtilizado = pedido.zoomPlus?.saldoUtilizado || 0;
  let saldoRestante = clienteZoomPlus?.saldo || 0;
  let valorPagar = totalFinal;
  let statusPagamento = pedido.statusPagamento || "pendente";

  // Em modo de edição, adicionar o saldo utilizado anteriormente de volta ao saldo disponível
  if (isEdit && pedido.zoomPlus?.saldoUtilizado) {
    saldoRestante += pedido.zoomPlus.saldoUtilizado;
  }

  // Se o cliente é Zoom+ e tem saldo disponível
  if (clienteZoomPlus?.isZoomPlus && saldoRestante > 0) {
    if (saldoRestante >= totalFinal) {
      saldoUtilizado = totalFinal;
      saldoRestante = saldoRestante - totalFinal;
      valorPagar = 0;
      statusPagamento = "pago_com_saldo";
    } else {
      saldoUtilizado = saldoRestante;
      saldoRestante = 0;
      valorPagar = totalFinal - saldoUtilizado;
      statusPagamento = "pago_parcial_saldo";
    }
  }

  return {
    total,
    taxaUrgencia,
    totalComUrgencia,
    desconto,
    totalFinal,
    saldoUtilizado,
    saldoRestante,
    valorPagar,
    statusPagamento,
    descontoAplicado: desconto,
    taxaUrgenciaGratis,
    modeloCompletaGratis: pedido.zoomPlus?.beneficiosUtilizados?.modeloCompletaGratis || false
  };
};

const totaisIniciais = {
  total: 0,
  taxaUrgencia: 0,
  totalComUrgencia: 0,
  valorPagar: 0,
  statusPagamento: "pendente",
  desconto: 0,
  totalFinal: 0,
  saldoUtilizado: 0,
  saldoRestante: 0,
  descontoAplicado: 0,
  taxaUrgenciaGratis: false,
  modeloCompletaGratis: false
};

export const calcularDataEntregaZoomPlus = (prazoEntrega) => {
  const novaData = new Date();
  let diasAdicionados = 0;

  while (diasAdicionados < prazoEntrega) {
    novaData.setDate(novaData.getDate() + 1);
    if (novaData.getDay() !== 0 && novaData.getDay() !== 6) {
      diasAdicionados++;
    }
  }

  return novaData.toISOString();
};

export const calcularDataEntregaNaoZoomPlus = () => {
  const novaData = new Date();
  let diasAdicionados = 0;

  while (diasAdicionados < 7) {
    novaData.setDate(novaData.getDate() + 1);
    if (novaData.getDay() !== 0 && novaData.getDay() !== 6) {
      diasAdicionados++;
    }
  }

  return novaData.toISOString();
};

export const calcularDataLimiteRetirada = (dataEntrega) => {
  const data = new Date(dataEntrega);
  // Adiciona 30 dias à data de entrega
  data.setDate(data.getDate() + 30);
  return data.toISOString();
};

// Função auxiliar para formatar datas
export const formatarData = (data) => {
  if (!data) return null;
  const dataObj = new Date(data);
  return dataObj.toISOString().split('T')[0];
};

// Função para preparar dados do pedido para o backend
export const prepararDadosPedido = (pedido, clienteZoomPlus, totais) => {
  const dataEntrega = pedido.dataEntrega ||
    (clienteZoomPlus?.isZoomPlus
      ? calcularDataEntregaZoomPlus(clienteZoomPlus.plano.prazoEntrega)
      : calcularDataEntregaNaoZoomPlus());

  const dadosPedido = {
    ...pedido,
    dataEntrega: formatarData(dataEntrega),
    dataLimiteRetirada: formatarData(calcularDataLimiteRetirada(dataEntrega)),
    valorPagar: totais.valorPagar,
    statusPagamento: totais.statusPagamento
  };

  // Adiciona informações do ZoomPlus se aplicável
  if (clienteZoomPlus?.isZoomPlus) {
    dadosPedido.isZoomPlus = true;
    dadosPedido.zoomPlus = {
      planoId: clienteZoomPlus.plano._id,
      saldoUtilizado: totais.saldoUtilizado,
      saldoRestante: totais.saldoRestante,
      descontoAplicado: totais.descontoAplicado,
      beneficiosUtilizados: {
        taxaUrgenciaGratis: pedido.zoomPlus?.beneficiosUtilizados?.taxaUrgenciaGratis || false,
        modeloCompletaGratis: pedido.zoomPlus?.beneficiosUtilizados?.modeloCompletaGratis || false
      }
    };
  }

  return dadosPedido;
};
