import React from 'react';
import {
    Breadcrumbs,
    Link,
    useTheme,
} from '@mui/material';
import {
    Folder,
    NavigateNext,
} from '@mui/icons-material';

const FileBreadcrumbs = ({ currentPath = '/', onNavigate }) => {
    const theme = useTheme();
    const paths = currentPath.split('/').filter(Boolean);

    return (
        <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            sx={{ mb: 2 }}
        >
            <Link
                component="button"
                variant="body1"
                onClick={() => onNavigate('/')}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.text.primary,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline'
                    }
                }}
            >
                <Folder sx={{ mr: 0.5 }} fontSize="small" />
                Raiz
            </Link>
            {paths.map((path, index) => (
                <Link
                    key={index}
                    component="button"
                    variant="body1"
                    onClick={() => onNavigate('/' + paths.slice(0, index + 1).join('/'))}
                    sx={{
                        color: theme.palette.text.primary,
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    {path}
                </Link>
            ))}
        </Breadcrumbs>
    );
};

export default FileBreadcrumbs;
