import React from 'react';
import { AppBar, Box, Button, IconButton, Stack, Toolbar, useMediaQuery, useTheme as useMuiTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import logoDark from '../assets/logos/Logo Preto com Laranja.svg';
import logoLight from '../assets/logos/Logo Branco com Laranja.svg';
import logoBlackWhite from '../assets/logos/logo preto com branco.svg';
import { useTheme } from '../contexts/ThemeContext';
import ThemeToggle from './ThemeToggle';

const Header = ({ variant = 'default', onScrollToSection }) => {
    const navigate = useNavigate();
    const { mode } = useTheme();
    const theme = useMuiTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/");
    };
    const handleLogin = () => {
        navigate('/login');
    };

    const getLogo = () => {
        if (variant === 'default') {
            return logoBlackWhite; // White logo for orange background
        }
        return mode === 'dark' ? logoLight : logoDark;
    };

    const renderContent = () => {
        switch (variant) {
            case 'auth':
                // For login and first access pages - only logo and theme toggle
                return (
                    <ThemeToggle />
                );
            case 'user':
                // For user page - logo + logout + settings + theme toggle
                return (
                    <Stack direction="row" spacing={1}>
                        <IconButton
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'primary.main',
                                    backgroundColor: 'action.hover',
                                }
                            }}
                        >
                            <SettingsIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleLogout}
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'primary.main',
                                    backgroundColor: 'action.hover',
                                }
                            }}
                        >
                            <LogoutIcon />
                        </IconButton>
                        <ThemeToggle />
                    </Stack>
                );
            default:
                // For landing page - navigation buttons + login + theme toggle
                return (
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        {!isMobile && (
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button
                                    sx={{ color: 'white' }}
                                    onClick={() => onScrollToSection?.('hero')}
                                >
                                    Início
                                </Button>
                                <Button
                                    sx={{ color: 'white' }}
                                    onClick={() => onScrollToSection?.('services')}
                                >
                                    Serviços
                                </Button>
                            </Box>
                        )}
                        <Button
                            variant="contained"
                            onClick={handleLogin}
                            sx={{
                                bgcolor: 'white',
                                color: '#F3470C',
                                '&:hover': {
                                    bgcolor: 'rgba(255,255,255,0.9)',
                                }
                            }}
                        >
                            Login
                        </Button>
                        <ThemeToggle />
                    </Box>
                );
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                bgcolor: variant === 'default' ? '#F3470C' : 'background.paper',
                boxShadow: variant === 'default' ? 1 : 'none',
                borderBottom: variant !== 'default' ? 1 : 0,
                borderColor: 'divider'
            }}
        >
            <Toolbar sx={{ px: { xs: 2, sm: 10 }, paddingY: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, flex: 1 }}>
                    <Box
                        component="img"
                        src={getLogo()}
                        alt="Logo"
                        sx={{
                            height: 60,
                            objectFit: 'contain',
                            cursor: variant !== 'auth' ? 'pointer' : 'default',
                        }}
                        onClick={() => variant !== 'auth' && navigate('/')}
                    />
                </Box>
                {renderContent()}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
