import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./components/Layout";
import UserLayout from "./components/user/UserLayout";
import CadastroClientes from "./pages/CadastroClientes";
import CadastroServicos from "./pages/CadastroServicos";
import CadastroPedidos from "./pages/CadastroPedidos";
import Login from "./pages/Login";
import PrimeiroAcesso from "./pages/PrimeiroAcesso";
import ResetarSenha from "./pages/ResetarSenha";
import UserPage from "./pages/UserPage";
import UserOrdersPage from "./pages/user/UserOrdersPage";
import UserSettings from "./pages/user/UserSettings";
import UserPayments from "./pages/user/UserPayments";
import UserDashboard from "./pages/user/UserDashboard";
import UserHelp from "./pages/user/UserHelp";
import KnowledgeBase from "./pages/user/KnowledgeBase";
import ArticleView from "./pages/user/ArticleView";
import Dashboard from "./pages/Dashboard";
import ZoomPlus from "./pages/ZoomPlus";
import EditarCliente from "./pages/EditarCliente";
import DadosCliente from "./pages/DadosCliente";
import VisualizarPedido from "./pages/VisualizarPedido";
import EditarPedido from "./pages/EditarPedido";
import LandingPage from "./pages/LandingPage";
import Payments from "./pages/Payments";
import OrcamentoPage from "./pages/OrcamentoPage";
import BoardsPage from "./pages/BoardsPage";
import BoardDetail from "./pages/BoardDetail";
import CadastroPublico from "./pages/CadastroPublico";
import CadastrosPendentes from "./pages/CadastrosPendentes";
import EditarCadastroPendente from "./pages/EditarCadastroPendente";

const App = () => {
  return (
    <ThemeProvider>
      <Routes>
        {/* Rotas públicas */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/primeiro-acesso" element={<PrimeiroAcesso />} />
        <Route path="/redefinir-senha/:token" element={<ResetarSenha />} />
        <Route path="/orcamento" element={<OrcamentoPage />} />
        <Route path="/cadastro" element={<CadastroPublico />} />

        {/* Rotas do usuário comum */}
        <Route path="/user" element={<UserLayout />}>
          <Route path=":id" element={<UserPage />} />
          <Route path="dashboard" element={<UserDashboard />} />
          <Route path="orders" element={<UserOrdersPage />} />
          <Route path="payments" element={<UserPayments />} />
          <Route path="settings" element={<UserSettings />} />
          <Route path="help" element={<UserHelp />} />
          <Route path="pedido/:id" element={<VisualizarPedido />} />
        </Route>

        { /* Rotas Base de Conhecimento */}
        <Route path="/base-de-conhecimento" element={<KnowledgeBase />} />
        <Route path="/base-de-conhecimento/artigo/:articleId" element={<ArticleView />} />

        {/* Rotas protegidas - Admin */}
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/cadastro-clientes"
          element={
            <Layout>
              <CadastroClientes />
            </Layout>
          }
        />
        <Route
          path="/cadastros-pendentes"
          element={
            <Layout>
              <CadastrosPendentes />
            </Layout>
          }
        />
        <Route
          path="/cadastros-pendentes/editar/:id"
          element={
            <Layout>
              <EditarCadastroPendente />
            </Layout>
          }
        />
        <Route
          path="/cadastro-pedidos"
          element={
            <Layout>
              <CadastroPedidos />
            </Layout>
          }
        />
        <Route
          path="/cadastro-servicos"
          element={
            <Layout>
              <CadastroServicos />
            </Layout>
          }
        />
        <Route
          path="/payments"
          element={
            <Layout>
              <Payments />
            </Layout>
          }
        />

        {/* Rotas de Quadros */}
        <Route
          path="/boards"
          element={
            <Layout>
              <BoardsPage />
            </Layout>
          }
        />
        <Route
          path="/boards/:boardId"
          element={
            <Layout>
              <BoardDetail />
            </Layout>
          }
        />

        {/* Outras rotas protegidas */}
        <Route
          path="/zoom-plus"
          element={
            <Layout>
              <ZoomPlus />
            </Layout>
          }
        />
        <Route
          path="/visualizar-pedido/:id"
          element={
            <Layout>
              <VisualizarPedido />
            </Layout>
          }
        />
        <Route
          path="/editar-pedido/:id"
          element={
            <Layout>
              <EditarPedido />
            </Layout>
          }
        />
        <Route
          path="/editar-cliente/:id"
          element={
            <Layout>
              <EditarCliente />
            </Layout>
          }
        />
        <Route
          path="/dados-cliente/:id"
          element={
            <Layout>
              <DadosCliente />
            </Layout>
          }
        />

        {/* Rota para qualquer outro caminho não definido */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ThemeProvider>
  );
};

export default App;
