import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Container,
    Paper,
    Grid,
    TextField,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useTheme as useMuiTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logoBranco from '../assets/logos/Logo Branco com Laranja.svg';
import logoPreto from '../assets/logos/Logo Preto com Laranja.svg';
import ThemeToggle from '../components/ThemeToggle';
import { useTheme } from '../contexts/ThemeContext';
import useCep from '../hooks/useCep';
import AlertaReutilizavel from '../components/shared/AlertaReutilizavel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CadastroPublico = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [alerta, setAlerta] = useState({ aberto: false, mensagem: '', tipo: 'info' });
    const [modalSucesso, setModalSucesso] = useState(false);
    const [emailError, setEmailError] = useState('');
    const muiTheme = useMuiTheme();
    const { mode } = useTheme();
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        confirmarEmail: '',
        documento: '',
        telefone: '',
        endereco: '',
        cep: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        inscricaoMunicipal: '',
        inscricaoEstadual: '',
        observacoes: ''
    });

    const handleAddressChange = (addressData) => {
        setFormData(prev => ({
            ...prev,
            endereco: addressData.endereco || '',
            bairro: addressData.bairro || '',
            cidade: addressData.cidade || '',
            estado: addressData.estado || ''
        }));
    };

    const { fetchAddress, loading: loadingCep, error: cepError } = useCep(handleAddressChange);

    const handleChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));

        if (field === 'cep') {
            const cleanCep = value.replace(/\D/g, '');
            if (cleanCep.length === 8) {
                fetchAddress(cleanCep);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.email !== formData.confirmarEmail) {
            setEmailError('Os emails não coincidem');
            return;
        }

        setLoading(true);
        setEmailError('');

        try {
            const response = await fetch('/api/cadastros-pendentes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Erro ao enviar cadastro');
            }

            setModalSucesso(true);
            setFormData({
                nome: '',
                email: '',
                confirmarEmail: '',
                documento: '',
                telefone: '',
                endereco: '',
                cep: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: '',
                inscricaoMunicipal: '',
                inscricaoEstadual: '',
                observacoes: ''
            });

        } catch (error) {
            setAlerta({
                aberto: true,
                mensagem: error.message || 'Erro ao enviar cadastro. Tente novamente.',
                tipo: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFecharModalSucesso = () => {
        setModalSucesso(false);
        navigate('/');
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            bgcolor: 'background.default',
            color: 'text.primary',
            pb: 8
        }}>
            {/* Top Bar */}
            <Box sx={{
                background: mode === 'dark' ? 'rgba(18, 18, 18, 0.8)' : 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(10px)',
                borderBottom: `1px solid ${mode === 'dark' ? 'rgba(255, 110, 64, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
                py: 2,
                position: 'sticky',
                top: 0,
                zIndex: 1000
            }}>
                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Box
                        component="img"
                        src={mode === 'dark' ? logoBranco : logoPreto}
                        alt="Zoompro Studio"
                        sx={{
                            height: '40px',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    />
                    <ThemeToggle />
                </Container>
            </Box>

            {/* Main Content */}
            <Container maxWidth="md" sx={{ mt: 8 }}>
                <Typography
                    variant="h2"
                    align="center"
                    sx={{
                        fontFamily: '"Poppins", sans-serif',
                        fontWeight: 800,
                        mb: 4,
                        background: `linear-gradient(45deg, ${muiTheme.palette.primary.main} 30%, ${muiTheme.palette.primary.light} 90%)`,
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                    }}
                >
                    Cadastro de Cliente
                </Typography>

                <Paper elevation={3} sx={{
                    p: 4,
                    bgcolor: 'background.paper',
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`
                }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                                    Informações Básicas
                                </Typography>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    value={formData.email}
                                    onChange={(e) => handleChange('email', e.target.value)}
                                    fullWidth
                                    required
                                    error={!!emailError}
                                    helperText={emailError}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Confirmar Email"
                                    type="email"
                                    value={formData.confirmarEmail}
                                    onChange={(e) => handleChange('confirmarEmail', e.target.value)}
                                    fullWidth
                                    required
                                    error={!!emailError}
                                    helperText={emailError}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Nome / Razão Social"
                                    value={formData.nome}
                                    onChange={(e) => handleChange('nome', e.target.value)}
                                    fullWidth
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Documento (CPF/CNPJ)"
                                    value={formData.documento}
                                    onChange={(e) => handleChange('documento', e.target.value)}
                                    fullWidth
                                    required
                                    placeholder='Apenas Numeros'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Telefone"
                                    value={formData.telefone}
                                    onChange={(e) => handleChange('telefone', e.target.value)}
                                    fullWidth
                                    required
                                    placeholder="Ex: (99) 99999-9999"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', mt: 2 }}>
                                    Endereço
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="CEP"
                                    value={formData.cep}
                                    onChange={(e) => handleChange('cep', e.target.value)}
                                    fullWidth
                                    required
                                    error={!!cepError}
                                    helperText={cepError}
                                    InputProps={{
                                        endAdornment: loadingCep && (
                                            <CircularProgress size={20} />
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <TextField
                                    label="Endereço"
                                    value={formData.endereco}
                                    onChange={(e) => handleChange('endereco', e.target.value)}
                                    fullWidth
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <TextField
                                    label="Número"
                                    value={formData.numero}
                                    onChange={(e) => handleChange('numero', e.target.value)}
                                    fullWidth
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Complemento"
                                    value={formData.complemento}
                                    onChange={(e) => handleChange('complemento', e.target.value)}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Bairro"
                                    value={formData.bairro}
                                    onChange={(e) => handleChange('bairro', e.target.value)}
                                    fullWidth
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Cidade"
                                    value={formData.cidade}
                                    onChange={(e) => handleChange('cidade', e.target.value)}
                                    fullWidth
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Estado"
                                    value={formData.estado}
                                    onChange={(e) => handleChange('estado', e.target.value)}
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 2 }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', mt: 2 }}>
                                    Informações Adicionais
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Inscrição Municipal"
                                    value={formData.inscricaoMunicipal}
                                    onChange={(e) => handleChange('inscricaoMunicipal', e.target.value)}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Inscrição Estadual"
                                    value={formData.inscricaoEstadual}
                                    onChange={(e) => handleChange('inscricaoEstadual', e.target.value)}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& input': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Observações"
                                    value={formData.observacoes}
                                    onChange={(e) => handleChange('observacoes', e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'divider',
                                            },
                                        },
                                        '& label': {
                                            color: 'text.secondary',
                                        },
                                        '& textarea': {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 2,
                                        py: 2,
                                        bgcolor: 'primary.main',
                                        '&:hover': {
                                            bgcolor: 'primary.dark',
                                        },
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : (
                                        'Enviar Cadastro'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>

            <AlertaReutilizavel
                mensagem={alerta.mensagem}
                tipo={alerta.tipo}
                aberto={alerta.aberto}
                onFechar={() => setAlerta({ ...alerta, aberto: false })}
            />

            <Dialog
                open={modalSucesso}
                onClose={handleFecharModalSucesso}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ textAlign: 'center', pt: 4 }}>
                    <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
                    <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                        Cadastro Enviado com Sucesso!
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography align="center" sx={{ mb: 2 }}>
                        Seu cadastro foi recebido e está em análise pela nossa equipe.
                    </Typography>
                    <Typography align="center" sx={{ mb: 2 }}>
                        Assim que for aprovado, você receberá um email de confirmação com as instruções de acesso.
                    </Typography>
                    <Typography align="center" variant="body2" color="text.secondary">
                        Fique atento à sua caixa de entrada e spam.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <Button
                        onClick={handleFecharModalSucesso}
                        variant="contained"
                        color="primary"
                        sx={{
                            px: 4,
                            py: 1,
                            borderRadius: 2,
                            textTransform: 'none',
                            fontSize: '1rem'
                        }}
                    >
                        Entendi
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CadastroPublico;
