import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Tab,
    Tabs,
    CircularProgress,
    Alert,
    Pagination,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    useTheme,
    useMediaQuery,
    Paper,
    Fade
} from '@mui/material';
import { AutorenewRounded, ShoppingBagRounded } from '@mui/icons-material';
import PaymentCard from '../../components/payments/PaymentCard';
import SubscriptionInfo from '../../components/payments/SubscriptionInfo';
import { usePayments } from '../../hooks/usePayments';
import SectionTitle from '../../components/SectionTitle';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

const PaymentSection = ({ title, icon: Icon, payments, loading, error }) => {
    const theme = useTheme();

    if (loading && !payments.length) {
        return (
            <Box display="flex" justifyContent="center" p={4}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert
                severity="error"
                sx={{
                    mb: 3,
                    borderRadius: 2,
                    backgroundColor: theme.palette.error.lighter,
                    color: theme.palette.error.darker
                }}
            >
                {error}
            </Alert>
        );
    }

    if (!payments.length) {
        return (
            <Fade in>
                <Paper
                    elevation={0}
                    sx={{
                        p: 4,
                        textAlign: 'center',
                        backgroundColor: theme.palette.background.default,
                        borderRadius: 2,
                        border: `1px dashed ${theme.palette.divider}`
                    }}
                >
                    <Icon sx={{ fontSize: 48, color: 'text.secondary', mb: 2, opacity: 0.5 }} />
                    <Typography color="text.secondary">
                        Nenhum pagamento encontrado nesta categoria.
                    </Typography>
                </Paper>
            </Fade>
        );
    }

    return (
        <Fade in>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 3,
                        p: 2,
                        borderRadius: 2,
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: `inset 0 -2px 0 ${theme.palette.primary.main}`
                    }}
                >
                    <Icon color="primary" />
                    <Typography variant="h6" fontWeight={600}>
                        {title}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {payments.map((payment) => (
                        <Fade key={payment.id} in>
                            <Box>
                                <PaymentCard payment={payment} />
                            </Box>
                        </Fade>
                    ))}
                </Box>
            </Box>
        </Fade>
    );
};

const UserPayments = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeTab, setActiveTab] = useState('all');

    const {
        payments,
        loading,
        error,
        pagination,
        handlePageChange,
        handleLimitChange
    } = usePayments(activeTab);

    const subscriptionPayments = payments.filter(p => !p.pedidoInfo && p.paymentType !== 'order');
    const orderPayments = payments.filter(p => p.pedidoInfo || p.paymentType === 'order');

    // Encontrar a assinatura ativa mais recente
    const activeSubscription = subscriptionPayments
        .filter(p => ['RECEIVED', 'CONFIRMED', 'PENDING'].includes(p.status))
        .sort((a, b) => {
            // Priorizar RECEIVED e CONFIRMED sobre PENDING
            if (a.status === 'PENDING' && ['RECEIVED', 'CONFIRMED'].includes(b.status)) return 1;
            if (b.status === 'PENDING' && ['RECEIVED', 'CONFIRMED'].includes(a.status)) return -1;

            // Para o mesmo status, usar a data mais recente
            const dateA = new Date(a.dueDate);
            const dateB = new Date(b.dueDate);
            return dateB - dateA;
        })[0];

    return (
        <Box
            sx={{
                minHeight: '100vh',
                backgroundColor: theme.palette.background.default,
                py: { xs: 2, sm: 4 }
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{ mb: 4 }}>
                    <SectionTitle
                        variant="h4"
                        mb={6}
                        width={250}
                    >
                        Pagamentos
                    </SectionTitle>

                    <SubscriptionInfo
                        subscription={activeSubscription}
                        loading={loading}
                        error={error}
                    />
                </Box>

                <Paper
                    elevation={0}
                    sx={{
                        borderRadius: 2,
                        overflow: 'hidden',
                        border: `1px solid ${theme.palette.divider}`,
                        mb: 4
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        variant={isMobile ? 'fullWidth' : 'standard'}
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            backgroundColor: theme.palette.background.paper,
                            '& .MuiTab-root': {
                                textTransform: 'none',
                                fontWeight: 500,
                                fontSize: '1rem',
                                minHeight: 56,
                                '&.Mui-selected': {
                                    color: theme.palette.primary.main,
                                }
                            },
                            '& .MuiTabs-indicator': {
                                height: 3,
                                borderRadius: '3px 3px 0 0'
                            }
                        }}
                    >
                        <Tab
                            label="Todos"
                            value="all"
                            icon={<Box sx={{ display: 'flex', gap: 0.5 }}>
                                <AutorenewRounded fontSize="small" />
                                <ShoppingBagRounded fontSize="small" />
                            </Box>}
                            iconPosition="start"
                        />
                        <Tab
                            label="Assinaturas"
                            value="subscription"
                            icon={<AutorenewRounded fontSize="small" />}
                            iconPosition="start"
                        />
                        <Tab
                            label="Pedidos"
                            value="order"
                            icon={<ShoppingBagRounded fontSize="small" />}
                            iconPosition="start"
                        />
                    </Tabs>
                </Paper>

                {loading && !payments.length ? (
                    <Box display="flex" justifyContent="center" p={4}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert
                        severity="error"
                        sx={{
                            mb: 3,
                            borderRadius: 2,
                            backgroundColor: theme.palette.error.lighter,
                            color: theme.palette.error.darker
                        }}
                    >
                        {error}
                    </Alert>
                ) : activeTab === 'all' ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <PaymentSection
                            title="Assinaturas"
                            icon={AutorenewRounded}
                            payments={subscriptionPayments}
                            loading={loading}
                            error={error}
                        />
                        <PaymentSection
                            title="Pedidos"
                            icon={ShoppingBagRounded}
                            payments={orderPayments}
                            loading={loading}
                            error={error}
                        />
                    </Box>
                ) : (
                    <PaymentSection
                        title={activeTab === 'subscription' ? 'Assinaturas' : 'Pedidos'}
                        icon={activeTab === 'subscription' ? AutorenewRounded : ShoppingBagRounded}
                        payments={activeTab === 'subscription' ? subscriptionPayments : orderPayments}
                        loading={loading}
                        error={error}
                    />
                )}

                {payments.length > 0 && (
                    <Box
                        sx={{
                            mt: 4,
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 2
                        }}
                    >
                        <FormControl
                            size="small"
                            sx={{
                                minWidth: 150,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2
                                }
                            }}
                        >
                            <InputLabel>Itens por página</InputLabel>
                            <Select
                                value={pagination.limit}
                                label="Itens por página"
                                onChange={handleLimitChange}
                            >
                                {ROWS_PER_PAGE_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option} itens
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Pagination
                            count={Math.ceil(pagination.total / pagination.limit)}
                            page={pagination.page}
                            onChange={handlePageChange}
                            color="primary"
                            size={isMobile ? 'small' : 'medium'}
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    borderRadius: 2
                                }
                            }}
                        />
                    </Box>
                )}
            </Container>
        </Box>
    );
};

export default UserPayments;
