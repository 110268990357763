import React, { useState, useEffect } from 'react';
import { useUserOrders } from '../../hooks/useUserOrders';
import {
    Box,
    Typography,
    Paper,
    Chip,
    IconButton,
    useTheme,
    Tooltip,
    Button,
    Stack,
    alpha,
    Divider,
    Snackbar,
    Alert
} from '@mui/material';
import {
    VisibilityOutlined as VisibilityIcon,
    AccessTimeOutlined as AccessTimeIcon,
    CheckCircleOutline as CheckCircleIcon,
    CancelOutlined as CancelIcon,
    RadioButtonUncheckedOutlined as CircleIcon,
    PaymentOutlined as PaymentIcon,
    ShoppingBagOutlined as OrderIcon,
    EventOutlined as EventIcon,
    LocalAtmOutlined as MoneyIcon,
    FolderOutlined as FolderIcon,
    ReceiptLong as InvoiceIcon
} from '@mui/icons-material';
import OrderDetailsModal from './OrderDetailsModal';
import ArquivosModal from '../pedido/ArquivosModal';
import InvoiceDetailsDialog from '../payments/InvoiceDetailsDialog';
import api from '../../api';

const UserOrders = ({ pedidos: initialPedidos = [], onViewOrder }) => {
    const theme = useTheme();
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showArquivosModal, setShowArquivosModal] = useState(false);
    const [selectedPedidoId, setSelectedPedidoId] = useState(null);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    const {
        pedidos,
        updatePedidos,
        socketError,
        setSocketError,
        socketConnected
    } = useUserOrders(initialPedidos);

    useEffect(() => {
        updatePedidos(initialPedidos);
    }, [initialPedidos, updatePedidos]);

    // Adiciona Snackbar para erros de socket
    React.useEffect(() => {
        if (socketError) {
            const timer = setTimeout(() => {
                setSocketError(null);
            }, 6000);
            return () => clearTimeout(timer);
        }
    }, [socketError]);

    const handleViewInvoice = async (invoiceId) => {
        try {
            setLoading(true);
            setInvoiceDialogOpen(true);
            const response = await api.get(`/pagamentos/invoices/${invoiceId}`);
            setInvoiceDetails(response.data);
        } catch (error) {
            console.error('Erro ao carregar nota fiscal:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseInvoiceDialog = () => {
        setInvoiceDialogOpen(false);
        setInvoiceDetails(null);
    };

    const getStatusConfig = (status) => {
        const configs = {
            'aberto': {
                color: theme.palette.info.main,
                icon: CircleIcon,
                label: 'Aberto',
                backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1)
            },
            'em andamento': {
                color: theme.palette.warning.main,
                icon: AccessTimeIcon,
                label: 'Em andamento',
                backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1)
            },
            'concluido': {
                color: theme.palette.success.main,
                icon: CheckCircleIcon,
                label: 'Concluído',
                backgroundColor: (theme) => alpha(theme.palette.success.main, 0.1)
            },
            'cancelado': {
                color: theme.palette.error.main,
                icon: CancelIcon,
                label: 'Cancelado',
                backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1)
            }
        };
        return configs[status] || configs['aberto'];
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value || 0);
    };

    const getPaymentStatusChip = (statusPagamento, pedido) => {
        // Verifica se o pedido está pago (seja pelo status do Asaas ou pelo statusPagamento)
        if ((pedido?.asaas?.paymentStatus && ['CONFIRMED', 'RECEIVED', 'RECEIVED_IN_CASH'].includes(pedido.asaas.paymentStatus)) ||
            pedido?.statusPagamento === 'pago' ||
            pedido?.statusPagamento === 'pago_com_saldo' ||
            pedido?.statusPagamento === 'pago_parcial_saldo') {
            return (
                <Chip
                    label="Pago"
                    size="small"
                    sx={{
                        backgroundColor: alpha(theme.palette.success.main, 0.1),
                        color: theme.palette.success.main,
                        border: `1px solid ${theme.palette.success.main}`,
                        fontWeight: 500,
                        '& .MuiChip-label': {
                            px: 1
                        }
                    }}
                />
            );
        }

        // Se não houver status do Asaas ou não for um dos status de pago, usa a lógica existente
        // Se não houver status do Asaas ou não for um dos status de pago, usa a lógica existente
        let color, label, bgColor;

        switch (statusPagamento) {
            case 'pago':
                color = theme.palette.success.main;
                label = 'Pago';
                bgColor = alpha(theme.palette.success.main, 0.1);
                break;
            case 'pago_com_saldo':
                color = theme.palette.success.main;
                label = 'Pago com Saldo';
                bgColor = alpha(theme.palette.success.main, 0.1);
                break;
            case 'pago_parcial_saldo':
                color = theme.palette.info.main;
                label = 'Pago Parcialmente';
                bgColor = alpha(theme.palette.info.main, 0.1);
                break;
            default:
                color = pedido?.asaas ? theme.palette.warning.main : theme.palette.info.main;
                label = pedido?.asaas ? 'Pagamento Pendente' : 'Aguardando Fatura';
                bgColor = alpha(pedido?.asaas ? theme.palette.warning.main : theme.palette.info.main, 0.1);
        }

        return (
            <Chip
                label={label}
                size="small"
                sx={{
                    backgroundColor: bgColor,
                    color: color,
                    border: `1px solid ${color}`,
                    fontWeight: 500,
                    '& .MuiChip-label': {
                        px: 1
                    }
                }}
            />
        );
    };

    if (!Array.isArray(pedidos) || pedidos.length === 0) {
        return (
            <Box p={3}>
                <Box
                    sx={{
                        textAlign: 'center',
                        py: 8,
                        color: theme.palette.text.secondary,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <OrderIcon sx={{ fontSize: 48, opacity: 0.5 }} />
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        Nenhum pedido encontrado
                    </Typography>
                </Box>
            </Box>
        );
    }

    const handleViewOrder = (pedido) => {
        setSelectedOrder(pedido);
    };

    const handleArquivosClick = (pedidoId) => {
        setSelectedPedidoId(pedidoId);
        setShowArquivosModal(true);
    };

    return (
        <Box p={3}>
            {socketError && (
                <Snackbar
                    open={Boolean(socketError)}
                    autoHideDuration={6000}
                    onClose={() => setSocketError(null)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => setSocketError(null)} severity="error">
                        Erro de conexão: {socketError}
                    </Alert>
                </Snackbar>
            )}
            {pedidos.map((pedido) => {
                const statusConfig = getStatusConfig(pedido?.status);
                const StatusIcon = statusConfig.icon;

                return (
                    <Paper
                        key={pedido?._id}
                        elevation={0}
                        sx={{
                            mb: 2,
                            background: theme.palette.background.paper,
                            borderRadius: 3,
                            border: `1px solid ${theme.palette.divider}`,
                            transition: 'all 0.3s ease-in-out',
                            position: 'relative',
                            overflow: 'visible',
                            '&:hover': {
                                transform: 'translateY(-4px)',
                                boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
                                borderColor: theme.palette.primary.main,
                                '& .order-icon': {
                                    transform: 'scale(1.1)',
                                    color: theme.palette.primary.main
                                }
                            }
                        }}
                    >
                        <Box sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                                <Stack spacing={2}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                        <OrderIcon
                                            className="order-icon"
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                transition: 'all 0.3s ease-in-out'
                                            }}
                                        />
                                        <Button
                                            onClick={() => handleViewOrder(pedido)}
                                            variant="text"
                                            sx={{
                                                color: theme.palette.text.primary,
                                                fontWeight: 600,
                                                fontSize: '1.1rem',
                                                letterSpacing: '-0.5px',
                                                p: 0,
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    color: theme.palette.primary.main
                                                }
                                            }}
                                        >
                                            Pedido #{pedido?._id?.slice(-4).toUpperCase() || 'N/A'}
                                        </Button>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <EventIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: theme.palette.text.secondary
                                                }}
                                            >
                                                {pedido?.createdAt ? new Date(pedido.createdAt).toLocaleDateString('pt-BR', {
                                                    day: '2-digit',
                                                    month: 'long',
                                                    year: 'numeric'
                                                }) : 'Data não disponível'}
                                            </Typography>
                                        </Box>
                                        {getPaymentStatusChip(pedido?.statusPagamento, pedido)}
                                    </Box>
                                </Stack>

                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {pedido?.asaas?.notaFiscalId && ['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(pedido?.asaas?.paymentStatus) && (
                                        <Tooltip title="Ver nota fiscal" arrow>
                                            <IconButton
                                                onClick={() => handleViewInvoice(pedido.asaas.notaFiscalId)}
                                                sx={{
                                                    border: `1px solid ${alpha(theme.palette.success.main, 0.5)}`,
                                                    color: theme.palette.success.main,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        backgroundColor: alpha(theme.palette.success.main, 0.1),
                                                        borderColor: theme.palette.success.main,
                                                        transform: 'scale(1.05)'
                                                    }
                                                }}
                                            >
                                                <InvoiceIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Arquivos do Pedido" arrow>
                                        <IconButton
                                            onClick={() => handleArquivosClick(pedido._id)}
                                            sx={{
                                                border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                    borderColor: theme.palette.primary.main,
                                                    transform: 'scale(1.05)'
                                                }
                                            }}
                                        >
                                            <FolderIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Detalhes do Pedido" arrow>
                                        <IconButton
                                            onClick={() => handleViewOrder(pedido)}
                                            sx={{
                                                border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                    borderColor: theme.palette.primary.main,
                                                    transform: 'scale(1.05)'
                                                }
                                            }}
                                        >
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Divider sx={{ my: 2 }} />

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: 2
                                }}
                            >
                                <Chip
                                    icon={<StatusIcon fontSize="small" />}
                                    label={statusConfig.label}
                                    size="small"
                                    sx={{
                                        backgroundColor: statusConfig.backgroundColor(theme),
                                        color: statusConfig.color,
                                        border: `1px solid ${statusConfig.color}`,
                                        fontWeight: 500,
                                        '& .MuiChip-icon': {
                                            color: 'inherit'
                                        }
                                    }}
                                />

                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            backgroundColor: alpha(theme.palette.primary.main, 0.05),
                                            padding: '8px 16px',
                                            borderRadius: 2
                                        }}
                                    >
                                        <MoneyIcon sx={{ color: theme.palette.primary.main }} />
                                        <Typography
                                            variant="h7"
                                            color="primary"
                                            sx={{
                                                fontWeight: 600,
                                                letterSpacing: '-0.5px'
                                            }}
                                        >
                                            {formatCurrency(pedido?.valorPagar)}
                                        </Typography>
                                    </Box>

                                    {pedido?.asaas?.paymentLink &&
                                        pedido?.statusPagamento === 'pendente' &&
                                        !['CONFIRMED', 'RECEIVED', 'RECEIVED_IN_CASH'].includes(pedido?.asaas?.paymentStatus) && (
                                            <Button
                                                startIcon={<PaymentIcon />}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                href={pedido.asaas.paymentLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    borderRadius: 2,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        transform: 'translateY(-2px)',
                                                        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`
                                                    }
                                                }}
                                            >
                                                Pagar
                                            </Button>
                                        )}
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                );
            })}

            <OrderDetailsModal
                open={!!selectedOrder}
                onClose={() => setSelectedOrder(null)}
                pedido={selectedOrder}
            />

            <ArquivosModal
                open={showArquivosModal}
                onClose={() => setShowArquivosModal(false)}
                pedidoId={selectedPedidoId}
                isAdmin={false}
            />
            <InvoiceDetailsDialog
                open={invoiceDialogOpen}
                onClose={handleCloseInvoiceDialog}
                invoice={invoiceDetails}
                loading={loading}
            />
        </Box>
    );
};

export default UserOrders;
