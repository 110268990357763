import { useCallback, useState } from 'react';
import { useSocketIO } from './useSocketIO';

export const useUserOrders = (initialPedidos = []) => {
    const [pedidos, setPedidos] = useState(initialPedidos);
    const [socketError, setSocketError] = useState(null);

    const handleSocketMessage = useCallback((message) => {
        try {
            const { event, data } = message.event ? message : { event: message.type, data: message.data };

            if (event === 'payment_updated' && data.pedidoId) {
                setPedidos(currentPedidos =>
                    currentPedidos.map(pedido =>
                        pedido._id === data.pedidoId
                            ? {
                                ...pedido,
                                statusPagamento: data.status,
                                asaas: {
                                    ...pedido.asaas,
                                    paymentStatus: data.paymentStatus,
                                    notaFiscalId: data.notaFiscalId
                                }
                            }
                            : pedido
                    )
                );
            }
        } catch (error) {
            console.error('[useUserOrders] Erro ao processar mensagem websocket:', error);
            setSocketError(error.message);
        }
    }, []);

    const { isConnected } = useSocketIO((message) => {
        try {
            handleSocketMessage(message);
        } catch (error) {
            console.error('[useUserOrders] Error handling socket message:', error);
            setSocketError(error.message);
        }
    }, {
        reconnectOnClose: true,
        retryAttempts: 5,
        onError: (error) => {
            console.error('[useUserOrders] Socket error:', error);
            setSocketError(error.message);
        }
    });

    // Atualiza o estado quando as props mudam
    const updatePedidos = useCallback((newPedidos) => {
        setPedidos(newPedidos);
    }, []);

    return {
        pedidos,
        updatePedidos,
        socketError,
        setSocketError,
        socketConnected: isConnected
    };
};
