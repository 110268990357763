import React, { useState, useCallback } from 'react';
import {
    Paper,
    TextField,
    Button,
    Box,
    IconButton,
    Typography,
    Collapse,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LabelIcon from '@mui/icons-material/Label';
import { styled } from '@mui/material/styles';
import axios from '../../api';

const CreateCardContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(1)
}));

const ExpandButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    textTransform: 'none',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary
}));

const TagsContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    marginTop: '8px'
});

const ETIQUETAS_DISPONIVEIS = [
    { valor: 'urgente', cor: '#f44336' },
    { valor: 'importante', cor: '#ff9800' },
    { valor: 'baixa_prioridade', cor: '#4caf50' },
    { valor: 'bug', cor: '#e91e63' },
    { valor: 'melhoria', cor: '#2196f3' },
    { valor: 'documentacao', cor: '#9c27b0' }
];

const CreateCard = ({ listId, boardId, onCancel, onSuccess }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [etiquetas, setEtiquetas] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        if (!title.trim()) {
            setError('Título é obrigatório');
            return;
        }

        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);
        setLoading(true);
        setError('');

        try {
            const cardData = {
                titulo: title.trim(),
                descricao: description.trim(),
                etiquetas,
                boardId,
                listId
            };

            const response = await axios.post(`/lists/${listId}/cards`, cardData);

            setTitle('');
            setDescription('');
            setEtiquetas([]);
            setError('');

            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (err) {
            console.error('Error creating card:', err);
            setError('Erro ao criar cartão');
        } finally {
            setLoading(false);
            // Aguarda um pequeno delay antes de permitir nova submissão
            setTimeout(() => {
                setIsSubmitting(false);
            }, 1000);
        }
    }, [title, description, etiquetas, boardId, listId, onSuccess, isSubmitting]);

    const handleEtiquetaToggle = useCallback((etiqueta) => {
        setEtiquetas(prev => {
            if (prev.includes(etiqueta)) {
                return prev.filter(e => e !== etiqueta);
            }
            return [...prev, etiqueta];
        });
    }, []);

    const handleTitleChange = useCallback((e) => {
        setTitle(e.target.value);
        if (error) {
            setError('');
        }
    }, [error]);

    return (
        <CreateCardContainer>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Digite o título do cartão..."
                    value={title}
                    onChange={handleTitleChange}
                    error={Boolean(error)}
                    helperText={error}
                    autoFocus
                    disabled={loading || isSubmitting}
                    sx={{ mb: 1 }}
                />

                <Collapse in={expanded}>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        placeholder="Adicione uma descrição mais detalhada..."
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        disabled={loading || isSubmitting}
                        sx={{ mb: 2 }}
                    />

                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Etiquetas
                    </Typography>
                    <TagsContainer>
                        {ETIQUETAS_DISPONIVEIS.map((etiqueta) => (
                            <Chip
                                key={etiqueta.valor}
                                label={etiqueta.valor}
                                icon={<LabelIcon />}
                                onClick={() => handleEtiquetaToggle(etiqueta.valor)}
                                color={etiquetas.includes(etiqueta.valor) ? "primary" : "default"}
                                variant={etiquetas.includes(etiqueta.valor) ? "filled" : "outlined"}
                                size="small"
                                disabled={loading || isSubmitting}
                                sx={{
                                    backgroundColor: etiquetas.includes(etiqueta.valor) ? etiqueta.cor : 'transparent',
                                    '&:hover': {
                                        backgroundColor: etiquetas.includes(etiqueta.valor)
                                            ? etiqueta.cor
                                            : 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            />
                        ))}
                    </TagsContainer>
                </Collapse>

                {!expanded && (
                    <ExpandButton
                        fullWidth
                        startIcon={<ExpandMoreIcon />}
                        onClick={() => setExpanded(true)}
                        disabled={loading || isSubmitting}
                    >
                        Mostrar mais
                    </ExpandButton>
                )}

                <Box display="flex" alignItems="center" gap={1} mt={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        disabled={loading || isSubmitting}
                    >
                        Adicionar Cartão
                    </Button>
                    <IconButton
                        size="small"
                        onClick={onCancel}
                        disabled={loading || isSubmitting}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </form>
        </CreateCardContainer>
    );
};

export default CreateCard;
