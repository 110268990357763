import React from 'react';
import { Paper, Typography } from '@mui/material';

const EcommerceSection = () => (
    <Paper sx={{
        p: { xs: 2, md: 4 },
        background: 'rgba(255,255,255,0.03)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255,255,255,0.1)',
        borderRadius: '24px',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'translateY(-5px)',
        }
    }}>
        <Typography variant="h4" sx={{
            color: '#eb5b25',
            mb: 2,
            fontFamily: '"Poppins", sans-serif',
            fontSize: { xs: '1.5rem', md: '2rem' },
        }}>
            R$ 14,00
        </Typography>
        <Typography sx={{
            color: 'rgba(255,255,255,0.7)',
            fontFamily: '"Inter", sans-serif',
            fontSize: { xs: '0.9rem', md: '1rem' },
        }}>
            Valor por foto com fundo 100% branco
        </Typography>
    </Paper>
);

export default EcommerceSection;
