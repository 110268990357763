import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Snackbar, LinearProgress, Box } from "@mui/material";

const AlertaReutilizavel = ({ mensagem, tipo, aberto, onFechar }) => {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        if (aberto) {
            setProgress(100);
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress <= 0) {
                        clearInterval(timer);
                        return 0;
                    }
                    return oldProgress - 2.5; // Decrease by 2.5% every 100ms to complete in 4 seconds
                });
            }, 100);

            return () => {
                clearInterval(timer);
            };
        }
    }, [aberto]);

    return (
        <Snackbar
            open={aberto}
            autoHideDuration={4000}
            onClose={onFechar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Box sx={{ width: '100%' }}>
                <Alert onClose={onFechar} severity={tipo} sx={{ width: "100%", mb: 0 }}>
                    {mensagem}
                </Alert>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        height: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: tipo === 'error' ? '#f44336' :
                                tipo === 'warning' ? '#ff9800' :
                                    tipo === 'success' ? '#4caf50' :
                                        '#2196f3',
                        }
                    }}
                />
            </Box>
        </Snackbar>
    );
};

AlertaReutilizavel.propTypes = {
    mensagem: PropTypes.string.isRequired,
    tipo: PropTypes.oneOf(["success", "error", "info", "warning"]).isRequired,
    aberto: PropTypes.bool.isRequired,
    onFechar: PropTypes.func.isRequired,
};

export default AlertaReutilizavel;
