import { useState, useCallback } from 'react';
import api from '../api';
import { useAlerta } from './useAlerta';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';

export const useArquivos = (pedidoId) => {
    const [arquivos, setArquivos] = useState([]);
    const [pedido, setPedido] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [downloadingAll, setDownloadingAll] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});
    const [currentPath, setCurrentPath] = useState('/');
    const [pathHistory, setPathHistory] = useState(['/']);
    const [arquivoDuplicado, setArquivoDuplicado] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);
    const [isSelectionFolder, setIsSelectionFolder] = useState(false);
    const [totalSelecionados, setTotalSelecionados] = useState(0);
    const { setAlerta } = useAlerta();

    const fetchPedido = useCallback(async () => {
        if (!pedidoId) return;
        try {
            const response = await api.get(`/pedidos/${pedidoId}`, {
                params: { includePaymentStatus: true }
            });
            setPedido(response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar pedido:', error);
            return null;
        }
    }, [pedidoId]);

    const fetchArquivos = useCallback(async (diretorio = '/') => {
        if (!pedidoId) return;

        setLoading(true);
        try {
            const pedidoAtualizado = await fetchPedido();

            const response = await api.get(`/arquivos/${pedidoId}`, {
                params: {
                    diretorio,
                    statusPagamento: pedidoAtualizado?.statusPagamento,
                    asaasPaymentStatus: pedidoAtualizado?.asaas?.paymentStatus
                }
            });

            // Atualiza os estados com base na resposta
            if (response.data.isSelectionFolder) {
                setIsSelectionFolder(true);
                setTotalSelecionados(response.data.totalSelecionados);
                setArquivos(response.data.itens);
            } else {
                setIsSelectionFolder(false);
                setTotalSelecionados(0);
                setArquivos(response.data.itens);
            }
        } catch (error) {
            console.error('Erro ao buscar arquivos:', error);
            setAlerta({
                tipo: 'error',
                mensagem: 'Erro ao carregar arquivos do pedido'
            });
        } finally {
            setLoading(false);
        }
    }, [pedidoId, setAlerta, fetchPedido]);

    const toggleSelectionFolder = useCallback(async (arquivoId, isSelectionFolder) => {
        if (!pedidoId) return;

        try {
            // Encontra o arquivo pelo ID para obter o path
            const arquivo = arquivos.find(a => a._id === arquivoId);
            if (!arquivo) {
                throw new Error('Arquivo não encontrado');
            }

            const response = await api.post(`/arquivos/${pedidoId}/directory`, {
                relativePath: arquivo.path,
                isSelectionFolder
            });

            setArquivos(prev => prev.map(arquivo =>
                arquivo._id === arquivoId
                    ? { ...arquivo, isSelectionFolder }
                    : arquivo
            ));

            setAlerta({
                tipo: 'success',
                mensagem: isSelectionFolder
                    ? 'Pasta marcada como seleção com sucesso'
                    : 'Pasta desmarcada como seleção com sucesso'
            });
        } catch (error) {
            console.error('Erro ao alterar pasta de seleção:', error);
            setAlerta({
                tipo: 'error',
                mensagem: 'Erro ao alterar pasta de seleção'
            });
        }
    }, [pedidoId, arquivos, setAlerta]);

    const marcarParaEntrega = useCallback(async (arquivoId, selecionado) => {
        if (!pedidoId || !arquivoId) return;

        try {
            const response = await api.patch(`/arquivos/${pedidoId}/arquivo/${arquivoId}/selecionar`, {
                selecionado
            });

            setArquivos(prev => prev.map(arquivo =>
                arquivo._id === arquivoId
                    ? { ...arquivo, selectedForDelivery: selecionado }
                    : arquivo
            ));

            setTotalSelecionados(response.data.totalSelecionados);
        } catch (error) {
            console.error('Erro ao marcar arquivo para entrega:', error);
            setAlerta({
                tipo: 'error',
                mensagem: 'Erro ao marcar arquivo para entrega'
            });
        }
    }, [pedidoId, setAlerta]);

    const uploadArquivo = useCallback(async (file, relativePath = '', substituir = false) => {
        if (!pedidoId || !file) return;

        const formData = new FormData();
        const uploadPath = relativePath ||
            (currentPath === '/' ? '/' + file.name : currentPath + '/' + file.name);

        formData.append('relativePath', uploadPath);
        formData.append('arquivo', file);
        formData.append('substituir', substituir.toString());

        const fileId = Math.random().toString(36).substring(7);

        try {
            setUploading(true);
            setUploadProgress(prev => ({
                ...prev,
                [fileId]: {
                    fileName: file.name,
                    progress: 0,
                    size: file.size
                }
            }));

            const response = await api.post(`/arquivos/${pedidoId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(prev => ({
                        ...prev,
                        [fileId]: {
                            ...prev[fileId],
                            progress: percentCompleted
                        }
                    }));
                }
            });

            if (response.data.diretorio === currentPath) {
                setArquivos(prev => [...prev, response.data]);
            }

            setAlerta({
                tipo: 'success',
                mensagem: 'Arquivo enviado com sucesso'
            });

            setArquivoDuplicado(null);

            // Remove este arquivo do progresso após completar
            setUploadProgress(prev => {
                const { [fileId]: removed, ...rest } = prev;
                return rest;
            });

        } catch (error) {
            console.error('Erro no upload:', error);

            if (error.response?.status === 409) {
                setArquivoDuplicado({
                    file,
                    relativePath: uploadPath,
                    arquivoExistente: error.response.data.arquivoExistente
                });
                return;
            }

            setAlerta({
                tipo: 'error',
                mensagem: error.response?.data?.message || 'Erro ao enviar arquivo'
            });
        } finally {
            // Se não houver mais arquivos em progresso, finaliza o upload
            setUploadProgress(prev => {
                if (Object.keys(prev).length === 0) {
                    setUploading(false);
                }
                return prev;
            });
        }
    }, [pedidoId, currentPath, setAlerta]);

    const confirmarSubstituicao = useCallback(async () => {
        if (!arquivoDuplicado) return;

        const { file, relativePath } = arquivoDuplicado;
        setArquivoDuplicado(null);
        await uploadArquivo(file, relativePath, true);
    }, [arquivoDuplicado, uploadArquivo]);

    const cancelarSubstituicao = useCallback(() => {
        setArquivoDuplicado(null);
    }, []);

    const processarItem = async (item) => {
        if (item.isDirectory) {
            const formData = new FormData();
            formData.append('relativePath', item.fullPath);
            formData.append('isDirectory', 'true');
            await api.post(`/arquivos/${pedidoId}/directory`, formData);

            if (item.children) {
                for (const child of item.children) {
                    if (child.type === 'directory') {
                        const formData = new FormData();
                        formData.append('relativePath', child.path);
                        formData.append('isDirectory', 'true');
                        await api.post(`/arquivos/${pedidoId}/directory`, formData);
                    } else if (child.type === 'file') {
                        await uploadArquivo(child.file, child.path);
                    }
                }
            }
        } else if (item.type === 'file') {
            await uploadArquivo(item.file, item.path);
        }
    };

    const uploadPasta = useCallback(async (items) => {
        if (!pedidoId) return;

        try {
            setUploading(true);

            for (const item of items) {
                await processarItem(item);
            }

            setAlerta({
                tipo: 'success',
                mensagem: 'Pasta enviada com sucesso'
            });
        } catch (error) {
            console.error('Erro no upload da pasta:', error);
            setAlerta({
                tipo: 'error',
                mensagem: 'Erro ao enviar pasta'
            });
        } finally {
            setUploading(false);
            setUploadProgress({});
            await fetchArquivos(currentPath);
        }
    }, [pedidoId, currentPath, uploadArquivo, fetchArquivos, setAlerta]);

    const excluirArquivos = useCallback(async (arquivoIds) => {
        if (!pedidoId || !arquivoIds.length) return;

        try {
            await api.delete(`/arquivos/${pedidoId}/batch`, {
                data: { arquivoIds }
            });
            setArquivos(prev => prev.filter(arquivo => !arquivoIds.includes(arquivo._id)));
            setAlerta({
                tipo: 'success',
                mensagem: arquivoIds.length > 1
                    ? 'Arquivos excluídos com sucesso'
                    : 'Arquivo excluído com sucesso'
            });
        } catch (error) {
            console.error('Erro ao excluir arquivos:', error);
            setAlerta({
                tipo: 'error',
                mensagem: 'Erro ao excluir arquivos'
            });
        }
    }, [pedidoId, setAlerta]);

    const excluirArquivo = useCallback(async (arquivoId) => {
        return excluirArquivos([arquivoId]);
    }, [excluirArquivos]);

    const downloadArquivo = useCallback(async (arquivoId, nomeArquivo) => {
        if (!pedidoId || !arquivoId) return;

        try {
            const response = await api.get(`/arquivos/${pedidoId}/download/${arquivoId}`, {
                responseType: 'blob'
            });

            // Verifica se a resposta é um JSON (erro)
            const contentType = response.headers['content-type'];
            if (contentType && contentType.includes('application/json')) {
                const reader = new FileReader();
                reader.onload = async () => {
                    const error = JSON.parse(reader.result);
                    if (error.code === 'PAYMENT_REQUIRED') {
                        setAlerta({
                            tipo: 'warning',
                            mensagem: 'Download bloqueado. Aguardando confirmação de pagamento.'
                        });
                    } else {
                        setAlerta({
                            tipo: 'error',
                            mensagem: error.message || 'Erro ao baixar arquivo'
                        });
                    }
                };
                reader.readAsText(response.data);
                return;
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeArquivo);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erro no download:', error);
            setAlerta({
                tipo: 'error',
                mensagem: error.response?.data?.message || 'Erro ao baixar arquivo'
            });
        }
    }, [pedidoId, setAlerta]);

    const buscarArquivosRecursivamente = async (diretorio) => {
        const response = await api.get(`/arquivos/${pedidoId}`, {
            params: {
                diretorio,
                recursivo: true
            }
        });

        return response.data.itens.filter(item => !item.isFolder);
    };

    const downloadTodosArquivos = useCallback(async (isMobile = false) => {
        if (!pedidoId) return;

        setDownloadingAll(true);
        try {
            const todosArquivos = await buscarArquivosRecursivamente(currentPath);

            if (todosArquivos.length === 0) {
                setAlerta({
                    tipo: 'warning',
                    mensagem: 'Não há arquivos para baixar'
                });
                return;
            }

            setAlerta({
                tipo: 'info',
                mensagem: 'Preparando download dos arquivos...'
            });

            const downloadApi = axios.create({
                baseURL: api.defaults.baseURL,
                timeout: 600000,
                withCredentials: true,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            let falhas = 0;

            if (isMobile) {
                // Em dispositivos móveis, baixa um arquivo por vez
                for (const arquivo of todosArquivos) {
                    try {
                        const response = await downloadApi.get(`/arquivos/${pedidoId}/download/${arquivo._id}`, {
                            responseType: 'blob',
                            timeout: 120000
                        });

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', arquivo.nome);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);

                        // Pequeno delay entre downloads para não sobrecarregar
                        await new Promise(resolve => setTimeout(resolve, 500));
                    } catch (error) {
                        console.error('Erro ao baixar arquivo:', arquivo.nome, error);
                        falhas++;
                    }
                }
            } else {
                // Em desktop, mantém o comportamento de ZIP
                const zip = new JSZip();
                const downloads = todosArquivos.map(async (arquivo) => {
                    try {
                        const response = await downloadApi.get(`/arquivos/${pedidoId}/download/${arquivo._id}`, {
                            responseType: 'blob',
                            timeout: 120000
                        });

                        const caminhoNoZip = arquivo.diretorio === '/'
                            ? arquivo.nome
                            : `${arquivo.diretorio.slice(1)}/${arquivo.nome}`;

                        zip.file(caminhoNoZip, response.data);
                    } catch (error) {
                        console.error('Erro ao baixar arquivo:', arquivo.nome, error);
                        falhas++;
                    }
                });

                await Promise.all(downloads);

                const content = await zip.generateAsync({
                    type: 'blob',
                    compression: 'DEFLATE',
                    compressionOptions: {
                        level: 6
                    }
                });

                saveAs(content, `pedido-${pedidoId.slice(-4).toUpperCase()}.zip`);
            }

            if (falhas === todosArquivos.length) {
                throw new Error('Não foi possível baixar nenhum arquivo');
            }

            if (falhas > 0) {
                setAlerta({
                    tipo: 'warning',
                    mensagem: `${falhas} arquivo(s) não puderam ser baixados`
                });
            }

            setAlerta({
                tipo: 'success',
                mensagem: 'Download concluído com sucesso'
            });
        } catch (error) {
            console.error('Erro ao baixar todos os arquivos:', error);
            setAlerta({
                tipo: 'error',
                mensagem: 'Erro ao baixar arquivos. Por favor, tente novamente.'
            });
        } finally {
            setDownloadingAll(false);
        }
    }, [pedidoId, currentPath, setAlerta]);

    const navegarPara = useCallback(async (diretorio) => {
        setCurrentPath(diretorio);
        setPathHistory(prev => [...prev, diretorio]);
        await fetchArquivos(diretorio);
    }, [fetchArquivos]);

    const voltarDiretorio = useCallback(async () => {
        if (pathHistory.length > 1) {
            const newHistory = [...pathHistory];
            newHistory.pop();
            const previousDir = newHistory[newHistory.length - 1];
            setCurrentPath(previousDir);
            setPathHistory(newHistory);
            await fetchArquivos(previousDir);
        }
    }, [pathHistory, fetchArquivos]);

    const abrirPreview = useCallback((arquivo) => {
        setPreviewFile(arquivo);
    }, []);

    const fecharPreview = useCallback(() => {
        setPreviewFile(null);
    }, []);

    return {
        arquivos,
        pedido,
        loading,
        uploading,
        downloadingAll,
        uploadProgress,
        currentPath,
        pathHistory,
        arquivoDuplicado,
        previewFile,
        isSelectionFolder,
        totalSelecionados,
        fetchArquivos,
        uploadArquivo,
        uploadPasta,
        excluirArquivo,
        excluirArquivos,
        downloadArquivo,
        downloadTodosArquivos,
        navegarPara,
        voltarDiretorio,
        confirmarSubstituicao,
        cancelarSubstituicao,
        abrirPreview,
        fecharPreview,
        marcarParaEntrega,
        toggleSelectionFolder
    };
};
