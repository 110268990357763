import { useState, useEffect } from 'react';
import paymentService from '../services/paymentService';

// Cache object to store payment data
const paymentsCache = {
    data: {},
    timestamp: {},
    CACHE_DURATION: 5 * 60 * 1000, // 5 minutes in milliseconds
};

const getCacheKey = (type, page, limit) => `${type}-${page}-${limit}`;

const isCacheValid = (key) => {
    if (!paymentsCache.timestamp[key]) return false;
    const now = Date.now();
    return (now - paymentsCache.timestamp[key]) < paymentsCache.CACHE_DURATION;
};

export const usePayments = (type = 'all') => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0
    });

    const fetchPayments = async () => {
        try {
            const cacheKey = getCacheKey(type, pagination.page, pagination.limit);

            // Check if we have valid cached data
            if (isCacheValid(cacheKey) && paymentsCache.data[cacheKey]) {
                const cachedData = paymentsCache.data[cacheKey];
                setPayments(cachedData.items);
                setPagination(prev => ({
                    ...prev,
                    total: cachedData.total || 0
                }));
                setLoading(false);
                return;
            }

            setLoading(true);
            setError(null);

            let response;

            if (type === 'subscription') {
                response = await paymentService.getUserSubscriptionPayments(
                    pagination.page,
                    pagination.limit
                );
            } else if (type === 'order') {
                response = await paymentService.getUserOrderPayments(
                    pagination.page,
                    pagination.limit
                );
            } else {
                // Para 'all', fazemos duas chamadas e combinamos os resultados
                const [subscriptions, orders] = await Promise.all([
                    paymentService.getUserSubscriptionPayments(pagination.page, pagination.limit),
                    paymentService.getUserOrderPayments(pagination.page, pagination.limit)
                ]);


                const combinedPayments = [
                    ...(subscriptions.items || []).map(p => ({ ...p, paymentType: 'subscription' })),
                    ...(orders.items || []).map(p => ({ ...p, paymentType: 'order' }))
                ].sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));

                response = {
                    items: combinedPayments,
                    total: (subscriptions.total || 0) + (orders.total || 0)
                };
            }


            if (response && Array.isArray(response.items)) {
                // Update cache
                paymentsCache.data[cacheKey] = response;
                paymentsCache.timestamp[cacheKey] = Date.now();

                setPayments(response.items);
                setPagination(prev => ({
                    ...prev,
                    total: response.total || 0
                }));
            } else {
                console.error('Invalid response format:', response);
                setError('Formato de resposta inválido');
            }
        } catch (err) {
            console.error('Error in usePayments:', err);
            setError(err.message || 'Erro ao carregar pagamentos');
            setPayments([]);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (_, newPage) => {
        setPagination(prev => ({ ...prev, page: newPage }));
    };

    const handleLimitChange = (event) => {
        const newLimit = parseInt(event.target.value);
        setPagination(prev => ({ ...prev, limit: newLimit, page: 1 }));
    };

    // Função para forçar atualização dos dados ignorando o cache
    const forceRefresh = async () => {
        const cacheKey = getCacheKey(type, pagination.page, pagination.limit);
        delete paymentsCache.data[cacheKey];
        delete paymentsCache.timestamp[cacheKey];
        await fetchPayments();
    };

    useEffect(() => {
        fetchPayments();
    }, [pagination.page, pagination.limit, type]);

    return {
        payments,
        loading,
        error,
        pagination,
        handlePageChange,
        handleLimitChange,
        refresh: forceRefresh // Agora usando forceRefresh para garantir dados atualizados quando necessário
    };
};
