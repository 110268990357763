import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Chip,
    IconButton,
    Tooltip,
    useTheme,
    alpha,
    Stack,
    Divider
} from '@mui/material';
import {
    ReceiptLong as ReceiptIcon,
    AccessTime as AccessTimeIcon,
    EventAvailable as EventAvailableIcon,
    OpenInNew as OpenInNewIcon,
    PaidOutlined as PaidIcon,
    LocalAtm as LocalAtmIcon,
    CheckCircleOutline as CheckCircleIcon,
    ErrorOutline as ErrorIcon,
    WarningAmber as WarningIcon,
    Description as InvoiceIcon
} from '@mui/icons-material';
import InvoiceDetailsDialog from './InvoiceDetailsDialog';
import api from '../../api';
import paymentService from '../../services/paymentService';
import { formatarDataComFuso } from '../../utils/dateUtils';

const getStatusConfig = (status) => {
    const configs = {
        RECEIVED: {
            color: 'success',
            icon: <CheckCircleIcon fontSize="small" />,
            label: 'Recebido',
            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.1),
            borderColor: (theme) => theme.palette.success.main
        },
        CONFIRMED: {
            color: 'success',
            icon: <CheckCircleIcon fontSize="small" />,
            label: 'Confirmado',
            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.1),
            borderColor: (theme) => theme.palette.success.main
        },
        PENDING: {
            color: 'warning',
            icon: <WarningIcon fontSize="small" />,
            label: 'Pendente',
            backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1),
            borderColor: (theme) => theme.palette.warning.main
        },
        OVERDUE: {
            color: 'error',
            icon: <ErrorIcon fontSize="small" />,
            label: 'Atrasado',
            backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
            borderColor: (theme) => theme.palette.error.main
        },
        default: {
            color: 'default',
            icon: <WarningIcon fontSize="small" />,
            label: 'Status Desconhecido',
            backgroundColor: (theme) => alpha(theme.palette.grey[500], 0.1),
            borderColor: (theme) => theme.palette.grey[500]
        }
    };
    return configs[status] || configs.default;
};

const PaymentCard = ({ payment }) => {
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleViewInvoice = async (invoiceId) => {
        try {
            setLoading(true);
            setInvoiceDialogOpen(true);
            const response = await api.get(`/pagamentos/invoices/${invoiceId}`);
            setInvoiceDetails(response.data);
        } catch (error) {
            console.error('Erro ao carregar nota fiscal:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseInvoiceDialog = () => {
        setInvoiceDialogOpen(false);
        setInvoiceDetails(null);
    };

    const theme = useTheme();
    const statusConfig = getStatusConfig(payment.status);
    const isOrder = payment.pedidoInfo || payment.paymentType === 'order';

    const getDescription = () => {
        if (isOrder && payment.pedidoInfo) {
            return `Pedido #${payment.pedidoInfo.id.toString().slice(-4).toUpperCase()}`;
        }
        return payment.description || 'Pagamento';
    };

    const getStatusText = () => {
        if (isOrder && payment.pedidoInfo?.status) {
            return payment.pedidoInfo.statusPagamento || payment.status;
        }
        return payment.status;
    };

    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(payment.value || payment.pedidoInfo?.total || 0);

    return (
        <>
            <Card
                elevation={0}
                sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 3,
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: theme.palette.background.paper,
                    position: 'relative',
                    overflow: 'visible',
                    '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
                        borderColor: theme.palette.primary.main,
                        '& .payment-icon': {
                            transform: 'scale(1.1)',
                            color: theme.palette.primary.main
                        }
                    }
                }}
            >
                <CardContent sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2.5 }}>
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                <LocalAtmIcon
                                    className="payment-icon"
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        transition: 'all 0.3s ease-in-out'
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 600,
                                        color: theme.palette.text.primary,
                                        letterSpacing: '-0.5px'
                                    }}
                                >
                                    {getDescription()}
                                </Typography>
                            </Box>
                            <Chip
                                icon={statusConfig.icon}
                                label={paymentService.formatPaymentStatus(getStatusText())}
                                size="small"
                                sx={{
                                    fontWeight: 500,
                                    backgroundColor: statusConfig.backgroundColor(theme),
                                    border: `1px solid ${statusConfig.borderColor(theme)}`,
                                    color: statusConfig.borderColor(theme),
                                    '& .MuiChip-icon': {
                                        color: 'inherit'
                                    }
                                }}
                            />
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            {payment.invoiceUrl && (
                                <Tooltip title="Ver fatura" arrow>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        href={payment.invoiceUrl}
                                        target="_blank"
                                        sx={{
                                            border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                                            backdropFilter: 'blur(8px)',
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                borderColor: theme.palette.primary.main,
                                                transform: 'scale(1.05)'
                                            }
                                        }}
                                    >
                                        <ReceiptIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {payment._raw?.asaas?.notaFiscalId && ['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(payment.status) && (
                                <Tooltip title="Ver nota fiscal" arrow>
                                    <IconButton
                                        color="success"
                                        size="small"
                                        onClick={() => handleViewInvoice(payment.pedidoInfo.asaas.notaFiscalId)}
                                        sx={{
                                            border: `1px solid ${alpha(theme.palette.success.main, 0.5)}`,
                                            backdropFilter: 'blur(8px)',
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                backgroundColor: alpha(theme.palette.success.main, 0.1),
                                                borderColor: theme.palette.success.main,
                                                transform: 'scale(1.05)'
                                            }
                                        }}
                                    >
                                        <InvoiceIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Box>

                    {payment.pedidoInfo?.mensagemResumo && (
                        <Box
                            sx={{
                                mb: 2.5,
                                p: 2,
                                borderRadius: 2,
                                backgroundColor: alpha(theme.palette.info.main, 0.05),
                                border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`,
                            }}
                        >
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ lineHeight: 1.6 }}
                            >
                                {payment.pedidoInfo.mensagemResumo}
                            </Typography>
                        </Box>
                    )}

                    <Divider sx={{ my: 2 }} />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 2
                        }}
                    >
                        <Stack spacing={1.5}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <AccessTimeIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
                                <Typography variant="body2" color="text.secondary">
                                    Vencimento: {formatarDataComFuso(payment.dueDate)}
                                </Typography>
                            </Box>
                            {payment.pedidoInfo?.dataEntrega && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <EventAvailableIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
                                    <Typography variant="body2" color="text.secondary">
                                        Entrega: {formatarDataComFuso(payment.pedidoInfo.dataEntrega)}
                                    </Typography>
                                </Box>
                            )}
                        </Stack>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: alpha(theme.palette.primary.main, 0.05),
                                padding: '8px 16px',
                                borderRadius: 2
                            }}
                        >
                            <PaidIcon sx={{ color: theme.palette.primary.main }} />
                            <Typography
                                variant="h7"
                                color="primary"
                                sx={{
                                    fontWeight: 600,
                                    letterSpacing: '-0.5px'
                                }}
                            >
                                {formattedValue}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <InvoiceDetailsDialog
                open={invoiceDialogOpen}
                onClose={handleCloseInvoiceDialog}
                invoice={invoiceDetails}
                loading={loading}
            />
        </>
    );
};

export default React.memo(PaymentCard);
