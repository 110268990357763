import React, { useRef } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Paper,
} from '@mui/material';
import {
    CloudUpload,
    CreateNewFolder,
} from '@mui/icons-material';

const UploadArea = ({
    onDragOver,
    onDragLeave,
    onDrop,
    onFileSelect,
    onFolderSelect,
    uploading,
    uploadProgress,
    dragOver,
}) => {
    const fileInput = useRef(null);
    const folderInput = useRef(null);

    return (
        <Paper
            elevation={0}
            sx={{
                mb: 3,
                p: 3,
                border: '2px dashed',
                borderColor: dragOver ? 'primary.main' : 'divider',
                borderRadius: 3,
                bgcolor: dragOver ? 'action.hover' : 'transparent',
                transition: 'all 0.2s'
            }}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            <input
                type="file"
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={onFileSelect}
                multiple
            />
            <input
                type="file"
                ref={folderInput}
                style={{ display: 'none' }}
                onChange={onFolderSelect}
                webkitdirectory=""
                directory=""
                multiple
            />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2
            }}>
                <CloudUpload sx={{ fontSize: 48, color: 'primary.main' }} />
                <Typography variant="h6" color="textSecondary" align="center">
                    Arraste e solte arquivos ou pastas aqui
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={uploading && !uploadProgress ? <CircularProgress size={20} /> : null}
                        onClick={() => fileInput.current?.click()}
                        disabled={uploading && !uploadProgress}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3
                        }}
                    >
                        {uploading && !uploadProgress ? 'Enviando...' : 'Selecionar Arquivos'}
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        startIcon={<CreateNewFolder />}
                        onClick={() => folderInput.current?.click()}
                        disabled={uploading && !uploadProgress}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3
                        }}
                    >
                        Selecionar Pastas
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default UploadArea;
