import React from 'react';
import { Grid, TextField, Typography, Divider, CircularProgress } from '@mui/material';
import useCep from '../../hooks/useCep';

const BasicInfoForm = ({ cliente, onChange, formErrors = {}, disabled = false }) => {
    const handleAddressChange = (addressData) => {
        // Atualiza cada campo do endereço individualmente usando a função onChange
        onChange('endereco', addressData.endereco || '');
        onChange('bairro', addressData.bairro || '');
        onChange('cidade', addressData.cidade || '');
        onChange('estado', addressData.estado || '');
    };

    const { fetchAddress, loading: loadingCep, error: cepError } = useCep(handleAddressChange);

    const handleCepChange = (e) => {
        const cep = e.target.value;
        onChange('cep', cep);

        // Se o CEP tiver 8 dígitos, busca o endereço
        const cleanCep = cep.replace(/\D/g, '');
        if (cleanCep.length === 8) {
            fetchAddress(cleanCep);
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Informações Básicas
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Nome"
                    value={cliente.nome}
                    onChange={(e) => onChange('nome', e.target.value)}
                    fullWidth
                    required
                    error={!!formErrors.nome}
                    helperText={formErrors.nome}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Email"
                    type="email"
                    value={cliente.email}
                    onChange={(e) => onChange('email', e.target.value)}
                    fullWidth
                    required
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Documento (CPF/CNPJ)"
                    value={cliente.documento}
                    onChange={(e) => onChange('documento', e.target.value)}
                    fullWidth
                    required
                    error={!!formErrors.documento}
                    helperText={formErrors.documento}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Telefone"
                    value={cliente.telefone}
                    onChange={(e) => onChange('telefone', e.target.value)}
                    fullWidth
                    error={!!formErrors.telefone}
                    helperText={formErrors.telefone}
                    disabled={disabled}
                    placeholder="(11) 99999-9999"
                />
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                    Endereço
                </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField
                    label="CEP"
                    value={cliente.cep}
                    onChange={handleCepChange}
                    fullWidth
                    error={!!formErrors.cep || !!cepError}
                    helperText={cepError || formErrors.cep}
                    disabled={disabled}
                    placeholder="12345-678"
                    InputProps={{
                        endAdornment: loadingCep && (
                            <CircularProgress size={20} />
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={12} md={8}>
                <TextField
                    label="Endereço"
                    value={cliente.endereco}
                    onChange={(e) => onChange('endereco', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={2}>
                <TextField
                    label="Número"
                    value={cliente.numero}
                    onChange={(e) => onChange('numero', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField
                    label="Complemento"
                    value={cliente.complemento}
                    onChange={(e) => onChange('complemento', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Bairro"
                    value={cliente.bairro}
                    onChange={(e) => onChange('bairro', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Cidade"
                    value={cliente.cidade}
                    onChange={(e) => onChange('cidade', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Estado"
                    value={cliente.estado}
                    onChange={(e) => onChange('estado', e.target.value)}
                    fullWidth
                    disabled={disabled}
                    placeholder="SP"
                    inputProps={{ maxLength: 2 }}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                    Informações Adicionais
                </Typography>
            </Grid>


            <Grid item xs={12} md={6}>
                <TextField
                    label="Emails Adicionais"
                    value={cliente.emailsAdicionais ? cliente.emailsAdicionais.join(', ') : ''}
                    onChange={(e) => onChange('emailsAdicionais', e.target.value.split(',').map(email => email.trim()))}
                    fullWidth
                    disabled={disabled}
                    placeholder="email1@exemplo.com, email2@exemplo.com"
                    helperText="Separe múltiplos emails por vírgula"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Inscrição Municipal"
                    value={cliente.inscricaoMunicipal}
                    onChange={(e) => onChange('inscricaoMunicipal', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Inscrição Estadual"
                    value={cliente.inscricaoEstadual}
                    onChange={(e) => onChange('inscricaoEstadual', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Observações"
                    value={cliente.observacoes}
                    onChange={(e) => onChange('observacoes', e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default BasicInfoForm;
