import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  Button
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import FolderIcon from '@mui/icons-material/Folder';

import TabelaReutilizavel from "./shared/table/TabelaReutilizavel";
import TableSkeleton from "./shared/TableSkeleton";
import AlertaReutilizavel from "./shared/AlertaReutilizavel";
import PedidosFilter from "./PedidosFilter";
import PaymentDetailsDialog from "./PaymentDetailsDialog";
import PaginationControls from "./shared/PaginationControls";
import ArquivosModal from "./pedido/ArquivosModal";

import { usePaymentHandling } from "../hooks/usePaymentHandling";
import { usePedidosFilters } from "../hooks/usePedidosFilters";
import { useAlerta } from "../hooks/useAlerta";
import { usePedidos } from "../hooks/usePedidos";
import { transformPedidosData, getCabecalhos } from "../utils/pedidosTransform";

const ListaPedidos = React.forwardRef(({ initialFilters = {}, readOnly = false, mostrarFormulario, toggleFormulario, isSubmitting }, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isMounted = useRef(true);
  const [socketError, setSocketError] = useState(null);
  const [selectedPedidoId, setSelectedPedidoId] = useState(null);
  const [showArquivosModal, setShowArquivosModal] = useState(false);

  // Hooks personalizados
  const {
    pagination,
    filters,
    showFilters,
    setShowFilters,
    handleFilterChange,
    clearFilters,
    handlePageChange,
    handleLimitChange
  } = usePedidosFilters(initialFilters);

  const {
    alerta,
    showAlerta,
    hideAlerta,
    showError,
    showSuccess,
    setAlerta
  } = useAlerta();

  const {
    pedidosData,
    isPedidosLoading,
    pedidosError,
    clientes,
    atualizarStatus,
    handleRetiradaChange,
    formatDate,
    socketConnected,
    optimisticUpdates
  } = usePedidos(filters, pagination, readOnly, showSuccess, showError, setSocketError);

  const {
    paymentDetails,
    showPaymentDetails,
    isLoadingPayment,
    handlePaymentAction,
    setShowPaymentDetails,
    getOptimisticState
  } = usePaymentHandling(() => {
    queryClient.invalidateQueries(['pedidos']);
  }, setAlerta);

  // Navigation handler
  const handleDetalhes = useCallback((pedidoId) => {
    if (!isMounted.current) return;
    navigate(`/visualizar-pedido/${pedidoId}`);
  }, [navigate]);

  const handleArquivosClick = useCallback((pedidoId) => {
    setSelectedPedidoId(pedidoId);
    setShowArquivosModal(true);
  }, []);

  // Memoized values
  const showClienteFilter = !initialFilters.clienteId;
  const cabecalhos = useMemo(() => getCabecalhos(readOnly, showClienteFilter), [readOnly, showClienteFilter]);

  // Transform pedidos data with optimistic updates
  const transformedPedidos = useMemo(() => {
    const pedidos = pedidosData?.pedidos || [];

    // Primeiro aplicar atualizações otimistas do usePaymentHandling
    const pedidosComPaymentUpdates = getOptimisticState(pedidos);

    // Depois aplicar atualizações otimistas do usePedidos
    const updatedPedidos = pedidosComPaymentUpdates.map(pedido => {
      const optimisticUpdate = optimisticUpdates[pedido._id];
      if (optimisticUpdate) {
        return {
          ...pedido,
          ...optimisticUpdate,
          asaas: {
            ...(pedido.asaas || {}),
            ...(optimisticUpdate.asaas || {})
          }
        };
      }
      return pedido;
    });

    // Aplicar transformação padrão
    const transformed = transformPedidosData(updatedPedidos, showClienteFilter, formatDate, readOnly);

    // Adicionar ícone de arquivos
    return transformed.map(pedido => ({
      ...pedido,
      Arquivos: (
        <Tooltip title="Ver Arquivos">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleArquivosClick(pedido.Arquivos);
            }}
            color="primary"
          >
            <FolderIcon />
          </IconButton>
        </Tooltip>
      )
    }));
  }, [pedidosData?.pedidos, optimisticUpdates, showClienteFilter, formatDate, readOnly, handleArquivosClick, getOptimisticState]);

  // Handle payment action with optimistic updates
  const handlePaymentActionWithOptimistic = useCallback((pedidoId, action) => {
    const pedido = pedidosData?.pedidos?.find(p => p._id === pedidoId);
    if (!pedido) return;

    handlePaymentAction(pedidoId, action, pedidosData?.pedidos || []);
  }, [handlePaymentAction, pedidosData?.pedidos]);

  if (pedidosError) {
    return (
      <Typography color="error">
        Erro ao carregar pedidos. Por favor, tente novamente.
      </Typography>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <AlertaReutilizavel
        mensagem={alerta?.mensagem || ""}
        tipo={alerta?.tipo || "info"}
        aberto={alerta?.aberto || false}
        onFechar={hideAlerta}
      />

      {socketError && (
        <Snackbar
          open={Boolean(socketError)}
          autoHideDuration={6000}
          onClose={() => setSocketError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSocketError(null)} severity="error">
            Erro de conexão: {socketError}
          </Alert>
        </Snackbar>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
        {!readOnly && toggleFormulario && (
          <Button
            variant="contained"
            color={mostrarFormulario ? "secondary" : "primary"}
            onClick={toggleFormulario}
            disabled={isSubmitting}
          >
            {mostrarFormulario ? "Cancelar" : "Novo Pedido"}
          </Button>
        )}
        <IconButton onClick={() => setShowFilters(!showFilters)} color="primary">
          <FilterAltIcon />
        </IconButton>
        {showFilters && (
          <IconButton onClick={clearFilters} color="secondary">
            <ClearIcon />
          </IconButton>
        )}
      </Box>

      {showFilters && (
        <PedidosFilter
          filters={filters}
          handleFilterChange={handleFilterChange}
          showClienteFilter={showClienteFilter}
          readOnly={readOnly}
          clientes={clientes}
        />
      )}

      <>
        <TabelaReutilizavel
          cabecalhos={cabecalhos}
          dados={transformedPedidos}
          handleStatusChange={!readOnly ? atualizarStatus : undefined}
          handleDetalhes={handleDetalhes}
          handleRetiradaChange={!readOnly ? handleRetiradaChange : undefined}
          handlePaymentAction={handlePaymentActionWithOptimistic}
          readOnly={readOnly}
          isLoading={isPedidosLoading}
          skeletonComponent={<TableSkeleton columns={cabecalhos.length} />}
        />

        <PaginationControls
          page={pagination.page}
          limit={pagination.limit}
          total={pedidosData?.pagination?.total || 0}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
          loading={isPedidosLoading || isLoadingPayment}
          itemsPerPageOptions={[10, 25, 50]}
        />
      </>

      <PaymentDetailsDialog
        open={showPaymentDetails}
        onClose={() => setShowPaymentDetails(false)}
        payment={paymentDetails}
      />

      <ArquivosModal
        open={showArquivosModal}
        onClose={() => setShowArquivosModal(false)}
        pedidoId={selectedPedidoId}
        isAdmin={!readOnly}
      />
    </Box>
  );
});

export default React.memo(ListaPedidos);
