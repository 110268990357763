import React, { useState } from "react";
import {
  Typography,
  Paper,
  List,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import InboxIcon from "@mui/icons-material/Inbox";
import api from "../api";
import ConfirmationModal from "./shared/ConfirmationModal";
import AlertaReutilizavel from "./shared/AlertaReutilizavel";
import ServiceItem from "./ServiceItem";
const ListaServicos = ({ servicos, fetchServicos }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedServico, setSelectedServico] = useState(null);
  const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("nome-asc");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingId, setIsDeletingId] = useState(null);

  const handleDelete = (id) => {
    setSelectedServico(id);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedServico(null);
  };

  const handleConfirm = async () => {
    if (!selectedServico) return;

    setIsLoading(true);
    setIsDeletingId(selectedServico);
    try {
      await api.delete(`/servicos/${selectedServico}`);
      await fetchServicos();
      handleClose();
      setAlerta({ mensagem: "Serviço deletado com sucesso!", tipo: "success", aberto: true });
    } catch (error) {
      console.error("Erro ao deletar o serviço:", error);
      handleClose();
      setAlerta({
        mensagem: error.response?.data?.error || "Erro ao deletar serviço.",
        tipo: "error",
        aberto: true
      });
    } finally {
      setIsLoading(false);
      setIsDeletingId(null);
    }
  };

  const fecharAlerta = () => {
    setAlerta({ ...alerta, aberto: false });
  };

  const filteredAndSortedServices = servicos
    .filter(servico =>
      servico.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (servico.descricao && servico.descricao.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      switch (sortBy) {
        case "nome-asc":
          return a.nome.localeCompare(b.nome);
        case "nome-desc":
          return b.nome.localeCompare(a.nome);
        case "preco-asc":
          return parseFloat(a.preco) - parseFloat(b.preco);
        case "preco-desc":
          return parseFloat(b.preco) - parseFloat(a.preco);
        default:
          return 0;
      }
    });

  return (
    <>

      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar serviços..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="sort-select-label">
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <SortIcon fontSize="small" />
              Ordenar por
            </Box>
          </InputLabel>
          <Select
            labelId="sort-select-label"
            value={sortBy}
            label="Ordenar por"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <MenuItem value="nome-asc">Nome (A-Z)</MenuItem>
            <MenuItem value="nome-desc">Nome (Z-A)</MenuItem>
            <MenuItem value="preco-asc">Preço (Menor-Maior)</MenuItem>
            <MenuItem value="preco-desc">Preço (Maior-Menor)</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Paper
        elevation={4}
        sx={{
          padding: "24px",
          borderRadius: "12px",
          backgroundColor: "background.paper",
          minHeight: "400px",
          position: "relative",
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "background.paper",
              opacity: 0.7,
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {filteredAndSortedServices.length > 0 ? (
          <List>
            {filteredAndSortedServices.map((servico) => (
              <ServiceItem
                key={servico._id}
                servico={servico}
                onDelete={handleDelete}
                isDeleting={isDeletingId === servico._id}
              />
            ))}
          </List>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
              textAlign: "center",
              opacity: 0.7
            }}
          >
            <InboxIcon
              sx={{
                fontSize: 80,
                color: "text.secondary",
                mb: 2,
                opacity: 0.6
              }}
            />
            <Typography variant="h6" color="textSecondary">
              {searchTerm
                ? "Nenhum serviço encontrado para sua busca"
                : "Nenhum serviço cadastrado ainda"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {searchTerm
                ? "Tente buscar com outros termos"
                : "Comece cadastrando um novo serviço"}
            </Typography>
          </Box>
        )}
      </Paper>

      <ConfirmationModal
        show={showModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title="Confirmar Exclusão"
        body="Tem certeza de que deseja excluir este serviço?"
        isLoading={isLoading}
      />

      <AlertaReutilizavel
        mensagem={alerta.mensagem}
        tipo={alerta.tipo}
        aberto={alerta.aberto}
        onFechar={fecharAlerta}
      />
    </>
  );
};

export default ListaServicos;
