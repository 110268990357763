import paymentService from '../services/paymentService';

export const formatarValor = (valor) => `R$ ${valor?.toFixed(2) || "0.00"}`;

export const getStatusPagamentoLabel = (statusPagamento, valorPagar, asaasStatus) => {
    // Se o pedido foi pago com saldo, mostrar esse status independente de qualquer outra condição
    if (statusPagamento === 'pago_com_saldo') {
        return 'Pago com Saldo';
    }

    // Se tem status do Asaas, usar a formatação do paymentService
    if (asaasStatus) {
        return paymentService.formatPaymentStatus(asaasStatus);
    }

    // Se não tem status do Asaas, usar os status internos
    const statusMap = {
        "pendente": "Pendente",
        "pago": "Pago",
        "pago_parcial_saldo": "Pago Parcialmente com Saldo"
    };

    return statusMap[statusPagamento] || "Pendente";
};

export const meses = [
    { value: "1", label: "Janeiro" },
    { value: "2", label: "Fevereiro" },
    { value: "3", label: "Março" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Maio" },
    { value: "6", label: "Junho" },
    { value: "7", label: "Julho" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" }
];
