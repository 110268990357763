import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Grid,
  useTheme,
  Fade,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { ResumoPedido } from "../components/ResumoPedido";
import ServicoList from "../components/ServicoList";
import { ClienteSelect } from "../components/ClienteSelect";
import ListaPedidos from "../components/ListaPedidos";
import AlertaReutilizavel from "../components/shared/AlertaReutilizavel";
import { useCadastroPedidos } from "../hooks/useCadastroPedidos";
import PageHeader from "../components/shared/PageHeader";

const CadastroPedidos = () => {
  const theme = useTheme();
  const loadingOverlayColor = theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.7)'
    : 'rgba(255, 255, 255, 0.7)';

  const {
    mostrarFormulario,
    toggleFormulario,
    clientes,
    servicos,
    novoPedido,
    clienteZoomPlus,
    alerta,
    totais,
    dataEntrega,
    handleClienteChange,
    handleServicoAdd,
    handleUrgenciaChange,
    handleQuantidadeChange,
    handleDeleteServico,
    handleSubmit,
    setAlerta,
    listaPedidosRef,
    isLoading,
    isLoadingCliente,
    isSubmitting,
    isLoadingPedido,
    handleZoomPlusBenefitChange,
  } = useCadastroPedidos('create');

  return (
    <PageHeader title="Cadastro de Pedidos">
      <Box>
        {isLoading && (
          <Fade in={isLoading}>
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor={loadingOverlayColor}
              zIndex={1000}
            >
              <CircularProgress />
            </Box>
          </Fade>
        )}

        <Box>
          <AlertaReutilizavel
            mensagem={alerta.mensagem}
            tipo={alerta.tipo}
            aberto={alerta.aberto}
            onFechar={() => setAlerta({ ...alerta, aberto: false })}
          />

          <Box sx={{ mb: 3 }}>
            {mostrarFormulario && (
              <Box sx={{ mb: 4 }}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    {/* Coluna Esquerda - Seleção de Cliente e Serviços */}
                    <Grid item xs={12} md={8}>
                      <Paper sx={{ p: 2, mb: 2, position: 'relative' }}>
                        {isLoadingCliente && (
                          <Fade in={isLoadingCliente}>
                            <Box
                              position="absolute"
                              top={0}
                              left={0}
                              right={0}
                              bottom={0}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor={loadingOverlayColor}
                              zIndex={1}
                            >
                              <CircularProgress />
                            </Box>
                          </Fade>
                        )}
                        <ClienteSelect
                          clientes={clientes}
                          handleClienteChange={handleClienteChange}
                          clienteZoomPlus={clienteZoomPlus}
                          totais={totais}
                        />
                      </Paper>

                      <Paper sx={{ p: 0, mb: 2 }}>
                        <ServicoList
                          servicos={servicos}
                          novoPedido={novoPedido}
                          handleServicoAdd={handleServicoAdd}
                          handleQuantidadeChange={handleQuantidadeChange}
                          handleDeleteServico={handleDeleteServico}
                          disabled={isSubmitting}
                        />
                      </Paper>

                      <Paper sx={{ p: 2, mb: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2, color: theme.palette.text.secondary }}>
                          Opções Adicionais
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={novoPedido.isUrgent}
                                onChange={(e) => handleUrgenciaChange(e.target.checked)}
                                disabled={isSubmitting}
                              />
                            }
                            label="Ativar Taxa de Urgência"
                          />
                          {clienteZoomPlus?.isZoomPlus && (
                            <>
                              {/* Só mostra o checkbox de taxa de urgência gratuita se o cliente tiver disponível */}
                              {novoPedido.isUrgent && clienteZoomPlus.taxasUrgenciaDisponiveis > 0 && (
                                <Box>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={novoPedido.zoomPlus?.beneficiosUtilizados?.taxaUrgenciaGratis || false}
                                        onChange={() => handleZoomPlusBenefitChange('taxaUrgenciaGratis')}
                                        disabled={isSubmitting}
                                      />
                                    }
                                    label={`Usar Taxa de Urgência Gratuita (${clienteZoomPlus.taxasUrgenciaDisponiveis} disponível${clienteZoomPlus.taxasUrgenciaDisponiveis !== 1 ? 'is' : ''})`}
                                  />
                                </Box>
                              )}
                              {/* Só mostra o checkbox de modelo completa se o cliente tiver disponível */}
                              {clienteZoomPlus.usoModelosDisponiveis > 0 && (
                                <Box>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={novoPedido.zoomPlus?.beneficiosUtilizados?.modeloCompletaGratis || false}
                                        onChange={() => handleZoomPlusBenefitChange('modeloCompletaGratis')}
                                        disabled={isSubmitting}
                                      />
                                    }
                                    label={`Usar Modelo Completa Grátis (${clienteZoomPlus.usoModelosDisponiveis} disponível${clienteZoomPlus.usoModelosDisponiveis !== 1 ? 'is' : ''})`}
                                  />
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      </Paper>
                    </Grid>

                    {/* Coluna Direita - Resumo do Pedido */}
                    <Grid item xs={12} md={4}>
                      <Box sx={{ position: { md: 'sticky' }, top: { md: '20px' } }}>
                        <Paper sx={{ p: 2, mb: 2 }}>
                          <ResumoPedido
                            totais={totais}
                            dataEntrega={dataEntrega}
                            clienteZoomPlus={clienteZoomPlus}
                          />
                        </Paper>

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          size="large"
                          startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                          disabled={isSubmitting}
                          sx={{
                            py: 1.5,
                            fontSize: '1.1rem',
                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                            '&:hover': {
                              boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                            }
                          }}
                        >
                          {isSubmitting ? "Salvando..." : "Cadastrar Pedido"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            )}
          </Box>

          <ListaPedidos
            ref={listaPedidosRef}
            mostrarFormulario={mostrarFormulario}
            toggleFormulario={toggleFormulario}
            isSubmitting={isSubmitting}
          />
        </Box>
      </Box>
    </PageHeader>
  );
};

export default CadastroPedidos;
