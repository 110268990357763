import { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../api';
import { useSocketIO } from './useSocketIO';
import paymentService from '../services/paymentService';

const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 1000;

export const usePedidos = (filters, pagination, readOnly, showSuccess, showError, setSocketError) => {
    const queryClient = useQueryClient();
    const [optimisticUpdates, setOptimisticUpdates] = useState({});

    const retryOperation = async (operation, retries = 0) => {
        try {
            return await operation();
        } catch (error) {
            if (retries < MAX_RETRIES) {
                const delay = INITIAL_RETRY_DELAY * Math.pow(2, retries);
                await new Promise(resolve => setTimeout(resolve, delay));
                return retryOperation(operation, retries + 1);
            }
            throw error;
        }
    };

    // Função para atualizar status de pagamentos em lote
    const fetchPaymentStatuses = async (pedidosData) => {
        // Get IDs of pedidos with payment IDs
        const pedidosWithPayments = pedidosData.filter(pedido => pedido.asaas?.paymentId);
        if (pedidosWithPayments.length === 0) {
            return pedidosData;
        }

        try {
            // Update payment statuses in bulk
            const updatedPedidos = await paymentService.updatePaymentStatuses(
                pedidosWithPayments.map(pedido => pedido._id)
            );

            // Create a map of updated pedidos for easy lookup
            const updatedPedidosMap = updatedPedidos.reduce((acc, pedido) => {
                acc[pedido._id] = pedido;
                return acc;
            }, {});

            // Merge updated pedidos with original data, preserving client data
            return pedidosData.map(pedido => {
                const updatedPedido = updatedPedidosMap[pedido._id];
                if (updatedPedido) {
                    return {
                        ...pedido,
                        statusPagamento: updatedPedido.statusPagamento,
                        asaas: updatedPedido.asaas,
                        clienteId: updatedPedido.clienteId || pedido.clienteId
                    };
                }
                return pedido;
            });
        } catch (error) {
            console.error('Error fetching payment statuses:', error);
            return pedidosData;
        }
    };

    const {
        data: pedidosData,
        isLoading: isPedidosLoading,
        error: pedidosError
    } = useQuery({
        queryKey: ['pedidos', filters, pagination],
        queryFn: async () => {
            const filtrosValidos = Object.entries(filters).reduce((acc, [key, value]) => {
                if (value !== "" && value !== null && value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const queryParams = {
                ...filtrosValidos,
                page: pagination.page,
                limit: pagination.limit
            };

            const response = await api.get(`/pedidos?${new URLSearchParams(queryParams)}`);

            if (response.data && response.data.pedidos) {
                // Primeiro atualizar os status de pagamento em lote
                const pedidosWithUpdatedPayments = await fetchPaymentStatuses(response.data.pedidos);

                // Depois aplicar atualizações otimistas
                const updatedPedidos = pedidosWithUpdatedPayments.map(pedido => {
                    const update = optimisticUpdates[pedido._id];
                    if (update) {
                        return {
                            ...pedido,
                            status: update.status || pedido.status,
                            statusPagamento: update.statusPagamento || pedido.statusPagamento,
                            retirada: update.retirada !== undefined ? update.retirada : pedido.retirada,
                            asaas: {
                                ...pedido.asaas,
                                ...update.asaas
                            }
                        };
                    }
                    return pedido;
                });
                return { ...response.data, pedidos: updatedPedidos };
            }
            return response.data;
        },
        staleTime: 1000 * 60, // 1 minuto
        cacheTime: 1000 * 60 * 5, // 5 minutos
        refetchOnMount: true,
        refetchOnWindowFocus: true
    });

    const { data: clientesData = [] } = useQuery({
        queryKey: ['clientes'],
        queryFn: async () => {
            try {
                const response = await api.get("/clientes");
                return Array.isArray(response.data) ? response.data : [];
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
                return [];
            }
        },
        staleTime: 0,
        cacheTime: 0
    });

    const clientes = Array.isArray(clientesData) ? clientesData : [];

    const updatePaymentStatus = useCallback(async (pedidoId, paymentId) => {
        try {
            const paymentDetails = await retryOperation(() =>
                paymentService.getPayment(paymentId)
            );

            if (!paymentDetails) return;

            const isPaymentReceived = ['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(paymentDetails.status);

            const newStatusPagamento = isPaymentReceived ? 'pago' : 'pendente';

            setOptimisticUpdates(prev => ({
                ...prev,
                [pedidoId]: {
                    statusPagamento: newStatusPagamento,
                    asaas: {
                        paymentStatus: paymentDetails.status,
                        paymentId: paymentId,
                        billingType: paymentDetails.billingType,
                        dueDate: paymentDetails.dueDate
                    }
                }
            }));

            queryClient.invalidateQueries(['pedidos']);
        } catch (error) {
            console.error('Error updating payment status:', error);
            showError(error);
        }
    }, [queryClient, showError]);

    const handleSocketMessage = useCallback((message) => {
        try {

            // O message já contém event e data
            const { event, data } = message.event ? message : { event: message.type, data: message.data };

            if (event === 'pedido_updated') {
                if (data.pedidoId) {
                    setOptimisticUpdates(prev => ({
                        ...prev,
                        [data.pedidoId]: {
                            status: data.status,
                            statusPagamento: data.statusPagamento,
                            asaas: data.asaas || {}
                        }
                    }));

                    // Se tiver paymentId, buscar status atualizado do pagamento
                    if (data.asaas?.paymentId) {
                        updatePaymentStatus(data.pedidoId, data.asaas.paymentId)
                            .catch(error => console.error('[usePedidos] Erro ao atualizar status de pagamento:', error));
                    }
                }
                queryClient.invalidateQueries(['pedidos'])
                    .catch(error => console.error('[usePedidos] Erro ao invalidar queries:', error));
            } else if (event === 'payment_updated' && data.pedidoId && data.paymentId) {
                updatePaymentStatus(data.pedidoId, data.paymentId)
                    .catch(error => console.error('[usePedidos] Erro ao atualizar status de pagamento:', error));
            }
        } catch (error) {
            console.error('[usePedidos] Erro ao processar mensagem websocket:', error);
        }
    }, [queryClient, updatePaymentStatus]);

    const { isConnected } = useSocketIO((message) => {
        try {
            handleSocketMessage(message);
        } catch (error) {
            console.error('[usePedidos] Error handling socket message:', error);
            if (setSocketError) {
                setSocketError(error.message);
            }
        }
    }, {
        reconnectOnClose: true,
        retryAttempts: 5,
        onError: (error) => {
            console.error('[usePedidos] Socket error:', error);
            if (setSocketError) {
                setSocketError(error.message);
            }
        }
    });

    const atualizarStatus = useCallback(async (pedidoId, novoStatus) => {
        if (readOnly) return;

        // Salvar status anterior para possível reversão
        const statusAnterior = pedidosData?.pedidos?.find(p => p._id === pedidoId)?.status;

        try {
            // Aplicar atualização otimista
            setOptimisticUpdates(prev => ({
                ...prev,
                [pedidoId]: {
                    ...prev[pedidoId],
                    status: novoStatus
                }
            }));

            // Fazer chamada API
            const response = await api.patch(`/pedidos/${pedidoId}`, { status: novoStatus });

            if (response?.data) {
                showSuccess("Status atualizado com sucesso");
                // Forçar atualização dos dados
                queryClient.invalidateQueries(['pedidos']);
            }
        } catch (error) {
            console.debug('[usePedidos] Debug - Erro ao atualizar status:', {
                error: error.response?.data || error.message,
                pedidoId,
                novoStatus,
                statusAnterior
            });

            // Reverter para status anterior em caso de erro
            setOptimisticUpdates(prev => ({
                ...prev,
                [pedidoId]: {
                    ...prev[pedidoId],
                    status: statusAnterior
                }
            }));

            if (error.response?.status === 500) {
                showError("Erro ao atualizar status. Por favor, tente novamente.");
            }
        }
    }, [readOnly, showSuccess, showError, pedidosData, queryClient]);

    const handleRetiradaChange = useCallback(async (pedidoId, retirada) => {
        if (readOnly) return;

        try {
            const response = await api.patch(`/pedidos/${pedidoId}`, { retirada });

            if (response?.data) {
                showSuccess("Status de retirada atualizado com sucesso");

                setOptimisticUpdates(prev => ({
                    ...prev,
                    [pedidoId]: {
                        ...prev[pedidoId],
                        retirada
                    }
                }));
            }
        } catch (error) {
            console.error('Error updating retirada:', error);
            showError(error);
        }
    }, [readOnly, showSuccess, showError]);

    const formatDate = useCallback((dateString) => {
        if (!dateString) return "Não definida";
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${day}/${month}/${year.slice(-2)}`;
    }, []);

    return {
        pedidosData,
        isPedidosLoading,
        pedidosError,
        clientes,
        atualizarStatus,
        handleRetiradaChange,
        formatDate,
        socketConnected: isConnected,
        optimisticUpdates
    };
};
