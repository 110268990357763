import React, { useState } from 'react';
import {
    Paper,
    Typography,
    Box,
    Chip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    List as MuiList,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Avatar,
    AvatarGroup,
    Divider
} from '@mui/material';
import { Draggable } from '@hello-pangea/dnd';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import axios from '../../api';

const CardContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
        backgroundColor: theme.palette.action.hover
    }
}));

const CardHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '8px'
});

const CardContent = styled(Box)({
    marginBottom: '8px'
});

const CardFooter = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const TagsContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    marginBottom: '8px'
});

const Card = ({ card, index, listId, boardId, onDelete }) => {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editedCard, setEditedCard] = useState(card);
    const [error, setError] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    const handleSave = async () => {
        try {
            await axios.put(`/lists/${listId}/cards/${card._id}`, editedCard);
            setEditMode(false);
            setError('');
        } catch (err) {
            console.error('Error updating card:', err);
            setError('Erro ao atualizar cartão');
        }
    };

    const handleDelete = async () => {
        if (isDeleting) return;

        try {
            setIsDeleting(true);
            // Chamar apenas o callback onDelete, que fará a requisição DELETE
            if (onDelete) {
                await onDelete(card._id);
            }
            setDeleteDialogOpen(false);
        } catch (err) {
            console.error('Error deleting card:', err);
            setError('Erro ao deletar cartão');
        } finally {
            setIsDeleting(false);
        }
    };

    const handleChecklistItemToggle = async (itemId) => {
        try {
            const updatedChecklist = editedCard.checklist.map(item =>
                item._id === itemId ? { ...item, completo: !item.completo } : item
            );

            await axios.put(`/lists/${listId}/cards/${card._id}/checklist/${itemId}`, {
                completo: !editedCard.checklist.find(item => item._id === itemId).completo
            });

            setEditedCard(prev => ({
                ...prev,
                checklist: updatedChecklist
            }));
        } catch (err) {
            console.error('Error toggling checklist item:', err);
            setError('Erro ao atualizar item do checklist');
        }
    };

    const handleAddComment = async (text) => {
        try {
            await axios.post(`/lists/${listId}/cards/${card._id}/comments`, { texto: text });
            setEditedCard(prev => ({
                ...prev,
                comentarios: [...prev.comentarios, { texto: text, createdAt: new Date() }]
            }));
        } catch (err) {
            console.error('Error adding comment:', err);
            setError('Erro ao adicionar comentário');
        }
    };

    const renderCardDetails = () => (
        <Dialog
            open={detailsOpen}
            onClose={() => setDetailsOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                {editMode ? (
                    <TextField
                        fullWidth
                        value={editedCard.titulo}
                        onChange={(e) => setEditedCard(prev => ({ ...prev, titulo: e.target.value }))}
                        error={Boolean(error)}
                        helperText={error}
                    />
                ) : (
                    editedCard.titulo
                )}
            </DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <Typography variant="subtitle2" color="textSecondary">Descrição</Typography>
                    {editMode ? (
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={editedCard.descricao || ''}
                            onChange={(e) => setEditedCard(prev => ({ ...prev, descricao: e.target.value }))}
                        />
                    ) : (
                        <Typography>{editedCard.descricao}</Typography>
                    )}
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Checklist */}
                <Box mb={2}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Checklist
                    </Typography>
                    <MuiList>
                        {editedCard.checklist?.map((item) => (
                            <ListItem key={item._id} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={item.completo}
                                        onChange={() => handleChecklistItemToggle(item._id)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={item.texto} />
                            </ListItem>
                        ))}
                    </MuiList>
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Comentários */}
                <Box>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Comentários
                    </Typography>
                    <MuiList>
                        {editedCard.comentarios?.map((comment, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={comment.texto}
                                    secondary={format(new Date(comment.createdAt), "dd/MM/yyyy HH:mm", { locale: ptBR })}
                                />
                            </ListItem>
                        ))}
                    </MuiList>
                </Box>
            </DialogContent>
            <DialogActions>
                {editMode ? (
                    <>
                        <Button onClick={() => setEditMode(false)}>Cancelar</Button>
                        <Button onClick={handleSave} variant="contained" color="primary">
                            Salvar
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => setEditMode(true)} startIcon={<EditIcon />}>
                            Editar
                        </Button>
                        <Button
                            onClick={() => setDeleteDialogOpen(true)}
                            startIcon={<DeleteIcon />}
                            color="error"
                        >
                            Excluir
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Draggable draggableId={card._id} index={index}>
                {(provided, snapshot) => (
                    <CardContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                        <CardHeader>
                            <Typography variant="subtitle1">{card.titulo}</Typography>
                            <IconButton size="small" onClick={() => setDetailsOpen(true)}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </CardHeader>

                        {card.etiquetas?.length > 0 && (
                            <TagsContainer>
                                {card.etiquetas.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        label={tag}
                                        size="small"
                                        variant="outlined"
                                    />
                                ))}
                            </TagsContainer>
                        )}

                        <CardContent>
                            {card.descricao && (
                                <Typography variant="body2" color="textSecondary" noWrap>
                                    {card.descricao}
                                </Typography>
                            )}
                        </CardContent>

                        <CardFooter>
                            <Box display="flex" gap={1}>
                                {card.checklist?.length > 0 && (
                                    <Box display="flex" alignItems="center">
                                        <CheckBoxIcon fontSize="small" color="action" />
                                        <Typography variant="caption" ml={0.5}>
                                            {card.checklist.filter(item => item.completo).length}/
                                            {card.checklist.length}
                                        </Typography>
                                    </Box>
                                )}
                                {card.comentarios?.length > 0 && (
                                    <Box display="flex" alignItems="center">
                                        <ChatIcon fontSize="small" color="action" />
                                        <Typography variant="caption" ml={0.5}>
                                            {card.comentarios.length}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            {card.membros?.length > 0 && (
                                <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 24, height: 24 } }}>
                                    {card.membros.map((membro) => (
                                        <Avatar
                                            key={membro._id}
                                            alt={membro.nome}
                                            src={membro.avatar}
                                            sx={{ width: 24, height: 24 }}
                                        />
                                    ))}
                                </AvatarGroup>
                            )}
                        </CardFooter>
                    </CardContainer>
                )}
            </Draggable>

            {renderCardDetails()}

            {/* Dialog de confirmação de exclusão */}
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <Typography>
                        Tem certeza que deseja excluir este cartão?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                        disabled={isDeleting}
                    >
                        {isDeleting ? 'Excluindo...' : 'Excluir'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Card;
