import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const packages = [
    { title: "Meia diária", duration: "até 4 horas", price: "800,00" },
    { title: "Uma diária", duration: "até 8 horas", price: "1500,00" },
    { title: "Hora extra", duration: "", price: "250,00" }
];

const EnsaiosSection = () => (
    <>
        {packages.map((package_, index) => (
            <Grid item xs={12} md={4} key={index}>
                <Paper sx={{
                    p: 4,
                    background: 'rgba(255,255,255,0.03)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255,255,255,0.1)',
                    borderRadius: '24px',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                    }
                }}>
                    <Typography variant="h5" sx={{
                        color: 'white',
                        mb: 2,
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        {package_.title}
                    </Typography>
                    {package_.duration && (
                        <Typography sx={{
                            color: 'rgba(255,255,255,0.7)',
                            mb: 2,
                            fontFamily: '"Inter", sans-serif'
                        }}>
                            {package_.duration}
                        </Typography>
                    )}
                    <Typography variant="h4" sx={{
                        color: '#eb5b25',
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        R$ {package_.price}
                    </Typography>
                </Paper>
            </Grid>
        ))}
    </>
);

export default EnsaiosSection;
