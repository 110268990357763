import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { ThreeSixty } from '@mui/icons-material';

const VideosSection = () => (
    <>
        <Grid item xs={12} md={6}>
            <Paper sx={{
                p: 4,
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <Typography variant="h6" sx={{
                    color: 'white',
                    mb: 3,
                    fontFamily: '"Poppins", sans-serif'
                }}>
                    Formatos disponíveis:
                </Typography>
                <Box sx={{
                    color: 'rgba(255,255,255,0.7)',
                    fontFamily: '"Inter", sans-serif'
                }}>
                    <Typography sx={{ mb: 2 }}>
                        <strong>Formato Paisagem:</strong> Perfeito para YouTube, e-commerce e sites
                    </Typography>
                    <Typography>
                        <strong>Formato Retrato:</strong> Otimizado para Instagram, Reels e WhatsApp
                    </Typography>
                </Box>
            </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box sx={{
                p: 4,
                height: '100%',
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <ThreeSixty sx={{
                    fontSize: 60,
                    color: '#eb5b25',
                    animation: 'rotate 10s linear infinite',
                    '@keyframes rotate': {
                        '0%': { transform: 'rotate(0deg)' },
                        '100%': { transform: 'rotate(360deg)' }
                    }
                }} />
            </Box>
        </Grid>
    </>
);

export default VideosSection;
