import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { WhatsApp, KeyboardArrowUp, Camera, PhotoLibrary, CameraEnhance } from '@mui/icons-material';

import Navigation from '../components/orcamento/Navigation';
import Hero from '../components/orcamento/Hero';
import ServiceSection from '../components/orcamento/ServiceSection';

// Import section components
import ClubeSection from '../components/orcamento/sections/ClubeSection';
import EcommerceSection from '../components/orcamento/sections/EcommerceSection';
import CatalogosSection from '../components/orcamento/sections/CatalogosSection';
import EnsaiosSection from '../components/orcamento/sections/EnsaiosSection';
import FotosSection from '../components/orcamento/sections/FotosSection';
import ClipsSection from '../components/orcamento/sections/ClipsSection';
import VideosSection from '../components/orcamento/sections/VideosSection';
import AmbientadasSection from '../components/orcamento/sections/AmbientadasSection';
import PecasSection from '../components/orcamento/sections/PecasSection';
import ManipulacaoSection from '../components/orcamento/sections/ManipulacaoSection';

const OrcamentoPage = () => {
    const [activeSection, setActiveSection] = useState('');
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Refs for each section
    const clubeRef = useRef(null);
    const ecommerceRef = useRef(null);
    const catalogosRef = useRef(null);
    const ensaiosRef = useRef(null);
    const fotosRef = useRef(null);
    const clipsRef = useRef(null);
    const videosRef = useRef(null);
    const ambientadasRef = useRef(null);
    const pecasRef = useRef(null);
    const manipulacaoRef = useRef(null);

    const sections = [
        { id: 'clube', label: 'Clube', ref: clubeRef, component: ClubeSection },
        { id: 'ecommerce', label: 'E-commerce', ref: ecommerceRef, component: EcommerceSection },
        { id: 'catalogos', label: 'Catálogos', ref: catalogosRef, component: CatalogosSection },
        { id: 'ensaios', label: 'Ensaios', ref: ensaiosRef, component: EnsaiosSection },
        { id: 'fotos', label: 'Fotos no Pé', ref: fotosRef, component: FotosSection },
        { id: 'clips', label: 'ML Clips', ref: clipsRef, component: ClipsSection },
        { id: 'videos', label: 'Vídeos 360°', ref: videosRef, component: VideosSection },
        { id: 'ambientadas', label: 'Ambientadas', ref: ambientadasRef, component: AmbientadasSection },
        { id: 'pecas', label: 'Peças Digitais', ref: pecasRef, component: PecasSection },
        { id: 'manipulacao', label: 'Manipulação', ref: manipulacaoRef, component: ManipulacaoSection },
    ];

    const placeholderImages = [
        { icon: <Camera />, label: 'Foto 1' },
        { icon: <PhotoLibrary />, label: 'Foto 2' },
        { icon: <CameraEnhance />, label: 'Foto 3' },
    ];

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTop(window.scrollY > 400);

            const current = sections.find(section => {
                if (section.ref.current) {
                    const rect = section.ref.current.getBoundingClientRect();
                    return rect.top <= 100 && rect.bottom >= 100;
                }
                return false;
            });

            if (current) {
                setActiveSection(current.id);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToSection = (id) => {
        const section = sections.find(s => s.id === id);
        section.ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleWhatsAppClick = () => {
        window.open('https://wa.me/5537991867595', '_blank');
    };

    const nextImage = () => {
        setCurrentImageIndex((prev) => (prev + 1) % placeholderImages.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prev) => (prev - 1 + placeholderImages.length) % placeholderImages.length);
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            bgcolor: '#121212',
            color: 'white',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'radial-gradient(circle at 0% 0%, rgba(255,110,64,0.1) 0%, transparent 50%)',
                pointerEvents: 'none',
                zIndex: 0,
            }
        }}>
            <Navigation
                sections={sections}
                activeSection={activeSection}
                onSectionClick={scrollToSection}
            />

            <Hero />

            {sections.map((section, index) => (
                <ServiceSection
                    key={section.id}
                    id={section.ref}
                    title={section.label}
                    bgVariant={index % 2 === 0 ? 'default' : 'alternate'}
                    showCarousel={section.id === 'ecommerce'}
                    currentImage={section.id === 'ecommerce' ? placeholderImages[currentImageIndex] : null}
                    onPrevImage={prevImage}
                    onNextImage={nextImage}
                >
                    <section.component />
                </ServiceSection>
            ))}

            {/* Floating WhatsApp Button */}
            <Button
                variant="contained"
                startIcon={<WhatsApp />}
                onClick={handleWhatsAppClick}
                sx={{
                    position: 'fixed',
                    bottom: { xs: 70, md: 20 },
                    right: 20,
                    bgcolor: '#25D366',
                    borderRadius: '50px',
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    '&:hover': {
                        bgcolor: '#128C7E',
                    },
                }}
            >
                Orçamento
            </Button>

            {/* Scroll to Top Button */}
            {showScrollTop && (
                <IconButton
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    sx={{
                        position: 'fixed',
                        bottom: { xs: 70, md: 20 },
                        left: 20,
                        bgcolor: 'rgba(255,255,255,0.1)',
                        color: 'white',
                        '&:hover': {
                            bgcolor: 'rgba(255,255,255,0.2)',
                        },
                    }}
                >
                    <KeyboardArrowUp />
                </IconButton>
            )}
        </Box>
    );
};

export default OrcamentoPage;
