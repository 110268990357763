import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const PriceCard = ({ title, price, items, gradient }) => (
    <Paper
        elevation={0}
        sx={{
            p: { xs: 2, md: 4 },
            height: '100%',
            background: 'rgba(255,255,255,0.03)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '24px',
            transition: 'transform 0.3s ease',
            position: 'relative',
            overflow: 'hidden',
            '&:hover': {
                transform: 'translateY(-5px)',
                '&::before': {
                    opacity: 0.1,
                }
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: gradient,
                opacity: 0.05,
                transition: 'opacity 0.3s ease',
            }
        }}
    >
        <Typography variant="h5" sx={{
            color: 'white',
            mb: 2,
            fontWeight: 600,
            fontFamily: '"Poppins", sans-serif',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
        }}>
            {title}
        </Typography>
        <Typography variant="h4" sx={{
            color: '#eb5b25',
            mb: 3,
            fontWeight: 700,
            fontFamily: '"Poppins", sans-serif',
            fontSize: { xs: '1.5rem', md: '2rem' },
        }}>
            R$ {price}
        </Typography>
        <Box sx={{ color: 'rgba(255,255,255,0.7)' }}>
            {items.map((item, index) => (
                <Typography
                    key={index}
                    sx={{
                        mb: 1,
                        fontFamily: '"Inter", sans-serif',
                        fontSize: { xs: '0.9rem', md: '1rem' },
                    }}
                >
                    {item}
                </Typography>
            ))}
        </Box>
    </Paper>
);

export default PriceCard;
