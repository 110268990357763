import React, { useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import Menu from "./Menu";
import Header from "./Header";
import { isUserAdmin } from "../utils/auth";

const Layout = ({ children }) => {
  const admin = isUserAdmin();
  const location = useLocation();
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Determine header variant based on route
  const getHeaderVariant = () => {
    if (location.pathname === '/login' || location.pathname === '/primeiro-acesso' || location.pathname.includes('/redefinir-senha')) {
      return 'auth';
    }
    if (location.pathname.startsWith('/user/')) {
      return 'user';
    }
    return 'default';
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      {admin && <Menu mobileOpen={mobileOpen} onMobileToggle={handleDrawerToggle} />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: '100%',
          marginLeft: { sm: admin ? `${drawerWidth}px` : 0 },
          minHeight: "100vh",
          backgroundColor: "background.default",
          transition: "background-color 0.3s ease",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!admin && <Header variant={getHeaderVariant()} />}
        <Box sx={{ flexGrow: 1, p: 3 }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
