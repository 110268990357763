import React, { useState } from 'react';
import {
    Grid,
    Paper,
    Box,
    Typography,
    Checkbox,
    Badge,
    Menu,
    MenuItem,
    IconButton,
    Stack,
    Button,
    Snackbar,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import {
    MoreVert as MoreIcon,
    PhotoLibrary as PhotoLibraryIcon,
    ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import FilePreview from './FilePreview';
import { FileMenuButton, FileCheckbox } from './FileActions';
import { formatFileSize } from '../../../utils/fileHelpers';

const FileItem = ({
    arquivo,
    pedido,
    isAdmin,
    selectedFiles,
    onSelectFile,
    onNavigate,
    onImageError,
    onPreviewClick,
    onMenuOpen,
    isSelectionFolder,
    onSelectForDelivery,
    onToggleSelectionFolder,
    showPreviewText,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFolderMenu, setShowFolderMenu] = useState(false);

    const handleFolderMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleFolderMenuClose = () => {
        setAnchorEl(null);
    };

    const handleToggleSelectionFolder = () => {
        handleFolderMenuClose();
        onToggleSelectionFolder(arquivo._id, !arquivo.isSelectionFolder);
    };

    return (
        <Paper
            elevation={0}
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                cursor: arquivo.isFolder ? 'pointer' : 'default',
                overflow: 'hidden',
                borderRadius: 2,
                bgcolor: 'background.paper',
                transition: 'all 0.2s',
                border: arquivo.isSelectionFolder ? '2px solid' : '2px solid transparent',
                borderColor: arquivo.isSelectionFolder ? 'primary.main' : 'transparent',
                '& .arquivo-actions': {
                    opacity: 1
                },
                ...(arquivo.isFolder && {
                    bgcolor: 'action.hover',
                    '& .folder-menu': {
                        opacity: 1
                    }
                })
            }}
            onClick={(e) => arquivo.isFolder && !e.target.closest('.menu-container') && onNavigate(arquivo.path)}
            onMouseEnter={() => !isMobile && arquivo.isFolder && setShowFolderMenu(true)}
            onMouseLeave={() => !isMobile && arquivo.isFolder && setShowFolderMenu(false)}
        >
            {/* Barra superior com ações */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                    position: 'absolute',
                    top: isMobile ? 4 : 8,
                    left: isMobile ? 4 : 8,
                    right: isMobile ? 4 : 8,
                    zIndex: 2,
                }}
            >
                {/* Lado esquerdo - Checkbox de admin */}
                {isAdmin && (
                    <Box sx={{
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        '& .MuiCheckbox-root': {
                            padding: isMobile ? '4px' : '8px'
                        }
                    }}>
                        <FileCheckbox
                            checked={selectedFiles.includes(arquivo._id)}
                            onChange={() => onSelectFile(arquivo._id)}
                        />
                    </Box>
                )}

                {/* Lado direito - Menu de pasta ou ícone de pasta de seleção */}
                {arquivo.isFolder && (showFolderMenu || isMobile) && isAdmin && (
                    <Box className="folder-menu menu-container" sx={{
                        opacity: isMobile ? 1 : 0,
                        transition: 'opacity 0.2s'
                    }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            {arquivo.isSelectionFolder && (
                                <PhotoLibraryIcon color="primary" fontSize={isMobile ? "small" : "medium"} />
                            )}
                            <IconButton
                                size={isMobile ? "small" : "medium"}
                                onClick={handleFolderMenuOpen}
                                sx={{
                                    bgcolor: 'background.paper',
                                    padding: isMobile ? '4px' : '8px'
                                }}
                            >
                                <MoreIcon fontSize={isMobile ? "small" : "medium"} />
                            </IconButton>
                        </Stack>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleFolderMenuClose}
                            PaperProps={{
                                sx: {
                                    minWidth: isMobile ? 200 : 220,
                                }
                            }}
                        >
                            <MenuItem onClick={handleToggleSelectionFolder}>
                                {arquivo.isSelectionFolder ? 'Remover pasta de seleção' : 'Marcar como pasta de seleção'}
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            </Stack>

            {/* Checkbox de seleção de fotos */}
            {isSelectionFolder && !arquivo.isFolder && (
                <Box
                    className="selection-checkbox"
                    sx={{
                        position: 'absolute',
                        bottom: isMobile ? 4 : 8,
                        right: isMobile ? 4 : 8,
                        zIndex: 2,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        opacity: 0.9,
                        boxShadow: 1,
                        '& .MuiCheckbox-root': {
                            padding: isMobile ? '4px' : '8px'
                        }
                    }}
                >
                    <Checkbox
                        checked={arquivo.selectedForDelivery || false}
                        onChange={(e) => onSelectForDelivery(arquivo._id, e.target.checked)}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Box>
            )}

            <Box sx={{ p: isMobile ? 0.5 : 1 }}>
                <FilePreview
                    arquivo={arquivo}
                    pedido={pedido}
                    onImageError={onImageError}
                    onPreviewClick={onPreviewClick}
                    isAdmin={isAdmin}
                    showPreviewText={showPreviewText}
                />

                <Box sx={{ mt: isMobile ? 0.5 : 1 }}>
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            minHeight: '2.5em',
                            lineHeight: '1.25em',
                            fontSize: isMobile ? '0.8rem' : 'inherit'
                        }}
                    >
                        {arquivo.nome}
                    </Typography>

                    {!arquivo.isFolder && (
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{
                                display: 'block',
                                textAlign: 'center',
                                mt: 0.5,
                                fontSize: isMobile ? '0.7rem' : 'inherit'
                            }}
                        >
                            {formatFileSize(arquivo.tamanho)}
                        </Typography>
                    )}
                </Box>
            </Box>

            {!arquivo.isFolder && (
                <FileMenuButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onMenuOpen(e, arquivo);
                    }}
                    pedido={pedido}
                    isMobile={isMobile}
                />
            )}

        </Paper>
    );
};

const FileGrid = ({
    arquivos,
    pedido,
    isAdmin,
    selectedFiles,
    onSelectFile,
    onNavigate,
    onImageError,
    onPreviewClick,
    onMenuOpen,
    isSelectionFolder,
    totalSelecionados,
    onSelectForDelivery,
    onToggleSelectionFolder,
    showPreviewText,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopyNumbers = () => {
        const selectedNumbers = arquivos
            .filter(arquivo => arquivo.selectedForDelivery)
            .map(arquivo => {
                const match = arquivo.nome.match(/MOA_(\d{4})/);
                return match ? match[1] : null;
            })
            .filter(number => number !== null)
            .join(' OU ');

        navigator.clipboard.writeText(selectedNumbers).then(() => {
            setSnackbarOpen(true);
        });
    };

    return (
        <>
            {isSelectionFolder && (
                <Box sx={{
                    mb: isMobile ? 2 : 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: isMobile ? 1 : 2,
                    justifyContent: 'space-between',
                    flexDirection: isMobile ? 'column' : 'row'
                }}>
                    <Typography
                        variant={isMobile ? "subtitle1" : "h6"}
                        component="div"
                        align={isMobile ? "center" : "left"}
                    >
                        Fotos Selecionadas  {
                            <Badge
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: -20,
                                        border: '2px solid currentColor',
                                        padding: '0 4px',
                                        scale: isMobile ? 1.2 : 1.5
                                    }
                                }}
                                badgeContent={totalSelecionados === 0 ? '0' : totalSelecionados}
                                color="primary"
                            />
                        }
                    </Typography>
                    {totalSelecionados > 0 && isAdmin && (
                        <Button
                            variant="outlined"
                            startIcon={<ContentCopyIcon />}
                            onClick={handleCopyNumbers}
                            size={isMobile ? "small" : "medium"}
                            fullWidth={isMobile}
                        >
                            Copiar Números
                        </Button>
                    )}
                </Box>
            )}

            <Grid container spacing={isMobile ? 1 : 2}>
                {arquivos.map((arquivo) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={arquivo._id}>
                        <FileItem
                            arquivo={arquivo}
                            pedido={pedido}
                            isAdmin={isAdmin}
                            selectedFiles={selectedFiles}
                            onSelectFile={onSelectFile}
                            onNavigate={onNavigate}
                            onImageError={onImageError}
                            onPreviewClick={onPreviewClick}
                            onMenuOpen={onMenuOpen}
                            isSelectionFolder={isSelectionFolder}
                            onSelectForDelivery={onSelectForDelivery}
                            onToggleSelectionFolder={onToggleSelectionFolder}
                            showPreviewText={showPreviewText}
                        />
                    </Grid>
                ))}
            </Grid>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="Números copiados para a área de transferência"
                sx={{
                    bottom: isMobile ? 16 : 24
                }}
            />
        </>
    );
};

export default FileGrid;
