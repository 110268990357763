import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    IconButton,
    TextField,
    useTheme,
    alpha,
    Autocomplete,
} from '@mui/material';
import {
    Delete as DeleteIcon,
    ShoppingCart as CartIcon,
} from '@mui/icons-material';

const EditarPedidoServicos = ({
    servicos,
    servicosDisponiveis,
    onAddServico,
    onRemoveServico,
    onQuantidadeChange
}) => {
    const theme = useTheme();

    return (
        <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CartIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                <Typography variant="h6" color="primary">
                    Serviços do Pedido
                </Typography>
            </Box>

            {/* Adicionar Novo Serviço */}
            <Box sx={{ mb: 3 }}>
                <Autocomplete
                    options={servicosDisponiveis || []}
                    getOptionLabel={(option) => option.nome}
                    onChange={(_, servico) => servico && onAddServico(servico)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Adicionar Serviço"
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                />
            </Box>

            {/* Lista de Serviços */}
            <Grid container spacing={2}>
                {servicos.map((servico, index) => (
                    <Grid item xs={12} key={index}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                borderRadius: 1,
                                bgcolor: alpha(theme.palette.primary.main, 0.03),
                                '&:hover': {
                                    bgcolor: alpha(theme.palette.primary.main, 0.05)
                                }
                            }}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle2" color="primary">
                                        {servico.servicoId.nome}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        value={servico.quantidade}
                                        onChange={(e) => onQuantidadeChange(index, parseInt(e.target.value) || 1)}
                                        size="small"
                                        inputProps={{ min: 1 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Typography variant="body2" color="textSecondary">
                                        Subtotal: R$ {servico.subtotal?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        onClick={() => onRemoveServico(index)}
                                        color="error"
                                        size="small"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}

                {servicos.length === 0 && (
                    <Grid item xs={12}>
                        <Box sx={{
                            p: 3,
                            textAlign: 'center',
                            bgcolor: alpha(theme.palette.primary.main, 0.03),
                            borderRadius: 1
                        }}>
                            <Typography color="textSecondary">
                                Nenhum serviço adicionado
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default EditarPedidoServicos;
