import { useState, useEffect } from 'react';

const initialState = {
    // Campos básicos
    nome: "",
    email: "",
    documento: "",
    telefone: "",

    // Campos de endereço
    endereco: "",
    cep: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",

    // Campos Zoom+
    isZoomPlus: false,
    planoId: "",
    planoNome: "",
    diaVencimento: "",
    taxasUrgenciaDisponiveis: 0,
    usoModelosDisponiveis: 0,
    dataInicioPlano: "",
    dataFinalPlano: "",
    valorMensalidade: "",
    saldo: 0,

    // Campos adicionais
    linkDrive: "",
    isAdmin: false,

    // Campos Asaas
    emailsAdicionais: [],
    inscricaoMunicipal: "",
    inscricaoEstadual: "",
    observacoes: "",
    notificacoesDesativadas: false
};

const useClienteForm = (initialData = null) => {
    const [cliente, setCliente] = useState(initialData || initialState);
    const [formErrors, setFormErrors] = useState({});
    const [selectedPlano, setSelectedPlano] = useState(null);
    const [modifiedFields, setModifiedFields] = useState(new Set());

    useEffect(() => {
        if (initialData) {
            setCliente(initialData);
        }
    }, [initialData]);

    const validateForm = () => {
        const errors = {};

        // Validação de campos básicos
        if (!cliente.nome?.trim()) {
            errors.nome = "Nome é obrigatório";
        }
        if (!cliente.email?.trim()) {
            errors.email = "Email é obrigatório";
        } else if (!/\S+@\S+\.\S+/.test(cliente.email)) {
            errors.email = "Email inválido";
        }
        if (!cliente.documento?.trim()) {
            errors.documento = "Documento é obrigatório";
        }
        if (cliente.telefone && !/^\+?\d{10,14}$/.test(cliente.telefone.replace(/\D/g, ''))) {
            errors.telefone = "Telefone inválido";
        }

        // Validação de endereço
        if (cliente.cep && !/^\d{8}$/.test(cliente.cep.replace(/\D/g, ''))) {
            errors.cep = "CEP inválido";
        }
        if (cliente.estado && !/^[A-Z]{2}$/.test(cliente.estado.toUpperCase())) {
            errors.estado = "Estado inválido (use sigla com 2 letras)";
        }

        // Validação de emails adicionais
        if (cliente.emailsAdicionais && Array.isArray(cliente.emailsAdicionais)) {
            const invalidEmails = cliente.emailsAdicionais.filter(email =>
                email && !/\S+@\S+\.\S+/.test(email.trim())
            );
            if (invalidEmails.length > 0) {
                errors.emailsAdicionais = "Um ou mais emails adicionais são inválidos";
            }
        }

        // Validação Zoom+
        if (cliente.isZoomPlus) {
            if (!cliente.planoId) {
                errors.planoId = "Selecione um plano";
            }
            if (!cliente.dataInicioPlano) {
                errors.dataInicioPlano = "Data de início é obrigatória";
            }
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFieldChange = (field, value, planos = []) => {
        setModifiedFields(prev => new Set(prev).add(field));

        if (field === 'planoId') {
            const plano = planos.find(p => p._id === value);
            setSelectedPlano(plano);

            setCliente(prev => ({
                ...prev,
                planoId: value,
                valorMensalidade: plano?.valorMensalidade || "",
                saldo: !prev.isZoomPlus ? plano?.saldoBase || 0 : prev.saldo,
                taxasUrgenciaDisponiveis: !prev.isZoomPlus ? plano?.taxasUrgenciasGratis || 0 : prev.taxasUrgenciaDisponiveis,
                usoModelosDisponiveis: !prev.isZoomPlus ? plano?.modeloCompleta || 0 : prev.usoModelosDisponiveis,
                planoNome: plano?.nome || ""
            }));
        } else if (field === 'dataInicioPlano' && value) {
            const startDate = new Date(value);
            const endDate = new Date(startDate);
            endDate.setMonth(endDate.getMonth() + 6);

            setCliente(prev => ({
                ...prev,
                [field]: value,
                dataFinalPlano: endDate.toISOString().split('T')[0],
                diaVencimento: startDate.getUTCDate()
            }));
        } else if (field === 'isZoomPlus' && !value) {
            setCliente(prev => ({
                ...prev,
                isZoomPlus: value,
                planoId: "",
                planoNome: "",
                dataInicioPlano: "",
                dataFinalPlano: "",
                diaVencimento: "",
                valorMensalidade: "",
                saldo: 0,
                taxasUrgenciaDisponiveis: 0,
                usoModelosDisponiveis: 0
            }));
            setSelectedPlano(null);
        } else if (field === 'cep') {
            // Remove caracteres não numéricos do CEP
            const cleanedValue = value.replace(/\D/g, '');
            setCliente(prev => ({ ...prev, [field]: cleanedValue }));
        } else if (field === 'estado') {
            // Converte estado para maiúsculas
            const upperValue = value.toUpperCase();
            setCliente(prev => ({ ...prev, [field]: upperValue }));
        } else if (field === 'emailsAdicionais' && Array.isArray(value)) {
            // Limpa emails vazios e duplicados
            const cleanedEmails = [...new Set(value.filter(email => email.trim()))];
            setCliente(prev => ({ ...prev, [field]: cleanedEmails }));
        } else {
            setCliente(prev => ({ ...prev, [field]: value }));
        }
    };

    const getModifiedData = () => {
        const updateData = {};
        modifiedFields.forEach(field => {
            updateData[field] = cliente[field];
        });
        return updateData;
    };

    const resetForm = () => {
        setCliente(initialState);
        setFormErrors({});
        setSelectedPlano(null);
        setModifiedFields(new Set());
    };

    return {
        cliente,
        setCliente,
        formErrors,
        selectedPlano,
        modifiedFields,
        handleFieldChange,
        validateForm,
        getModifiedData,
        resetForm
    };
};

export default useClienteForm;
