import React, { useState, useCallback } from 'react';
import {
    Paper,
    Typography,
    IconButton,
    Box,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from '@mui/material';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Card from './Card';
import CreateCard from './CreateCard';
import axios from '../../api';
import { useSocket } from '../../hooks/useSocket';

const ListContainer = styled(Paper)(({ theme }) => ({
    width: 280,
    maxHeight: 'calc(100vh - 100px)',
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
}));

const ListHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`
}));

const ListContent = styled(Box)({
    padding: '8px',
    flexGrow: 1,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: '8px'
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)',
        borderRadius: '4px'
    }
});

const List = ({ list, index, boardId, onCardAdd, onCardDelete, onListDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showCreateCard, setShowCreateCard] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editTitle, setEditTitle] = useState(list.titulo);
    const [error, setError] = useState('');
    const [isProcessingCard, setIsProcessingCard] = useState(false);
    const socket = useSocket();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        handleMenuClose();
        setEditDialogOpen(true);
    };

    const handleDeleteClick = () => {
        handleMenuClose();
        setDeleteDialogOpen(true);
    };

    const handleEditSubmit = async () => {
        try {
            await axios.put(`/boards/${boardId}/lists/${list._id}`, {
                titulo: editTitle
            });
            setEditDialogOpen(false);
            setError('');
        } catch (err) {
            console.error('Error updating list:', err);
            setError('Erro ao atualizar lista');
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`/boards/${boardId}/lists/${list._id}`);

            // Emite o evento websocket
            if (socket) {
                socket.emit('list-deleted', { listId: list._id, boardId });
            }

            // Chama a função de callback para atualizar o estado no componente pai
            if (onListDelete) {
                onListDelete(list._id);
            }

            setDeleteDialogOpen(false);
        } catch (err) {
            console.error('Error deleting list:', err);
            setError('Erro ao deletar lista');
        }
    };

    const handleCardCreate = useCallback(async (newCard) => {
        if (isProcessingCard) {
            return;
        }

        setIsProcessingCard(true);
        try {
            if (onCardAdd) {
                await onCardAdd(list._id, newCard);
            }
            setShowCreateCard(false);
        } catch (error) {
            console.error('Error creating card:', error);
        } finally {
            // Aguarda um pequeno delay antes de permitir nova criação
            setTimeout(() => {
                setIsProcessingCard(false);
            }, 1000);
        }
    }, [list._id, onCardAdd, isProcessingCard]);

    const handleCardDelete = useCallback(async (cardId) => {
        if (onCardDelete) {
            await onCardDelete(list._id, cardId);
        }
    }, [list._id, onCardDelete]);

    return (
        <Draggable draggableId={list._id} index={index}>
            {(provided) => (
                <ListContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <ListHeader {...provided.dragHandleProps}>
                        <Typography variant="h6" component="div">
                            {list.titulo}
                        </Typography>
                        <IconButton size="small" onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                    </ListHeader>

                    <Droppable droppableId={list._id} type="CARD">
                        {(provided, snapshot) => (
                            <ListContent
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                    backgroundColor: snapshot.isDraggingOver ?
                                        'rgba(0, 0, 0, 0.04)' : 'transparent'
                                }}
                            >
                                {list.cards?.map((card, cardIndex) => (
                                    <Card
                                        key={card._id}
                                        card={card}
                                        index={cardIndex}
                                        listId={list._id}
                                        boardId={boardId}
                                        onDelete={handleCardDelete}
                                    />
                                ))}
                                {provided.placeholder}
                                {showCreateCard ? (
                                    <CreateCard
                                        listId={list._id}
                                        boardId={boardId}
                                        onCancel={() => setShowCreateCard(false)}
                                        onSuccess={handleCardCreate}
                                    />
                                ) : (
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => setShowCreateCard(true)}
                                        fullWidth
                                        disabled={isProcessingCard}
                                        sx={{ mt: 1, justifyContent: 'flex-start' }}
                                    >
                                        Adicionar cartão
                                    </Button>
                                )}
                            </ListContent>
                        )}
                    </Droppable>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEditClick}>Editar</MenuItem>
                        <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
                    </Menu>

                    <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                        <DialogTitle>Editar Lista</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Título"
                                fullWidth
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.target.value)}
                                error={Boolean(error)}
                                helperText={error}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditDialogOpen(false)}>Cancelar</Button>
                            <Button onClick={handleEditSubmit} variant="contained">
                                Salvar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                        <DialogTitle>Confirmar Exclusão</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Tem certeza que deseja excluir esta lista e todos os seus cartões?
                            </Typography>
                            {error && (
                                <Typography color="error" sx={{ mt: 1 }}>
                                    {error}
                                </Typography>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
                            <Button onClick={handleDeleteConfirm} color="error" variant="contained">
                                Excluir
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ListContainer>
            )}
        </Draggable>
    );
};

export default List;
