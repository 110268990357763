import { useState, useCallback } from 'react';
import paymentService from '../services/paymentService';
import api from '../api';

const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 1000;

export const usePaymentHandling = (carregarPedidos, setAlerta) => {
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [optimisticUpdates, setOptimisticUpdates] = useState({});

    const retryOperation = async (operation, retries = 0) => {
        try {
            return await operation();
        } catch (error) {
            if (retries < MAX_RETRIES) {
                const delay = INITIAL_RETRY_DELAY * Math.pow(2, retries);
                await new Promise(resolve => setTimeout(resolve, delay));
                return retryOperation(operation, retries + 1);
            }
            throw error;
        }
    };

    const handlePaymentStatusChange = useCallback(async (pedidoId, newStatus) => {
        try {
            await carregarPedidos(true);
        } catch (error) {
            console.error('Error refreshing data:', error);
            if (setAlerta) {
                setAlerta({
                    mensagem: "Erro ao atualizar dados",
                    tipo: "error",
                    aberto: true,
                });
            }
        }
    }, [carregarPedidos, setAlerta]);

    const getPaymentId = useCallback((pedido) => {
        if (!pedido) return null;
        return pedido.asaas?.paymentId || pedido.id || pedido._id || null;
    }, []);

    const formatPaymentDetails = (pedido, paymentDetails = null) => {
        if (!pedido) return null;

        // Gerar link da fatura se não existir
        let invoiceUrl = pedido.asaas?.paymentLink;
        if (!invoiceUrl && pedido.asaas?.paymentId) {
            const baseUrl = process.env.REACT_APP_ASAAS_URL?.includes('sandbox')
                ? 'https://www.asaas.com'
                : 'https://sandbox.asaas.com';
            const paymentId = pedido.asaas.paymentId.replace('pay_', '');
            invoiceUrl = `${baseUrl}/i/${paymentId}`;
        }

        const pedidoId = pedido._id || pedido.externalReference;

        return {
            id: pedido.asaas?.paymentId || pedido.id || pedido._id,
            value: pedido.valorPagar || pedido.value || pedido.total,
            status: paymentDetails?.status || pedido.asaas?.paymentStatus || pedido.status || 'PENDING',
            billingType: paymentDetails?.billingType || pedido.asaas?.billingType || 'UNDEFINED',
            customer: pedido.clienteId?.nome || pedido.customerDetails?.name || pedido.customer?.name,
            dueDate: pedido.dataEntrega || pedido.dueDate,
            description: pedidoId ? `Pedido #${pedidoId.toString().slice(-4).toUpperCase()}` : '',
            invoiceUrl: paymentDetails?.invoiceUrl || invoiceUrl,
            externalReference: pedidoId
        };
    };

    const handlePaymentAction = useCallback(async (pedidoId, action, pedidos) => {
        if (!pedidoId || !action || !Array.isArray(pedidos)) {
            console.warn('Invalid parameters:', { pedidoId, action, pedidos });
            return;
        }

        setIsLoadingPayment(true);

        // Procura o pedido primeiro pelo _id (caso ListaPedidos) e depois pelo id (caso PaymentsTable)
        const pedido = pedidos.find(p =>
            p._id === pedidoId ||
            p.id === pedidoId ||
            p.asaas?.paymentId === pedidoId ||
            (p.externalReference && p.externalReference.includes(pedidoId))
        );

        if (!pedido) {
            console.warn('Payment not found:', pedidoId);
            setIsLoadingPayment(false);
            return;
        }

        try {
            switch (action) {
                case 'view':
                    const paymentIdForView = pedido.asaas?.paymentId || pedido.id;
                    if (paymentIdForView) {
                        const details = await retryOperation(() =>
                            paymentService.getPayment(paymentIdForView)
                        );

                        if (!details) {
                            throw new Error('Failed to fetch payment details');
                        }

                        setPaymentDetails(formatPaymentDetails(pedido, details));
                        setShowPaymentDetails(true);

                        const isPaymentReceived = ['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(details.status);
                        const isPaymentOverdue = details.status === 'OVERDUE';

                        if (isPaymentReceived && pedido.statusPagamento !== 'pago') {
                            setOptimisticUpdates(prev => ({
                                ...prev,
                                [pedidoId]: {
                                    statusPagamento: 'pago',
                                    asaas: {
                                        ...(pedido.asaas || {}),
                                        paymentStatus: details.status
                                    }
                                }
                            }));
                            await handlePaymentStatusChange(pedidoId, details.status);
                        } else if (isPaymentOverdue && pedido.statusPagamento !== 'pendente') {
                            setOptimisticUpdates(prev => ({
                                ...prev,
                                [pedidoId]: {
                                    statusPagamento: 'pendente',
                                    asaas: {
                                        ...(pedido.asaas || {}),
                                        paymentStatus: details.status
                                    }
                                }
                            }));
                            await handlePaymentStatusChange(pedidoId, details.status);
                        }
                    } else {
                        setPaymentDetails(formatPaymentDetails(pedido));
                        setShowPaymentDetails(true);
                    }
                    break;

                case 'pix':
                case 'boleto':
                case 'credit_card':
                    const customerId = pedido.clienteId?.asaasId || pedido.customer?.id;
                    if (!customerId) {
                        throw new Error('Cliente não possui cadastro no sistema de pagamento');
                    }

                    const paymentValue = pedido.valorPagar || pedido.value;
                    if (!paymentValue || paymentValue <= 0) {
                        throw new Error('Não há valor pendente para pagamento');
                    }

                    const paymentData = {
                        customer: customerId,
                        billingType: action === 'pix' ? 'PIX' : action === 'boleto' ? 'BOLETO' : 'CREDIT_CARD',
                        value: paymentValue,
                        dueDate: pedido.dataEntrega || pedido.dueDate || new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
                        description: `Pedido #${getPaymentId(pedido)}`,
                        externalReference: getPaymentId(pedido)
                    };

                    const newPayment = await retryOperation(async () => {
                        if (action === 'pix') {
                            return await paymentService.createPixPayment(paymentData);
                        }
                        return await paymentService.createPayment(paymentData);
                    });

                    if (!newPayment?.id) {
                        throw new Error('Falha ao criar cobrança');
                    }

                    await carregarPedidos(true);
                    break;

                case 'cancel':
                    const paymentIdForCancel = pedido.asaas?.paymentId || pedido.id;
                    if (!paymentIdForCancel) {
                        throw new Error('ID de pagamento não encontrado para cancelamento');
                    }

                    await retryOperation(() => paymentService.cancelPayment(paymentIdForCancel));

                    setOptimisticUpdates(prev => ({
                        ...prev,
                        [pedidoId]: {
                            statusPagamento: 'pendente',
                            asaas: {
                                ...(pedido.asaas || {}),
                                paymentStatus: 'CANCELLED'
                            }
                        }
                    }));

                    await carregarPedidos(true);
                    break;

                case 'restore':
                    const paymentIdForRestore = pedido.asaas?.paymentId || pedido.id;
                    if (!paymentIdForRestore) {
                        throw new Error('ID de pagamento não encontrado para restauração');
                    }

                    const restoredPayment = await retryOperation(() =>
                        paymentService.restorePayment(paymentIdForRestore)
                    );

                    if (!restoredPayment) {
                        throw new Error('Falha ao restaurar pagamento');
                    }

                    setOptimisticUpdates(prev => ({
                        ...prev,
                        [pedidoId]: {
                            statusPagamento: 'pendente',
                            asaas: {
                                ...(pedido.asaas || {}),
                                paymentStatus: restoredPayment.status
                            }
                        }
                    }));

                    await carregarPedidos(true);
                    break;

                default:
                    console.warn('Ação de pagamento desconhecida:', action);
                    break;
            }

            if (setAlerta) {
                setAlerta({
                    mensagem: "Operação de pagamento realizada com sucesso",
                    tipo: "success",
                    aberto: true,
                });
            }
        } catch (error) {
            console.error('Payment action error:', error);
            const errorMessage = error.response?.data?.message || error.message;
            if (setAlerta) {
                setAlerta({
                    mensagem: `Erro na operação de pagamento: ${errorMessage}`,
                    tipo: "error",
                    aberto: true,
                });
            }
        } finally {
            setIsLoadingPayment(false);
        }
    }, [carregarPedidos, setAlerta, handlePaymentStatusChange, getPaymentId]);

    const getOptimisticState = useCallback((pedidos) => {
        if (!Array.isArray(pedidos)) {
            console.warn('getOptimisticState received non-array:', pedidos);
            return [];
        }

        const validPedidos = pedidos.filter(Boolean);

        return validPedidos.map(pedido => {
            if (!pedido) return null;

            const itemId = getPaymentId(pedido);
            if (!itemId) return pedido;

            const update = optimisticUpdates[itemId];
            if (update) {
                return {
                    ...pedido,
                    ...update,
                    asaas: {
                        ...(pedido.asaas || {}),
                        ...(update.asaas || {})
                    }
                };
            }
            return pedido;
        }).filter(Boolean);
    }, [optimisticUpdates, getPaymentId]);

    return {
        paymentDetails,
        showPaymentDetails,
        isLoadingPayment,
        handlePaymentAction,
        handlePaymentStatusChange,
        setShowPaymentDetails,
        getOptimisticState
    };
};
