import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Button,
    Chip,
    IconButton,
    useTheme,
    alpha
} from '@mui/material';
import {
    Edit as EditIcon,
    Warning as WarningIcon,
    ArrowBack as ArrowBackIcon,
    Link as LinkIcon,
    AttachMoney as MoneyIcon,
    Person as PersonIcon,
    ShoppingCart as CartIcon,
    CalendarToday as CalendarIcon,
    Receipt as ReceiptIcon,
    LocalShipping as ShippingIcon,
    Star as StarIcon,
    Delete as DeleteIcon,
    Folder as FolderIcon
} from '@mui/icons-material';
import api from '../api';
import AlertaReutilizavel from '../components/shared/AlertaReutilizavel';
import ConfirmationModal from '../components/shared/ConfirmationModal';
import { formatarDataComFuso } from '../utils/dateUtils';
import PedidoArquivosGrid from '../components/pedido/PedidoArquivosGrid';
import { useArquivos } from '../hooks/useArquivos';

const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(valor);
};

const formatarDadosCliente = (cliente) => {
    if (!cliente) return 'Cliente não encontrado';
    return typeof cliente === 'object' ? cliente.nome : 'Cliente não encontrado';
};

const getStatusConfig = (status) => {
    const config = {
        'aberto': { color: '#FFA726', bgColor: '#FFF3E0', label: 'Aberto', icon: ReceiptIcon },
        'em andamento': { color: '#1E88E5', bgColor: '#E3F2FD', label: 'Em Andamento', icon: ShippingIcon },
        'concluido': { color: '#66BB6A', bgColor: '#E8F5E9', label: 'Concluído', icon: StarIcon },
        'cancelado': { color: '#EF5350', bgColor: '#FFEBEE', label: 'Cancelado', icon: WarningIcon }
    };
    return config[status] || config['aberto'];
};

const getStatusPagamentoLabel = (status) => {
    const statusMap = {
        'pendente': { label: 'Pendente', color: '#FFA726' },
        'pago': { label: 'Pago', color: '#66BB6A' },
        'pago_com_saldo': { label: 'Pago com Saldo', color: '#66BB6A' },
        'pago_parcial_saldo': { label: 'Pago Parcialmente com Saldo', color: '#1E88E5' }
    };
    return statusMap[status] || { label: status, color: '#757575' };
};

const InfoCard = ({ icon: Icon, title, children, elevation = 2 }) => {
    const theme = useTheme();
    return (
        <Paper
            elevation={elevation}
            sx={{
                mb: 3,
                borderRadius: 2,
                overflow: 'hidden',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: theme.shadows[elevation + 1]
                }
            }}
        >
            <Box
                sx={{
                    p: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                    bgcolor: alpha(theme.palette.primary.main, 0.03),
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}
            >
                <Icon sx={{ color: theme.palette.primary.main }} />
                <Typography variant="h6" color="primary">
                    {title}
                </Typography>
            </Box>
            <Box sx={{ px: 2, py: 2 }}>
                {children}
            </Box>
        </Paper>
    );
};
const VisualizarPedido = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [pedido, setPedido] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const {
        arquivos,
        loading: loadingArquivos,
        uploading,
        fetchArquivos,
        uploadArquivo,
        excluirArquivo,
        downloadArquivo,
        downloadTodosArquivos
    } = useArquivos(id);

    useEffect(() => {
        const carregarDados = async () => {
            try {
                setLoading(true);
                const [pedidoResponse] = await Promise.all([
                    api.get(`/pedidos/${id}`),
                    fetchArquivos()
                ]);
                setPedido(pedidoResponse.data);
            } catch (error) {
                console.error("Erro ao carregar pedido:", error);
                setError("Erro ao carregar o pedido. Por favor, tente novamente.");
            } finally {
                setLoading(false);
            }
        };
        carregarDados();
    }, [id, fetchArquivos]);

    const handleExcluirPedido = async () => {
        try {
            await api.delete(`/pedidos/${id}`);
            setAlerta({
                mensagem: "Pedido excluído com sucesso!",
                tipo: "success",
                aberto: true
            });
            setTimeout(() => {
                navigate('/cadastro-pedidos');
            }, 2000);
        } catch (error) {
            console.error("Erro ao excluir pedido:", error);
            setAlerta({
                mensagem: "Erro ao excluir o pedido. Por favor, tente novamente.",
                tipo: "error",
                aberto: true
            });
        }
        setConfirmModalOpen(false);
    };

    if (loading) {
        return <Typography>Carregando...</Typography>;
    }

    if (error || !pedido) {
        return <Typography>Erro ao carregar pedido: {error}</Typography>;
    }

    const statusConfig = getStatusConfig(pedido.status);
    const StatusIcon = statusConfig.icon;

    return (
        <Box sx={{ p: 2 }}>
            {/* Header com Gradiente */}
            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    mb: 2,
                    background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
                    color: 'white',
                    borderRadius: 2
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            color: 'white',
                            bgcolor: alpha('#fff', 0.1),
                            '&:hover': { bgcolor: alpha('#fff', 0.2) }
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                            Pedido #{id.slice(-4).toUpperCase()}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Chip
                                icon={<StatusIcon />}
                                label={statusConfig.label}
                                size="small"
                                sx={{
                                    bgcolor: statusConfig.bgColor,
                                    color: statusConfig.color,
                                    fontWeight: 'bold',
                                    '& .MuiChip-icon': { color: statusConfig.color }
                                }}
                            />
                            <Typography variant="body2">
                                Cliente: {formatarDadosCliente(pedido.clienteId)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            variant="contained"
                            startIcon={<EditIcon />}
                            onClick={() => navigate(`/editar-pedido/${id}`)}
                            size="small"
                            sx={{
                                bgcolor: alpha('#fff', 0.2),
                                color: 'white',
                                '&:hover': { bgcolor: alpha('#fff', 0.3) }
                            }}
                        >
                            Editar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={() => setConfirmModalOpen(true)}
                            size="small"
                            sx={{
                                bgcolor: alpha('#fff', 0.2),
                                color: 'white',
                                '&:hover': { bgcolor: theme.palette.error.main }
                            }}
                        >
                            Excluir
                        </Button>
                    </Box>
                </Box>
            </Paper>

            <Grid container spacing={2}>
                {/* Informações Principais e Datas */}
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InfoCard icon={PersonIcon} title="Informações do Cliente">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">Cliente</Typography>
                                        <Typography variant="body2">{formatarDadosCliente(pedido.clienteId)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle2" color="textSecondary">Retirado</Typography>
                                        <Chip
                                            label={pedido.retirada ? "Sim" : "Não"}
                                            size="small"
                                            color={pedido.retirada ? "primary" : "default"}
                                        />
                                    </Grid>
                                    {pedido.linkDrive && (
                                        <Grid item xs={12}>
                                            <Button
                                                startIcon={<LinkIcon />}
                                                href={pedido.linkDrive}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            >
                                                Acessar Arquivos
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </InfoCard>
                        </Grid>
                        <Grid item xs={12}>
                            <InfoCard icon={CalendarIcon} title="Datas">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle2" color="textSecondary">Cadastro</Typography>
                                        <Typography variant="body2">{formatarDataComFuso(pedido.createdAt)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle2" color="textSecondary">Entrega</Typography>
                                        <Typography variant="body2">{formatarDataComFuso(pedido.dataEntrega)}</Typography>
                                    </Grid>
                                    {!pedido.retirada && (
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="subtitle2" color="textSecondary">Limite Retirada</Typography>
                                            <Typography variant="body2">{formatarDataComFuso(pedido.dataLimiteRetirada)}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </InfoCard>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Valores e ZoomPlus */}
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InfoCard icon={MoneyIcon} title="Valores">
                                <Grid container spacing={1}>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant="subtitle2" color="textSecondary">Total</Typography>
                                        <Typography variant="body2" color="primary">{formatarValor(pedido.total)}</Typography>
                                    </Grid>
                                    {pedido.valorUrgencia > 0 && (
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="subtitle2" color="textSecondary">Taxa Urgência</Typography>
                                            <Typography variant="body2" color="error">{formatarValor(pedido.valorUrgencia)}</Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant="subtitle2" color="textSecondary">A Pagar</Typography>
                                        <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold' }}>{formatarValor(pedido.valorPagar)}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                                        <Chip
                                            label={getStatusPagamentoLabel(pedido.statusPagamento).label}
                                            size="small"
                                            sx={{
                                                color: getStatusPagamentoLabel(pedido.statusPagamento).color,
                                                bgcolor: alpha(getStatusPagamentoLabel(pedido.statusPagamento).color, 0.1)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </InfoCard>
                        </Grid>
                        {pedido.isZoomPlus && (
                            <Grid item xs={12}>
                                <InfoCard icon={StarIcon} title="ZoomPlus" elevation={3}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} sm={4}>
                                            <Typography variant="subtitle2" color="textSecondary">Saldo Utilizado</Typography>
                                            <Typography variant="body2" color="primary">{formatarValor(pedido.zoomPlus.saldoUtilizado)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Typography variant="subtitle2" color="textSecondary">Saldo Restante</Typography>
                                            <Typography variant="body2">{formatarValor(pedido.zoomPlus.saldoRestante)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Typography variant="subtitle2" color="textSecondary">Desconto</Typography>
                                            <Typography variant="body2" color="success.main">{formatarValor(pedido.zoomPlus.descontoAplicado)}</Typography>
                                        </Grid>
                                        {(pedido.zoomPlus.taxaUrgenciaGratis || pedido.zoomPlus.modeloCompletaGratis) && (
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" color="textSecondary">Benefícios</Typography>
                                                <Box sx={{ mt: 0.5 }}>
                                                    {pedido.zoomPlus.beneficiosUtilizados.taxaUrgenciaGratis && (
                                                        <Chip
                                                            label="Taxa de Urgência Grátis"
                                                            size="small"
                                                            sx={{ mr: 1, mb: 0.5 }}
                                                            color="primary"
                                                        />
                                                    )}
                                                    {pedido.zoomPlus.beneficiosUtilizados.modeloCompletaGratis && (
                                                        <Chip
                                                            label="Modelo Completa Grátis"
                                                            size="small"
                                                            sx={{ mb: 0.5 }}
                                                            color="primary"
                                                        />
                                                    )}
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </InfoCard>
                            </Grid>
                        )}
                        {/* Informações de Pagamento Asaas */}
                        {pedido.asaas && pedido.asaas.paymentId && (
                            <Grid item xs={12}>
                                <InfoCard icon={ReceiptIcon} title="Informações de Pagamento">
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={3}>
                                            <Typography variant="subtitle2" color="textSecondary">Status Asaas</Typography>
                                            <Chip
                                                label={pedido.asaas.paymentStatus}
                                                size="small"
                                                color={pedido.asaas.paymentStatus === 'RECEIVED' ? 'success' : 'default'}
                                            />
                                        </Grid>
                                        {pedido.asaas.billingType && (
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle2" color="textSecondary">Forma de Pagamento</Typography>
                                                <Typography variant="body2">{pedido.asaas.billingType}</Typography>
                                            </Grid>
                                        )}
                                        {pedido.asaas.dueDate && (
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle2" color="textSecondary">Vencimento</Typography>
                                                <Typography variant="body2">{formatarDataComFuso(pedido.asaas.dueDate)}</Typography>
                                            </Grid>
                                        )}
                                        {pedido.asaas.paymentLink && (
                                            <Grid item xs={12} sm={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    href={pedido.asaas.paymentLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    startIcon={<LinkIcon />}
                                                    size="small"
                                                    fullWidth
                                                >
                                                    Acessar Link
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </InfoCard>
                            </Grid>
                        )}
                    </Grid>
                </Grid>


                {/* Serviços */}
                <Grid item xs={12} >
                    <InfoCard icon={CartIcon} title="Serviços">
                        <Box>
                            <Grid container spacing={0.5}>
                                {pedido.servicos.map((servico, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                p: 1,
                                                borderRadius: 1,
                                                bgcolor: alpha(theme.palette.primary.main, 0.03),
                                                '&:hover': {
                                                    bgcolor: alpha(theme.palette.primary.main, 0.05)
                                                }
                                            }}
                                        >
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle2" color="primary">
                                                        {servico.servicoId.nome}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Quantidade: {servico.quantidade}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Subtotal: {formatarValor(servico.subtotal)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </InfoCard>

                    {/* Exibir Arquivos*/}
                    <InfoCard icon={FolderIcon} title="Arquivos">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PedidoArquivosGrid
                                    arquivos={arquivos}
                                    isAdmin={true}
                                    uploading={uploading}
                                    onUpload={uploadArquivo}
                                    onDelete={excluirArquivo}
                                    onDownload={downloadArquivo}
                                    onDownloadAll={downloadTodosArquivos}
                                />
                            </Grid>
                        </Grid>
                    </InfoCard>
                </Grid>
            </Grid>

            <AlertaReutilizavel
                mensagem={alerta.mensagem}
                tipo={alerta.tipo}
                aberto={alerta.aberto}
                onFechar={() => setAlerta({ ...alerta, aberto: false })}
            />

            <ConfirmationModal
                show={confirmModalOpen}
                handleClose={() => setConfirmModalOpen(false)}
                handleConfirm={handleExcluirPedido}
                title="Confirmar Exclusão"
                body="Tem certeza que deseja excluir este pedido? Esta ação não pode ser desfeita."
            />
        </Box>
    );
};


export default VisualizarPedido;
