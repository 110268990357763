import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { CloudDownload } from '@mui/icons-material';

import PreviewDialog from './arquivos/PreviewDialog';
import UploadProgressBar from './arquivos/UploadProgressBar';
import UploadArea from './arquivos/UploadArea';
import { FileMenu, SelectionBar } from './arquivos/FileActions';
import FileGrid from './arquivos/FileGrid';
import FileBreadcrumbs from './arquivos/FileBreadcrumbs';
import { useFileHandlers, usePreviewHandlers, useMenuHandlers } from '../../hooks/useFileHandlers';

const PedidoArquivosGrid = ({
    arquivos,
    pedido,
    isAdmin,
    uploading,
    downloadingAll,
    uploadProgress,
    currentPath,
    onUpload,
    uploadPasta,
    onDelete,
    onDeleteMany,
    onDownload,
    onDownloadAll,
    onNavigate,
    onUploadSuccess,
    onUploadError,
    isSelectionFolder,
    totalSelecionados,
    onSelectForDelivery,
    onToggleSelectionFolder,
}) => {
    const [imageError, setImageError] = useState({});

    const {
        dragOver,
        selectedFiles,
        handleDragOver,
        handleDragLeave,
        handleDrop,
        handleFileSelect,
        handleFolderSelect,
        handleSelectAll,
        handleSelectFile,
        clearSelection
    } = useFileHandlers({
        onUpload,
        uploadPasta,
        onUploadSuccess,
        onUploadError
    });

    const {
        previewFile,
        currentPreviewIndex,
        setPreviewFile,
        setCurrentPreviewIndex,
        handlePreviewNext,
        handlePreviewPrev,
        handlePreviewClose
    } = usePreviewHandlers();

    const {
        anchorEl,
        activeFile,
        handleMenuOpen,
        handleMenuClose
    } = useMenuHandlers();

    const previewableFiles = arquivos.filter(arquivo =>
        arquivo.tipo?.startsWith('image/') || arquivo.tipo?.startsWith('video/') || arquivo.tipo === 'application/pdf'
    );

    const handleDeleteSelected = () => {
        onDeleteMany(selectedFiles);
        clearSelection();
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPendente = pedido?.statusPagamento === 'pendente';

    return (
        <Box>
            <FileBreadcrumbs currentPath={currentPath} onNavigate={onNavigate} />

            {isAdmin && (
                <>
                    <UploadArea
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onFileSelect={handleFileSelect}
                        onFolderSelect={handleFolderSelect}
                        uploading={uploading}
                        uploadProgress={uploadProgress}
                        dragOver={dragOver}
                    />

                    {uploading && uploadProgress && (
                        <UploadProgressBar progress={uploadProgress} />
                    )}

                    {arquivos.length > 0 && (
                        <SelectionBar
                            selectedFiles={selectedFiles}
                            totalFiles={arquivos.length}
                            onSelectAll={() => handleSelectAll(arquivos)}
                            onDeleteSelected={handleDeleteSelected}
                        />
                    )}
                </>
            )}

            {arquivos.length > 0 && !isAdmin && (
                <Button
                    variant="outlined"
                    startIcon={downloadingAll ? <CircularProgress size={20} /> : <CloudDownload />}
                    onClick={() => onDownloadAll(isMobile)}
                    disabled={downloadingAll || isPendente}
                    fullWidth
                    sx={{
                        mb: 3,
                        borderRadius: 2,
                        textTransform: 'none'
                    }}
                >
                    {downloadingAll
                        ? 'Baixando arquivos...'
                        : isPendente
                            ? 'Download disponível após pagamento'
                            : isMobile
                                ? 'Baixar Todos os Arquivos'
                                : 'Baixar Todos os Arquivos (ZIP)'}
                </Button>
            )}

            {arquivos.length > 0 ? (
                <FileGrid
                    arquivos={arquivos}
                    pedido={pedido}
                    isAdmin={isAdmin}
                    selectedFiles={selectedFiles}
                    onSelectFile={handleSelectFile}
                    onNavigate={onNavigate}
                    onImageError={(id) => setImageError(prev => ({ ...prev, [id]: true }))}
                    onPreviewClick={(arquivo) => {
                        const index = previewableFiles.findIndex(file => file._id === arquivo._id);
                        setCurrentPreviewIndex(index);
                        setPreviewFile(arquivo);
                    }}
                    onMenuOpen={handleMenuOpen}
                    isSelectionFolder={isSelectionFolder}
                    totalSelecionados={totalSelecionados}
                    onSelectForDelivery={onSelectForDelivery}
                    onToggleSelectionFolder={onToggleSelectionFolder}
                    showPreviewText={true}
                />
            ) : (
                <Typography
                    color="textSecondary"
                    align="center"
                    sx={{ py: 8 }}
                >
                    Nenhum arquivo disponível
                </Typography>
            )}

            <PreviewDialog
                open={!!previewFile}
                onClose={handlePreviewClose}
                arquivo={previewFile}
                pedido={pedido}
                isAdmin={isAdmin}
                onNext={() => handlePreviewNext(previewableFiles)}
                onPrev={() => handlePreviewPrev(previewableFiles)}
                hasNext={currentPreviewIndex < previewableFiles.length - 1}
                hasPrev={currentPreviewIndex > 0}
                isSelectionFolder={isSelectionFolder}
                totalSelecionados={totalSelecionados}
                onSelectForDelivery={onSelectForDelivery}
            />

            <FileMenu
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                onDownload={onDownload}
                onDelete={onDelete}
                isAdmin={isAdmin}
                activeFile={activeFile}
                pedido={pedido}
            />
        </Box>
    );
};

export default PedidoArquivosGrid;
