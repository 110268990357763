import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    AppBar,
    Toolbar,
    Breadcrumbs,
    Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleIcon from '@mui/icons-material/People';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import Board from '../components/board/Board';
import axios from '../api';

const BoardDetailContainer = styled(Box)({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
});

const BoardHeader = styled(AppBar)(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`
}));

const BoardContent = styled(Box)({
    flexGrow: 1,
    overflowY: 'hidden'
});

const BoardDetail = () => {
    const { boardId } = useParams();
    const navigate = useNavigate();
    const [board, setBoard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editTitle, setEditTitle] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [membersDialogOpen, setMembersDialogOpen] = useState(false);

    useEffect(() => {
        fetchBoardDetails();
    }, [boardId]);

    const fetchBoardDetails = async () => {
        try {
            const response = await axios.get(`/boards/${boardId}`);
            setBoard(response.data);
            setEditTitle(response.data.titulo);
            setEditDescription(response.data.descricao || '');
            setLoading(false);
        } catch (err) {
            console.error('Error fetching board details:', err);
            setError('Erro ao carregar o quadro');
            setLoading(false);
        }
    };

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleEditClick = () => {
        handleMenuClose();
        setEditDialogOpen(true);
    };

    const handleEditSubmit = async () => {
        try {
            const response = await axios.put(`/boards/${boardId}`, {
                titulo: editTitle.trim(),
                descricao: editDescription.trim()
            });
            setBoard(response.data);
            setEditDialogOpen(false);
        } catch (err) {
            console.error('Error updating board:', err);
            setError('Erro ao atualizar quadro');
        }
    };

    const handleDeleteBoard = async () => {
        try {
            await axios.delete(`/boards/${boardId}`);
            navigate('/boards');
        } catch (err) {
            console.error('Error deleting board:', err);
            setError('Erro ao deletar quadro');
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <BoardDetailContainer>
            <BoardHeader>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        component={RouterLink}
                        to="/boards"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon />
                    </IconButton>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            component={RouterLink}
                            to="/boards"
                            color="inherit"
                            underline="hover"
                        >
                            Quadros
                        </Link>
                        <Typography color="textPrimary">{board?.titulo}</Typography>
                    </Breadcrumbs>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton
                        color="inherit"
                        onClick={() => setMembersDialogOpen(true)}
                    >
                        <PeopleIcon />
                    </IconButton>

                    <IconButton
                        color="inherit"
                        onClick={handleMenuOpen}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Toolbar>
            </BoardHeader>

            <BoardContent>
                <Board />
            </BoardContent>

            {/* Menu de opções */}
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditClick}>Editar</MenuItem>
                <MenuItem onClick={handleDeleteBoard}>Excluir</MenuItem>
            </Menu>

            {/* Dialog de edição */}
            <Dialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Editar Quadro</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Título"
                        fullWidth
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Descrição"
                        fullWidth
                        multiline
                        rows={3}
                        value={editDescription}
                        onChange={(e) => setEditDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)}>Cancelar</Button>
                    <Button onClick={handleEditSubmit} variant="contained">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog de membros */}
            <Dialog
                open={membersDialogOpen}
                onClose={() => setMembersDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Membros do Quadro</DialogTitle>
                <DialogContent>
                    {/* TODO: Implementar lista de membros e funcionalidade de adicionar/remover membros */}
                    <Typography>
                        Funcionalidade em desenvolvimento
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMembersDialogOpen(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </BoardDetailContainer>
    );
};

export default BoardDetail;
