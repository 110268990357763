import React from 'react';
import {
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from '@mui/material';
import { meses } from '../utils/pedidoUtils';

const PedidosFilter = ({
    filters,
    handleFilterChange,
    showClienteFilter,
    readOnly,
    clientes = [] // Garantir valor padrão como array vazio
}) => {
    return (
        <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Grid container spacing={2}>
                {showClienteFilter && !readOnly && (
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Cliente</InputLabel>
                            <Select
                                value={filters.clienteId || ""}
                                onChange={(e) => handleFilterChange('clienteId')(e)}
                                label="Cliente"
                            >
                                <MenuItem value="">Todos</MenuItem>
                                {Array.isArray(clientes) && clientes.map(cliente => (
                                    <MenuItem key={cliente._id} value={cliente._id}>
                                        {cliente.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={filters.status || ""}
                            onChange={(e) => handleFilterChange('status')(e)}
                            label="Status"
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="aberto">Aberto</MenuItem>
                            <MenuItem value="em andamento">Em Andamento</MenuItem>
                            <MenuItem value="concluido">Concluído</MenuItem>
                            <MenuItem value="cancelado">Cancelado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Status Pagamento</InputLabel>
                        <Select
                            value={filters.statusPagamento || ""}
                            onChange={(e) => handleFilterChange('statusPagamento')(e)}
                            label="Status Pagamento"
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="pendente">Pendente</MenuItem>
                            <MenuItem value="pago">Pago</MenuItem>
                            <MenuItem value="pago_com_saldo">Pago com Saldo</MenuItem>
                            <MenuItem value="pago_parcial_saldo">Pago Parcial Saldo</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {!readOnly && (
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Retirada</InputLabel>
                            <Select
                                value={filters.retirada || ""}
                                onChange={(e) => handleFilterChange('retirada')(e)}
                                label="Retirada"
                            >
                                <MenuItem value="">Todos</MenuItem>
                                <MenuItem value="true">Sim</MenuItem>
                                <MenuItem value="false">Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Urgente</InputLabel>
                        <Select
                            value={filters.isUrgent || ""}
                            onChange={(e) => handleFilterChange('isUrgent')(e)}
                            label="Urgente"
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="true">Sim</MenuItem>
                            <MenuItem value="false">Não</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Mês de Criação</InputLabel>
                        <Select
                            value={filters.mesCriacao || ""}
                            onChange={(e) => handleFilterChange('mesCriacao')(e)}
                            label="Mês de Criação"
                        >
                            <MenuItem value="">Todos</MenuItem>
                            {meses.map(mes => (
                                <MenuItem key={mes.value} value={mes.value}>
                                    {mes.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        fullWidth
                        label="Valor Mínimo"
                        type="number"
                        value={filters.valorMinimo || ""}
                        onChange={(e) => handleFilterChange('valorMinimo')(e)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        fullWidth
                        label="Valor Máximo"
                        type="number"
                        value={filters.valorMaximo || ""}
                        onChange={(e) => handleFilterChange('valorMaximo')(e)}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PedidosFilter;
