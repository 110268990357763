import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    CircularProgress,
    Menu,
    MenuItem,
    Paper,
    Fade,
    Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom';
import axios from '../api';
import PageHeader from '../components/shared/PageHeader';

const BoardCard = styled(Card)(({ theme }) => ({
    height: '150px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[8]
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
}));

const AddBoardCard = styled(Card)(({ theme }) => ({
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[8],
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200]
    }
}));

const BoardsPage = () => {
    const navigate = useNavigate();
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [newBoardTitle, setNewBoardTitle] = useState('');
    const [newBoardDescription, setNewBoardDescription] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedBoard, setSelectedBoard] = useState(null);


    useEffect(() => {
        fetchBoards();
    }, []);

    const fetchBoards = async () => {
        try {
            const response = await axios.get('/boards');
            setBoards(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching boards:', err);
            setError('Erro ao carregar os quadros');
            setLoading(false);
        }
    };

    const handleCreateBoard = async () => {
        if (!newBoardTitle.trim()) {
            return;
        }

        try {
            const response = await axios.post('/boards', {
                titulo: newBoardTitle.trim(),
                descricao: newBoardDescription.trim()
            });

            setBoards(prev => [...prev, response.data]);
            setCreateDialogOpen(false);
            setNewBoardTitle('');
            setNewBoardDescription('');
        } catch (err) {
            console.error('Error creating board:', err);
            setError('Erro ao criar quadro');
        }
    };

    const handleDeleteBoard = async (boardId) => {
        try {
            await axios.delete(`/boards/${boardId}`);
            setBoards(prev => prev.filter(board => board._id !== boardId));
            handleMenuClose();
        } catch (err) {
            console.error('Error deleting board:', err);
            setError('Erro ao deletar quadro');
        }
    };

    const handleBoardClick = (boardId) => {
        navigate(`/boards/${boardId}`);
    };

    const handleMenuClick = (event, board) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedBoard(board);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedBoard(null);
    };

    return (
        <PageHeader
            title="Meus Quadros"
            icon={<DashboardIcon />}
        >
            <Box sx={{ width: '100%', p: 1 }}>
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {loading && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 1000,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setCreateDialogOpen(true)}
                        size="medium"
                    >
                        Novo Quadro
                    </Button>
                </Box>

                <Grid container spacing={3}>
                    {boards.map((board) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={board._id}>
                            <BoardCard onClick={() => handleBoardClick(board._id)}>
                                <CardContent>
                                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="h6" noWrap sx={{ fontWeight: 'bold' }}>
                                                {board.titulo}
                                            </Typography>
                                            {board.descricao && (
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{
                                                        mt: 1,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical'
                                                    }}
                                                >
                                                    {board.descricao}
                                                </Typography>
                                            )}
                                        </Box>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleMenuClick(e, board)}
                                            sx={{ ml: 1 }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </BoardCard>
                        </Grid>
                    ))}

                </Grid>

                {/* Dialog para criar novo quadro */}
                <Dialog
                    open={createDialogOpen}
                    onClose={() => setCreateDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        elevation: 24,
                        sx: { borderRadius: 2 }
                    }}
                >
                    <DialogTitle>Criar Novo Quadro</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Título"
                            fullWidth
                            value={newBoardTitle}
                            onChange={(e) => setNewBoardTitle(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Descrição"
                            fullWidth
                            multiline
                            rows={3}
                            value={newBoardDescription}
                            onChange={(e) => setNewBoardDescription(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCreateDialogOpen(false)}>Cancelar</Button>
                        <Button onClick={handleCreateBoard} variant="contained">
                            Criar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Menu de opções do quadro */}
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => {
                        if (selectedBoard) {
                            handleDeleteBoard(selectedBoard._id);
                        }
                    }}>
                        Excluir
                    </MenuItem>
                </Menu>
            </Box>
        </PageHeader>
    );
};

export default BoardsPage;
