import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    IconButton,
    CircularProgress,
    Grid,
    useTheme,
    alpha,
    Chip,
} from '@mui/material';
import {
    Save as SaveIcon,
    ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { useCadastroPedidos } from '../hooks/useCadastroPedidos';
import AlertaReutilizavel from '../components/shared/AlertaReutilizavel';
import EditarPedidoInfo from '../components/editarPedido/EditarPedidoInfo';
import EditarPedidoServicos from '../components/editarPedido/EditarPedidoServicos';
import EditarPedidoResumo from '../components/editarPedido/EditarPedidoResumo';

const EditarPedido = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const {
        servicos: servicosDisponiveis,
        novoPedido,
        clienteZoomPlus,
        alerta,
        totais,
        dataEntrega,
        handleServicoAdd,
        handleUrgenciaChange,
        handleQuantidadeChange,
        handleDeleteServico,
        handleSubmit,
        setAlerta,
        isSubmitting,
        handleZoomPlusBenefitChange,
        loadPedidoForEdit,
        setNovoPedido,
        fetchData,
        isLoadingPedido
    } = useCadastroPedidos('edit');

    useEffect(() => {
        const inicializarPagina = async () => {
            try {
                setLoading(true);
                // Primeiro carrega os dados básicos (clientes e serviços)
                await fetchData();
                // Depois carrega os dados do pedido específico
                await loadPedidoForEdit(id);
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
                setError('Erro ao carregar o pedido. Por favor, tente novamente.');
            } finally {
                setLoading(false);
            }
        };

        inicializarPagina();
    }, [id, loadPedidoForEdit, fetchData]);

    const handleSubmitWrapper = async (e) => {
        e.preventDefault();
        try {
            const success = await handleSubmit(e, id);
            if (success) {
                // Aguarda um pequeno intervalo antes de redirecionar
                setTimeout(() => {
                    navigate(-1, { replace: true });
                }, 100);
            }
        } catch (error) {
            console.error('Erro ao salvar pedido:', error);
            setAlerta({
                mensagem: "Erro ao salvar o pedido",
                tipo: "error",
                aberto: true
            });
        }
    };

    const handleLinkDriveChange = (value) => {
        setNovoPedido(prev => ({
            ...prev,
            linkDrive: value
        }));
    };

    if (loading || isLoadingPedido) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    const getStatusConfig = (status) => {
        const config = {
            'aberto': { color: '#FFA726', bgColor: '#FFF3E0', label: 'Aberto' },
            'em andamento': { color: '#1E88E5', bgColor: '#E3F2FD', label: 'Em Andamento' },
            'concluido': { color: '#66BB6A', bgColor: '#E8F5E9', label: 'Concluído' },
            'cancelado': { color: '#EF5350', bgColor: '#FFEBEE', label: 'Cancelado' }
        };
        return config[status] || config['aberto'];
    };

    const statusConfig = getStatusConfig(novoPedido.status || 'aberto');

    // Definindo cores do header baseado no tema
    const headerStyle = theme.palette.mode === 'light'
        ? {
            background: 'main.primary', // Cinza escuro no modo light
            color: '#222224',      // Texto branco
        }
        : {
            background: 'main.primary', // Off-white no modo dark
            color: '#fff',      // Texto preto
        };

    return (
        <Box sx={{ p: 2 }}>
            {/* Header com design adaptativo */}
            <Paper
                elevation={1}
                sx={{
                    p: 2,
                    mb: 3,
                    ...headerStyle,
                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                    borderRadius: 2
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            color: 'inherit',
                            '&:hover': {
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                color: theme.palette.primary.main
                            }
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                mb: 0.5,
                                color: 'inherit'
                            }}
                        >
                            Editar Pedido #{id.slice(-4).toUpperCase()}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Chip
                                label={statusConfig.label}
                                size="small"
                                sx={{
                                    bgcolor: statusConfig.bgColor,
                                    color: statusConfig.color,
                                    fontWeight: 'bold',
                                }}
                            />
                            <Typography
                                variant="body2"
                                sx={{ color: 'inherit', opacity: 0.9 }}
                            >
                                Cliente: {novoPedido.cliente?.nome || 'Cliente não encontrado'}
                            </Typography>
                            {novoPedido.isZoomPlus && (
                                <Chip
                                    label="ZOOM+"
                                    size="small"
                                    sx={{
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                        color: theme.palette.primary.main,
                                        fontWeight: 'bold'
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Paper>

            <AlertaReutilizavel
                mensagem={alerta.mensagem}
                tipo={alerta.tipo}
                aberto={alerta.aberto}
                onFechar={() => setAlerta({ ...alerta, aberto: false })}
            />

            <form onSubmit={handleSubmitWrapper}>
                <Grid container spacing={3}>
                    {/* Coluna Esquerda - Informações e Serviços */}
                    <Grid item xs={12} md={8}>
                        <EditarPedidoInfo
                            cliente={novoPedido.cliente}
                            dataEntrega={dataEntrega}
                            dataLimiteRetirada={novoPedido.dataLimiteRetirada}
                            dataCadastro={novoPedido.createdAt}
                            retirada={novoPedido.retirada}
                            isUrgent={novoPedido.isUrgent}
                            isZoomPlus={novoPedido.isZoomPlus}
                            beneficiosUtilizados={novoPedido.zoomPlus?.beneficiosUtilizados}
                            onUrgenciaChange={handleUrgenciaChange}
                            onBeneficioChange={handleZoomPlusBenefitChange}
                            onLinkDriveChange={handleLinkDriveChange}
                            linkDrive={novoPedido.linkDrive}
                        />

                        <EditarPedidoServicos
                            servicos={novoPedido.servicos}
                            servicosDisponiveis={servicosDisponiveis}
                            onAddServico={handleServicoAdd}
                            onRemoveServico={handleDeleteServico}
                            onQuantidadeChange={handleQuantidadeChange}
                        />
                    </Grid>

                    {/* Coluna Direita - Resumo */}
                    <Grid item xs={12} md={4}>
                        <Box sx={{ position: { md: 'sticky' }, top: { md: '20px' } }}>
                            <EditarPedidoResumo
                                totais={totais}
                                isZoomPlus={novoPedido.isZoomPlus}
                                statusPagamento={novoPedido.statusPagamento}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                                disabled={isSubmitting}
                                sx={{
                                    py: 1.5,
                                    fontSize: '1.1rem',
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                    '&:hover': {
                                        boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    }
                                }}
                            >
                                {isSubmitting ? "Salvando..." : "Salvar Alterações"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default EditarPedido;
