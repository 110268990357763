import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { MenuBook } from '@mui/icons-material';

const CatalogosSection = () => (
    <>
        <Grid item xs={12} md={6}>
            <Paper sx={{
                p: 4,
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ color: '#eb5b25', mb: 1 }}>Layout</Typography>
                    <Typography variant="h4" sx={{ color: 'white' }}>R$ 120,00</Typography>
                    <Typography sx={{ color: 'rgba(255,255,255,0.7)' }}>(gratuito no Clube)</Typography>
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ color: '#eb5b25', mb: 1 }}>Valor por página</Typography>
                    <Typography variant="h4" sx={{ color: 'white' }}>R$ 30,00</Typography>
                </Box>
                <Box>
                    <Typography variant="h6" sx={{ color: '#eb5b25', mb: 1 }}>Alterações</Typography>
                    <Typography variant="h4" sx={{ color: 'white' }}>R$ 20,00</Typography>
                </Box>
            </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box sx={{
                p: 4,
                height: '100%',
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-5px)',
                }
            }}>
                <MenuBook sx={{ fontSize: 60, color: '#eb5b25' }} />
            </Box>
        </Grid>
    </>
);

export default CatalogosSection;
