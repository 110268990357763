import React from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    AppBar,
    Toolbar,
    useMediaQuery,
} from '@mui/material';
import {
    Close as CloseIcon,
    Folder as FolderIcon,
    Image as ImageIcon,
    PictureAsPdf as PdfIcon,
    VideoFile as VideoIcon,
    Archive as ArchiveIcon,
    ViewModule as GridViewIcon,
    ViewList as ListViewIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import PedidoArquivos from './PedidoArquivos';

const DRAWER_WIDTH = 240;

const ArquivosModal = ({ open, onClose, pedidoId, isAdmin = false }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = React.useState('grid');
    const [selectedCategory, setSelectedCategory] = React.useState('all');
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

    const categories = [
        { id: 'all', label: 'Todos os Arquivos', icon: FolderIcon },
        { id: 'images', label: 'Imagens', icon: ImageIcon },
        { id: 'documents', label: 'Documentos', icon: PdfIcon },
        { id: 'videos', label: 'Vídeos', icon: VideoIcon },
    ];

    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
        if (isMobile) {
            setMobileDrawerOpen(false);
        }
    };

    const renderDrawerContent = () => (
        <List sx={{ py: 2 }}>
            {categories.map((category) => {
                const Icon = category.icon;
                return (
                    <ListItem
                        key={category.id}
                        button
                        selected={selectedCategory === category.id}
                        onClick={() => handleCategorySelect(category.id)}
                        sx={{
                            cursor: 'pointer',
                            borderRadius: 1,
                            mx: 1,
                            width: 'auto',
                            '&.Mui-selected': {
                                bgcolor: theme.palette.primary.main + '1A',
                                '&:hover': {
                                    bgcolor: theme.palette.primary.main + '33',
                                },
                            },
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: 40 }}>
                            <Icon color={selectedCategory === category.id ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText
                            primary={category.label}
                            primaryTypographyProps={{
                                color: selectedCategory === category.id ? 'primary' : 'textPrimary',
                                fontWeight: selectedCategory === category.id ? 500 : 400,
                            }}
                        />
                    </ListItem>
                );
            })}
        </List>
    );

    const handleClose = () => {
        setMobileDrawerOpen(false);
        onClose();
    };

    return (
        <>
            {/* Mobile Drawer - Fora do Dialog para evitar problemas de z-index */}
            {isMobile && (
                <Drawer
                    open={mobileDrawerOpen}
                    onClose={() => setMobileDrawerOpen(false)}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            width: DRAWER_WIDTH,
                            boxSizing: 'border-box',
                        },
                    }}
                    ModalProps={{
                        keepMounted: true,
                        style: { zIndex: theme.zIndex.modal + 1 }
                    }}
                >
                    {renderDrawerContent()}
                </Drawer>
            )}

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
                fullScreen={isMobile}
                PaperProps={{
                    sx: {
                        borderRadius: isMobile ? 0 : 2,
                        height: isMobile ? '100vh' : '90vh',
                        maxHeight: isMobile ? '100vh' : '90vh',
                        m: isMobile ? 0 : 2,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }
                }}
            >
                <AppBar position="static" color="default" elevation={0}>
                    <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {isMobile && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setMobileDrawerOpen(true)}
                                sx={{
                                    mr: 2,
                                    '&:active': {
                                        bgcolor: 'action.selected'
                                    }
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Arquivos do Pedido
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <IconButton
                                onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                                size="small"
                                sx={{
                                    bgcolor: 'action.hover',
                                    borderRadius: 1,
                                    mr: 1,
                                    '&:hover': { bgcolor: 'action.selected' }
                                }}
                            >
                                {viewMode === 'grid' ? <ListViewIcon /> : <GridViewIcon />}
                            </IconButton>
                            <IconButton onClick={handleClose} size="small">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                    {!isMobile && (
                        <Drawer
                            variant="permanent"
                            sx={{
                                width: DRAWER_WIDTH,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    position: 'relative',
                                    width: DRAWER_WIDTH,
                                    border: 'none',
                                    borderRight: 1,
                                    borderColor: 'divider',
                                },
                            }}
                        >
                            {renderDrawerContent()}
                        </Drawer>
                    )}

                    <DialogContent sx={{
                        p: { xs: 1, sm: 3 },
                        overflow: 'auto',
                        bgcolor: theme.palette.background.default,
                    }}>
                        <PedidoArquivos
                            pedidoId={pedidoId}
                            isAdmin={isAdmin}
                            viewMode={viewMode}
                            selectedCategory={selectedCategory}
                        />
                    </DialogContent>
                </Box>
            </Dialog>
        </>
    );
};

export default ArquivosModal;
