import React, { useState, useEffect } from 'react';
import { formatarDataComFuso } from '../../utils/dateUtils';
import {
    Box,
    Typography,
    Avatar,
    Grid,
    useTheme,
    Chip,
    alpha,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    useMediaQuery
} from '@mui/material';
import {
    Email,
    Phone,
    Star,
    CalendarToday,
    Badge,
    Speed,
    Description,
    CloudQueue,
    Payment,
    LocationCity,
    AttachMoney,
    Event as EventIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import api from '../../api';
import SectionTitle from '../SectionTitle';
import InfoItem from '../InfoItem';

// Define avatar paths for the public directory
const avatars = Array.from({ length: 12 }, (_, i) => {
    const num = String(i + 1).padStart(2, '0');
    return `/avatares/${num}.jpg`;
});

const UserProfile = ({ cliente }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedAvatar, setSelectedAvatar] = useState(cliente?.avatar || null);
    const [openAvatarDialog, setOpenAvatarDialog] = useState(false);

    useEffect(() => {
        if (cliente?.avatar) {
            setSelectedAvatar(cliente.avatar);
        }
    }, [cliente?.avatar]);

    const handleAvatarClick = () => {
        setOpenAvatarDialog(true);
    };

    const handleAvatarSelect = async (avatar) => {
        try {
            await api.put('/user/avatar', { avatar });
            setSelectedAvatar(avatar);
            setOpenAvatarDialog(false);
        } catch (error) {
            console.error('Error updating avatar:', error);
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    };

    const GlassCard = ({ children, elevation = 0, noPadding = false }) => (
        <Box
            sx={{
                background: alpha(theme.palette.background.paper, 0.7),
                backdropFilter: 'blur(10px)',
                borderRadius: '24px',
                border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
                boxShadow: elevation ? `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}` : 'none',
                overflow: 'hidden',
                position: 'relative',
                p: noPadding ? 0 : { xs: 2, sm: 3 }
            }}
        >
            {children}
        </Box>
    );

    const sectionTitleWidth = 100;

    if (!cliente) return null;

    return (
        <Box
            sx={{
                p: { xs: 2, sm: 3 },
                minHeight: '100vh',
                background: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0.05)}, ${alpha(theme.palette.primary.main, 0.04)})`,
                maxWidth: '100%',
                margin: '0 auto'
            }}
        >
            {/* Header Card */}
            <GlassCard elevation={1}>
                <Box sx={{
                    p: { xs: 2, sm: 4 },
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'center', sm: 'flex-start' },
                    gap: { xs: 2, sm: 4 }
                }}>
                    {/* Avatar Section */}
                    <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Avatar
                            src={selectedAvatar}
                            alt={cliente.nome}
                            sx={{
                                width: { xs: 100, sm: 120 },
                                height: { xs: 100, sm: 120 },
                                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                                fontSize: { xs: '2rem', sm: '2.5rem' },
                                fontWeight: 600,
                                border: `4px solid ${alpha(theme.palette.background.paper, 0.9)}`,
                                boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.2)}`
                            }}
                        >
                            {!selectedAvatar && cliente.nome ? cliente.nome.charAt(0).toUpperCase() : '?'}
                        </Avatar>
                        <IconButton
                            onClick={handleAvatarClick}
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                                width: 36,
                                height: 36
                            }}
                        >
                            <EditIcon sx={{ color: '#fff', fontSize: '1.2rem' }} />
                        </IconButton>
                    </Box>

                    {/* User Info */}
                    <Box sx={{
                        flex: 1,
                        textAlign: { xs: 'center', sm: 'left' }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: 1,
                            justifyContent: { xs: 'center', sm: 'flex-start' },
                            flexWrap: 'wrap'
                        }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontWeight: 700,
                                    letterSpacing: '-0.5px',
                                    fontSize: { xs: '1.5rem', sm: '2rem' }
                                }}
                            >
                                {cliente.nome}
                            </Typography>
                            {cliente.isZoomPlus && (
                                <Chip
                                    icon={<Star />}
                                    label="Zoom+"
                                    sx={{
                                        background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                                        color: '#fff',
                                        fontWeight: 600,
                                        '& .MuiChip-icon': {
                                            color: '#fff'
                                        }
                                    }}
                                />
                            )}
                        </Box>
                        <Typography
                            sx={{
                                color: alpha(theme.palette.text.secondary, 0.8),
                                mb: 2,
                                fontSize: { xs: '0.875rem', sm: '1rem' }
                            }}
                        >
                            Cliente desde {formatarDataComFuso(cliente.createdAt)}
                        </Typography>
                        {cliente.isZoomPlus && (
                            <Box sx={{
                                display: 'flex',
                                gap: 3,
                                mt: 2,
                                justifyContent: { xs: 'center', sm: 'flex-start' },
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'center', sm: 'flex-start' }
                            }}>
                                <Box>
                                    <Typography variant="caption" sx={{ color: alpha(theme.palette.text.secondary, 0.8) }}>
                                        Plano Atual
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
                                        {cliente.planoNome || 'Não definido'}
                                    </Typography>
                                </Box>
                                <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />
                                <Box>
                                    <Typography variant="caption" sx={{ color: alpha(theme.palette.text.secondary, 0.8) }}>
                                        Saldo Disponível
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
                                        {formatCurrency(cliente.saldo || 0)}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </GlassCard>

            {/* Avatar Selection Dialog */}
            <Dialog open={openAvatarDialog} onClose={() => setOpenAvatarDialog(false)}>
                <DialogTitle>Escolha seu avatar</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        {avatars.map((avatar, index) => (
                            <Grid item xs={4} key={index}>
                                <Avatar
                                    src={avatar}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        cursor: 'pointer',
                                        border: selectedAvatar === avatar ? `3px solid ${theme.palette.primary.main}` : 'none',
                                        '&:hover': {
                                            opacity: 0.8,
                                        }
                                    }}
                                    onClick={() => handleAvatarSelect(avatar)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Content Grid */}
            <Grid container spacing={2} sx={{ mt: 3 }}>
                {/* Basic Information */}
                <Grid item xs={12}>
                    <SectionTitle width={sectionTitleWidth}>Informações Básicas</SectionTitle>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InfoItem
                        icon={Email}
                        label="Email"
                        value={cliente.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InfoItem
                        icon={Phone}
                        label="Telefone"
                        value={cliente.telefone}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InfoItem
                        icon={Badge}
                        label="Documento"
                        value={cliente.documento}
                    />
                </Grid>


                {cliente.isZoomPlus && (
                    <>
                        {/* Plan Details */}
                        <Grid item xs={12}>
                            <SectionTitle width={sectionTitleWidth}>Detalhes do Plano</SectionTitle>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoItem
                                icon={CalendarToday}
                                label="Início do Plano"
                                value={cliente.dataInicioPlano ? formatarDataComFuso(cliente.dataInicioPlano) : 'Não definido'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoItem
                                icon={CalendarToday}
                                label="Fim do Plano"
                                value={cliente.dataFinalPlano ? formatarDataComFuso(cliente.dataFinalPlano) : 'Não definido'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoItem
                                icon={AttachMoney}
                                label="Valor Mensalidade"
                                value={formatCurrency(cliente.valorMensalidade || 0)}
                                highlight
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoItem
                                icon={EventIcon}
                                label="Dia de Vencimento"
                                value={cliente.diaVencimento || 0}
                                highlight
                            />
                        </Grid>

                        {/* Available Resources */}
                        <Grid item xs={12}>
                            <SectionTitle width={sectionTitleWidth}>Recursos Disponíveis</SectionTitle>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoItem
                                icon={Speed}
                                label="Taxas de Urgência"
                                value={cliente.taxasUrgenciaDisponiveis}
                                highlight
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoItem
                                icon={Description}
                                label="Usos de Modelos"
                                value={cliente.usoModelosDisponiveis}
                                highlight
                            />
                        </Grid>

                        {/* Payment Information */}
                        {cliente.dadosPagamento && (
                            <>
                                <Grid item xs={12}>
                                    <SectionTitle width={sectionTitleWidth}>Dados de Pagamento</SectionTitle>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InfoItem
                                        icon={Payment}
                                        label="CEP"
                                        value={cliente.dadosPagamento.cep}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InfoItem
                                        icon={LocationCity}
                                        label="Cidade/Estado"
                                        value={`${cliente.dadosPagamento.cidade}/${cliente.dadosPagamento.estado}`}
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default UserProfile;
