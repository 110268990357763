// Função para ajustar a data para o fuso horário local
const formatarDataComFuso = (dataString) => {
    if (!dataString) return null;

    // Cria a data a partir da string ISO, que preserva o dia correto
    const data = new Date(dataString);
    const dataISO = data.toISOString().split('T')[0]; // Pega apenas a parte da data (YYYY-MM-DD)
    const [ano, mes, dia] = dataISO.split('-');

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano}`;
};

// Função para ajustar a data ao criar/editar
const ajustarDataParaBackend = (dataString) => {
    if (!dataString) return null;

    // Cria a data interpretando como UTC
    const [dia, mes, ano] = dataString.split('/');
    const data = new Date(Date.UTC(ano, mes - 1, dia));

    // Formata no padrão ISO
    const anoISO = data.getUTCFullYear();
    const mesISO = String(data.getUTCMonth() + 1).padStart(2, '0');
    const diaISO = String(data.getUTCDate()).padStart(2, '0');

    return `${anoISO}-${mesISO}-${diaISO}`;
};

module.exports = {
    formatarDataComFuso,
    ajustarDataParaBackend
};
