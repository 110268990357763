import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Container,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
    Chip,
    Snackbar,
    Alert
} from '@mui/material';
import {
    Check as CheckIcon,
    Close as CloseIcon,
    Edit as EditIcon,
    Visibility as VisibilityIcon
} from '@mui/icons-material';
import api from '../api';
import PageHeader from '../components/shared/PageHeader';

const CadastrosPendentes = () => {
    const navigate = useNavigate();
    const [cadastros, setCadastros] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCadastro, setSelectedCadastro] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [alerta, setAlerta] = useState({ aberto: false, mensagem: '', tipo: 'info' });

    const fetchCadastros = async () => {
        try {
            const response = await api.get('/cadastros-pendentes');
            setCadastros(response.data);
        } catch (error) {
            console.error('Erro ao carregar cadastros:', error);
            setAlerta({
                aberto: true,
                mensagem: 'Erro ao carregar cadastros pendentes',
                tipo: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCadastros();
    }, []);

    const handleViewDetails = (cadastro) => {
        setSelectedCadastro(cadastro);
        setDialogOpen(true);
    };

    const handleApprove = async (cadastro) => {
        try {
            await api.post(`/cadastros-pendentes/${cadastro._id}/aprovar`);
            setAlerta({
                aberto: true,
                mensagem: 'Cadastro aprovado com sucesso!',
                tipo: 'success'
            });
            fetchCadastros(); // Recarrega a lista
        } catch (error) {
            console.error('Erro ao aprovar cadastro:', error);
            setAlerta({
                aberto: true,
                mensagem: 'Erro ao aprovar cadastro',
                tipo: 'error'
            });
        }
    };

    const handleReject = async (cadastro) => {
        try {
            await api.post(`/cadastros-pendentes/${cadastro._id}/rejeitar`);
            setAlerta({
                aberto: true,
                mensagem: 'Cadastro rejeitado com sucesso',
                tipo: 'success'
            });
            fetchCadastros(); // Recarrega a lista
        } catch (error) {
            console.error('Erro ao rejeitar cadastro:', error);
            setAlerta({
                aberto: true,
                mensagem: 'Erro ao rejeitar cadastro',
                tipo: 'error'
            });
        }
    };

    const handleEdit = (cadastro) => {
        navigate(`/cadastros-pendentes/editar/${cadastro._id}`);
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <PageHeader
            title="Cadastros Pendentes"
        >
            <Box>
                {cadastros.length === 0 ? (
                    <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        Não há cadastros pendentes no momento.
                    </Typography>
                ) : (
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        {cadastros.map((cadastro) => (
                            <Grid item xs={12} md={6} key={cadastro._id}>
                                <Card
                                    variant="outlined"
                                    sx={
                                        {
                                            maxWidth: 400,
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }
                                    }

                                >
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            {cadastro.nome}
                                        </Typography>
                                        <Typography color="textSecondary" gutterBottom>
                                            {cadastro.email}
                                        </Typography>
                                        <Typography variant="body2">
                                            Documento: {cadastro.documento}
                                        </Typography>
                                        <Typography variant="body2">
                                            Telefone: {cadastro.telefone}
                                        </Typography>
                                        <Box sx={{ mt: 2 }}>
                                            <Chip
                                                label="Pendente"
                                                color="warning"
                                                size="small"
                                            />
                                        </Box>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            startIcon={<VisibilityIcon />}
                                            onClick={() => handleViewDetails(cadastro)}
                                        >
                                            Detalhes
                                        </Button>
                                        <Button
                                            size="small"
                                            color="success"
                                            startIcon={<CheckIcon />}
                                            onClick={() => handleApprove(cadastro)}
                                        >
                                            Aprovar
                                        </Button>
                                        <Button
                                            size="small"
                                            color="error"
                                            startIcon={<CloseIcon />}
                                            onClick={() => handleReject(cadastro)}
                                        >
                                            Rejeitar
                                        </Button>
                                        <Button
                                            size="small"
                                            startIcon={<EditIcon />}
                                            onClick={() => handleEdit(cadastro)}
                                        >
                                            Editar
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}


                {/* Dialog para visualizar detalhes */}
                <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>
                        Detalhes do Cadastro
                    </DialogTitle>
                    <DialogContent dividers>
                        {selectedCadastro && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Nome
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {selectedCadastro.nome}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Email
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {selectedCadastro.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Documento
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {selectedCadastro.documento}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Telefone
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {selectedCadastro.telefone}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Endereço Completo
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {`${selectedCadastro.endereco}, ${selectedCadastro.numero}${selectedCadastro.complemento ? ` - ${selectedCadastro.complemento}` : ''}`}
                                        <br />
                                        {`${selectedCadastro.bairro} - ${selectedCadastro.cidade}/${selectedCadastro.estado}`}
                                        <br />
                                        {`CEP: ${selectedCadastro.cep}`}
                                    </Typography>
                                </Grid>
                                {selectedCadastro.inscricaoMunicipal && (
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Inscrição Municipal
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {selectedCadastro.inscricaoMunicipal}
                                        </Typography>
                                    </Grid>
                                )}
                                {selectedCadastro.inscricaoEstadual && (
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Inscrição Estadual
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {selectedCadastro.inscricaoEstadual}
                                        </Typography>
                                    </Grid>
                                )}
                                {selectedCadastro.observacoes && (
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Observações
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {selectedCadastro.observacoes}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbar para alertas */}
                <Snackbar
                    open={alerta.aberto}
                    autoHideDuration={6000}
                    onClose={() => setAlerta({ ...alerta, aberto: false })}
                >
                    <Alert
                        onClose={() => setAlerta({ ...alerta, aberto: false })}
                        severity={alerta.tipo}
                        sx={{ width: '100%' }}
                    >
                        {alerta.mensagem}
                    </Alert>
                </Snackbar>

            </Box>
        </PageHeader>
    );
};

export default CadastrosPendentes;
