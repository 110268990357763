import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
} from "@mui/material";
import {
    Loupe as LoupeIcon,
    Event as EventIcon,
    QueryBuilder as QueryBuilderIcon,
    MonetizationOn as MonetizationOnIcon,
    Woman as WomanIcon,
    Bolt as BoltIcon,
    CalendarMonth as CalendarMonthIcon,
} from "@mui/icons-material";

const InfoCard = ({ icon: Icon, label, value, color = "primary" }) => (
    <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Icon color={color} sx={{ fontSize: 28 }} />
                    <Box>
                        <Typography variant="caption" color="textSecondary">
                            {label}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {value}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    </Grid>
);

const ClientZoomPlusCard = ({ cliente }) => {
    const formatDate = (dateString) => {
        if (!dateString) return "Não informado";
        try {
            return new Date(dateString).toLocaleDateString('pt-BR');
        } catch {
            return "Data inválida";
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value || 0);
    };

    return (
        <Box>
            {/* Cabeçalho do Zoom+ */}
            <Box sx={{ mb: 3, display: "flex", alignItems: "center", gap: 1 }}>
                <LoupeIcon color="primary" sx={{ fontSize: 28 }} />
                <Typography variant="h6" color="primary">
                    Zoom+ {cliente.planoNome || ""}
                </Typography>
            </Box>

            {/* Grid de informações */}
            <Grid container spacing={3}>
                <InfoCard
                    icon={MonetizationOnIcon}
                    label="Saldo Disponível"
                    value={formatCurrency(cliente.saldo)}
                />

                <InfoCard
                    icon={QueryBuilderIcon}
                    label="Dia do Vencimento"
                    value={cliente.diaVencimento ? `Dia ${cliente.diaVencimento}` : "Não definido"}
                />

                <InfoCard
                    icon={BoltIcon}
                    label="Urgências Disponíveis"
                    value={cliente.taxasUrgenciaDisponiveis || 0}
                    color={cliente.taxasUrgenciaDisponiveis > 0 ? "success" : "primary"}
                />

                <InfoCard
                    icon={WomanIcon}
                    label="Modelos Disponíveis"
                    value={cliente.usoModelosDisponiveis || 0}
                    color={cliente.usoModelosDisponiveis > 0 ? "success" : "primary"}
                />

                {cliente.dataInicioPlano && (
                    <InfoCard
                        icon={CalendarMonthIcon}
                        label="Início do Plano"
                        value={formatDate(cliente.dataInicioPlano)}
                    />
                )}

                {cliente.dataFinalPlano && (
                    <InfoCard
                        icon={EventIcon}
                        label="Fim do Plano"
                        value={formatDate(cliente.dataFinalPlano)}
                    />
                )}

                {cliente.valorMensalidade && (
                    <InfoCard
                        icon={MonetizationOnIcon}
                        label="Valor da Mensalidade"
                        value={formatCurrency(cliente.valorMensalidade)}
                    />
                )}
            </Grid>
        </Box>
    );
};

ClientZoomPlusCard.propTypes = {
    cliente: PropTypes.shape({
        planoNome: PropTypes.string,
        dataInicioPlano: PropTypes.string,
        diaVencimento: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        dataFinalPlano: PropTypes.string,
        saldo: PropTypes.number,
        taxasUrgenciaDisponiveis: PropTypes.number,
        usoModelosDisponiveis: PropTypes.number,
        valorMensalidade: PropTypes.number,
    }).isRequired,
};

export default React.memo(ClientZoomPlusCard);