import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { useParams } from 'react-router-dom';
import { useSocket } from '../../hooks/useSocket';
import List from './List';
import CreateList from './CreateList';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../api';

const BoardContainer = styled(Box)(({ theme }) => ({
    height: 'calc(100vh - 64px)',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'flex-start'
}));

const ListsContainer = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
    minHeight: '100%'
});

const Board = () => {
    const { boardId } = useParams();
    const [board, setBoard] = useState(null);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCreateList, setShowCreateList] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const socket = useSocket();

    const fetchBoardData = useCallback(async () => {
        try {
            const response = await axios.get(`/boards/${boardId}`);
            setBoard(response.data);
            const listsResponse = await axios.get(`/boards/${boardId}/lists`);
            setLists(listsResponse.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching board data:', err);
            setError('Erro ao carregar o quadro');
            setLoading(false);
        }
    }, [boardId]);

    useEffect(() => {
        if (!socket) return;

        socket.emit('join-board', boardId);

        socket.on('list-created', (newList) => {
            setLists(prev => [...prev, newList]);
        });

        socket.on('list-updated', (updatedList) => {
            setLists(prev => prev.map(list =>
                list._id === updatedList._id ? updatedList : list
            ));
        });

        socket.on('list-deleted', ({ listId, boardId: deletedBoardId }) => {
            if (deletedBoardId === boardId) {
                setLists(prev => prev.filter(list => list._id !== listId));
            }
        });

        socket.on('lists-reordered', (updatedLists) => {
            if (!isDragging) {
                setLists(updatedLists);
            }
        });

        socket.on('card-created', (newCard) => {
            setLists(prev => prev.map(list => {
                if (list._id === newCard.listId) {
                    // Verifica se o card já existe na lista
                    const cardExists = list.cards?.some(card => card._id === newCard._id);
                    if (!cardExists) {
                        const updatedCards = [...(list.cards || [])];
                        // Insere o card na posição correta
                        const insertIndex = updatedCards.findIndex(card => card.posicao > newCard.posicao);
                        if (insertIndex === -1) {
                            updatedCards.push(newCard);
                        } else {
                            updatedCards.splice(insertIndex, 0, newCard);
                        }
                        return {
                            ...list,
                            cards: updatedCards
                        };
                    }
                }
                return list;
            }));
        });

        socket.on('card-updated', (updatedCard) => {
            setLists(prev => prev.map(list => {
                if (list._id === updatedCard.listId) {
                    return {
                        ...list,
                        cards: (list.cards || []).map(card =>
                            card._id === updatedCard._id ? updatedCard : card
                        )
                    };
                }
                return list;
            }));
        });

        socket.on('card-deleted', ({ cardId, listId }) => {
            setLists(prev => prev.map(list => {
                if (list._id === listId) {
                    return {
                        ...list,
                        cards: (list.cards || []).filter(card => card._id !== cardId)
                    };
                }
                return list;
            }));
        });

        socket.on('card-moved', ({ cardId, sourceListId, targetListId, sourceCards, targetCards }) => {
            if (!isDragging) {
                setLists(prev => {
                    const newLists = [...prev];
                    const sourceList = newLists.find(l => l._id === sourceListId);
                    const targetList = newLists.find(l => l._id === targetListId);

                    if (!sourceList || !targetList) return prev;

                    // Atualiza os cards da lista de origem
                    sourceList.cards = sourceCards;

                    // Se for uma lista diferente, atualiza os cards da lista de destino
                    if (sourceListId !== targetListId) {
                        targetList.cards = targetCards;
                    }

                    return newLists;
                });
            }
        });

        return () => {
            socket.emit('leave-board', boardId);
            socket.off('list-created');
            socket.off('list-updated');
            socket.off('list-deleted');
            socket.off('lists-reordered');
            socket.off('card-created');
            socket.off('card-updated');
            socket.off('card-deleted');
            socket.off('card-moved');
        };
    }, [socket, boardId, isDragging]);

    useEffect(() => {
        fetchBoardData();
    }, [fetchBoardData]);

    const handleDragStart = () => {
        setIsDragging(true);
    };

    const handleDragEnd = async (result) => {
        const { destination, source, draggableId, type } = result;

        if (!destination) {
            setIsDragging(false);
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            setIsDragging(false);
            return;
        }

        setLists(prev => {
            const newLists = [...prev];

            if (type === 'LIST') {
                const [removed] = newLists.splice(source.index, 1);
                newLists.splice(destination.index, 0, removed);
            } else {
                const sourceList = newLists.find(l => l._id === source.droppableId);
                const destList = newLists.find(l => l._id === destination.droppableId);

                if (!sourceList || !destList) return prev;

                const [movedCard] = sourceList.cards.splice(source.index, 1);
                destList.cards = [...(destList.cards || [])];
                destList.cards.splice(destination.index, 0, movedCard);
            }

            return newLists;
        });

        try {
            if (type === 'LIST') {
                await axios.put(`/boards/${boardId}/lists/${draggableId}/position`, {
                    posicao: destination.index
                });
            } else {
                const sourceList = lists.find(l => l._id === source.droppableId);
                const destinationList = lists.find(l => l._id === destination.droppableId);

                if (!sourceList || !destinationList) {
                    console.error('Source or destination list not found');
                    return;
                }

                if (source.droppableId === destination.droppableId) {
                    await axios.put(`/lists/${sourceList._id}/cards/${draggableId}/position`, {
                        posicao: destination.index
                    });
                } else {
                    await axios.put(`/lists/${sourceList._id}/cards/${draggableId}/position`, {
                        listId: destinationList._id,
                        posicao: destination.index
                    });
                }
            }
        } catch (err) {
            console.error('Error updating position:', err);
            fetchBoardData();
        } finally {
            setIsDragging(false);
        }
    };

    const handleCardAdd = async (listId, cardData) => {
        try {
            const response = await axios.post(`/lists/${listId}/cards`, cardData);
            const newCard = response.data;

            // Atualiza o estado imediatamente após criar o card
            setLists(prev => prev.map(list => {
                if (list._id === listId) {
                    const updatedCards = [...(list.cards || [])];
                    // Insere o card na posição correta
                    const insertIndex = updatedCards.findIndex(card => card.posicao > newCard.posicao);
                    if (insertIndex === -1) {
                        updatedCards.push(newCard);
                    } else {
                        updatedCards.splice(insertIndex, 0, newCard);
                    }
                    return {
                        ...list,
                        cards: updatedCards
                    };
                }
                return list;
            }));

            return newCard;
        } catch (error) {
            console.error('Error adding card:', error);
            throw error;
        }
    };

    const handleCardDelete = async (listId, cardId) => {
        try {
            await axios.delete(`/lists/${listId}/cards/${cardId}`);
            setLists(prev => prev.map(list => {
                if (list._id === listId) {
                    return {
                        ...list,
                        cards: (list.cards || []).filter(card => card._id !== cardId)
                    };
                }
                return list;
            }));
        } catch (error) {
            console.error('Error deleting card:', error);
            throw error;
        }
    };

    const handleListDelete = (listId) => {
        setLists(prev => prev.filter(list => list._id !== listId));
    };

    const handleCreateListSuccess = (newList) => {
        setLists(prev => [...prev, newList]);
        setShowCreateList(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <BoardContainer>
            <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <Droppable droppableId="lists" direction="horizontal" type="LIST">
                    {(provided) => (
                        <ListsContainer
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {lists.map((list, index) => (
                                <List
                                    key={list._id}
                                    list={list}
                                    index={index}
                                    boardId={boardId}
                                    onCardAdd={handleCardAdd}
                                    onCardDelete={handleCardDelete}
                                    onListDelete={handleListDelete}
                                />
                            ))}
                            {provided.placeholder}
                            {showCreateList ? (
                                <CreateList
                                    boardId={boardId}
                                    onCancel={() => setShowCreateList(false)}
                                    onSuccess={handleCreateListSuccess}
                                />
                            ) : (
                                <IconButton
                                    onClick={() => setShowCreateList(true)}
                                    sx={{
                                        minWidth: 280,
                                        height: 40,
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: 1,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.2)'
                                        }
                                    }}
                                >
                                    <AddIcon />
                                    <Typography sx={{ ml: 1 }}>Adicionar lista</Typography>
                                </IconButton>
                            )}
                        </ListsContainer>
                    )}
                </Droppable>
            </DragDropContext>
        </BoardContainer>
    );
};

export default Board;
