import api from '../api';

class PaymentService {
    cleanFilters(filters) {
        return Object.entries({
            ...filters,
            page: Math.max(1, Number(filters.page) || 1),
            limit: Math.max(1, Number(filters.limit) || 10)
        }).reduce((acc, [key, value]) => {
            if (value !== '' && value !== null && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});
    }

    async createCustomer(customerData) {
        try {
            const response = await api.post('/pagamentos/customers', customerData);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error creating customer:', error);
            throw new Error(`Erro ao criar cliente no Asaas: ${error.response?.data?.message || error.message}`);
        }
    }

    async getCustomer(customerId) {
        try {
            const response = await api.get(`/pagamentos/customers/${customerId}`);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error getting customer:', error);
            throw new Error(`Erro ao obter detalhes do cliente: ${error.response?.data?.message || error.message}`);
        }
    }

    async createPayment(paymentData) {
        try {
            const response = await api.post('/pagamentos/payments', paymentData);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error creating payment:', error);
            throw new Error(`Erro ao criar cobrança: ${error.response?.data?.message || error.message}`);
        }
    }

    async createPixPayment(paymentData) {
        try {
            const response = await api.post('/pagamentos/payments/pix', paymentData);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error creating PIX payment:', error);
            throw new Error(`Erro ao criar cobrança PIX: ${error.response?.data?.message || error.message}`);
        }
    }

    async getPayment(paymentId) {
        try {
            const response = await api.get(`/pagamentos/payments/${paymentId}`);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error getting payment:', error);
            throw new Error(`Erro ao consultar cobrança: ${error.response?.data?.message || error.message}`);
        }
    }

    async updatePaymentStatuses(pedidoIds) {
        try {
            const response = await api.post('/pedidos/update-payment-statuses', { pedidoIds });
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error updating payment statuses:', error);
            throw new Error(`Erro ao atualizar status dos pagamentos: ${error.response?.data?.message || error.message}`);
        }
    }

    async listPayments(filters = {}) {
        try {
            const cleanFilters = this.cleanFilters(filters);
            const response = await api.get('/pagamentos/payments', {
                params: cleanFilters
            });

            return this.validateResponse(response?.data || {});
        } catch (error) {
            console.error('[PaymentService] Error in listPayments:', error);
            return {
                items: [],
                total: 0,
                limit: Number(filters.limit) || 10,
                page: Number(filters.page) || 1
            };
        }
    }

    async listSubscriptionPayments(subscriptionId) {
        try {
            const response = await api.get(`/pagamentos/subscriptions/${subscriptionId}/payments`);
            return this.validateResponse(response?.data || {});
        } catch (error) {
            console.error('[PaymentService] Error listing subscription payments:', error);
            throw new Error(`Erro ao listar pagamentos da assinatura: ${error.response?.data?.message || error.message}`);
        }
    }

    async getUserSubscriptionPayments(page = 1, limit = 10) {
        try {
            const response = await api.get('/pagamentos/user/subscription-payments', {
                params: { page, limit }
            });
            return response.data || { items: [], total: 0 };
        } catch (error) {
            console.error('[PaymentService] Error getting user subscription payments:', error);
            return {
                items: [],
                total: 0,
                limit,
                page
            };
        }
    }

    async getUserOrderPayments(page = 1, limit = 10) {
        try {
            const response = await api.get('/pagamentos/user/order-payments', {
                params: { page, limit }
            });
            return response.data || { items: [], total: 0 };
        } catch (error) {
            console.error('[PaymentService] Error getting user order payments:', error);
            return {
                items: [],
                total: 0,
                limit,
                page
            };
        }
    }

    async getUserActiveSubscription() {
        try {
            const response = await api.get('/pagamentos/user/active-subscription');
            return response?.data || null;
        } catch (error) {
            console.error('[PaymentService] Error getting user active subscription:', error);
            return null;
        }
    }

    async getUserPayments() {
        try {
            const response = await api.get('/pagamentos/user/payments');
            return response?.data || { items: [], total: 0 };
        } catch (error) {
            console.error('[PaymentService] Error getting user payments:', error);
            return {
                items: [],
                total: 0,
                limit: 10,
                page: 1
            };
        }
    }

    async getSubscription(subscriptionId) {
        try {
            const response = await api.get(`/pagamentos/subscriptions/${subscriptionId}`);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error getting subscription:', error);
            throw new Error(`Erro ao obter detalhes da assinatura: ${error.response?.data?.message || error.message}`);
        }
    }

    async listSubscriptions(filters = {}) {
        try {
            const cleanFilters = this.cleanFilters(filters);

            const response = await api.get('/pagamentos/subscriptions', {
                params: cleanFilters
            });

            return this.validateResponse(response?.data || {});
        } catch (error) {
            console.error('[PaymentService] Error listing subscriptions:', error);
            const processedFilters = this.cleanFilters(filters);
            return {
                items: [],
                total: 0,
                limit: processedFilters.limit,
                page: processedFilters.page
            };
        }
    }

    async cancelPayment(paymentId) {
        try {
            const response = await api.delete(`/pagamentos/payments/${paymentId}`);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error canceling payment:', error);
            throw new Error(`Erro ao cancelar cobrança: ${error.response?.data?.message || error.message}`);
        }
    }

    async restorePayment(paymentId) {
        try {
            const response = await api.post(`/pagamentos/payments/${paymentId}/restore`);
            return response.data;
        } catch (error) {
            console.error('[PaymentService] Error restoring payment:', error);
            throw new Error(`Erro ao restaurar cobrança: ${error.response?.data?.message || error.message}`);
        }
    }

    validateResponse(responseData) {
        if (!responseData || typeof responseData !== 'object') {
            console.warn('[PaymentService] Invalid response data:', responseData);
            return {
                items: [],
                total: 0,
                limit: 10,
                page: 1
            };
        }

        // Se a resposta já estiver no formato correto, retorna ela
        if (responseData.items) {
            return {
                items: responseData.items,
                total: responseData.total || 0,
                limit: Number(responseData.limit) || 10,
                page: Number(responseData.page) || 1
            };
        }

        // Se a resposta estiver no formato antigo com data
        if (responseData.data) {
            return {
                items: Array.isArray(responseData.data) ? responseData.data.filter(Boolean) : [],
                total: Number(responseData.totalCount) || 0,
                limit: Number(responseData.limit) || 10,
                page: Number(responseData.page) || 1
            };
        }

        // Se a resposta for um array direto
        if (Array.isArray(responseData)) {
            return {
                items: responseData.filter(Boolean),
                total: responseData.length,
                limit: 10,
                page: 1
            };
        }

        return {
            items: [],
            total: 0,
            limit: 10,
            page: 1
        };
    }

    formatPaymentStatus(status) {
        const statusMap = {
            PENDING: 'Aguardando pagamento',
            RECEIVED: 'Pagamento Recebido',
            CONFIRMED: 'Pagamento Confirmado',
            OVERDUE: 'Pagamento Vencido',
            REFUNDED: 'Pagamento Estornado',
            RECEIVED_IN_CASH: 'Pago em Dinheiro',
            REFUND_REQUESTED: 'Estorno Solicitado',
            CHARGEBACK_REQUESTED: 'Chargeback Solicitado',
            CHARGEBACK_DISPUTE: 'Em Disputa de Chargeback',
            AWAITING_CHARGEBACK_REVERSAL: 'Aguardando Reversão de Chargeback',
            DUNNING_REQUESTED: 'Em Processo de Dunning',
            DUNNING_RECEIVED: 'Recebido por Dunning',
            AWAITING_RISK_ANALYSIS: 'Em Análise de Risco',
            CANCELLED: 'Cancelado'
        };
        return statusMap[status] || status;
    }

    formatBillingType(type) {
        const typeMap = {
            BOLETO: 'Boleto',
            CREDIT_CARD: 'Cartão de Crédito',
            PIX: 'PIX',
            UNDEFINED: 'Não definido'
        };
        return typeMap[type] || type;
    }
}

export default new PaymentService();
