import React from 'react';
import {
    Box,
    Link,
    Typography,
    useTheme,
    alpha,
} from '@mui/material';


// InfoItem component
// Usage: <InfoItem icon={Icon} label="Label" value="Value" isLink={true} highlight={true} />
// Usage: <InfoItem icon={Icon} label="Label" value="Value" isLink={false} highlight={false} />

const InfoItem = ({ icon: Icon, label, value, isLink, highlight }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 2.5,
                borderRadius: '16px',
                background: alpha(theme.palette.background.paper, 0.4),
                backdropFilter: 'blur(8px)',
                transition: 'transform 0.2s, box-shadow 0.2s',
                border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
                }
            }}
        >
            <Box
                sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 1)}, ${alpha(theme.palette.primary.light, 0.8)})`,
                    backdropFilter: 'blur(5px)',
                }}
            >
                <Icon sx={{ color: theme.palette.common.white, fontSize: '1.5rem' }} />
            </Box>
            <Box sx={{ flex: 1 }}>
                <Typography
                    variant="caption"
                    sx={{
                        color: alpha(theme.palette.text.secondary, 0.8),
                        fontFamily: theme.typography.fontFamily,
                        display: 'block',
                        mb: 0.5,
                        fontSize: '0.75rem',
                        letterSpacing: '0.5px',
                        textTransform: 'uppercase'
                    }}
                >
                    {label}
                </Typography>
                {isLink && value ? (
                    <Link
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            color: theme.palette.primary.main,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            textDecoration: 'none',
                            fontSize: '0.95rem',
                            '&:hover': {
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        Acessar Drive
                    </Link>
                ) : (
                    <Typography
                        sx={{
                            color: highlight ? theme.palette.primary.main : theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: highlight ? 700 : 500,
                            fontSize: highlight ? '1.1rem' : '0.95rem'
                        }}
                    >
                        {value !== undefined && value !== null ? value : 'Não informado'}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default InfoItem;