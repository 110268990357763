import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Link,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  HighlightOff as HighlightOffIcon,
  WhatsApp as WhatsAppIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import { useClientList } from "../hooks/useClientList";
import SearchFilterBar from "./SearchFilterBar";
import ZoomPlusStyling from "./ZoomPlusStyling";
import ZoomPlusInfo from "./Tooltip";
import ConfirmationModal from "./shared/ConfirmationModal";
import PaginationControls from "./shared/PaginationControls";
import AlertaReutilizavel from "./shared/AlertaReutilizavel";

const getIcon = (condition, TrueIcon, FalseIcon, props = {}) => {
  return condition ? <TrueIcon {...props} /> : <FalseIcon {...props} />;
};

const ListaClientes = React.memo(({ onDelete }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [selectedCliente, setSelectedCliente] = React.useState(null);
  const [alerta, setAlerta] = React.useState({
    mensagem: "",
    tipo: "info",
    aberto: false,
  });

  const {
    loading,
    clientes,
    pagination,
    filters,
    handleFilterChange,
    handleClearFilters,
    handlePageChange,
    handleLimitChange,
    refreshData
  } = useClientList();

  useEffect(() => {
    refreshData();
  }, []); // Load data when component mounts

  const handleDelete = useCallback(async () => {
    setShowModal(false);
    try {
      if (selectedCliente) {
        await onDelete(selectedCliente._id);
        setAlerta({
          mensagem: "Cliente excluído com sucesso!",
          tipo: "success",
          aberto: true,
        });
        refreshData(); // Refresh data after deletion
      }
    } catch (error) {
      setAlerta({
        mensagem: "Erro ao excluir cliente: " + (error.response?.data?.error || error.message),
        tipo: "error",
        aberto: true,
      });
    } finally {
      setSelectedCliente(null);
    }
  }, [selectedCliente, onDelete, refreshData]);

  const openModal = useCallback((cliente) => {
    setSelectedCliente(cliente);
    setShowModal(true);
  }, []);

  const fecharAlerta = useCallback(() => {
    setAlerta(prev => ({ ...prev, aberto: false }));
  }, []);

  const handleSearchChange = useCallback((value) => {
    handleFilterChange("searchTerm", value);
  }, [handleFilterChange]);

  const handleZoomPlusChange = useCallback((value) => {
    handleFilterChange("showZoomPlus", value);
  }, [handleFilterChange]);

  const handleOrderByChange = useCallback((value) => {
    if (value === filters.orderBy) {
      handleFilterChange("orderDirection",
        filters.orderDirection === "asc" ? "desc" : "asc"
      );
    } else {
      handleFilterChange("orderBy", value);
      handleFilterChange("orderDirection", "asc");
    }
  }, [filters.orderBy, filters.orderDirection, handleFilterChange]);

  const renderTableRow = useCallback((cliente) =>
  (
    <TableRow key={cliente._id} hover>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {getIcon(cliente.senha, CheckCircleIcon, HighlightOffIcon, {
            sx: { color: cliente.senha ? "green.main" : "inherit" },
            titleAccess: cliente.senha
              ? "Cliente Cadastrado"
              : "Cliente Não Cadastrado",
          })}
          <ZoomPlusStyling
            name={cliente.nome}
            planId={cliente.planoId?._id}
            isZoomPlus={Boolean(cliente.isZoomPlus)}
          />
        </Box>
      </TableCell>
      <TableCell>{cliente.observacoes}</TableCell>
      <TableCell>{cliente.email}</TableCell>
      <TableCell>{cliente.documento || "Não informado"}</TableCell>
      <TableCell align="center">
        {cliente.telefone ? (
          <Link
            href={`https://wa.me/+55${cliente.telefone}`}
            target="_blank"
            rel="noopener noreferrer"
            color="success.main"
          >
            <WhatsAppIcon />
          </Link>
        ) : (
          <HighlightOffIcon color="error" />
        )}
      </TableCell>

      <TableCell align="center">
        <ZoomPlusInfo cliente={cliente} />
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={() => openModal(cliente)}
          disabled={loading}
        >
          <DeleteIcon color="error" />
        </IconButton>
        <IconButton
          aria-label="Editar"
          color="primary"
          onClick={() => navigate(`/dados-cliente/${cliente._id}`)}
          disabled={loading}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ), [loading, navigate, openModal]);

  return (
    <
      >
      <Box>
        <AlertaReutilizavel {...alerta} onFechar={fecharAlerta} />

        <SearchFilterBar
          initialSearchTerm={filters.searchTerm}
          onSearchChange={handleSearchChange}
          showZoomPlus={filters.showZoomPlus}
          onZoomPlusChange={handleZoomPlusChange}
          orderBy={filters.orderBy}
          onOrderByChange={handleOrderByChange}
          onClearFilters={handleClearFilters}
          loading={loading}
        />

        <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                {["Nome", "Apelido", "Email", "Documento", "Telefone", "Zoom+", "Ações"].map(
                  (header) => (
                    <TableCell
                      key={header}
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : clientes.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                    Nenhum cliente encontrado
                  </TableCell>
                </TableRow>
              ) : (
                clientes.map(renderTableRow)
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <PaginationControls
          page={pagination.page}
          limit={pagination.limit}
          total={pagination.total}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
          loading={loading}
        />

        <ConfirmationModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleConfirm={handleDelete}
          title="Confirmar Exclusão"
          body={`Tem certeza que deseja excluir o cliente ${selectedCliente?.nome}?`}
        />
      </Box>
    </>
  );
});

ListaClientes.displayName = 'ListaClientes';

export default ListaClientes;
