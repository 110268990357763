import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const packages = [
    { photos: "5 fotos", price: "250,00" },
    { photos: "10 fotos", price: "350,00" },
    { photos: "15 fotos", price: "450,00" },
    { photos: "20 fotos", price: "500,00" }
];

const AmbientadasSection = () => (
    <>
        {packages.map((package_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper sx={{
                    p: 4,
                    background: 'rgba(255,255,255,0.03)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255,255,255,0.1)',
                    borderRadius: '24px',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                    }
                }}>
                    <Typography variant="h5" sx={{
                        color: 'white',
                        mb: 2,
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        {package_.photos}
                    </Typography>
                    <Typography variant="h4" sx={{
                        color: '#eb5b25',
                        fontFamily: '"Poppins", sans-serif'
                    }}>
                        R$ {package_.price}
                    </Typography>
                </Paper>
            </Grid>
        ))}
        <Grid item xs={12}>
            <Paper sx={{
                p: 4,
                background: 'rgba(255,255,255,0.03)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '24px',
            }}>
                <Typography variant="h6" sx={{
                    color: '#eb5b25',
                    mb: 1,
                    fontFamily: '"Poppins", sans-serif'
                }}>
                    Foto Extra
                </Typography>
                <Typography variant="h4" sx={{
                    color: 'white',
                    fontFamily: '"Poppins", sans-serif'
                }}>
                    R$ 35,00
                </Typography>
            </Paper>
        </Grid>
    </>
);

export default AmbientadasSection;
