import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    Container,
    Link,
    useTheme,
} from "@mui/material";
import AlertaReutilizavel from "../components/shared/AlertaReutilizavel";
import ThemeToggle from "../components/ThemeToggle";
import api from "../api";
import logoHorizontalLight from "../assets/logos/Logo Branco com Laranja.svg";
import logoHorizontalDark from "../assets/logos/Logo Preto com Laranja.svg";

const PrimeiroAcesso = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [form, setForm] = useState({
        email: "",
        senha: "",
        confirmarSenha: "",
    });
    const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const validarForm = () => {
        if (!form.email || !form.senha || !form.confirmarSenha) {
            setAlerta({
                mensagem: "Todos os campos são obrigatórios",
                tipo: "error",
                aberto: true,
            });
            return false;
        }

        if (form.senha.length < 6) {
            setAlerta({
                mensagem: "A senha deve ter no mínimo 6 caracteres",
                tipo: "error",
                aberto: true,
            });
            return false;
        }

        if (form.senha !== form.confirmarSenha) {
            setAlerta({
                mensagem: "As senhas não coincidem",
                tipo: "error",
                aberto: true,
            });
            return false;
        }

        return true;
    };

    const handleSalvar = async () => {
        if (!validarForm()) return;

        setLoading(true);
        try {
            await api.post("/auth/primeiro-acesso", {
                email: form.email,
                senha: form.senha,
            });

            setAlerta({
                mensagem: "Senha criada com sucesso! Redirecionando para o login...",
                tipo: "success",
                aberto: true,
            });

            // Limpar o formulário
            setForm({
                email: "",
                senha: "",
                confirmarSenha: "",
            });

            // Aguardar um pouco para mostrar a mensagem de sucesso antes de redirecionar
            setTimeout(() => {
                navigate("/login", { replace: true });
            }, 1500);

        } catch (error) {
            const mensagem = error.response?.data?.mensagem || "Erro ao criar senha";
            setAlerta({
                mensagem,
                tipo: "error",
                aberto: true,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: theme.palette.background.default,
                color: theme.palette.text.primary,
                overflow: 'hidden',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '200%',
                    height: '200%',
                    background: `radial-gradient(circle, ${theme.palette.primary.main} 0%, transparent 50%)`,
                    opacity: 0.15,
                    top: '-25%',
                    left: '-25%'
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '200%',
                    height: '200%',
                    background: `radial-gradient(circle, ${theme.palette.secondary.main} 0%, transparent 40%)`,
                    opacity: 0.2,
                    top: '-25%',
                    left: '-25%'
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 'calc(100vh - 80px)',
                    padding: '20px',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Container maxWidth="xs">
                    <Paper
                        sx={{
                            padding: '30px',
                            maxWidth: '400px',
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            background: 'rgba(255,255,255,0.03)',
                            backdropFilter: 'blur(10px)',
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: '20px',
                            position: 'relative',
                        }}
                        elevation={0}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 16,
                                right: 16,
                            }}
                        >
                            <ThemeToggle />
                        </Box>

                        <Box
                            component="img"
                            src={theme.palette.mode === 'dark' ? logoHorizontalLight : logoHorizontalDark}
                            alt="Zoompro Studio"
                            sx={{
                                width: '150px',
                                height: '150px',
                                display: { xs: 'none', md: 'block' },
                                mb: 2,
                            }}
                        />
                        <Typography
                            variant="h5"
                            sx={{
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: 600,
                                mb: 2,
                                background: theme.palette.primary.main,
                                backgroundClip: 'text',
                                textFillColor: 'transparent',
                            }}
                        >
                            Primeiro Acesso
                        </Typography>

                        <Typography
                            variant="body2"
                            color={theme.palette.text.secondary}
                            align="center"
                            sx={{ mb: 3 }}
                        >
                            Configure sua senha para acessar o sistema
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    autoFocus
                                    type="email"
                                    variant="outlined"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            color: theme.palette.text.primary,
                                        },
                                        '& .MuiInputBase-root': {
                                            '& fieldset': {
                                                borderColor: theme.palette.divider,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: theme.palette.primary.main,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: theme.palette.primary.main,
                                            },
                                        },
                                        '& label': {
                                            color: theme.palette.text.secondary,
                                        },
                                        '& label.Mui-focused': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Senha"
                                    name="senha"
                                    type="password"
                                    value={form.senha}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    helperText="Mínimo de 6 caracteres"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            color: theme.palette.text.primary,
                                        },
                                        '& .MuiInputBase-root': {
                                            '& fieldset': {
                                                borderColor: theme.palette.divider,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: theme.palette.primary.main,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: theme.palette.primary.main,
                                            },
                                        },
                                        '& label': {
                                            color: theme.palette.text.secondary,
                                        },
                                        '& label.Mui-focused': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Confirmar Senha"
                                    name="confirmarSenha"
                                    type="password"
                                    value={form.confirmarSenha}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            color: theme.palette.text.primary,
                                        },
                                        '& .MuiInputBase-root': {
                                            '& fieldset': {
                                                borderColor: theme.palette.divider,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: theme.palette.primary.main,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: theme.palette.primary.main,
                                            },
                                        },
                                        '& label': {
                                            color: theme.palette.text.secondary,
                                        },
                                        '& label.Mui-focused': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSalvar}
                                    fullWidth
                                    disabled={loading}
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        height: '48px',
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.background.paper,
                                        py: 2,
                                        px: 4,
                                        borderRadius: '50px',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'linear-gradient(45deg, transparent 0%, rgba(255,255,255,0.2) 50%, transparent 100%)',
                                            transform: 'translateX(-100%)',
                                            transition: 'transform 0.6s',
                                        },
                                        '&:hover': {
                                            bgcolor: theme.palette.primary.main,
                                            transform: 'scale(1.05)',
                                            transition: 'transform 0.3s',
                                            '&::before': {
                                                transform: 'translateX(100%)',
                                            },
                                        },
                                    }}
                                >
                                    {loading ? "Processando..." : "Criar Senha"}
                                </Button>
                            </Grid>
                        </Grid>

                        <Link
                            href="/login"
                            variant="body2"
                            sx={{
                                mt: 2,
                                textDecoration: 'none',
                                color: theme.palette.text.secondary,
                                '&:hover': {
                                    textDecoration: 'underline',
                                    color: theme.palette.primary.main,
                                }
                            }}
                        >
                            Já possui senha? Faça login
                        </Link>
                    </Paper>
                </Container>
            </Box>

            <AlertaReutilizavel
                mensagem={alerta.mensagem}
                tipo={alerta.tipo}
                aberto={alerta.aberto}
                onFechar={() => setAlerta({ ...alerta, aberto: false })}
                autoHideDuration={6000}
            />
        </Box>
    );
};

export default PrimeiroAcesso;