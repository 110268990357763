import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
    useTheme,
    CircularProgress,
    Divider
} from '@mui/material';
import {
    TrendingUp,
    ShoppingBag,
    Star,
    Speed,
    Timeline,
    Assessment
} from '@mui/icons-material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    BarChart,
    Bar,
    Legend
} from 'recharts';
import api from '../../api';
import SectionTitle from '../../components/SectionTitle';

// Cores da marca
const COLORS = [
    '#F3470C', // Primary brand orange
    '#FF7A4C', // Lighter orange variant
    '#222224', // Brand dark gray
    '#4D4D4F', // Medium gray variant
    '#EAEAEA'  // Brand off-white
];

const StatCard = ({ title, value, icon, subtitle, color, progress }) => {
    const theme = useTheme();

    return (
        <Card sx={{
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: theme.palette.mode === 'dark' ? '0 4px 12px rgba(0,0,0,0.3)' : '0 4px 12px rgba(0,0,0,0.1)'
        }}>
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="h4" component="div">
                            {typeof value === 'number' && !isNaN(value) ?
                                value % 1 === 0 ?
                                    value.toLocaleString('pt-BR') :
                                    value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                : value}
                        </Typography>
                        {subtitle && (
                            <Typography variant="body2" color="textSecondary">
                                {subtitle}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: `${color}15`,
                            borderRadius: '50%',
                            padding: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {React.cloneElement(icon, { sx: { fontSize: 40, color: color } })}
                    </Box>
                </Box>
                {progress !== undefined && (
                    <Box sx={{ width: '100%', mt: 1 }}>
                        <Box
                            sx={{
                                height: 8,
                                borderRadius: 5,
                                bgcolor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : `${color}15`,
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                        >
                            <Box
                                sx={{
                                    width: `${Math.max(0, Math.min(100, progress))}%`,
                                    height: '100%',
                                    bgcolor: color,
                                    position: 'absolute',
                                    transition: 'width 1s ease-in-out'
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

const ChartCard = ({ title, children }) => {
    const theme = useTheme();

    return (
        <Card sx={{
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: theme.palette.mode === 'dark' ? '0 4px 12px rgba(0,0,0,0.3)' : '0 4px 12px rgba(0,0,0,0.1)'
        }}>
            <CardContent>
                <Typography variant="h6" gutterBottom color="textPrimary">
                    {title}
                </Typography>
                {children}
            </CardContent>
        </Card>
    );
};

const UserDashboard = () => {
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await api.get('/user/stats');
                setStats(response.data);
            } catch (error) {
                console.error('Erro ao carregar estatísticas:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress sx={{ color: COLORS[0] }} />
            </Box>
        );
    }

    if (!stats) return null;

    const formatCurrency = (value) => {
        return value?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }) || 'R$ 0,00';
    };

    // Preparar dados para o gráfico de gastos mensais
    const gastosData = stats.gastosMensais.map(item => ({
        name: `${item._id.mes}/${item._id.ano}`,
        valor: item.total,
        quantidade: item.quantidade
    }));

    // Preparar dados para o gráfico de status
    const statusData = stats.pedidosPorStatus.map(item => ({
        name: item._id,
        value: item.quantidade,
    }));

    // Preparar dados para o gráfico de serviços
    const servicosData = stats.servicosMaisUsados.map(item => ({
        name: item.servicoInfo.nome,
        quantidade: item.quantidade,
        valor: item.valorTotal
    }));

    const getGridColor = () => {
        return theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : COLORS[4];
    };

    const getTooltipStyle = () => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : theme.palette.background.paper,
        border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : COLORS[4]}`,
        borderRadius: '8px',
        boxShadow: theme.palette.mode === 'dark'
            ? '0 4px 12px rgba(0,0,0,0.5)'
            : '0 4px 12px rgba(0,0,0,0.1)'
    });

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <SectionTitle
                variant="h4"
                mb={6}
                width={250}
            >
                Dashboard
            </SectionTitle>

            {/* KPIs */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Total de Pedidos"
                        value={stats.totalPedidos}
                        icon={<ShoppingBag />}
                        subtitle="Todos os pedidos"
                        color={COLORS[0]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Ticket Médio"
                        value={formatCurrency(stats.kpis.ticketMedio)}
                        icon={<Assessment />}
                        subtitle="Valor médio por pedido"
                        color={COLORS[1]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Taxa de Sucesso"
                        value={`${stats.kpis.taxaSucessoGeral.toFixed(1)}%`}
                        icon={<Speed />}
                        subtitle="Pedidos concluídos"
                        color={COLORS[0]}
                        progress={stats.kpis.taxaSucessoGeral}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                        title="Crescimento"
                        value={`${stats.kpis.crescimentoMensal.toFixed(1)}%`}
                        icon={<TrendingUp />}
                        subtitle="Em relação ao mês anterior"
                        color={COLORS[1]}
                        progress={stats.kpis.crescimentoMensal > 0 ? stats.kpis.crescimentoMensal : 0}
                    />
                </Grid>
            </Grid>

            {/* Gráficos */}
            <Grid container spacing={3}>
                {/* Gráfico de Gastos Mensais */}
                <Grid item xs={12} lg={8}>
                    <ChartCard title="Evolução de Gastos">
                        <Box sx={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                                <LineChart data={gastosData}>
                                    <CartesianGrid
                                        strokeDasharray="3 3"
                                        stroke={getGridColor()}
                                        strokeOpacity={0.5}
                                    />
                                    <XAxis
                                        dataKey="name"
                                        stroke={theme.palette.text.secondary}
                                        tick={{ fill: theme.palette.text.secondary }}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        stroke={COLORS[0]}
                                        tick={{ fill: theme.palette.text.secondary }}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        stroke={COLORS[1]}
                                        tick={{ fill: theme.palette.text.secondary }}
                                    />
                                    <Tooltip
                                        formatter={(value) => formatCurrency(value)}
                                        contentStyle={getTooltipStyle()}
                                        labelStyle={{ color: theme.palette.text.primary }}
                                    />
                                    <Legend
                                        wrapperStyle={{ color: theme.palette.text.primary }}
                                    />
                                    <Line
                                        yAxisId="left"
                                        type="monotone"
                                        dataKey="valor"
                                        name="Valor Total"
                                        stroke={COLORS[0]}
                                        strokeWidth={2}
                                        dot={{ fill: COLORS[0], strokeWidth: 2 }}
                                        activeDot={{ r: 8, fill: COLORS[0] }}
                                    />
                                    <Line
                                        yAxisId="right"
                                        type="monotone"
                                        dataKey="quantidade"
                                        name="Quantidade"
                                        stroke={COLORS[1]}
                                        strokeWidth={2}
                                        dot={{ fill: COLORS[1], strokeWidth: 2 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </ChartCard>
                </Grid>

                {/* Gráfico de Status dos Pedidos */}
                <Grid item xs={12} sm={6} lg={4}>
                    <ChartCard title="Status dos Pedidos">
                        <Box sx={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={statusData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                                        outerRadius={80}
                                        fill={COLORS[0]}
                                        dataKey="value"
                                        strokeWidth={2}
                                        blendStroke={true}
                                    >
                                        {statusData.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS[index % COLORS.length]}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip
                                        formatter={(value) => value.toLocaleString('pt-BR')}
                                        contentStyle={getTooltipStyle()}
                                        labelStyle={{ color: theme.palette.text.primary }}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Box>
                    </ChartCard>
                </Grid>

                {/* Gráfico de Serviços Mais Utilizados */}
                <Grid item xs={12}>
                    <ChartCard title="Serviços Mais Utilizados">
                        <Box sx={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                                <BarChart data={servicosData}>
                                    <CartesianGrid
                                        strokeDasharray="3 3"
                                        stroke={getGridColor()}
                                        strokeOpacity={0.5}
                                    />
                                    <XAxis
                                        dataKey="name"
                                        stroke={theme.palette.text.secondary}
                                        tick={{ fill: theme.palette.text.secondary }}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        stroke={COLORS[0]}
                                        tick={{ fill: theme.palette.text.secondary }}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        stroke={COLORS[1]}
                                        tick={{ fill: theme.palette.text.secondary }}
                                    />
                                    <Tooltip
                                        formatter={(value, name) => [
                                            name === 'valor' ? formatCurrency(value) : value.toLocaleString('pt-BR'),
                                            name === 'valor' ? 'Valor Total' : 'Quantidade'
                                        ]}
                                        contentStyle={getTooltipStyle()}
                                        labelStyle={{ color: theme.palette.text.primary }}
                                    />
                                    <Legend
                                        wrapperStyle={{ color: theme.palette.text.primary }}
                                    />
                                    <Bar
                                        yAxisId="left"
                                        dataKey="quantidade"
                                        name="Quantidade"
                                        fill={COLORS[0]}
                                        radius={[4, 4, 0, 0]}
                                    />
                                    <Bar
                                        yAxisId="right"
                                        dataKey="valor"
                                        name="Valor Total"
                                        fill={COLORS[1]}
                                        radius={[4, 4, 0, 0]}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                    </ChartCard>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UserDashboard;
