import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Drawer,
  Fab,
  useTheme as useMuiTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaymentsIcon from '@mui/icons-material/Payments';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import logoDark from '../assets/logos/Logo Preto com Laranja.svg';
import logoLight from '../assets/logos/Logo Branco com Laranja.svg';
import { isUserAdmin } from "../utils/auth";
import ThemeToggle from './ThemeToggle';
import { useTheme } from '../contexts/ThemeContext';

const drawerWidth = 240;

const Menu = ({ mobileOpen = false, onMobileToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const admin = isUserAdmin();
  const { mode } = useTheme();
  const theme = useMuiTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!admin) return null;

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleMouseDown = (e, path) => {
    // Botão do meio do mouse (button = 1)
    if (e.button === 1) {
      e.preventDefault();
      window.open(path, '_blank');
    }
  };

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
    { text: "Clientes", icon: <PersonIcon />, path: "/cadastro-clientes" },
    { text: "Pedidos", icon: <ShoppingCartIcon />, path: "/cadastro-pedidos" },
    { text: "Pagamentos", icon: <PaymentsIcon />, path: "/payments" },
    { text: "Serviços", icon: <AssignmentIcon />, path: "/cadastro-servicos" },
    { text: "Quadros", icon: <ViewKanbanIcon />, path: "/boards" },
    { text: "Zoom+", icon: <AddCircleOutlineIcon />, path: "/zoom-plus" },
    { text: "Cadastros Pendentes", icon: <PendingActionsIcon />, path: "/cadastros-pendentes" },
  ];

  return (
    <>
      {isMobile && !mobileOpen && (
        <Fab
          color="primary"
          aria-label="abrir menu"
          onClick={onMobileToggle}
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <MenuIcon />
        </Fab>
      )}

      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={onMobileToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'background.paper',
            borderRight: 1,
            borderColor: 'divider',
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }}
        >
          <Box
            sx={{
              padding: 2,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              borderBottom: 1,
              borderColor: "divider"
            }}
          >
            <img
              src={mode === 'dark' ? logoLight : logoDark}
              alt="Logo"
              style={{ width: 150, objectFit: 'contain', paddingTop: 10 }}
            />
          </Box>
          <List>
            {menuItems.map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                selected={location.pathname === item.path}
              >
                <ListItemButton
                  onClick={() => {
                    navigate(item.path);
                    if (isMobile) onMobileToggle();
                  }}
                  onMouseDown={(e) => handleMouseDown(e, item.path)}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'primary.light',
                    },
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: location.pathname === item.path ? "primary.main" : "inherit" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      color: location.pathname === item.path ? "primary" : "inherit",
                      fontWeight: location.pathname === item.path ? "bold" : "normal",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{ p: 2 }}
          >
            <Button
              variant="text"
              startIcon={<LogoutIcon />}
              sx={{
                color: 'text.secondary',
                transition: 'all 0.3s ease',
                borderRadius: '8px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: 'action.hover',
                  transform: 'translateY(-1px)',
                  color: 'text.primary'
                }
              }}
              onClick={handleLogout}
            >
              Sair
            </Button>
            <ThemeToggle />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default Menu;
