import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Stack,
  Grid,
  Paper,
  Divider,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import api from "../api";
import paymentService from "../services/paymentService";
import ClientDetails from "../components/ClientDetails";
import ListaPedidos from "../components/ListaPedidos";
import PaymentsTable from "../components/PaymentsTable";

const DadosCliente = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cliente, setCliente] = useState(null);
  const listaPedidosRef = useRef();
  const [paymentFilters, setPaymentFilters] = useState({});
  const [paymentPage, setPaymentPage] = useState(1);
  const [paymentLimit, setPaymentLimit] = useState(10);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchCliente = async () => {
      try {
        const response = await api.get(`/clientes/${id}`);
        setCliente(response.data);
      } catch (error) {
        console.error("Erro ao carregar dados do cliente:", error.message);
      }
    };

    fetchCliente();
  }, [id]);

  const handleLoadPayments = async () => {
    try {
      setLoading(true);
      const result = await paymentService.listPayments({
        ...paymentFilters,
        customer: cliente?.asaasId,
        page: paymentPage,
        limit: paymentLimit
      });

      setPayments(result.items || []);
      setTotalCount(result.total || 0);
    } catch (error) {
      console.error("Erro ao carregar pagamentos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cliente?.asaasId) {
      handleLoadPayments();
    }
  }, [cliente, paymentPage, paymentLimit, paymentFilters]);

  if (!cliente) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Typography variant="h6">Carregando...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: 2, md: 4 } }}>
      {/* Cabeçalho com botões de ação */}
      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          alignItems={{ xs: 'stretch', sm: 'center' }}
          justifyContent="space-between"
        >
          <Typography variant="h4" color="primary">
            Dados do Cliente
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/cadastro-clientes")}
              fullWidth={false}
              sx={{ flex: { xs: 1, sm: 'none' } }}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => navigate(`/editar-cliente/${id}`)}
              fullWidth={false}
              sx={{ flex: { xs: 1, sm: 'none' } }}
            >
              Editar Cliente
            </Button>
          </Stack>
        </Stack>
      </Paper>

      <Grid container spacing={3}>
        {/* Informações do Cliente */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <ClientDetails cliente={cliente} />
          </Paper>
        </Grid>

        {/* Pagamentos */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Pagamentos
            </Typography>
            <Divider sx={{ my: 2 }} />
            <PaymentsTable
              data={payments}
              loading={loading}
              page={paymentPage}
              limit={paymentLimit}
              totalCount={totalCount}
              filters={paymentFilters}
              onFiltersChange={setPaymentFilters}
              onPageChange={(_, newPage) => setPaymentPage(newPage)}
              onLimitChange={(event) => setPaymentLimit(parseInt(event.target.value))}
              showCustomerColumn={false}
            />
          </Paper>
        </Grid>

        {/* Pedidos do Cliente */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h5" color="primary" gutterBottom>
              Pedidos do Cliente
            </Typography>
            <Divider sx={{ my: 2 }} />
            <ListaPedidos
              ref={listaPedidosRef}
              initialFilters={{ clienteId: id }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DadosCliente;
