import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
    Typography,
    Toolbar,
    Checkbox,
    Stack,
    Badge,
    Paper,
    AppBar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import * as pdfjsLib from 'pdfjs-dist';
import watermarkImage from '../../../assets/marca_dagua/marca_dagua.png';

// Configuração do worker do PDF.js
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const ProtectedImage = ({ src, alt, isPendente }) => {
    const canvasRef = React.useRef(null);
    const watermarkRef = React.useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas || !src) return;

        const ctx = canvas.getContext('2d');
        const img = new Image();
        const watermark = new Image();
        watermarkRef.current = watermark;

        const drawImageWithWatermark = () => {
            const containerWidth = canvas.parentElement.clientWidth;
            const containerHeight = canvas.parentElement.clientHeight;
            const scale = Math.min(containerWidth / img.width, containerHeight / img.height);

            if (isPendente) {
                // Reduz drasticamente o tamanho do canvas para baixa qualidade
                const reductionScale = 0.7; // Reduz para 10% do tamanho original
                const tempCanvas = document.createElement('canvas');
                const tempCtx = tempCanvas.getContext('2d');

                tempCanvas.width = Math.max(img.width * scale * reductionScale, 1);
                tempCanvas.height = Math.max(img.height * scale * reductionScale, 1);

                // Desenha em tamanho reduzido
                tempCtx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height);

                // Configura o canvas principal
                canvas.width = img.width * scale;
                canvas.height = img.height * scale;

                // Desenha a imagem reduzida de volta no tamanho original
                ctx.imageSmoothingEnabled = false;
                ctx.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);

                // Adiciona a marca d'água com mais opacidade
                const watermarkWidth = canvas.width;
                const watermarkHeight = (watermarkWidth * watermark.height) / watermark.width;
                const x = (canvas.width - watermarkWidth) / 2;
                const y = (canvas.height - watermarkHeight) / 2;

                ctx.globalAlpha = 1; // Aumenta opacidade da marca d'água
                ctx.drawImage(watermark, x, y, watermarkWidth, watermarkHeight);
                ctx.globalAlpha = 1.0;

                // Força renderização pixelada
                canvas.style.imageRendering = 'pixelated';
            } else {
                // Desenha normalmente se não estiver pendente
                canvas.width = img.width * scale;
                canvas.height = img.height * scale;
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
        };

        img.onload = () => {
            if (watermarkRef.current.complete) {
                drawImageWithWatermark();
            } else {
                watermarkRef.current.onload = drawImageWithWatermark;
            }
        };

        watermark.src = watermarkImage;
        img.src = src;

        const preventDefault = (e) => e.preventDefault();
        canvas.addEventListener('contextmenu', preventDefault);
        canvas.addEventListener('dragstart', preventDefault);
        canvas.addEventListener('selectstart', preventDefault);
        canvas.addEventListener('copy', preventDefault);

        return () => {
            canvas.removeEventListener('contextmenu', preventDefault);
            canvas.removeEventListener('dragstart', preventDefault);
            canvas.removeEventListener('selectstart', preventDefault);
            canvas.removeEventListener('copy', preventDefault);
        };
    }, [src, isPendente]);

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <canvas
                ref={canvasRef}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                }}
            />
            {isPendente && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        bgcolor: 'rgba(0, 0, 0, 0.4)',
                        color: 'white',
                        px: 3,
                        py: 2,
                        borderRadius: 2,
                        pointerEvents: 'none',
                    }}
                >
                    <LockOutlinedIcon />
                    <Typography variant="h6">
                        Prévia - Aguardando Pagamento
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

const PDFPreview = ({ arquivo }) => {
    const thumbnailUrl = `/storage/pedidos/${arquivo.pedidoId}/thumbnails/${arquivo.path.split('/').pop()}.jpg`;
    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'auto' }}>
            <Box
                component="img"
                src={thumbnailUrl}
                alt={arquivo.nome}
                sx={{
                    display: 'block',
                    margin: '0 auto',
                    maxWidth: '100%',
                    maxHeight: '90vh',
                    objectFit: 'contain',
                    filter: 'blur(2px)',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    px: 3,
                    py: 2,
                    borderRadius: 2,
                    zIndex: 2,
                }}
            >
                <LockOutlinedIcon />
                <Typography variant="h6">
                    Prévia - Aguardando Pagamento
                </Typography>
            </Box>
        </Box>
    );
};

const PDFViewer = ({ arquivo, isPendente }) => {
    const canvasRef = React.useRef(null);
    const [numPages, setNumPages] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);

    useEffect(() => {
        if (isPendente || !canvasRef.current) return;

        const loadPDF = async () => {
            try {
                const token = localStorage.getItem('token');
                const url = process.env.NODE_ENV === 'production'
                    ? `/api/arquivos/${arquivo.pedidoId}/download/${arquivo._id}`
                    : `http://localhost:5000/api/arquivos/${arquivo.pedidoId}/download/${arquivo._id}`;

                const loadingTask = pdfjsLib.getDocument({
                    url,
                    httpHeaders: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const pdf = await loadingTask.promise;
                setNumPages(pdf.numPages);

                const page = await pdf.getPage(currentPage);
                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');

                const viewport = page.getViewport({ scale: 1.5 });
                canvas.width = viewport.width;
                canvas.height = viewport.height;

                await page.render({
                    canvasContext: ctx,
                    viewport: viewport
                }).promise;

            } catch (error) {
                console.error('Erro ao carregar PDF:', error);
            }
        };

        loadPDF();
    }, [arquivo, currentPage, isPendente]);

    if (isPendente) {
        return <PDFPreview arquivo={arquivo} />;
    }

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'auto' }}>
            <canvas
                ref={canvasRef}
                style={{
                    display: 'block',
                    margin: '0 auto',
                }}
            />
            {numPages > 1 && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        bgcolor: 'background.paper',
                        px: 2,
                        py: 1,
                        borderRadius: 1,
                        boxShadow: 1,
                    }}
                >
                    <IconButton
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <Typography>
                        {currentPage} / {numPages}
                    </Typography>
                    <IconButton
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages))}
                        disabled={currentPage === numPages}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

const PreviewDialog = ({
    open,
    onClose,
    arquivo,
    pedido,
    onNext,
    onPrev,
    hasNext,
    hasPrev,
    isAdmin,
    isSelectionFolder,
    onSelectForDelivery,
    totalSelecionados,
}) => {
    const [isSelected, setIsSelected] = useState(arquivo?.selectedForDelivery || false);

    useEffect(() => {
        if (arquivo) {
            setIsSelected(arquivo.selectedForDelivery || false);
        }
    }, [arquivo]);

    if (!arquivo) return null;

    const isVideo = arquivo.tipo.startsWith('video/');
    const isImage = arquivo.tipo.startsWith('image/');
    const isPDF = arquivo.tipo === 'application/pdf';
    const isPendente = pedido?.statusPagamento === 'pendente' && !isAdmin;

    const fileUrl = `/storage/pedidos/${arquivo.pedidoId}/${arquivo.diretorio.slice(1)}/${arquivo.path.split('/').pop()}`;

    const handleSelectionChange = (event) => {
        event.stopPropagation();
        setIsSelected(event.target.checked);
        onSelectForDelivery(arquivo._id, event.target.checked);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullWidth
            PaperProps={{
                sx: {
                    bgcolor: 'background.default',
                    position: 'relative',
                    m: 1,
                    maxHeight: 'calc(100% - 16px)',
                    backgroundImage: 'none',
                }
            }}
        >
            {/* Barra superior */}
            <AppBar position="relative" color="default" elevation={0} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ width: '40px' }} /> {/* Espaço para balancear o botão de fechar */}
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 0 }}>
                        {arquivo.nome}
                    </Typography>
                    <IconButton edge="end" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <DialogContent
                sx={{
                    p: 0,
                    height: '90vh',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}
            >
                {/* Botões de navegação */}
                {hasPrev && (
                    <IconButton
                        onClick={onPrev}
                        sx={{
                            position: 'absolute',
                            left: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            bgcolor: 'background.paper',
                            zIndex: 1200,
                            '&:hover': { bgcolor: 'background.paper' }
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                )}

                {hasNext && (
                    <IconButton
                        onClick={onNext}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            bgcolor: 'background.paper',
                            zIndex: 1200,
                            '&:hover': { bgcolor: 'background.paper' }
                        }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                )}

                {/* Conteúdo principal */}
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {isVideo ? (
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <video
                                    controls={!isPendente}
                                    autoPlay={true}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '90vh',
                                    }}
                                    src={fileUrl}
                                />
                                {isPendente && (
                                    <Box
                                        component="img"
                                        src={watermarkImage}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '100%',
                                            height: 'auto',
                                            opacity: 1,
                                            pointerEvents: 'none',
                                        }}
                                    />
                                )}
                            </Box>
                            {isPendente && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        bgcolor: 'rgba(0, 0, 0, 0.3)',
                                        color: 'white',
                                        px: 3,
                                        py: 2,
                                        borderRadius: 2,
                                    }}
                                >
                                    <LockOutlinedIcon />
                                    <Typography variant="h6">
                                        Prévia - Aguardando Pagamento
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ) : isImage ? (
                        <ProtectedImage
                            src={fileUrl}
                            alt={arquivo.nome}
                            isPendente={isPendente}
                        />
                    ) : isPDF ? (
                        <PDFViewer
                            arquivo={arquivo}
                            isPendente={isPendente}
                        />
                    ) : null}
                </Box>
            </DialogContent>

            {/* Barra inferior com status de seleção e contador (apenas em pasta de seleção) */}
            {isSelectionFolder && !arquivo.isFolder && (
                <Paper
                    elevation={3}
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderTop: 1,
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        zIndex: 1200,
                    }}
                >
                    <Toolbar variant="dense">
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            sx={{ width: '100%' }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Checkbox
                                    checked={isSelected}
                                    onChange={handleSelectionChange}
                                    color="primary"
                                />
                                <Typography>
                                    {isSelected ? 'Selecionada' : 'Não selecionada'}
                                </Typography>
                            </Box>

                            <Box sx={{ flex: 1 }} />

                            <Stack direction="row" spacing={1} alignItems="center">
                                <PhotoLibraryIcon color="primary" />
                                <Typography variant="body2" color="primary">
                                    Fotos selecionadas: {totalSelecionados}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Toolbar>
                </Paper>
            )}
        </Dialog>
    );
};

export default PreviewDialog;
