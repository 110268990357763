import { useState, useCallback } from 'react';

export const INITIAL_PAGINATION = {
    page: 1,
    limit: 50
};

export const INITIAL_FILTERS = {
    clienteId: "",
    status: "",
    statusPagamento: "",
    retirada: "",
    isUrgent: "",
    mesCriacao: "",
    valorMaximo: "",
    valorMinimo: ""
};

export const usePedidosFilters = (initialFilters = {}) => {
    const [pagination, setPagination] = useState(INITIAL_PAGINATION);
    const [filters, setFilters] = useState({ ...INITIAL_FILTERS, ...initialFilters });
    const [showFilters, setShowFilters] = useState(false);

    // Função para atualizar um único filtro
    const handleFilterChange = useCallback((field) => (event) => {
        const value = event.target.value;
        setFilters(prev => ({
            ...prev,
            [field]: value
        }));
        setPagination(prev => ({
            ...prev,
            page: 1
        }));
    }, []);

    // Função para atualizar múltiplos filtros de uma vez
    const updateMultipleFilters = useCallback((newFilters) => {
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
        setPagination(prev => ({
            ...prev,
            page: 1
        }));
    }, []);

    // Função para limpar todos os filtros
    const clearFilters = useCallback(() => {
        const clearedFilters = {
            ...initialFilters,
            status: "",
            statusPagamento: "",
            retirada: "",
            isUrgent: "",
            mesCriacao: "",
            valorMinimo: "",
            valorMaximo: ""
        };
        setFilters(clearedFilters);
        setPagination(prev => ({
            ...prev,
            page: 1
        }));
    }, [initialFilters]);

    const handlePageChange = useCallback((event, newPage) => {
        setPagination(prev => ({
            ...prev,
            page: newPage
        }));
    }, []);

    const handleLimitChange = useCallback((event) => {
        setPagination({
            limit: event.target.value,
            page: 1
        });
    }, []);

    return {
        pagination,
        filters,
        showFilters,
        setShowFilters,
        handleFilterChange,
        updateMultipleFilters, // Nova função para atualizar múltiplos filtros
        clearFilters,
        handlePageChange,
        handleLimitChange
    };
};
