import React from 'react';
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import ImageCarousel from './ImageCarousel';

const ServiceSection = ({
    id,
    title,
    children,
    showCarousel = false,
    currentImage = null,
    onPrevImage = () => { },
    onNextImage = () => { },
    bgVariant = 'default'
}) => (
    <Box
        ref={id}
        sx={{
            py: { xs: 4, md: 8 },
            position: 'relative',
            bgcolor: bgVariant === 'alternate' ? 'rgba(255,255,255,0.02)' : 'transparent',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'radial-gradient(circle at 50% 50%, rgba(255,110,64,0.1) 0%, transparent 70%)',
                opacity: 0.5,
                pointerEvents: 'none'
            }
        }}
    >
        <Container>
            <Typography variant="h3" sx={{
                mb: 4,
                fontWeight: 700,
                background: 'linear-gradient(45deg, #eb5b25 30%, #ff8f6b 90%)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                fontFamily: '"Poppins", sans-serif',
                fontSize: { xs: '2rem', md: '3rem' },
            }}>
                {title}
            </Typography>
            <Grid container spacing={4}>
                {showCarousel ? (
                    <>
                        <Grid item xs={12} md={6}>
                            {children}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{
                                p: { xs: 2, md: 4 },
                                height: '100%',
                                background: 'rgba(255,255,255,0.03)',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(255,255,255,0.1)',
                                borderRadius: '24px',
                            }}>
                                <ImageCarousel
                                    currentImage={currentImage}
                                    onPrevious={onPrevImage}
                                    onNext={onNextImage}
                                />
                            </Box>
                        </Grid>
                    </>
                ) : (
                    children
                )}
            </Grid>
        </Container>
    </Box>
);

export default ServiceSection;
