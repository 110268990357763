import React, { useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import PageHeader from '../components/shared/PageHeader';
import PaymentsTable from '../components/PaymentsTable';
import { usePaymentsList } from '../hooks/usePaymentsList';

const Payments = () => {
    const {
        loading,
        payments,
        pagination,
        filters,
        handleFilterChange,
        handlePageChange,
        handleLimitChange,
        refreshData
    } = usePaymentsList();

    useEffect(() => {
        refreshData();
    }, []); // Load data when component mounts

    const filterOptions = [
        {
            type: 'select',
            label: 'Status',
            name: 'status',
            options: [
                { value: '', label: 'Todos' },
                { value: 'PENDING', label: 'Aguardando Pagamento' },
                { value: 'RECEIVED', label: 'Recebido' },
                { value: 'CONFIRMED', label: 'Confirmado' },
                { value: 'OVERDUE', label: 'Vencido' },
                { value: 'CANCELLED', label: 'Cancelado' }
            ]
        },
        {
            type: 'select',
            label: 'Tipo',
            name: 'billingType',
            options: [
                { value: '', label: 'Todos' },
                { value: 'BOLETO', label: 'Boleto' },
                { value: 'CREDIT_CARD', label: 'Cartão de Crédito' },
                { value: 'PIX', label: 'PIX' }
            ]
        },
        {
            type: 'text',
            label: 'Cliente',
            name: 'customer'
        },
        {
            type: 'date',
            label: 'Data Inicial',
            name: 'dateFrom'
        },
        {
            type: 'date',
            label: 'Data Final',
            name: 'dateTo'
        }
    ];

    return (
        <PageHeader
            title="Pagamentos"
        >

            <PaymentsTable
                data={payments}
                loading={loading}
                page={pagination.page}
                limit={pagination.limit}
                totalCount={pagination.total}
                filters={filters}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
                onLimitChange={handleLimitChange}
                filterOptions={filterOptions}
                showFilters={true}
                showPagination={true}
                readOnly={false}
            />
        </PageHeader>
    );
};

export default React.memo(Payments);
