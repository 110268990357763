import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Pagination, useTheme, useMediaQuery } from '@mui/material';

const PaginationControls = ({
    page,
    limit,
    total,
    onPageChange,
    onLimitChange,
    loading = false,
    itemsPerPageOptions = [5, 10, 25, 50]
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const totalPages = Math.ceil(total / limit);

    // Ensure page is treated as a number and is 1-based
    const currentPage = Math.max(1, Number(page));

    const handlePageChange = (event, newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !loading) {
            onPageChange(event, newPage);
        }
    };

    const mobileContent = (
        <>
            {/* Paginação no topo */}
            <Box sx={{ width: '100%', mb: 2 }}>
                <Pagination

                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    disabled={loading}
                    showFirstButton
                    showLastButton
                    siblingCount={0}
                    size="small"
                    sx={{
                        transform: 'scale(1.3)',
                        '& .MuiPagination-ul': {
                            gap: '4px',
                            justifyContent: 'center'
                        }
                    }}
                />
            </Box>

            {/* Container para itens por página e total */}
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                        minWidth: '120px',
                        maxWidth: '150px'
                    }}
                >
                    <InputLabel>Itens por página</InputLabel>
                    <Select
                        value={limit}
                        onChange={onLimitChange}
                        label="Itens por página"
                        disabled={loading}
                    >
                        {itemsPerPageOptions.map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography
                    variant="body2"
                    color="text.secondary"
                >
                    Total: {total} {total === 1 ? 'item' : 'itens'}
                </Typography>
            </Box>
        </>
    );

    const desktopContent = (
        <>
            <FormControl
                variant="outlined"
                size="small"
                sx={{
                    minWidth: '120px',
                    maxWidth: '150px'
                }}
            >
                <InputLabel>Itens por página</InputLabel>
                <Select
                    value={limit}
                    onChange={onLimitChange}
                    label="Itens por página"
                    disabled={loading}
                >
                    {itemsPerPageOptions.map(option => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    disabled={loading}
                    showFirstButton
                    showLastButton
                    siblingCount={0}
                    size="small"
                    sx={{
                        '& .MuiPagination-ul': {
                            gap: '4px'
                        }
                    }}
                />
            </Box>

            <Typography
                variant="body2"
                color="text.secondary"
            >
                Total: {total} {total === 1 ? 'item' : 'itens'}
            </Typography>
        </>
    );

    return (
        <Box sx={{
            mt: 2,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2
        }}>
            {isMobile ? mobileContent : desktopContent}
        </Box>
    );
};

export default React.memo(PaginationControls);
