import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import logoHorizontal from '../../assets/logos/Logo Branco com Laranja.svg';

const Hero = () => (
    <Box sx={{
        position: 'relative',
        py: { xs: 4, md: 8 },
        background: 'linear-gradient(135deg, #121212 0%, #1a1a1a 100%)',
        overflow: 'hidden',
        mt: { xs: '60px', md: '80px' },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at 50% 50%, rgba(255,110,64,0.1) 0%, transparent 70%)',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '150%',
            height: '150%',
            background: 'radial-gradient(circle, #222224 0%, transparent 40%)',
            opacity: 0.2,
            animation: 'rotate 15s linear infinite reverse',
            top: '-25%',
            left: '-25%',
        },
        '@keyframes rotate': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' }
        }
    }}>
        <Container>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                zIndex: 1,
            }}>
                <Box
                    component="img"
                    src={logoHorizontal}
                    alt="ZoomPro"
                    sx={{
                        width: { xs: '200px', md: '300px' },
                        height: 'auto',
                        mb: 4,
                        filter: 'brightness(1.2)',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        }
                    }}
                />
                <Typography
                    variant="h2"
                    align="center"
                    sx={{
                        fontWeight: 800,
                        background: 'linear-gradient(45deg, #eb5b25 30%, #ff8f6b 90%)',
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                        mb: 2,
                        fontFamily: '"Poppins", sans-serif',
                        fontSize: { xs: '2rem', md: '3.75rem' },
                        animation: 'fadeIn 1s ease-out',
                        '@keyframes fadeIn': {
                            from: { opacity: 0, transform: 'translateY(20px)' },
                            to: { opacity: 1, transform: 'translateY(0)' }
                        }
                    }}
                >
                    Tabela de Preços 2025
                </Typography>
                <Typography
                    variant="h5"
                    align="center"
                    sx={{
                        color: 'rgba(255,255,255,0.7)',
                        maxWidth: '800px',
                        mx: 'auto',
                        mb: 4,
                        fontFamily: '"Inter", sans-serif',
                        fontSize: { xs: '1rem', md: '1.5rem' },
                        animation: 'fadeIn 1s ease-out 0.3s backwards',
                    }}
                >
                    Soluções profissionais para elevar seu e-commerce
                </Typography>
            </Box>
        </Container>
    </Box>
);

export default Hero;
