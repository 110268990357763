import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    useTheme,
    Chip,
} from '@mui/material';
import {
    Person as PersonIcon,
    CalendarToday as CalendarIcon,
    Star as StarIcon,
} from '@mui/icons-material';
import { formatarDataComFuso } from '../../utils/dateUtils';

const InfoSection = ({ title, icon: Icon, children }) => {
    const theme = useTheme();
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Icon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                <Typography variant="h6" color="primary">
                    {title}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

const EditarPedidoInfo = ({
    cliente,
    dataEntrega,
    dataLimiteRetirada,
    dataCadastro,
    retirada,
    isUrgent,
    isZoomPlus,
    beneficiosUtilizados = {},
    onUrgenciaChange,
    onBeneficioChange,
}) => {
    const theme = useTheme();

    return (
        <>

            {/* Datas */}
            <Paper sx={{ p: 2, mb: 2 }}>
                <InfoSection title="Datas" icon={CalendarIcon}>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" color="textSecondary">Cadastro</Typography>
                            <Typography variant="body1">
                                {formatarDataComFuso(dataCadastro)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" color="textSecondary">Entrega</Typography>
                            <Typography variant="body1">
                                {formatarDataComFuso(dataEntrega)}
                            </Typography>
                        </Grid>
                        {!retirada && dataLimiteRetirada && (
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">Limite Retirada</Typography>
                                <Typography variant="body1">
                                    {formatarDataComFuso(dataLimiteRetirada)}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </InfoSection>
            </Paper>

            {/* Opções do Pedido */}
            <Paper sx={{ p: 2, mb: 2 }}>
                <InfoSection title="Opções do Pedido" icon={StarIcon}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isUrgent}
                                        onChange={(e) => onUrgenciaChange(e.target.checked)}
                                    />
                                }
                                label="Pedido Urgente"
                            />
                        </Grid>

                        {isZoomPlus && (
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                    Benefícios ZOOM+
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={beneficiosUtilizados?.taxaUrgenciaGratis || false}
                                                onChange={() => onBeneficioChange('taxaUrgenciaGratis')}
                                                disabled={!isUrgent}
                                            />
                                        }
                                        label="Taxa de Urgência Grátis"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={beneficiosUtilizados?.modeloCompletaGratis || false}
                                                onChange={() => onBeneficioChange('modeloCompletaGratis')}
                                            />
                                        }
                                        label="Modelo Completa Grátis"
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </InfoSection>
            </Paper>
        </>
    );
};

export default EditarPedidoInfo;
