import React from 'react';
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Checkbox,
    Typography,
    Button,
    Tooltip,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import {
    MoreVert,
    CloudDownload,
    Delete,
    LockOutlined,
} from '@mui/icons-material';

export const FileMenu = ({ anchorEl, onClose, onDownload, onDelete, isAdmin, activeFile, pedido }) => {
    const isPendente = pedido?.statusPagamento === 'pendente';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                sx: {
                    minWidth: isMobile ? 200 : 'auto'
                }
            }}
        >
            <MenuItem
                onClick={() => {
                    if (!isPendente) {
                        onDownload(activeFile._id, activeFile.nome);
                        onClose();
                    }
                }}
                disabled={isPendente}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                {isPendente ? <LockOutlined sx={{ mr: 1 }} /> : <CloudDownload sx={{ mr: 1 }} />}
                <Box>
                    <Typography variant="inherit">
                        Download
                    </Typography>
                    {isPendente && (
                        <Typography variant="caption" color="text.secondary" display="block">
                            Disponível após pagamento
                        </Typography>
                    )}
                </Box>
            </MenuItem>
            {isAdmin && (
                <MenuItem onClick={() => {
                    onDelete(activeFile._id);
                    onClose();
                }}>
                    <Delete sx={{ mr: 1 }} /> Excluir
                </MenuItem>
            )}
        </Menu>
    );
};

export const FileMenuButton = ({ onClick, pedido, isMobile }) => {
    const isPendente = pedido?.statusPagamento === 'pendente';

    return (
        <Box
            className="arquivo-actions"
            sx={{
                position: 'absolute',
                top: isMobile ? 4 : 8,
                right: isMobile ? 4 : 8,
                opacity: isMobile ? 1 : 0,
                transition: 'opacity 0.2s',
                display: 'flex',
                gap: 1,
                zIndex: 1
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <Tooltip title={isPendente ? "Download disponível após pagamento" : "Opções"}>
                <IconButton
                    size={isMobile ? "medium" : "small"}
                    onClick={onClick}
                    sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        '&:hover': {
                            bgcolor: 'background.paper',
                        }
                    }}
                >
                    <MoreVert fontSize={isMobile ? "medium" : "small"} />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export const SelectionBar = ({ selectedFiles, totalFiles, onSelectAll, onDeleteSelected }) => (
    <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
                checked={selectedFiles.length === totalFiles}
                indeterminate={selectedFiles.length > 0 && selectedFiles.length < totalFiles}
                onChange={onSelectAll}
            />
            <Typography variant="body2">
                {selectedFiles.length} selecionado(s)
            </Typography>
        </Box>
        {selectedFiles.length > 0 && (
            <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
                onClick={onDeleteSelected}
                sx={{
                    borderRadius: 2,
                    textTransform: 'none'
                }}
            >
                Excluir Selecionados
            </Button>
        )}
    </Box>
);

export const FileCheckbox = ({ checked, onChange }) => (
    <Box
        className="checkbox-container"
        sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 1
        }}
        onClick={(e) => e.stopPropagation()}
    >
        <Checkbox
            checked={checked}
            onChange={onChange}
            size="small"
        />
    </Box>
);
