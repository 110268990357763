import React from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    LinearProgress,
} from '@mui/material';
import {
    TrendingUp,
    People,
    Star,
    AttachMoney,
    ShowChart,
    Assessment,
    Timeline,
    CompareArrows,
    Speed,
    Group,
    AccountBalance,
    MoneyOff
} from '@mui/icons-material';

const StatCard = ({ title, value, icon, subtitle, color, progress }) => (
    <Card sx={{ height: '100%' }}>
        <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="h4" component="div">
                        {typeof value === 'number' && value % 1 === 0
                            ? value.toLocaleString('pt-BR')
                            : typeof value === 'number'
                                ? value.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })
                                : value || '0'}
                    </Typography>
                    {subtitle && (
                        <Typography variant="body2" color="textSecondary">
                            {subtitle}
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        backgroundColor: `${color}15`,
                        borderRadius: '50%',
                        padding: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {React.cloneElement(icon, { sx: { fontSize: 40, color: color } })}
                </Box>
            </Box>
            {progress !== undefined && (
                <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        value={Math.max(0, Math.min(100, progress || 0))}
                        sx={{
                            height: 8,
                            borderRadius: 5,
                            backgroundColor: `${color}30`,
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: color,
                            }
                        }}
                    />
                </Box>
            )}
        </CardContent>
    </Card>
);

const DashboardStats = ({ statistics = {} }) => {
    const {
        totalFaturamento = 0,
        valorRecebido = 0,
        valorAReceber = 0,
        totalPedidos = 0,
        avgOrderValue = 0,
        totalClientes = 0,
        zoomPlusClientes = 0,
        clientesRecorrentes = 0,
        successRate = 0,
        retentionRate = 0,
        valorVencido = 0,
        zoomPlusRevenue = 0,
        revenuePerClient = 0,
        maxOrderValue = 0,
        monthOverMonthGrowth = { revenue: 0, orders: 0, avgOrderValue: 0 }
    } = statistics;

    const formatCurrency = (value) => {
        return (value || 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    const calculateGrowthRate = () => {
        if (!monthOverMonthGrowth) return 0;
        return monthOverMonthGrowth.revenue || 0;
    };

    const growthRate = calculateGrowthRate();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Faturamento Total"
                    value={formatCurrency(totalFaturamento)}
                    icon={<AttachMoney />}
                    subtitle={`${(growthRate || 0).toFixed(1)}% em relação ao mês anterior`}
                    color="#4CAF50"
                    progress={growthRate > 0 ? Math.min(growthRate, 100) : 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Valor Recebido"
                    value={formatCurrency(valorRecebido)}
                    icon={<AccountBalance />}
                    subtitle="Total de pedidos concluídos"
                    color="#8E24AA"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Valor a Receber"
                    value={formatCurrency(valorAReceber)}
                    icon={<MoneyOff />}
                    subtitle="Total de pedidos em aberto"
                    color="#D32F2F"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Total de Pedidos"
                    value={totalPedidos}
                    icon={<Assessment />}
                    subtitle={`${(monthOverMonthGrowth?.orders || 0).toFixed(1)}% em relação ao mês anterior`}
                    color="#2196F3"
                    progress={monthOverMonthGrowth?.orders > 0 ? Math.min(monthOverMonthGrowth.orders, 100) : 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Ticket Médio"
                    value={formatCurrency(avgOrderValue)}
                    icon={<ShowChart />}
                    subtitle={`${(monthOverMonthGrowth?.avgOrderValue || 0).toFixed(1)}% em relação ao mês anterior`}
                    color="#FF9800"
                    progress={monthOverMonthGrowth?.avgOrderValue > 0 ? Math.min(monthOverMonthGrowth.avgOrderValue, 100) : 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Taxa de Sucesso"
                    value={`${(successRate || 0).toFixed(1)}%`}
                    icon={<Speed />}
                    subtitle="Pedidos concluídos com sucesso"
                    color="#00BCD4"
                    progress={successRate || 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Total de Clientes"
                    value={totalClientes}
                    icon={<People />}
                    subtitle={`${clientesRecorrentes || 0} clientes recorrentes`}
                    color="#9C27B0"
                    progress={totalClientes ? ((clientesRecorrentes || 0) / totalClientes) * 100 : 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Clientes Zoom+"
                    value={zoomPlusClientes}
                    icon={<Star />}
                    subtitle={`${totalClientes ? ((zoomPlusClientes / totalClientes) * 100).toFixed(1) : '0'}% do total`}
                    color="#E91E63"
                    progress={totalClientes ? ((zoomPlusClientes / totalClientes) * 100) : 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Taxa de Retenção"
                    value={`${(retentionRate || 0).toFixed(1)}%`}
                    icon={<Group />}
                    subtitle="Clientes que retornam"
                    color="#3F51B5"
                    progress={retentionRate || 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Valores Vencidos"
                    value={formatCurrency(valorVencido)}
                    icon={<Timeline />}
                    subtitle="Total de pedidos vencidos"
                    color="#FF5252"
                    progress={valorAReceber ? Math.min((valorVencido / valorAReceber) * 100, 100) : 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Receita Zoom+"
                    value={formatCurrency(zoomPlusRevenue)}
                    icon={<CompareArrows />}
                    subtitle={`${totalFaturamento ? ((zoomPlusRevenue / totalFaturamento) * 100).toFixed(1) : '0'}% da receita total`}
                    color="#795548"
                    progress={totalFaturamento ? ((zoomPlusRevenue / totalFaturamento) * 100) : 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <StatCard
                    title="Receita por Cliente"
                    value={formatCurrency(revenuePerClient)}
                    icon={<TrendingUp />}
                    subtitle="Média de receita por cliente"
                    color="#607D8B"
                    progress={maxOrderValue ? Math.min((revenuePerClient / maxOrderValue) * 100, 100) : 0}
                />
            </Grid>
        </Grid>
    );
};

export default DashboardStats;
