import { useState, useCallback } from 'react';
import { processDirectoryEntry } from '../utils/fileHelpers';

export const useFileHandlers = ({ onUpload, uploadPasta, onUploadSuccess, onUploadError }) => {
    const [dragOver, setDragOver] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(async (e) => {
        e.preventDefault();
        setDragOver(false);

        if (e.dataTransfer.items) {
            try {
                const entries = Array.from(e.dataTransfer.items)
                    .map(item => item.webkitGetAsEntry())
                    .filter(entry => entry !== null);

                // Agrupa os items por pasta raiz
                const rootFolders = new Map();

                for (const entry of entries) {
                    if (entry.isDirectory) {
                        const processedItems = await processDirectoryEntry(entry);
                        if (!rootFolders.has(entry.name)) {
                            rootFolders.set(entry.name, []);
                        }
                        rootFolders.get(entry.name).push(...processedItems);
                    } else {
                        // Se for arquivo solto, cria uma estrutura similar
                        const file = await new Promise((resolve) => entry.file(resolve));
                        if (!rootFolders.has('files')) {
                            rootFolders.set('files', []);
                        }
                        rootFolders.get('files').push({
                            type: 'file',
                            file,
                            path: '/' + file.name,
                            name: file.name
                        });
                    }
                }

                // Processa cada pasta raiz
                for (const [folderName, items] of rootFolders) {
                    if (folderName === 'files') {
                        // Upload de arquivos individuais
                        for (const item of items) {
                            await onUpload(item.file);
                        }
                    } else {
                        // Upload de pastas
                        await uploadPasta([{
                            isDirectory: true,
                            fullPath: '/' + folderName,
                            name: folderName,
                            children: items
                        }]);
                    }
                }

                onUploadSuccess();
            } catch (error) {
                console.error('Erro ao processar drag and drop:', error);
                onUploadError(error);
            }
        } else {
            const files = Array.from(e.dataTransfer.files);
            if (files.length > 0) {
                try {
                    for (const file of files) {
                        await onUpload(file);
                    }
                    onUploadSuccess();
                } catch (error) {
                    onUploadError(error);
                }
            }
        }
    }, [onUpload, uploadPasta, onUploadSuccess, onUploadError]);

    const handleFileSelect = useCallback(async (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            try {
                for (const file of files) {
                    await onUpload(file);
                }
                onUploadSuccess();
            } catch (error) {
                onUploadError(error);
            }
        }
        event.target.value = '';
    }, [onUpload, onUploadSuccess, onUploadError]);

    const handleFolderSelect = useCallback(async (event, isSelectionFolder = false) => {
        try {
            const files = Array.from(event.target.files);
            if (files.length > 0) {
                // Agrupa arquivos por pasta
                const folderMap = new Map();

                files.forEach(file => {
                    const pathParts = file.webkitRelativePath.split('/');
                    const folderName = pathParts[0];

                    if (!folderMap.has(folderName)) {
                        folderMap.set(folderName, []);
                    }
                    folderMap.get(folderName).push(file);
                });

                // Cria estrutura de items para cada pasta
                const items = Array.from(folderMap.entries()).map(([folderName, folderFiles]) => ({
                    isDirectory: true,
                    fullPath: '/' + folderName,
                    name: folderName,
                    children: folderFiles.map(file => ({
                        type: 'file',
                        file,
                        path: '/' + file.webkitRelativePath,
                        name: file.name
                    }))
                }));

                await uploadPasta(items, isSelectionFolder);
                onUploadSuccess();
            }
        } catch (error) {
            console.error('Erro ao processar pasta:', error);
            onUploadError(error);
        } finally {
            event.target.value = '';
        }
    }, [uploadPasta, onUploadSuccess, onUploadError]);

    const handleSelectAll = useCallback((arquivos) => {
        setSelectedFiles(prev =>
            prev.length === arquivos.length ? [] : arquivos.map(arquivo => arquivo._id)
        );
    }, []);

    const handleSelectFile = useCallback((fileId) => {
        setSelectedFiles(prev => {
            if (prev.includes(fileId)) {
                return prev.filter(id => id !== fileId);
            }
            return [...prev, fileId];
        });
    }, []);

    const clearSelection = useCallback(() => {
        setSelectedFiles([]);
    }, []);

    return {
        dragOver,
        selectedFiles,
        handleDragOver,
        handleDragLeave,
        handleDrop,
        handleFileSelect,
        handleFolderSelect,
        handleSelectAll,
        handleSelectFile,
        clearSelection
    };
};

export const usePreviewHandlers = () => {
    const [previewFile, setPreviewFile] = useState(null);
    const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);

    const handlePreviewNext = useCallback((previewableFiles) => {
        if (currentPreviewIndex < previewableFiles.length - 1) {
            setCurrentPreviewIndex(prev => prev + 1);
            setPreviewFile(previewableFiles[currentPreviewIndex + 1]);
        }
    }, [currentPreviewIndex]);

    const handlePreviewPrev = useCallback((previewableFiles) => {
        if (currentPreviewIndex > 0) {
            setCurrentPreviewIndex(prev => prev - 1);
            setPreviewFile(previewableFiles[currentPreviewIndex - 1]);
        }
    }, [currentPreviewIndex]);

    const handlePreviewClose = useCallback(() => {
        setPreviewFile(null);
        setCurrentPreviewIndex(0);
    }, []);

    return {
        previewFile,
        currentPreviewIndex,
        setPreviewFile,
        setCurrentPreviewIndex,
        handlePreviewNext,
        handlePreviewPrev,
        handlePreviewClose
    };
};

export const useMenuHandlers = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeFile, setActiveFile] = useState(null);

    const handleMenuOpen = useCallback((event, file) => {
        setAnchorEl(event.currentTarget);
        setActiveFile(file);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
        setActiveFile(null);
    }, []);

    return {
        anchorEl,
        activeFile,
        handleMenuOpen,
        handleMenuClose
    };
};
