export const formatFileSize = (bytes) => {
    if (!bytes) return '-';
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};

export const processDirectoryEntry = async (entry) => {
    if (entry.isDirectory) {
        const dirReader = entry.createReader();
        const entries = await new Promise((resolve) => {
            const results = [];
            const readEntries = () => {
                dirReader.readEntries((entries) => {
                    if (entries.length === 0) {
                        resolve(results);
                    } else {
                        results.push(...entries);
                        readEntries();
                    }
                });
            };
            readEntries();
        });

        const children = [];
        for (const entry of entries) {
            if (entry.isDirectory) {
                const subItems = await processDirectoryEntry(entry);
                children.push({
                    type: 'directory',
                    path: entry.fullPath,
                    name: entry.name,
                    children: subItems
                });
            } else {
                const file = await new Promise((resolve) => entry.file(resolve));
                children.push({
                    type: 'file',
                    file,
                    path: entry.fullPath,
                    name: entry.name
                });
            }
        }
        return children;
    } else {
        const file = await new Promise((resolve) => entry.file(resolve));
        return [{
            type: 'file',
            file,
            path: '/' + file.name,
            name: file.name
        }];
    }
};

export const getFileUrl = (arquivo) => {
    const baseUrl = `/storage/pedidos/${arquivo.pedidoId}`;
    const directory = arquivo.diretorio.slice(1);
    const fileName = arquivo.path.split('/').pop();

    if (arquivo.tipo?.startsWith('video/')) {
        return `${baseUrl}/thumbnails/${fileName}.jpg`;
    }

    return `${baseUrl}${directory ? '/' + directory : ''}/${fileName}`;
};

export const isPreviewable = (arquivo) => {
    return arquivo.tipo?.startsWith('image/') || arquivo.tipo?.startsWith('video/');
};
