import React from "react";
import { Button, Typography, Checkbox, Tooltip, Box } from "@mui/material";
import {
    Warning as WarningIcon,
    WhatsApp as WhatsAppIcon,
    DriveFileMove as DriveFileIcon,
} from "@mui/icons-material";
import StatusDisplay from "./StatusDisplay";
import PaymentActions from "./PaymentActions";
import PaymentStatus from "./PaymentStatus";

export const createCellRenderers = ({
    readOnly,
    handleStatusChange,
    handleDetalhes,
    handleRetiradaChange,
    handlePaymentAction,
    dados,
}) => {


    return {
        ID: (linha) => linha.ID ? `#${linha.ID.toString().slice(-4).toUpperCase()}` : "-",
        Status: (linha) => {

            return (
                <StatusDisplay
                    status={linha.Status}
                    id={linha.ID}
                    readOnly={readOnly}
                    handleStatusChange={handleStatusChange}
                    allData={dados}
                />
            );
        },
        Cliente: (linha) => linha.Cliente || '-',
        'Data Cadastro': (linha) => linha.DataCadastro || '-',
        DataCadastro: (linha) => linha.DataCadastro || '-',
        'Data Vencimento': (linha) => linha.DataVencimento || '-',
        DataVencimento: (linha) => linha.DataVencimento || '-',
        'Data Entrega': (linha) => linha.DataEntrega || '-',
        DataEntrega: (linha) => linha.DataEntrega || '-',
        Retirada: (linha) => {

            const calcularDataFinalRetirada = (dataEntrega) => {
                try {
                    if (!dataEntrega) return '';

                    // Parse date in format "DD/MM/YY"
                    const [dia, mes, ano] = dataEntrega.split('/');
                    if (!dia || !mes || !ano) return '';

                    // Convert two-digit year to four digits (20XX)
                    const anoCompleto = parseInt(ano) + 2000;

                    const date = new Date(anoCompleto, parseInt(mes) - 1, parseInt(dia));
                    if (isNaN(date.getTime())) return '';

                    date.setDate(date.getDate() + 30);
                    return date.toLocaleDateString('pt-BR');
                } catch (error) {
                    console.error('Erro ao calcular data final:', error);
                    return '';
                }
            };

            const dataFinalRetirada = calcularDataFinalRetirada(linha.DataEntrega);

            return (
                readOnly ? (
                    <Typography variant="body2">{linha.Retirada ? "Sim" : "Não"}</Typography>
                ) : (
                    <Tooltip
                        title={dataFinalRetirada || 'Data não definida'}
                        placement="top"
                    >
                        <Checkbox
                            checked={linha.Retirada}
                            onChange={(e) => {
                                handleRetiradaChange(linha.ID, e.target.checked);
                            }}
                        />
                    </Tooltip>
                )
            );
        },
        Detalhes: (linha) => (
            <Button
                variant="text"
                color="primary"
                onClick={() => handleDetalhes(linha.ID)}
                sx={{ textTransform: 'none' }}
            >
                {linha.Detalhes || 'Detalhes'}
            </Button>
        ),
        Total: (linha) => linha.Total || 'R$ 0,00',
        'Valor a Pagar': (linha) => linha['Valor a Pagar'] || 'R$ 0,00',
        Pagamento: (linha) => (
            <PaymentStatus
                pagamento={linha.Pagamento}
                allData={dados}
                currentRow={linha}
            />
        ),
        'Ações de Pagamento': (linha) => (
            <PaymentActions
                linha={linha}
                readOnly={readOnly}
                handlePaymentAction={handlePaymentAction}
                allData={dados}
            />
        ),
        WhatsApp: (linha) => linha.Telefone && (
            <Button
                startIcon={<WhatsAppIcon />}
                onClick={() => window.open(`https://wa.me/+55${linha.Telefone}`)}
            >
                WhatsApp
            </Button>
        ),
        Drive: (linha) => linha.DriveLink && (
            <Button
                startIcon={<DriveFileIcon />}
                onClick={() => window.open(linha.DriveLink)}
            >
                Drive
            </Button>
        ),
        Urgente: (linha) => linha.boolean && (
            <WarningIcon color="warning" titleAccess="Urgente" />
        ),
        ZoomPlus: (linha) => linha.ZoomPlus || 'Não',
    };
};
