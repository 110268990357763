import React from 'react';
import { Grid } from '@mui/material';
import PriceCard from '../PriceCard';

const ClubeSection = () => (
    <>
        <Grid item xs={12} md={4}>
            <PriceCard
                title="Essencial"
                price="300,00"
                items={[
                    "5% de desconto em serviços",
                    "Prazo de até 5 dias úteis",
                    "1 taxa de urgência inclusa",
                    "Layout de catálogo gratuito",
                    "Modelo de pé gratuito",
                    "Grupo exclusivo no WhatsApp"
                ]}
                gradient="linear-gradient(135deg, #eb5b25 0%, #ff8f6b 100%)"
            />
        </Grid>
        <Grid item xs={12} md={4}>
            <PriceCard
                title="Profissional"
                price="500,00"
                items={[
                    "10% de desconto em serviços",
                    "Prazo de até 4 dias úteis",
                    "2 taxas de urgência inclusas",
                    "Layout de catálogo gratuito",
                    "Modelo de pé gratuito",
                    "Grupo exclusivo no WhatsApp"
                ]}
                gradient="linear-gradient(135deg, #ff8f6b 0%, #ffa07d 100%)"
            />
        </Grid>
        <Grid item xs={12} md={4}>
            <PriceCard
                title="Premium"
                price="700,00"
                items={[
                    "15% de desconto em serviços",
                    "Prazo de até 3 dias úteis",
                    "3 taxas de urgência inclusas",
                    "Layout de catálogo gratuito",
                    "Modelo de pé gratuito",
                    "Grupo exclusivo no WhatsApp"
                ]}
                gradient="linear-gradient(135deg, #ffa07d 0%, #ffb38f 100%)"
            />
        </Grid>
    </>
);

export default ClubeSection;
