import React from 'react';
import { Box, Container, Button } from '@mui/material';

const Navigation = ({ sections, activeSection, onSectionClick }) => (
    <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        background: 'rgba(18, 18, 18, 0.8)',
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid rgba(255, 110, 64, 0.1)',
    }}>
        <Container>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                py: { xs: 1, md: 2 },
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    overflowX: 'auto',
                    width: '100%',
                    justifyContent: 'center',
                    '&::-webkit-scrollbar': {
                        height: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'rgba(255,255,255,0.1)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#eb5b25',
                        borderRadius: '4px',
                    },
                }}>
                    {sections.map(({ id, label }) => (
                        <Button
                            key={id}
                            onClick={() => onSectionClick(id)}
                            sx={{
                                color: activeSection === id ? '#eb5b25' : 'white',
                                borderBottom: activeSection === id ? '2px solid #eb5b25' : '2px solid transparent',
                                borderRadius: 0,
                                px: { xs: 1, md: 2 },
                                minWidth: 'auto',
                                whiteSpace: 'nowrap',
                                fontSize: { xs: '0.8rem', md: '1rem' },
                                fontFamily: '"Inter", sans-serif',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    color: '#eb5b25',
                                    background: 'rgba(235, 91, 37, 0.05)',
                                }
                            }}
                        >
                            {label}
                        </Button>
                    ))}
                </Box>
            </Box>
        </Container>
    </Box>
);

export default Navigation;
