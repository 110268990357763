import React, { useState } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Button,
    CircularProgress,
    Checkbox,
    Badge,
    Menu,
    MenuItem,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import {
    CloudDownload,
    MoreVert as MoreIcon,
} from '@mui/icons-material';

import PreviewDialog from './arquivos/PreviewDialog';
import UploadProgressBar from './arquivos/UploadProgressBar';
import UploadArea from './arquivos/UploadArea';
import { FileMenu, SelectionBar } from './arquivos/FileActions';
import FileBreadcrumbs from './arquivos/FileBreadcrumbs';
import FilePreview from './arquivos/FilePreview';
import { useFileHandlers, usePreviewHandlers, useMenuHandlers } from '../../hooks/useFileHandlers';
import { formatFileSize } from '../../utils/fileHelpers';

const FileListItem = ({
    arquivo,
    isAdmin,
    selectedFiles,
    onSelectFile,
    onNavigate,
    onMenuOpen,
    isSelectionFolder,
    onSelectForDelivery,
    onToggleSelectionFolder,
    pedido,
    onPreviewClick,
    onImageError,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFolderMenu, setShowFolderMenu] = useState(false);

    const handleFolderMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleFolderMenuClose = () => {
        setAnchorEl(null);
    };

    const handleToggleSelectionFolder = () => {
        handleFolderMenuClose();
        onToggleSelectionFolder(arquivo._id, !arquivo.isSelectionFolder);
    };

    return (
        <ListItem
            button={arquivo.isFolder}
            onClick={() => arquivo.isFolder && onNavigate(arquivo.path)}
            sx={{
                borderRadius: 2,
                mb: 1,
                bgcolor: 'background.paper',
                border: arquivo.isSelectionFolder ? '2px solid' : '2px solid transparent',
                borderColor: arquivo.isSelectionFolder ? 'warning.main' : 'transparent',
                '&:hover': {
                    '& .folder-menu': {
                        opacity: 1
                    }
                },
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}
            onMouseEnter={() => arquivo.isFolder && setShowFolderMenu(true)}
            onMouseLeave={() => arquivo.isFolder && setShowFolderMenu(false)}
        >
            {isAdmin && (
                <Checkbox
                    checked={selectedFiles.includes(arquivo._id)}
                    onChange={() => onSelectFile(arquivo._id)}
                    onClick={(e) => e.stopPropagation()}
                />
            )}

            {isSelectionFolder && !arquivo.isFolder && (
                <Checkbox
                    checked={arquivo.selectedForDelivery || false}
                    onChange={(e) => onSelectForDelivery(arquivo._id, e.target.checked)}
                    onClick={(e) => e.stopPropagation()}
                />
            )}

            <Box sx={{ width: 60, height: 60, flexShrink: 0 }}>
                <FilePreview
                    arquivo={arquivo}
                    pedido={pedido}
                    onImageError={onImageError}
                    onPreviewClick={onPreviewClick}
                    isAdmin={isAdmin}
                />
            </Box>

            <ListItemText
                primary={arquivo.nome}
                secondary={!arquivo.isFolder && formatFileSize(arquivo.tamanho)}
            />

            <ListItemSecondaryAction>
                {arquivo.isFolder && showFolderMenu && isAdmin ? (
                    <Box className="folder-menu" sx={{ opacity: 0, transition: 'opacity 0.2s' }}>
                        <IconButton
                            edge="end"
                            onClick={handleFolderMenuOpen}
                        >
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleFolderMenuClose}
                        >
                            <MenuItem onClick={handleToggleSelectionFolder}>
                                {arquivo.isSelectionFolder ? 'Remover pasta de seleção' : 'Marcar como pasta de seleção'}
                            </MenuItem>
                        </Menu>
                    </Box>
                ) : !arquivo.isFolder && (
                    <IconButton
                        edge="end"
                        onClick={(e) => onMenuOpen(e, arquivo)}
                    >
                        <MoreIcon />
                    </IconButton>
                )}
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const PedidoArquivosList = ({
    arquivos,
    pedido,
    isAdmin,
    uploading,
    downloadingAll,
    uploadProgress,
    currentPath,
    onUpload,
    uploadPasta,
    onDelete,
    onDeleteMany,
    onDownload,
    onDownloadAll,
    onNavigate,
    onUploadSuccess,
    onUploadError,
    isSelectionFolder,
    totalSelecionados,
    onSelectForDelivery,
    onToggleSelectionFolder,
}) => {
    const [imageError, setImageError] = useState({});

    const {
        dragOver,
        selectedFiles,
        handleDragOver,
        handleDragLeave,
        handleDrop,
        handleFileSelect,
        handleFolderSelect,
        handleSelectAll,
        handleSelectFile,
        clearSelection
    } = useFileHandlers({
        onUpload,
        uploadPasta,
        onUploadSuccess,
        onUploadError
    });

    const {
        previewFile,
        currentPreviewIndex,
        setPreviewFile,
        setCurrentPreviewIndex,
        handlePreviewNext,
        handlePreviewPrev,
        handlePreviewClose
    } = usePreviewHandlers();

    const {
        anchorEl,
        activeFile,
        handleMenuOpen,
        handleMenuClose
    } = useMenuHandlers();

    const previewableFiles = arquivos.filter(arquivo =>
        arquivo.tipo?.startsWith('image/') || arquivo.tipo?.startsWith('video/') || arquivo.tipo === 'application/pdf'
    );

    const handleDeleteSelected = () => {
        onDeleteMany(selectedFiles);
        clearSelection();
    };

    const handlePreviewClick = (arquivo) => {
        const index = previewableFiles.findIndex(file => file._id === arquivo._id);
        setCurrentPreviewIndex(index);
        setPreviewFile(arquivo);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPendente = pedido?.statusPagamento === 'pendente';

    return (
        <Box>
            <FileBreadcrumbs currentPath={currentPath} onNavigate={onNavigate} />

            {isAdmin && (
                <>
                    <UploadArea
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onFileSelect={handleFileSelect}
                        onFolderSelect={handleFolderSelect}
                        uploading={uploading}
                        uploadProgress={uploadProgress}
                        dragOver={dragOver}
                    />

                    {uploading && uploadProgress && (
                        <UploadProgressBar progress={uploadProgress} />
                    )}

                    {arquivos.length > 0 && (
                        <SelectionBar
                            selectedFiles={selectedFiles}
                            totalFiles={arquivos.length}
                            onSelectAll={() => handleSelectAll(arquivos)}
                            onDeleteSelected={handleDeleteSelected}
                        />
                    )}
                </>
            )}

            {arquivos.length > 0 && !isAdmin && (
                <Button
                    variant="outlined"
                    startIcon={downloadingAll ? <CircularProgress size={20} /> : <CloudDownload />}
                    onClick={() => onDownloadAll(isMobile)}
                    disabled={downloadingAll || isPendente}
                    fullWidth
                    sx={{
                        mb: 3,
                        borderRadius: 2,
                        textTransform: 'none'
                    }}
                >
                    {downloadingAll
                        ? 'Baixando arquivos...'
                        : isPendente
                            ? 'Download disponível após pagamento'
                            : isMobile
                                ? 'Baixar Todos os Arquivos'
                                : 'Baixar Todos os Arquivos (ZIP)'}
                </Button>
            )}

            {isSelectionFolder && (
                <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Badge
                        badgeContent={totalSelecionados}
                        color="primary"
                        sx={{
                            '& .MuiBadge-badge': {
                                fontSize: '1rem',
                                height: '24px',
                                minWidth: '24px',
                                borderRadius: '12px'
                            }
                        }}
                    >
                        <Typography variant="h6" component="div">
                            Fotos Selecionadas
                        </Typography>
                    </Badge>
                </Box>
            )}

            {arquivos.length > 0 ? (
                <List>
                    {arquivos.map((arquivo) => (
                        <FileListItem
                            key={arquivo._id}
                            arquivo={arquivo}
                            isAdmin={isAdmin}
                            selectedFiles={selectedFiles}
                            onSelectFile={handleSelectFile}
                            onNavigate={onNavigate}
                            onMenuOpen={handleMenuOpen}
                            isSelectionFolder={isSelectionFolder}
                            onSelectForDelivery={onSelectForDelivery}
                            onToggleSelectionFolder={onToggleSelectionFolder}
                            pedido={pedido}
                            onPreviewClick={handlePreviewClick}
                            onImageError={(id) => setImageError(prev => ({ ...prev, [id]: true }))}
                        />
                    ))}
                </List>
            ) : (
                <Typography
                    color="textSecondary"
                    align="center"
                    sx={{ py: 8 }}
                >
                    Nenhum arquivo disponível
                </Typography>
            )}

            <PreviewDialog
                open={!!previewFile}
                onClose={handlePreviewClose}
                arquivo={previewFile}
                pedido={pedido}
                isAdmin={isAdmin}
                onNext={() => handlePreviewNext(previewableFiles)}
                onPrev={() => handlePreviewPrev(previewableFiles)}
                hasNext={currentPreviewIndex < previewableFiles.length - 1}
                hasPrev={currentPreviewIndex > 0}
                isSelectionFolder={isSelectionFolder}
                totalSelecionados={totalSelecionados}
                onSelectForDelivery={onSelectForDelivery}
            />

            <FileMenu
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                onDownload={onDownload}
                onDelete={onDelete}
                isAdmin={isAdmin}
                activeFile={activeFile}
                pedido={pedido}
            />
        </Box>
    );
};

export default PedidoArquivosList;
