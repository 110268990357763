import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Paper,
    Typography,
    TextField,
    Grid,
    Button,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';
import api from '../api';
import useCep from '../hooks/useCep';

const EditarCadastroPendente = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [alerta, setAlerta] = useState({ aberto: false, mensagem: '', tipo: 'info' });
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        documento: '',
        telefone: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: '',
        inscricaoMunicipal: '',
        inscricaoEstadual: '',
        observacoes: ''
    });

    const handleAddressChange = (addressData) => {
        setFormData(prev => ({
            ...prev,
            endereco: addressData.endereco || prev.endereco,
            bairro: addressData.bairro || prev.bairro,
            cidade: addressData.cidade || prev.cidade,
            estado: addressData.estado || prev.estado
        }));
    };

    const { fetchAddress, loading: loadingCep, error: cepError } = useCep(handleAddressChange);

    useEffect(() => {
        const fetchCadastro = async () => {
            try {
                const response = await api.get(`/cadastros-pendentes/${id}`);
                setFormData(response.data);
            } catch (error) {
                console.error('Erro ao carregar cadastro:', error);
                setAlerta({
                    aberto: true,
                    mensagem: 'Erro ao carregar dados do cadastro',
                    tipo: 'error'
                });
            } finally {
                setLoading(false);
            }
        };

        fetchCadastro();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        // Se o campo for CEP e tiver 8 dígitos, busca o endereço
        if (name === 'cep') {
            const cleanCep = value.replace(/\D/g, '');
            if (cleanCep.length === 8) {
                fetchAddress(cleanCep);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.put(`/cadastros-pendentes/${id}`, formData);
            setAlerta({
                aberto: true,
                mensagem: 'Cadastro atualizado com sucesso',
                tipo: 'success'
            });
            // Redireciona após 2 segundos
            setTimeout(() => {
                navigate('/cadastros-pendentes');
            }, 2000);
        } catch (error) {
            console.error('Erro ao atualizar cadastro:', error);
            setAlerta({
                aberto: true,
                mensagem: 'Erro ao atualizar cadastro',
                tipo: 'error'
            });
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ py: 4 }}>
            <Container maxWidth="lg">
                <Paper elevation={2} sx={{ p: 3 }}>
                    <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Editar Cadastro Pendente
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Documento"
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Telefone"
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="CEP"
                                    name="cep"
                                    value={formData.cep}
                                    onChange={handleChange}
                                    required
                                    error={!!cepError}
                                    helperText={cepError}
                                    InputProps={{
                                        endAdornment: loadingCep && (
                                            <CircularProgress size={20} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    fullWidth
                                    label="Endereço"
                                    name="endereco"
                                    value={formData.endereco}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Número"
                                    name="numero"
                                    value={formData.numero}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    fullWidth
                                    label="Complemento"
                                    name="complemento"
                                    value={formData.complemento}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Bairro"
                                    name="bairro"
                                    value={formData.bairro}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Cidade"
                                    name="cidade"
                                    value={formData.cidade}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Estado"
                                    name="estado"
                                    value={formData.estado}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Inscrição Municipal"
                                    name="inscricaoMunicipal"
                                    value={formData.inscricaoMunicipal}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Inscrição Estadual"
                                    name="inscricaoEstadual"
                                    value={formData.inscricaoEstadual}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Observações"
                                    name="observacoes"
                                    multiline
                                    rows={4}
                                    value={formData.observacoes}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate('/cadastros-pendentes')}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Salvar Alterações
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <Snackbar
                    open={alerta.aberto}
                    autoHideDuration={6000}
                    onClose={() => setAlerta({ ...alerta, aberto: false })}
                >
                    <Alert
                        onClose={() => setAlerta({ ...alerta, aberto: false })}
                        severity={alerta.tipo}
                        sx={{ width: '100%' }}
                    >
                        {alerta.mensagem}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
};

export default EditarCadastroPendente;
