import { useEffect, useCallback, useRef } from 'react';
import io from 'socket.io-client';

const getBaseURL = () => {
    if (process.env.NODE_ENV === 'production') {
        return window.location.origin;
    }
    return 'http://localhost:5000';
};

export const useSocketIO = (onMessage, options = {}) => {
    const {
        retryAttempts = 5,
        reconnectOnClose = true,
        heartbeatInterval = 15000,
    } = options;

    const socket = useRef(null);
    const isConnected = useRef(false);
    const messageQueue = useRef([]);
    const processingMessage = useRef(false);
    const mounted = useRef(true);
    const reconnectAttempts = useRef(0);

    const processMessageQueue = useCallback(async () => {
        if (!mounted.current || processingMessage.current || messageQueue.current.length === 0) return;

        processingMessage.current = true;
        try {
            const messages = [...messageQueue.current];
            messageQueue.current = [];

            for (const message of messages) {
                if (!mounted.current) break;
                try {
                    await onMessage(message);
                } catch (error) {
                    console.error('[SocketIO] Error processing message:', error);
                }
            }
        } finally {
            processingMessage.current = false;
            if (messageQueue.current.length > 0 && mounted.current) {
                setTimeout(processMessageQueue, 0);
            }
        }
    }, [onMessage]);

    const connect = useCallback(() => {
        if (socket.current?.connected) {
            return;
        }

        try {
            // Get Socket.IO URL using the same logic as API
            const baseUrl = getBaseURL();

            // Get authentication token from localStorage
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('[SocketIO] No authentication token found');
                return;
            }

            // Cleanup existing socket if any
            if (socket.current) {
                socket.current.removeAllListeners();
                socket.current.close();
            }

            // Create new socket instance
            socket.current = io(baseUrl, {
                path: '/socket.io',
                reconnectionAttempts: retryAttempts,
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                randomizationFactor: 0.5,
                timeout: 20000,
                autoConnect: false,
                withCredentials: true,
                auth: {
                    token: token
                },
                extraHeaders: {
                    Authorization: `Bearer ${token}`
                },
                transports: ['websocket']
            });

            // Setup event handlers before connecting
            socket.current.io.on("error", (error) => {
                console.error('[SocketIO] Manager error:', error);
            });

            socket.current.io.on("reconnect_attempt", (attempt) => {
                // Tente websocket primeiro, depois polling se necessário
                socket.current.io.opts.transports = ['websocket', 'polling'];
            });

            socket.current.io.on("reconnect_error", (error) => {
                console.error('[SocketIO] Reconnection error:', error);
                reconnectAttempts.current++;
            });

            socket.current.io.on("reconnect_failed", () => {
                console.error('[SocketIO] Failed to reconnect');
                reconnectAttempts.current = 0;
            });

            socket.current.on('connect', () => {
                isConnected.current = true;
                reconnectAttempts.current = 0;

                if (messageQueue.current.length > 0) {
                    processMessageQueue();
                }
            });

            socket.current.on('connect_error', (error) => {
                console.error('[SocketIO] Connection error:', error);
                if (error.message.includes('Authentication error')) {
                    console.error('[SocketIO] Authentication failed, clearing token');
                    localStorage.removeItem('token');
                    window.location.href = '/';
                    return;
                }

                if (reconnectAttempts.current < retryAttempts) {
                    setTimeout(() => {
                        if (mounted.current) {
                            socket.current?.connect();
                        }
                    }, 1000 * Math.min(reconnectAttempts.current + 1, 5));
                }
            });

            socket.current.on('disconnect', (reason) => {
                isConnected.current = false;
            });

            socket.current.on('error', (error) => {
                console.error('[SocketIO] Error:', error);
                if (options.onError) {
                    options.onError(error);
                }
            });

            socket.current.on('heartbeat', (data) => {
                if (mounted.current) {
                    socket.current.emit('pong');
                }
            });

            socket.current.on('message', (data) => {
                if (!mounted.current) return;
                try {
                    messageQueue.current.push(data);
                    if (!processingMessage.current) {
                        processMessageQueue();
                    }
                } catch (error) {
                    console.error('[SocketIO] Error processing message:', error);
                }
            });


            ['payment_updated', 'pedido_updated', 'notification'].forEach(event => {
                socket.current.on(event, async (data) => {
                    if (!mounted.current) return;

                    // Processar imediatamente eventos críticos
                    if (event === 'pedido_updated') {
                        try {
                            await onMessage({ event, data });
                        } catch (error) {
                            console.error('[SocketIO] Erro ao processar pedido_updated:', error);
                        }
                    } else {
                        messageQueue.current.push({ event, data });
                        if (!processingMessage.current) {
                            processMessageQueue();
                        }
                    }
                });
            });

            // Iniciar conexão após configurar todos os handlers
            setTimeout(() => {
                if (mounted.current && socket.current) {
                    socket.current.connect();
                }
            }, 100);

        } catch (error) {
            console.error('[SocketIO] Setup error:', error);
            if (options.onError) {
                options.onError(error);
            }
        }
    }, [onMessage, retryAttempts, processMessageQueue, options]);

    useEffect(() => {
        mounted.current = true;
        connect();

        return () => {
            mounted.current = false;
            if (socket.current) {
                socket.current.removeAllListeners();
                socket.current.close();
                socket.current = null;
            }
            messageQueue.current = [];
            processingMessage.current = false;
            reconnectAttempts.current = 0;
        };
    }, [connect]);

    const reconnect = useCallback(() => {
        connect();
    }, [connect]);

    const emit = useCallback((event, data) => {
        if (socket.current?.connected) {
            try {
                socket.current.emit(event, data);
            } catch (error) {
                console.error('[SocketIO] Error sending message:', error);
                reconnect();
            }
        } else {
            console.warn('[SocketIO] Cannot send message - not connected');
            reconnect();
        }
    }, [reconnect]);

    return {
        isConnected: isConnected.current,
        reconnect,
        emit,
        socket: socket.current
    };
};
