import React from 'react';
import { Skeleton, TableCell, TableRow, Box } from '@mui/material';

const TableSkeleton = ({ columns = 8, rows = 5 }) => {
    return Array(rows).fill(0).map((_, rowIndex) => (
        <TableRow key={`skeleton-row-${rowIndex}`}>
            {Array(columns).fill(0).map((_, colIndex) => (
                <TableCell key={`skeleton-cell-${rowIndex}-${colIndex}`}>
                    <Skeleton animation="wave" />
                </TableCell>
            ))}
        </TableRow>
    ));
};

export default React.memo(TableSkeleton);
