import { useState, useCallback, useEffect, useMemo } from 'react';
import api from '../api';

// Helper function to normalize text for searching
const normalizeText = (text) => {
    if (!text) return '';
    return text.toString()
        .toLowerCase()
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/[^a-z0-9]/g, ''); // Remove special characters
};

export const useClientList = () => {
    const [loading, setLoading] = useState(false);
    const [allClientes, setAllClientes] = useState([]); // Store all clients
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 50,
        total: 0,
    });

    const [filters, setFilters] = useState({
        searchTerm: "",
        showZoomPlus: false,
        orderBy: "",
        orderDirection: "asc",
    });

    // Fetch all clients only once when component mounts
    const fetchAllClientes = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get('/clientes', {
                params: {
                    limit: 1000,
                    fields: '_id nome email documento telefone linkDrive isZoomPlus planoId createdAt senha planoNome saldo diaVencimento taxasUrgenciaDisponiveis usoModelosDisponiveis valorMensalidade dataInicioPlano dataFinalPlano observacoes'
                }
            });
            setAllClientes(response.data.clientes.sort((a, b) => a.nome.localeCompare(b.nome)) || []);
        } catch (error) {
            console.error("Erro ao carregar clientes:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAllClientes();
    }, []); // Only fetch once on mount

    // Apply filters and sorting in memory
    const filteredClientes = useMemo(() => {
        let result = [...allClientes];

        // Apply search filter
        if (filters.searchTerm) {
            const normalizedSearch = normalizeText(filters.searchTerm);

            result = result.filter(cliente => {
                if (!cliente) return false;

                // Normalize only the name field
                const searchableText = normalizeText(cliente.nome || '');

                return searchableText.includes(normalizedSearch);
            });
        }

        // Apply Zoom+ filter
        if (filters.showZoomPlus) {
            result = result.filter(cliente => cliente.isZoomPlus);
        }

        // Apply sorting
        if (filters.orderBy) {
            result.sort((a, b) => {
                let comparison = 0;
                switch (filters.orderBy) {
                    case 'nome':
                        comparison = normalizeText(a.nome || '').localeCompare(normalizeText(b.nome || ''));
                        break;
                    case 'nome-desc':
                        comparison = normalizeText(b.nome || '').localeCompare(normalizeText(a.nome || ''));
                        break;
                    case 'email':
                        comparison = normalizeText(a.email || '').localeCompare(normalizeText(b.email || ''));
                        break;
                    case 'status':
                        comparison = (b.isZoomPlus ? 1 : 0) - (a.isZoomPlus ? 1 : 0);
                        break;
                    case 'dataCriacao':
                        comparison = new Date(a.createdAt) - new Date(b.createdAt);
                        break;
                    default:
                        break;
                }
                return filters.orderDirection === 'desc' ? -comparison : comparison;
            });
        }

        return result;
    }, [allClientes, filters]);

    // Apply pagination in memory
    const paginatedClientes = useMemo(() => {
        const start = (pagination.page - 1) * pagination.limit;
        const end = start + pagination.limit;
        return filteredClientes.slice(start, end);
    }, [filteredClientes, pagination.page, pagination.limit]);

    // Update total count based on filtered results
    useEffect(() => {
        setPagination(prev => ({
            ...prev,
            total: filteredClientes.length
        }));
    }, [filteredClientes]);

    const handleFilterChange = useCallback((key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value
        }));
        setPagination(prev => ({ ...prev, page: 1 }));
    }, []);

    const handleClearFilters = useCallback(() => {
        setFilters({
            searchTerm: "",
            showZoomPlus: false,
            orderBy: "",
            orderDirection: "asc",
        });
        setPagination(prev => ({ ...prev, page: 1 }));
    }, []);

    const handlePageChange = useCallback((event, newPage) => {
        setPagination(prev => ({ ...prev, page: newPage }));
    }, []);

    const handleLimitChange = useCallback((event) => {
        setPagination(prev => ({
            ...prev,
            page: 1,
            limit: event.target.value,
        }));
    }, []);

    return {
        loading,
        clientes: paginatedClientes,
        pagination,
        filters,
        handleFilterChange,
        handleClearFilters,
        handlePageChange,
        handleLimitChange,
        refreshData: fetchAllClientes
    };
};
