export const STATUS_OPTIONS = {
    ABERTO: "aberto",
    EM_ANDAMENTO: "em andamento",
    CONCLUIDO: "concluido",
    CANCELADO: "cancelado",
};

export const PAYMENT_STATUS = {
    PENDING: "PENDING",
    RECEIVED: "RECEIVED",
    CONFIRMED: "CONFIRMED",
    RECEIVED_IN_CASH: "RECEIVED_IN_CASH",
    OVERDUE: "OVERDUE",
    REFUNDED: "REFUNDED",
    REFUND_REQUESTED: "REFUND_REQUESTED",
    CHARGEBACK_REQUESTED: "CHARGEBACK_REQUESTED",
    CHARGEBACK_DISPUTE: "CHARGEBACK_DISPUTE",
    AWAITING_CHARGEBACK_REVERSAL: "AWAITING_CHARGEBACK_REVERSAL",
    DUNNING_REQUESTED: "DUNNING_REQUESTED",
    DUNNING_RECEIVED: "DUNNING_RECEIVED",
    AWAITING_RISK_ANALYSIS: "AWAITING_RISK_ANALYSIS"
};

export const STYLES = {
    header: {
        fontWeight: "normal",
        backgroundColor: "background.default",
        color: "text.secondary",
        textTransform: "none",
        fontSize: "14px",
        padding: "16px",
        borderBottom: "1px solid #e0e0e0",
    }
};
