import { useEffect, useRef, useState, useCallback } from 'react';
import io from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';

// Manter uma única instância do socket para toda a aplicação
let globalSocket = null;

export const useSocket = (namespace = '/boards') => {
    const [connected, setConnected] = useState(false);
    const socketRef = useRef(null);

    useEffect(() => {
        // Se já existe um socket global, use-o
        if (globalSocket) {
            socketRef.current = globalSocket;
            setConnected(true);
            return;
        }

        // Se não existe, crie um novo
        globalSocket = io(`${SOCKET_URL}${namespace}`, {
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000
        });

        socketRef.current = globalSocket;

        globalSocket.on('connect', () => {
            setConnected(true);
        });

        globalSocket.on('disconnect', () => {
            setConnected(false);
        });

        globalSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
            setConnected(false);
        });

        globalSocket.on('error', (error) => {
            console.error('Socket error:', error);
        });

        return () => {
            // Não desconecte o socket global quando o componente for desmontado
            // Apenas remova a referência local
            socketRef.current = null;
        };
    }, [namespace]);

    const emit = useCallback((eventName, data) => {
        if (socketRef.current && connected) {
            socketRef.current.emit(eventName, data);
        } else {
            console.warn('Socket not connected, cannot emit:', eventName);
        }
    }, [connected]);

    const on = useCallback((eventName, callback) => {
        if (socketRef.current) {
            socketRef.current.on(eventName, callback);
        }
    }, []);

    const off = useCallback((eventName, callback) => {
        if (socketRef.current) {
            socketRef.current.off(eventName, callback);
        }
    }, []);

    return {
        socket: socketRef.current,
        connected,
        emit,
        on,
        off
    };
};

export default useSocket;
