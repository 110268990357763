import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, Typography } from "@mui/material";
import { STATUS_OPTIONS } from "./constants";

const StatusDisplay = ({ status, id, readOnly, handleStatusChange, allData }) => {
    // Use allData for context-aware status options
    const availableStatuses = useMemo(() => {
        const baseStatuses = Object.entries(STATUS_OPTIONS);

        if (!allData || !id) return baseStatuses;

        // Find the current row in allData for context
        const currentRow = allData.find(row => row.ID === id);
        if (!currentRow) return baseStatuses;

        return baseStatuses;
    }, [allData, id]);

    if (readOnly) {
        return (
            <Typography
                variant="body2"
                sx={{
                    textTransform: 'capitalize',
                    fontWeight: status.toLowerCase() === 'urgente' ? 'bold' : 'normal'
                }}
            >
                {status}
            </Typography>
        );
    }

    const handleChange = (event) => {
        handleStatusChange(id, event.target.value);
    };

    return (
        <Select
            value={status}
            onChange={handleChange}
            sx={{
                width: "150px",
                // Add any conditional styling based on status
                ...(status.toLowerCase() === 'urgente' && {
                    '& .MuiSelect-select': {
                        color: 'error.main',
                        fontWeight: 'bold'
                    }
                })
            }}
        >
            {availableStatuses.map(([key, value]) => (
                <MenuItem
                    key={key}
                    value={value}
                >
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                </MenuItem>
            ))}
        </Select>
    );
};

StatusDisplay.propTypes = {
    status: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    readOnly: PropTypes.bool.isRequired,
    handleStatusChange: PropTypes.func,
    allData: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(StatusDisplay, (prevProps, nextProps) => {
    if (prevProps.status !== nextProps.status ||
        prevProps.id !== nextProps.id ||
        prevProps.readOnly !== nextProps.readOnly ||
        prevProps.handleStatusChange !== nextProps.handleStatusChange ||
        prevProps.allData !== nextProps.allData) {
        return false;
    }
    return true;
});
