import React from 'react';
import { Box, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const ImageCarousel = ({ currentImage, onPrevious, onNext }) => (
    <Box sx={{
        position: 'relative',
        width: '100%',
        height: '200px',
        background: 'rgba(255,255,255,0.03)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255,255,255,0.1)',
        borderRadius: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.02)',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle at 50% 50%, rgba(255,110,64,0.1) 0%, transparent 70%)',
            opacity: 0.5,
            transition: 'opacity 0.3s ease',
        }
    }}>
        <Box sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#eb5b25',
            transition: 'all 0.3s ease',
            '& svg': {
                fontSize: '3rem',
            }
        }}>
            {currentImage.icon}
        </Box>
        <IconButton
            onClick={onPrevious}
            sx={{
                position: 'absolute',
                left: 8,
                color: 'white',
                bgcolor: 'rgba(235,91,37,0.2)',
                backdropFilter: 'blur(4px)',
                '&:hover': {
                    bgcolor: 'rgba(235,91,37,0.4)',
                },
                transition: 'all 0.3s ease',
            }}
        >
            <KeyboardArrowLeft />
        </IconButton>
        <IconButton
            onClick={onNext}
            sx={{
                position: 'absolute',
                right: 8,
                color: 'white',
                bgcolor: 'rgba(235,91,37,0.2)',
                backdropFilter: 'blur(4px)',
                '&:hover': {
                    bgcolor: 'rgba(235,91,37,0.4)',
                },
                transition: 'all 0.3s ease',
            }}
        >
            <KeyboardArrowRight />
        </IconButton>
    </Box>
);

export default ImageCarousel;
