import { useState, useCallback, useMemo } from 'react';
import api from '../api';

// Helper function to normalize text for searching
const normalizeText = (text) => {
    if (!text) return '';
    return text.toString()
        .toLowerCase()
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/[^a-z0-9]/g, ''); // Remove special characters
};

export const usePaymentsList = () => {
    const [loading, setLoading] = useState(false);
    const [rawPayments, setRawPayments] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
    });

    const [filters, setFilters] = useState({
        status: '',
        billingType: '',
        customer: '',
        dateFrom: '',
        dateTo: '',
    });

    // Pre-process payments with normalized customer names
    const allPayments = useMemo(() => {
        return rawPayments.map(payment => ({
            ...payment,
            _normalizedCustomerName: normalizeText(payment.customerDetails?.name || payment.customer?.name || '')
        }));
    }, [rawPayments]);

    // Fetch all payments
    const fetchAllPayments = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get('/pagamentos/payments', {
                params: {
                    limit: 1000 // Get a large number of payments
                }
            });
            setRawPayments(response.data.data || []);
        } catch (error) {
            console.error("Erro ao carregar pagamentos:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    // Apply filters in memory
    const filteredPayments = useMemo(() => {
        return allPayments.filter(payment => {
            if (!payment) return false;

            // Apply status filter
            if (filters.status && payment.status !== filters.status) {
                return false;
            }

            // Apply billing type filter
            if (filters.billingType && payment.billingType !== filters.billingType) {
                return false;
            }

            // Apply customer name filter
            if (filters.customer) {
                const normalizedSearch = normalizeText(filters.customer);
                if (!payment._normalizedCustomerName.includes(normalizedSearch)) {
                    return false;
                }
            }

            // Apply date filters
            if (filters.dateFrom) {
                const fromDate = new Date(filters.dateFrom);
                const paymentDate = new Date(payment.dateCreated);
                if (paymentDate < fromDate) {
                    return false;
                }
            }

            if (filters.dateTo) {
                const toDate = new Date(filters.dateTo);
                const paymentDate = new Date(payment.dateCreated);
                if (paymentDate > toDate) {
                    return false;
                }
            }

            return true;
        });
    }, [allPayments, filters]);

    // Apply pagination in memory
    const paginatedPayments = useMemo(() => {
        const start = (pagination.page - 1) * pagination.limit;
        const end = start + pagination.limit;
        return filteredPayments.slice(start, end);
    }, [filteredPayments, pagination.page, pagination.limit]);

    // Update total count based on filtered results
    useMemo(() => {
        setPagination(prev => ({
            ...prev,
            total: filteredPayments.length
        }));
    }, [filteredPayments]);

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
        setPagination(prev => ({ ...prev, page: 1 }));
    }, []);

    const handleClearFilters = useCallback(() => {
        setFilters({
            status: '',
            billingType: '',
            customer: '',
            dateFrom: '',
            dateTo: '',
        });
        setPagination(prev => ({ ...prev, page: 1 }));
    }, []);

    const handlePageChange = useCallback((event, newPage) => {
        setPagination(prev => ({ ...prev, page: newPage }));
    }, []);

    const handleLimitChange = useCallback((event) => {
        setPagination(prev => ({
            ...prev,
            page: 1,
            limit: parseInt(event.target.value, 10),
        }));
    }, []);

    return {
        loading,
        payments: paginatedPayments,
        pagination,
        filters,
        handleFilterChange,
        handleClearFilters,
        handlePageChange,
        handleLimitChange,
        refreshData: fetchAllPayments
    };
};
