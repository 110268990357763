import React from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
    useMediaQuery,
    IconButton,
    Tooltip
} from '@mui/material';
import {
    Person,
    ShoppingBag,
    Payment,
    Settings,
    Help,
    Logout,
    Dashboard
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserId } from '../../utils/auth';
import ThemeToggle from '../ThemeToggle';
import LogoBrancoLaranja from '../../assets/logos/Logo Branco com Laranja.svg';
import LogoPretoLaranja from '../../assets/logos/Logo Preto com Laranja.svg';

const UserSidebar = ({ onMobileClose }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const userId = getUserId();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNavigation = (path) => {
        navigate(path);
        if (isMobile && onMobileClose) {
            onMobileClose();
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        navigate('/');
    };

    const menuItems = [
        { icon: Person, text: 'Meu Perfil', path: `/user/${userId}` },
        { icon: Dashboard, text: 'Dashboard', path: '/user/dashboard' },
        { icon: ShoppingBag, text: 'Meus Pedidos', path: '/user/orders' },
        { icon: Payment, text: 'Pagamentos', path: '/user/payments' },
        { icon: Settings, text: 'Configurações', path: '/user/settings' },
        { icon: Help, text: 'Ajuda', path: '/user/help' }
    ];

    return (
        <Box
            sx={{
                width: '280px',
                height: '100%',
                background: theme.palette.mode === 'dark'
                    ? 'rgba(18, 18, 18, 0.85)'
                    : 'rgba(255, 255, 255, 0.85)',
                backdropFilter: 'blur(12px)',
                borderRight: `1px solid ${theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(0, 0, 0, 0.1)'}`,
                position: 'fixed',
                left: 0,
                top: 0,
                zIndex: 1000,
                padding: '2rem 0',
                display: 'flex',
                flexDirection: 'column',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: theme.palette.mode === 'dark'
                        ? 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)'
                        : 'linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%)',
                    borderRadius: '0 16px 16px 0',
                    pointerEvents: 'none'
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: '20%',
                    left: '10%',
                    right: '10%',
                    bottom: '20%',
                    background: 'radial-gradient(circle at top right, rgba(235, 91, 37, 0.05), transparent 70%)',
                    filter: 'blur(8px)',
                    opacity: 0.7,
                    pointerEvents: 'none'
                }
            }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                my: 2,
                position: 'relative',
                zIndex: 1
            }}>
                <Box
                    component="img"
                    src={theme.palette.mode === 'dark' ? LogoBrancoLaranja : LogoPretoLaranja}
                    alt="Logo"
                    sx={{
                        height: '50px',
                        width: 'auto'
                    }}
                />
            </Box>

            <List sx={{ width: '100%', flex: 1, position: 'relative' }}>
                {menuItems.map((item, index) => {
                    const Icon = item.icon;
                    const isActive = location.pathname === item.path;

                    return (
                        <ListItem
                            key={index}
                            button
                            onClick={() => handleNavigation(item.path)}
                            sx={{
                                cursor: 'pointer',
                                mx: 2,
                                borderRadius: '12px',
                                mb: 1,
                                position: 'relative',
                                width: 'calc(100% - 2rem)',
                                bgcolor: isActive
                                    ? theme.palette.mode === 'dark'
                                        ? 'rgba(235, 91, 37, 0.15)'
                                        : 'rgba(235, 91, 37, 0.1)'
                                    : 'transparent',
                                transition: 'all 0.3s',
                                overflow: 'hidden',
                                backdropFilter: isActive ? 'blur(4px)' : 'none',
                                '&:hover': {
                                    bgcolor: theme.palette.mode === 'dark'
                                        ? 'rgba(235, 91, 37, 0.2)'
                                        : 'rgba(235, 91, 37, 0.15)',
                                    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                                        color: theme.palette.primary.main
                                    }
                                },
                                '&::before': isActive ? {
                                    content: '""',
                                    position: 'absolute',
                                    inset: 0,
                                    background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}15, transparent)`,
                                    animation: 'shine 1.5s ease-in-out infinite',
                                    opacity: 0.7,
                                    pointerEvents: 'none'
                                } : {},
                                '@keyframes shine': {
                                    from: { transform: 'translateX(-100%)' },
                                    to: { transform: 'translateX(100%)' }
                                }
                            }}
                        >
                            <ListItemIcon>
                                <Icon sx={{
                                    color: isActive
                                        ? theme.palette.primary.main
                                        : theme.palette.text.secondary,
                                    transition: 'color 0.3s'
                                }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={item.text}
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        color: isActive
                                            ? theme.palette.primary.main
                                            : theme.palette.text.primary,
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: isActive ? 600 : 400,
                                        transition: 'all 0.3s'
                                    }
                                }}
                            />
                            {isActive && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        width: '4px',
                                        height: '60%',
                                        bgcolor: theme.palette.primary.main,
                                        borderRadius: '0 4px 4px 0'
                                    }}
                                />
                            )}
                        </ListItem>
                    );
                })}
            </List>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                mt: 'auto',
                pt: 2,
                position: 'relative',
                zIndex: 1
            }}>
                <Tooltip title="Sair">
                    <IconButton
                        onClick={handleLogout}
                        sx={{
                            color: theme.palette.primary.main,
                            bgcolor: theme.palette.mode === 'dark'
                                ? 'rgba(235, 91, 37, 0.15)'
                                : 'rgba(235, 91, 37, 0.1)',
                            borderRadius: '12px',
                            width: '40px',
                            height: '40px',
                            transition: 'all 0.3s',
                            '&:hover': {
                                bgcolor: theme.palette.mode === 'dark'
                                    ? 'rgba(235, 91, 37, 0.25)'
                                    : 'rgba(235, 91, 37, 0.2)',
                                transform: 'scale(1.05)'
                            }
                        }}
                    >
                        <Logout />
                    </IconButton>
                </Tooltip>
                <ThemeToggle />
            </Box>
        </Box>
    );
};

export default UserSidebar;
