import { useState } from 'react';

const useCep = (onAddressChange) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchAddress = async (cep) => {
        // Remove caracteres não numéricos
        const cleanCep = cep.replace(/\D/g, '');

        // Verifica se o CEP tem 8 dígitos
        if (cleanCep.length !== 8) {
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`https://viacep.com.br/ws/${cleanCep}/json/`);
            const data = await response.json();

            if (data.erro) {
                setError('CEP não encontrado');
                return;
            }

            // Chama a função de callback com os dados do endereço
            onAddressChange({
                endereco: data.logradouro,
                bairro: data.bairro,
                cidade: data.localidade,
                estado: data.uf
            });
        } catch (error) {
            setError('Erro ao buscar CEP');
        } finally {
            setLoading(false);
        }
    };

    return {
        fetchAddress,
        loading,
        error
    };
};

export default useCep;
