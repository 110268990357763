import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Button,
  FormControlLabel,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import SearchInput from "./shared/SearchInput";

const SearchFilterBar = memo(({
  initialSearchTerm = "",
  onSearchChange,
  showZoomPlus,
  onZoomPlusChange,
  onClearFilters,
  orderBy,
  onOrderByChange,
  loading = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Verifica se há filtros ativos
  const hasActiveFilters = Boolean(initialSearchTerm || showZoomPlus || orderBy);

  const orderByOptions = [
    { value: "", label: "Sem Ordenação" },
    { value: "nome", label: "Nome (A-Z)" },
    { value: "nome-desc", label: "Nome (Z-A)" },
    { value: "email", label: "Email" },
    { value: "status", label: "Status (Zoom+)" },
    { value: "dataCriacao", label: "Data de Cadastro" },
  ];

  const handleSearchChange = (value) => {
    onSearchChange(value);
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        padding: 2,
        borderRadius: 2,
        boxShadow: theme.shadows[1],
        marginBottom: 3,
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 2,
        position: "relative",
      }}
    >
      <FilterListIcon
        sx={{
          backgroundColor: "background.paper",
          padding: "2px",
          color: hasActiveFilters ? "primary.main" : "text.secondary",
        }}
      />

      <SearchInput
        value={initialSearchTerm}
        onChange={handleSearchChange}
        disabled={loading}
      />

      <FormControl
        size="small"
        sx={{ flex: isMobile ? 1 : "1 1 200px" }}
        disabled={loading}
      >
        <InputLabel>Ordenar por</InputLabel>
        <Select
          value={orderBy}
          onChange={(e) => onOrderByChange(e.target.value)}
          label="Ordenar por"
        >
          {orderByOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Filtrar apenas clientes Zoom+">
        <FormControlLabel
          control={
            <Checkbox
              checked={showZoomPlus}
              onChange={(e) => onZoomPlusChange(e.target.checked)}
              disabled={loading}
              color="primary"
            />
          }
          label="Somente Zoom+"
          sx={{
            margin: 0,
            flex: "0 0 auto",
            "& .MuiFormControlLabel-label": {
              color: showZoomPlus ? "primary.main" : "text.primary",
            },
          }}
        />
      </Tooltip>

      <Tooltip title="Limpar todos os filtros">
        <span>
          <Button
            variant="outlined"
            color="error"
            onClick={onClearFilters}
            size="small"
            disabled={!hasActiveFilters || loading}
            startIcon={<ClearIcon />}
            sx={{
              height: "40px",
              minWidth: "140px",
              marginLeft: "auto",
            }}
          >
            Limpar Filtros
          </Button>
        </span>
      </Tooltip>

      {hasActiveFilters && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            width: "100%",
            mt: 1,
          }}
        >
          {initialSearchTerm && (
            <Chip
              label={`Busca: ${initialSearchTerm}`}
              onDelete={() => handleSearchChange("")}
              size="small"
              disabled={loading}
            />
          )}
          {showZoomPlus && (
            <Chip
              label="Zoom+"
              onDelete={() => onZoomPlusChange(false)}
              size="small"
              color="primary"
              disabled={loading}
            />
          )}
          {orderBy && (
            <Chip
              label={`Ordenado por: ${orderByOptions.find((opt) => opt.value === orderBy)?.label
                }`}
              onDelete={() => onOrderByChange("")}
              size="small"
              disabled={loading}
            />
          )}
        </Box>
      )}
    </Box>
  );
});

SearchFilterBar.propTypes = {
  initialSearchTerm: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  showZoomPlus: PropTypes.bool.isRequired,
  onZoomPlusChange: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  onOrderByChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SearchFilterBar.displayName = 'SearchFilterBar';

export default SearchFilterBar;
