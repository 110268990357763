import React from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  IconButton,
  Tooltip,
  Paper,
  Fade,
  CircularProgress
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const ServiceItem = ({ servico, onDelete, isDeleting }) => {
  const formatarPreco = (preco) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(preco);
  };

  return (
    <Fade in={true} timeout={500}>
      <Paper
        elevation={2}
        sx={{
          mb: 2,
          pr: 2,
          transition: "all 0.3s ease",
          "&:hover": {
            transform: "translateX(5px)",
            boxShadow: 4,
          }
        }}
      >
        <ListItem
          key={servico._id}
          sx={{
            padding: "24px",
            borderRadius: "5px",
            position: "relative",
            overflow: "hidden",
            pr: "80px", // Espaço extra para o botão de delete
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              width: "5px",
              backgroundColor: "primary.main",
              transition: "width 0.2s ease",
            },
            "&:hover::before": {
              width: "8px",
            },
          }}
        >
          <ShoppingCartIcon
            sx={{
              mr: 2,
              color: "primary.main",
              opacity: 0.7
            }}
          />

          <ListItemText
            primary={
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  mb: 0.5
                }}
              >
                {servico.nome}
              </Typography>
            }
            secondary={
              <>
                {servico.descricao && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                    component="div"
                  >
                    {servico.descricao}
                  </Typography>
                )}
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem"
                  }}
                  component="div"
                >
                  {formatarPreco(servico.preco)}
                </Typography>
              </>
            }
          />

          <ListItemSecondaryAction>
            <Tooltip title="Excluir Serviço" arrow placement="left">
              <IconButton
                edge="end"
                color="error"
                onClick={() => onDelete(servico._id)}
                disabled={isDeleting}
                sx={{
                  border: "1px solid rgba(211, 47, 47, 0.5)",
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: "error.light",
                    color: "white",
                    transform: "scale(1.1)",
                  },
                  width: 40,
                  height: 40,
                }}
              >
                {isDeleting ? (
                  <CircularProgress
                    size={24}
                    color="error"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px"
                    }}
                  />
                ) : (
                  <DeleteIcon />
                )}
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      </Paper>
    </Fade>
  );
};

ServiceItem.propTypes = {
  servico: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    preco: PropTypes.string.isRequired,
    descricao: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
};

ServiceItem.defaultProps = {
  isDeleting: false,
};

export default ServiceItem;
