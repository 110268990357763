import React from "react";
import { Tooltip, IconButton, Typography, Box } from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  HighlightOff as HighlightOffIcon,
} from "@mui/icons-material";

const ZoomPlusInfo = ({ cliente }) => {
  // Ensure isZoomPlus is treated as a boolean
  const isZoomPlus = Boolean(cliente?.isZoomPlus);

  return isZoomPlus ? (
    <Tooltip
      title={
        <Box>
          <Typography variant="body2">Saldo: {cliente.saldo || 0}</Typography>
          <Typography variant="body2">Dia Vencimento: {cliente.diaVencimento || '-'}</Typography>
          <Typography variant="body2">Taxas Urgência: {cliente.taxasUrgenciaDisponiveis || 0}</Typography>
          <Typography variant="body2">Modelos Disponíveis: {cliente.usoModelosDisponiveis || 0}</Typography>
          <Typography variant="body2">Plano: {cliente.planoNome || '-'}</Typography>
          <Typography variant="body2">Valor Mensalidade: R$ {cliente.valorMensalidade?.toFixed(2) || '0.00'}</Typography>
        </Box>
      }
    >
      <IconButton>
        <CheckCircleIcon color="success" />
      </IconButton>
    </Tooltip>
  ) : (
    <HighlightOffIcon color="error" />
  );
};

export default ZoomPlusInfo;
