import React, { useState, useEffect } from "react";
import api from "../api";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  FormControlLabel,
  Container,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import PageHeader from "../components/shared/PageHeader";

const ZoomPlus = () => {
  const [planos, setPlanos] = useState([]);
  const [form, setForm] = useState({
    nome: "",
    saldoBase: "",
    prazoEntrega: "",
    descricao: "",
    taxasUrgenciasGratis: 0,
    porcentagemDesconto: 0,
    assistenciaBPP: false,
    layoutCatalogoGratuito: true,
    modeloPe: true,
    modeloCompleta: 0,
  });
  const [editandoPlanoId, setEditandoPlanoId] = useState(null);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);

  useEffect(() => {
    const fetchPlanos = async () => {
      try {
        const response = await api.get("/planos");
        setPlanos(response.data);
      } catch (error) {
        console.error("Erro ao buscar planos:", error.message);
      }
    };
    fetchPlanos();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editandoPlanoId) {
        const response = await api.put(`/planos/${editandoPlanoId}`, form);
        alert(response.data.mensagem);
        setPlanos((prev) =>
          prev.map((plano) =>
            plano._id === editandoPlanoId ? response.data.plano : plano
          )
        );
      } else {
        const response = await api.post("/planos", form);
        alert(response.data.mensagem);
        setPlanos((prev) => [...prev, response.data.plano]);
      }
      setForm({
        nome: "",
        saldoBase: "",
        prazoEntrega: "",
        descricao: "",
        taxasUrgenciasGratis: 0,
        porcentagemDesconto: 0,
        assistenciaBPP: false,
        layoutCatalogoGratuito: true,
        modeloPe: true,
        modeloCompleta: 0,
      });
      setEditandoPlanoId(null);
      setMostrarFormulario(false);
    } catch (error) {
      console.error("Erro ao salvar plano:", error.message);
      alert("Erro ao salvar plano. Verifique os campos e tente novamente.");
    }
  };

  const handleEdit = (plano) => {
    setForm(plano);
    setEditandoPlanoId(plano._id);
    setMostrarFormulario(true);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/planos/${id}`);
      alert("Plano excluído com sucesso!");
      setPlanos((prev) => prev.filter((plano) => plano._id !== id));
    } catch (error) {
      console.error("Erro ao excluir plano:", error.message);
      alert("Erro ao excluir plano.");
    }
  };

  const toggleFormulario = () => {
    setMostrarFormulario(!mostrarFormulario);
    if (mostrarFormulario) {
      setForm({
        nome: "",
        saldoBase: "",
        prazoEntrega: "",
        descricao: "",
        taxasUrgenciasGratis: 0,
        porcentagemDesconto: 0,
        assistenciaBPP: false,
        layoutCatalogoGratuito: true,
        modeloPe: true,
        modeloCompleta: 0,
      });
      setEditandoPlanoId(null);
    }
  };

  return (
    <PageHeader
      title="Gerenciamento de Planos - Zoom+"
      actionButton={{
        label: mostrarFormulario ? "Cancelar" : "Criar Novo Plano",
        onClick: toggleFormulario,
        icon: mostrarFormulario ? <CancelIcon /> : <AddCircleOutlineIcon />,
        color: mostrarFormulario ? "error" : "primary"
      }}
    >
      <Container maxWidth="100%">

        {mostrarFormulario && (
          <Paper
            elevation={3}
            sx={{
              p: 4,
              mb: 4,
              borderRadius: 2,
              backgroundColor: 'background.paper'
            }}
          >
            <Typography variant="h6" color="primary" gutterBottom sx={{ mb: 3 }}>
              {editandoPlanoId ? "Editar Plano" : "Novo Plano"}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome do Plano"
                    name="nome"
                    value={form.nome}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Saldo Base"
                    type="number"
                    name="saldoBase"
                    value={form.saldoBase}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Prazo de Entrega"
                    name="prazoEntrega"
                    value={form.prazoEntrega}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Descrição"
                    name="descricao"
                    value={form.descricao}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Taxas de Urgência Grátis"
                    type="number"
                    name="taxasUrgenciasGratis"
                    value={form.taxasUrgenciasGratis}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Porcentagem de Desconto"
                    type="number"
                    name="porcentagemDesconto"
                    value={form.porcentagemDesconto}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.assistenciaBPP}
                        onChange={handleInputChange}
                        name="assistenciaBPP"
                        color="primary"
                      />
                    }
                    label="Assistência BPP"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.layoutCatalogoGratuito}
                        onChange={handleInputChange}
                        name="layoutCatalogoGratuito"
                        color="primary"
                      />
                    }
                    label="Layout Catálogo Gratuito"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      mt: 2,
                      py: 1.5,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontSize: '1.1rem',
                      boxShadow: 3
                    }}
                  >
                    {editandoPlanoId ? "Salvar Alterações" : "Cadastrar Plano"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}

        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Box sx={{ p: 3, backgroundColor: 'background.paper' }}>
            <Typography variant="h6" color="primary" gutterBottom>
              Planos Cadastrados
            </Typography>
          </Box>
          <Divider />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'background.paper' }}>
                  <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Saldo Base</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Prazo</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Desconto</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Assistência BPP</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Taxas Grátis</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planos.map((plano) => (
                  <TableRow
                    key={plano._id}
                    sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
                  >
                    <TableCell>{plano.nome}</TableCell>
                    <TableCell>R$ {plano.saldoBase}</TableCell>
                    <TableCell>{plano.prazoEntrega}</TableCell>
                    <TableCell>{plano.porcentagemDesconto}%</TableCell>
                    <TableCell>{plano.assistenciaBPP ? "Sim" : "Não"}</TableCell>
                    <TableCell>{plano.taxasUrgenciasGratis}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleEdit(plano)}
                        color="primary"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(plano._id)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </PageHeader>
  );
};

export default ZoomPlus;
