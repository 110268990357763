import { useState, useEffect, useCallback, useRef } from "react";
import { useQueryClient } from '@tanstack/react-query';
import api from "../api";
import {
    calcularTotais,
    calcularDataEntregaNaoZoomPlus,
    calcularDataEntregaZoomPlus,
} from "../utils/calculations";

const totaisIniciais = {
    total: 0,
    taxaUrgencia: 0,
    totalComUrgencia: 0,
    valorPagar: 0,
    statusPagamento: "pendente",
    desconto: 0,
    totalFinal: 0,
    saldoUtilizado: 0,
    saldoRestante: 0
};

export const useCadastroPedidos = (mode = 'create') => {
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [modoEdicao, setModoEdicao] = useState(mode === 'edit');
    const [novoPedido, setNovoPedido] = useState({
        clienteId: "",
        servicos: [],
        isUrgent: false,
        linkDrive: "",
        retirada: false
    });
    const [clienteZoomPlus, setClienteZoomPlus] = useState(null);
    const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
    const [totais, setTotais] = useState(totaisIniciais);
    const [dataEntrega, setDataEntrega] = useState("");
    const [dataLimiteRetirada, setDataLimiteRetirada] = useState(null);
    const dataFetchedRef = useRef(false);
    const queryClient = useQueryClient();

    // Loading states
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCliente, setIsLoadingCliente] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingPedido, setIsLoadingPedido] = useState(false);

    const resetForm = useCallback(() => {
        setNovoPedido({
            clienteId: "",
            servicos: [],
            isUrgent: false,
            linkDrive: "",
            retirada: false
        });
        setClienteZoomPlus(null);
        setTotais(totaisIniciais);
        setDataEntrega("");
        setDataLimiteRetirada(null);
    }, []);

    const toggleFormulario = useCallback(() => {
        setMostrarFormulario(prev => !prev);
        if (!mostrarFormulario) {
            dataFetchedRef.current = false;
        }
        if (mostrarFormulario) {
            resetForm();
        }
    }, [mostrarFormulario, resetForm]);

    const fetchData = useCallback(async () => {
        if (dataFetchedRef.current) return;
        setIsLoading(true);
        try {
            const [clientesRes, servicosRes] = await Promise.all([
                api.get("/clientes"),
                api.get("/servicos"),
            ]);

            if (!clientesRes.data) {
                setAlerta({
                    mensagem: "Erro ao carregar clientes: dados não recebidos.",
                    tipo: "error",
                    aberto: true,
                });
                return;
            }

            const clientesData = Array.isArray(clientesRes.data) ? clientesRes.data :
                (clientesRes.data.clientes || []);

            setClientes(clientesData);
            setServicos(servicosRes.data || []);
            dataFetchedRef.current = true;
        } catch (error) {
            setAlerta({
                mensagem: "Erro ao carregar clientes e serviços.",
                tipo: "error",
                aberto: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleClienteChange = useCallback(async (clienteId) => {
        if (!clienteId) {
            setClienteZoomPlus(null);
            setNovoPedido((prev) => ({ ...prev, clienteId: "" }));
            setDataEntrega("");
            setDataLimiteRetirada(null);
            return;
        }

        setIsLoadingCliente(true);
        const cliente = clientes.find((c) => c._id === clienteId);
        if (!cliente) {
            setClienteZoomPlus(null);
            setDataEntrega("");
            setDataLimiteRetirada(null);
            setIsLoadingCliente(false);
            return;
        }

        setNovoPedido((prev) => ({ ...prev, clienteId }));
        if (cliente.isZoomPlus && cliente.planoId) {
            try {
                const planoId =
                    typeof cliente.planoId === "string" ? cliente.planoId : cliente.planoId._id;
                const planoResponse = await api.get(`/planos/${planoId}`);
                const plano = planoResponse.data;

                setClienteZoomPlus({
                    ...cliente,
                    plano,
                    isZoomPlus: true,
                    saldo: cliente.saldo || 0
                });
                const dataEntregaCalculada = calcularDataEntregaZoomPlus(plano.prazoEntrega);
                setDataEntrega(dataEntregaCalculada);
                const dataLimite = new Date(dataEntregaCalculada);
                dataLimite.setDate(dataLimite.getDate() + 30);
                setDataLimiteRetirada(dataLimite);
            } catch {
                setClienteZoomPlus(null);
                const dataEntregaCalculada = calcularDataEntregaNaoZoomPlus();
                setDataEntrega(dataEntregaCalculada);
                const dataLimite = new Date(dataEntregaCalculada);
                dataLimite.setDate(dataLimite.getDate() + 30);
                setDataLimiteRetirada(dataLimite);
            }
        } else {
            setClienteZoomPlus(null);
            const dataEntregaCalculada = calcularDataEntregaNaoZoomPlus();
            setDataEntrega(dataEntregaCalculada);
            const dataLimite = new Date(dataEntregaCalculada);
            dataLimite.setDate(dataLimite.getDate() + 30);
            setDataLimiteRetirada(dataLimite);
        }
        setIsLoadingCliente(false);
    }, [clientes]);

    const handleServicoAdd = useCallback((servico) => {
        if (!servico || !servico._id || typeof servico.preco !== 'number' || novoPedido.servicos.some((s) => s.servicoId?._id === servico._id)) return;

        const preco = Number(servico.preco) || 0;
        setNovoPedido((prev) => ({
            ...prev,
            servicos: [
                ...prev.servicos,
                {
                    servicoId: {
                        _id: servico._id,
                        nome: servico.nome,
                        preco: preco
                    },
                    quantidade: 1,
                    subtotal: preco,
                },
            ],
        }));

    }, [novoPedido.servicos]);

    const handleUrgenciaChange = useCallback((checked) => {
        setNovoPedido((prev) => ({
            ...prev,
            isUrgent: checked,
            // Se desmarcar urgência, desmarcar também o benefício de taxa de urgência grátis
            zoomPlus: checked ? prev.zoomPlus : {
                ...prev.zoomPlus,
                beneficiosUtilizados: {
                    ...prev.zoomPlus?.beneficiosUtilizados,
                    taxaUrgenciaGratis: false
                }
            }
        }));
    }, []);

    const handleZoomPlusBenefitChange = useCallback((beneficio) => {
        setNovoPedido((prev) => ({
            ...prev,
            zoomPlus: {
                ...prev.zoomPlus,
                beneficiosUtilizados: {
                    ...prev.zoomPlus?.beneficiosUtilizados,
                    [beneficio]: !prev.zoomPlus?.beneficiosUtilizados?.[beneficio]
                }
            }
        }));
    }, []);

    const handleQuantidadeChange = useCallback((index, quantidade) => {
        setNovoPedido((prev) => {
            const novosServicos = [...prev.servicos];
            const servico = novosServicos[index].servicoId;

            if (!servico || typeof servico.preco !== 'number') {
                console.error("Preço do serviço não encontrado ou inválido!");
                return prev;
            }

            const qtd = Number(quantidade) || 1;
            novosServicos[index].quantidade = qtd;
            novosServicos[index].subtotal = qtd * servico.preco;

            return { ...prev, servicos: novosServicos };
        });
    }, []);

    const handleDeleteServico = useCallback((index) => {
        setNovoPedido(prev => {
            const servicosAtualizados = [...prev.servicos];
            servicosAtualizados.splice(index, 1);
            return { ...prev, servicos: servicosAtualizados };
        });
    }, []);

    const createPayload = useCallback(() => {
        const totaisFinais = calcularTotais(novoPedido, clienteZoomPlus, modoEdicao);

        const payloadBase = {
            clienteId: novoPedido.cliente?._id || novoPedido.clienteId,
            servicos: novoPedido.servicos.map((s) => ({
                servicoId: s.servicoId._id,
                quantidade: Number(s.quantidade),
                subtotal: Number(s.subtotal),
            })),
            total: Number(totaisFinais.total),
            valorUrgencia: novoPedido.isUrgent ? Number(totaisFinais.valorUrgencia) : 0,
            valorPagar: Number(totaisFinais.valorPagar),
            retirada: Boolean(novoPedido.retirada),
            status: novoPedido.status || "aberto",
            statusPagamento: totaisFinais.statusPagamento,
            dataEntrega,
            dataLimiteRetirada,
            isUrgent: Boolean(novoPedido.isUrgent),
            linkDrive: novoPedido.linkDrive || "",
            isZoomPlus: Boolean(clienteZoomPlus),
        };

        if (clienteZoomPlus) {
            payloadBase.zoomPlus = {
                planoId: clienteZoomPlus.plano._id,
                saldoUtilizado: totaisFinais.saldoUtilizado || 0,
                saldoRestante: totaisFinais.saldoRestante || 0,
                descontoAplicado: totaisFinais.descontoAplicado || 0,
                beneficiosUtilizados: novoPedido.zoomPlus?.beneficiosUtilizados || {
                    taxaUrgenciaGratis: false,
                    modeloCompletaGratis: false
                }
            };
        }

        return { payload: payloadBase, totaisFinais };
    }, [novoPedido, clienteZoomPlus, dataEntrega, dataLimiteRetirada, modoEdicao]);

    const handleSubmit = async (e, pedidoId = null, onSuccess = null) => {
        e.preventDefault();

        if (!novoPedido.clienteId || novoPedido.servicos.length === 0) {
            setAlerta({
                mensagem: "Cliente e pelo menos um serviço são obrigatórios.",
                tipo: "error",
                aberto: true,
            });
            return false;
        }

        setIsSubmitting(true);
        const { payload, totaisFinais } = createPayload();

        try {
            if (pedidoId) {
                await api.patch(`/pedidos/${pedidoId}`, payload);
            } else {
                await api.post("/pedidos", payload);
            }

            setAlerta({
                mensagem: pedidoId ? "Pedido atualizado com sucesso!" : "Pedido cadastrado com sucesso!",
                tipo: "success",
                aberto: true,
            });

            if (onSuccess) {
                onSuccess();
            } else {
                resetForm();
                setMostrarFormulario(false);
            }

            // Invalidar a query de pedidos para forçar uma nova busca
            queryClient.invalidateQueries(['pedidos']);

            return true;
        } catch (error) {
            setAlerta({
                mensagem: error.response?.data?.error || `Erro ao ${pedidoId ? 'atualizar' : 'cadastrar'} pedido.`,
                tipo: "error",
                aberto: true,
            });
            return false;
        } finally {
            setIsSubmitting(false);
        }
    };

    const loadPedidoForEdit = useCallback(async (pedidoId) => {
        if (!pedidoId) return;

        setIsLoadingPedido(true);
        try {
            // Carregar dados do pedido
            const response = await api.get(`/pedidos/${pedidoId}`);
            const pedido = response.data;

            // Carregar dados do cliente
            const clienteId = typeof pedido.clienteId === 'object' ? pedido.clienteId._id : pedido.clienteId;
            const clienteResponse = await api.get(`/clientes/${clienteId}`);
            const cliente = clienteResponse.data;

            // Se o cliente é ZoomPlus, usar o plano que já vem no objeto cliente
            if (cliente.isZoomPlus && cliente.planoId) {
                const plano = typeof cliente.planoId === 'object' ? cliente.planoId : null;
                if (plano) {
                    // Configurar clienteZoomPlus com o plano que já veio no cliente
                    setClienteZoomPlus({
                        ...cliente,
                        plano,
                        saldo: cliente.saldo || 0,
                        isZoomPlus: true
                    });
                }
            }

            // Carregar detalhes completos dos serviços
            const servicosPromises = pedido.servicos.map(async (s) => {
                const servicoId = typeof s.servicoId === 'object' ? s.servicoId._id : s.servicoId;
                try {
                    const servicoResponse = await api.get(`/servicos/${servicoId}`);
                    const servicoCompleto = servicoResponse.data;
                    return {
                        servicoId: {
                            _id: servicoCompleto._id,
                            nome: servicoCompleto.nome,
                            preco: servicoCompleto.preco
                        },
                        quantidade: s.quantidade,
                        subtotal: s.subtotal,
                    };
                } catch (error) {
                    console.error(`Erro ao carregar serviço ${servicoId}:`, error);
                    return null;
                }
            });

            const servicosDetalhados = (await Promise.all(servicosPromises)).filter(s => s !== null);

            // Atualizar o estado com todos os dados carregados
            const pedidoAtualizado = {
                clienteId,
                cliente: {
                    ...cliente,
                    plano: cliente.planoId
                },
                servicos: servicosDetalhados,
                isUrgent: pedido.isUrgent,
                retirada: pedido.retirada || false,
                status: pedido.status,
                statusPagamento: pedido.statusPagamento || "pendente",
                linkDrive: pedido.linkDrive || "",
                zoomPlus: pedido.zoomPlus || null,
                createdAt: pedido.createdAt,
                isZoomPlus: cliente.isZoomPlus
            };

            setNovoPedido(pedidoAtualizado);
            setDataEntrega(pedido.dataEntrega);
            setDataLimiteRetirada(pedido.dataLimiteRetirada);

            // Atualizar totais usando o pedido atualizado
            const totaisCalculados = calcularTotais(
                pedidoAtualizado,
                cliente.isZoomPlus ? {
                    ...cliente,
                    plano: cliente.planoId,
                    saldo: cliente.saldo || 0,
                    isZoomPlus: true
                } : null,
                true
            );

            setTotais(totaisCalculados);

        } catch (error) {
            console.error("Erro detalhado ao carregar pedido:", error);
            setAlerta({
                mensagem: error.response?.data?.error || "Erro ao carregar pedido.",
                tipo: "error",
                aberto: true,
            });
        } finally {
            setIsLoadingPedido(false);
        }
    }, []);

    useEffect(() => {
        if ((mode === 'create' || mode === 'edit') && mostrarFormulario) {
            fetchData();
        }
        return () => {
            if (mode === 'create') {
                resetForm();
            }
        };
    }, [mode, fetchData, resetForm, mostrarFormulario]);

    useEffect(() => {
        const calculatedTotais = calcularTotais(novoPedido, clienteZoomPlus, modoEdicao);
        setTotais({ ...totaisIniciais, ...calculatedTotais });
    }, [novoPedido.servicos, clienteZoomPlus, novoPedido.isUrgent, modoEdicao, novoPedido.zoomPlus]);

    return {
        mostrarFormulario,
        toggleFormulario,
        clientes,
        servicos,
        novoPedido,
        clienteZoomPlus,
        alerta,
        totais,
        dataEntrega,
        dataLimiteRetirada,
        handleClienteChange,
        handleServicoAdd,
        handleUrgenciaChange,
        handleQuantidadeChange,
        handleDeleteServico,
        handleSubmit,
        setAlerta,
        fetchData,
        setNovoPedido,
        resetForm,
        modoEdicao,
        loadPedidoForEdit,
        handleZoomPlusBenefitChange,
        // Loading states
        isLoading,
        isLoadingCliente,
        isSubmitting,
        isLoadingPedido
    };
};
