import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
    ButtonGroup,
    IconButton,
    Tooltip,
} from "@mui/material";
import {
    QrCode as QrCodeIcon,
    Receipt as ReceiptIcon,
    CreditCard as CreditCardIcon,
    Cancel as CancelIcon,
    Restore as RestoreIcon,
    Visibility as VisibilityIcon,
    ReceiptLong as InvoiceIcon,
} from "@mui/icons-material";
import InvoiceDetailsDialog from "../../payments/InvoiceDetailsDialog";
import api from "../../../api";

const PaymentActions = ({ linha, readOnly, handlePaymentAction, allData }) => {
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleViewInvoice = async (invoiceId) => {
        try {
            setLoading(true);
            setInvoiceDialogOpen(true);
            const response = await api.get(`/pagamentos/invoices/${invoiceId}`);
            setInvoiceDetails(response.data);
        } catch (error) {
            console.error('Erro ao carregar nota fiscal:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseInvoiceDialog = () => {
        setInvoiceDialogOpen(false);
        setInvoiceDetails(null);
    };

    const { ID, asaas, _raw } = linha;

    // Determina se é um pedido ou um pagamento baseado na estrutura
    const isPedido = Boolean(_raw?.clienteId || _raw?.retirada);

    // Se for pedido, usa o ID do pedido, senão usa o ID do pagamento
    const paymentId = isPedido ? ID : (asaas?.paymentId || ID);
    const hasPayment = Boolean(asaas?.paymentId);

    // Use allData for additional context if needed - must be before any returns
    const paymentContext = useMemo(() => {
        if (!allData || !ID) return null;

        // Find related payments or other contextual data
        const relatedPayments = allData.filter(item =>
            item.ID !== ID &&
            item.Cliente === linha.Cliente
        );

        return {
            hasRelatedPayments: relatedPayments.length > 0,
            relatedPaymentsStatus: relatedPayments.map(p => p.asaas?.paymentStatus)
        };
    }, [allData, ID, linha.Cliente]);

    // Return null if payment is "pago com saldo" or status is "pago_com_saldo"
    const isPagoComSaldo = linha.pagamento?.toLowerCase().includes("pago com saldo") ||
        linha.statusPagamento === "pago_com_saldo";

    if (isPagoComSaldo) {
        return null;
    }

    if (hasPayment) {
        const canRestore = asaas?.paymentStatus === 'CANCELLED';
        const canCancel = !['CANCELLED', 'RECEIVED', 'CONFIRMED'].includes(asaas?.paymentStatus);
        const hasInvoice = linha._raw?.asaas?.notaFiscalId;
        const showInvoiceButton = hasInvoice && ['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(asaas?.paymentStatus);

        return (
            <>
                <ButtonGroup size="small" sx={{ gap: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Tooltip title="Ver Detalhes">
                        <IconButton onClick={() => handlePaymentAction(paymentId, 'view')}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    {showInvoiceButton && (
                        <Tooltip title="Ver Nota Fiscal">
                            <IconButton
                                onClick={() => handleViewInvoice(linha._raw.asaas.notaFiscalId)}
                                sx={{ color: 'success.main' }}
                            >
                                <InvoiceIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!readOnly && (
                        <>
                            {canCancel && (
                                <Tooltip title="Cancelar Cobrança">
                                    <IconButton onClick={() => handlePaymentAction(paymentId, 'cancel')}>
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {canRestore && (
                                <Tooltip title="Restaurar Cobrança">
                                    <IconButton onClick={() => handlePaymentAction(paymentId, 'restore')}>
                                        <RestoreIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                </ButtonGroup>
                <InvoiceDetailsDialog
                    open={invoiceDialogOpen}
                    onClose={handleCloseInvoiceDialog}
                    invoice={invoiceDetails}
                    loading={loading}
                />
            </>
        );
    }

    if (!readOnly) {
        const actions = [
            { type: 'pix', title: 'Gerar PIX', Icon: QrCodeIcon },
            { type: 'boleto', title: 'Gerar Boleto', Icon: ReceiptIcon },
            { type: 'credit_card', title: 'Cartão de Crédito', Icon: CreditCardIcon },
        ];

        // Use payment context to potentially modify available actions
        const availableActions = actions.filter(action => {
            if (!paymentContext) return true;

            // Add any context-based filtering logic here
            // For example, disable certain payment methods based on customer history
            return true;
        });

        return (
            <ButtonGroup size="small">
                {availableActions.map(({ type, title, Icon }) => (
                    <Tooltip key={type} title={title}>
                        <IconButton onClick={() => handlePaymentAction(paymentId, type)}>
                            <Icon />
                        </IconButton>
                    </Tooltip>
                ))}
            </ButtonGroup>
        );
    }

    return null;
};

PaymentActions.propTypes = {
    linha: PropTypes.shape({
        ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        Cliente: PropTypes.string,
        pagamento: PropTypes.string,
        statusPagamento: PropTypes.string,
        asaas: PropTypes.shape({
            paymentId: PropTypes.string,
            paymentStatus: PropTypes.string,
        }),
        _raw: PropTypes.shape({
            clienteId: PropTypes.string,
            retirada: PropTypes.bool,
            asaas: PropTypes.shape({
                notaFiscalId: PropTypes.string,
            }),
        }),
    }).isRequired,
    readOnly: PropTypes.bool.isRequired,
    handlePaymentAction: PropTypes.func.isRequired,
    allData: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(PaymentActions, (prevProps, nextProps) => {
    // Check if the row data changed
    if (prevProps.linha.ID !== nextProps.linha.ID ||
        prevProps.linha.Cliente !== nextProps.linha.Cliente ||
        prevProps.linha.pagamento !== nextProps.linha.pagamento ||
        prevProps.linha.statusPagamento !== nextProps.linha.statusPagamento ||
        prevProps.linha.asaas?.paymentId !== nextProps.linha.asaas?.paymentId ||
        prevProps.linha.asaas?.paymentStatus !== nextProps.linha.asaas?.paymentStatus ||
        prevProps.linha._raw?.asaas?.notaFiscalId !== nextProps.linha._raw?.asaas?.notaFiscalId ||
        JSON.stringify(prevProps.linha._raw) !== JSON.stringify(nextProps.linha._raw)) {
        return false;
    }

    // Check if readOnly status changed
    if (prevProps.readOnly !== nextProps.readOnly) {
        return false;
    }

    // Check if the payment action handler changed
    if (prevProps.handlePaymentAction !== nextProps.handlePaymentAction) {
        return false;
    }

    // Check if the overall data array reference changed
    if (prevProps.allData !== nextProps.allData) {
        return false;
    }

    return true;
});
