import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Email as EmailIcon,
  Description as DescriptionIcon,
  Phone as PhoneIcon,
  Home as HomeIcon,
  LocationCity as LocationCityIcon,
  Domain as DomainIcon,
} from "@mui/icons-material";
import ZoomPlusStyling from "./ZoomPlusStyling";
import ClientZoomPlusCard from "./ClientZoomPlusCard";

const InfoItem = ({ icon: Icon, label, value, fullWidth = false }) => (
  <Grid item xs={12} md={fullWidth ? 12 : 6}>
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Icon color="primary" sx={{ fontSize: 28 }} />
          <Box>
            <Typography variant="caption" color="textSecondary">
              {label}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {value || "Não informado"}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
);

const ClientDetails = ({ cliente }) => {
  const enderecoCompleto = [
    cliente.endereco,
    cliente.numero && `Nº ${cliente.numero}`,
    cliente.complemento,
    cliente.bairro,
    cliente.cidade,
    cliente.estado,
    cliente.cep && `CEP: ${cliente.cep}`
  ].filter(Boolean).join(", ");

  const emailsAdicionaisFormatados = cliente.emailsAdicionais?.length > 0
    ? cliente.emailsAdicionais.join(", ")
    : null;

  return (
    <Box>
      {/* Cabeçalho com Nome e Status Zoom+ */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
          <AccountCircleIcon color="primary" sx={{ fontSize: 40 }} />
          <ZoomPlusStyling
            name={cliente.nome}
            planId={cliente.planoId}
            isZoomPlus={Boolean(cliente.isZoomPlus)}
          />
        </Box>
      </Box>

      {/* Informações Básicas */}
      <Grid container spacing={3}>
        <InfoItem
          icon={EmailIcon}
          label="Email Principal"
          value={cliente.email}
        />
        <InfoItem
          icon={DescriptionIcon}
          label="Documento"
          value={cliente.documento}
        />
        <InfoItem
          icon={PhoneIcon}
          label="Telefone"
          value={cliente.telefone}
        />
        <InfoItem
          icon={HomeIcon}
          label="Endereço"
          value={enderecoCompleto}
          fullWidth
        />
        {emailsAdicionaisFormatados && (
          <InfoItem
            icon={EmailIcon}
            label="Emails Adicionais"
            value={emailsAdicionaisFormatados}
            fullWidth
          />
        )}
        {cliente.inscricaoMunicipal && (
          <InfoItem
            icon={LocationCityIcon}
            label="Inscrição Municipal"
            value={cliente.inscricaoMunicipal}
          />
        )}
        {cliente.inscricaoEstadual && (
          <InfoItem
            icon={DomainIcon}
            label="Inscrição Estadual"
            value={cliente.inscricaoEstadual}
          />
        )}
      </Grid>

      {/* Dados Zoom+ */}
      {Boolean(cliente.isZoomPlus) && (
        <>
          <Divider sx={{ my: 4 }} />
          <ClientZoomPlusCard cliente={cliente} />
        </>
      )}

      {/* Observações */}
      {cliente.observacoes && (
        <>
          <Divider sx={{ my: 4 }} />
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Observações
              </Typography>
              <Typography variant="body2" whiteSpace="pre-wrap">
                {cliente.observacoes}
              </Typography>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
};

ClientDetails.propTypes = {
  cliente: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    documento: PropTypes.string,
    telefone: PropTypes.string,
    endereco: PropTypes.string,
    numero: PropTypes.string,
    complemento: PropTypes.string,
    bairro: PropTypes.string,
    cidade: PropTypes.string,
    estado: PropTypes.string,
    cep: PropTypes.string,
    emailsAdicionais: PropTypes.arrayOf(PropTypes.string),
    inscricaoMunicipal: PropTypes.string,
    inscricaoEstadual: PropTypes.string,
    observacoes: PropTypes.string,
    isZoomPlus: PropTypes.bool,
    dataInicioPlano: PropTypes.string,
    diaVencimento: PropTypes.number,
    dataFinalPlano: PropTypes.string,
    saldo: PropTypes.number,
    taxasUrgenciaDisponiveis: PropTypes.number,
    usoModelosDisponiveis: PropTypes.number,
    planoId: PropTypes.string,
  }).isRequired,
};

export default React.memo(ClientDetails);