import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Chip,
    useTheme,
    alpha,
} from '@mui/material';
import {
    AttachMoney as MoneyIcon,
    Star as StarIcon,
    Receipt as ReceiptIcon,
} from '@mui/icons-material';
import { formatarDataComFuso } from '../../utils/dateUtils';

const formatarValor = (valor) => {
    if (typeof valor !== 'number') return 'R$ 0,00';
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(valor);
};

const InfoSection = ({ title, icon: Icon, children }) => {
    const theme = useTheme();
    return (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Icon sx={{ color: theme.palette.primary.main, mr: 1 }} />
                <Typography variant="h6" color="primary">
                    {title}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

const EditarPedidoResumo = ({
    totais,
    isZoomPlus,
    statusPagamento,
}) => {
    const theme = useTheme();

    const getStatusPagamentoColor = (status) => {
        const statusMap = {
            'pendente': '#FFA726',
            'pago': '#66BB6A',
            'pago_com_saldo': '#66BB6A',
            'pago_parcial_saldo': '#1E88E5',
        };
        return statusMap[status] || '#757575';
    };

    const getStatusPagamentoLabel = (status) => {
        const statusMap = {
            'pendente': 'Pendente',
            'pago': 'Pago',
            'pago_com_saldo': 'Pago com Saldo',
            'pago_parcial_saldo': 'Pago Parcialmente com Saldo',
        };
        return statusMap[status] || status;
    };

    return (
        <Box>
            {/* Valores */}
            <Paper sx={{ p: 2, mb: 2 }}>
                <InfoSection title="Valores" icon={MoneyIcon}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" color="textSecondary">Total</Typography>
                            <Typography variant="body1" color="primary">
                                {formatarValor(totais.total)}
                            </Typography>
                        </Grid>
                        {totais.taxaUrgencia > 0 && (
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" color="textSecondary">Taxa Urgência</Typography>
                                <Typography variant="body1" color="error">
                                    {formatarValor(totais.taxaUrgencia)}
                                </Typography>
                            </Grid>
                        )}
                        {totais.desconto > 0 && (
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" color="textSecondary">Desconto</Typography>
                                <Typography variant="body1" color="success.main">
                                    - {formatarValor(totais.desconto)}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" color="textSecondary">A Pagar</Typography>
                            <Typography variant="body1" color="primary" sx={{ fontWeight: 'bold' }}>
                                {formatarValor(totais.valorPagar)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                            <Chip
                                label={getStatusPagamentoLabel(statusPagamento)}
                                size="small"
                                sx={{
                                    bgcolor: alpha(getStatusPagamentoColor(statusPagamento), 0.1),
                                    color: getStatusPagamentoColor(statusPagamento),
                                }}
                            />
                        </Grid>
                    </Grid>
                </InfoSection>
            </Paper>

            {/* ZoomPlus */}
            {isZoomPlus && (
                <Paper sx={{ p: 2, mb: 2 }}>
                    <InfoSection title="ZoomPlus" icon={StarIcon}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">Saldo Utilizado</Typography>
                                <Typography variant="body1" color="primary">
                                    {formatarValor(totais.saldoUtilizado)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">Saldo Restante</Typography>
                                <Typography variant="body1">
                                    {formatarValor(totais.saldoRestante)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">Desconto</Typography>
                                <Typography variant="body1" color="success.main">
                                    {formatarValor(totais.desconto)}
                                </Typography>
                            </Grid>
                            {(totais.taxaUrgenciaGratis || totais.modeloCompletaGratis) && (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                        Benefícios Utilizados
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                        {totais.taxaUrgenciaGratis && (
                                            <Chip
                                                label="Taxa de Urgência Grátis"
                                                size="small"
                                                color="primary"
                                            />
                                        )}
                                        {totais.modeloCompletaGratis && (
                                            <Chip
                                                label="Modelo Completa Grátis"
                                                size="small"
                                                color="primary"
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </InfoSection>
                </Paper>
            )}

            {/* Pagamento Asaas */}
            {totais.asaas && totais.asaas.paymentId && (
                <Paper sx={{ p: 2, mb: 2 }}>
                    <InfoSection title="Informações de Pagamento" icon={ReceiptIcon}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle2" color="textSecondary">Status Asaas</Typography>
                                <Chip
                                    label={totais.asaas.paymentStatus}
                                    size="small"
                                    color={totais.asaas.paymentStatus === 'RECEIVED' ? 'success' : 'default'}
                                />
                            </Grid>
                            {totais.asaas.billingType && (
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" color="textSecondary">Forma de Pagamento</Typography>
                                    <Typography variant="body1">{totais.asaas.billingType}</Typography>
                                </Grid>
                            )}
                            {totais.asaas.dueDate && (
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" color="textSecondary">Vencimento</Typography>
                                    <Typography variant="body1">
                                        {formatarDataComFuso(totais.asaas.dueDate)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </InfoSection>
                </Paper>
            )}
        </Box>
    );
};

export default EditarPedidoResumo;
