import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useTheme,
} from "@mui/material";
import { STYLES } from "./constants";
import { createCellRenderers } from "./cellRenderers";
import { getRowBackgroundColor } from "./rowUtils";

const TabelaReutilizavel = ({
    cabecalhos,
    dados,
    handleStatusChange,
    handleDetalhes,
    handleRetiradaChange,
    handlePaymentAction,
    readOnly = false,
    isLoading = false,
    skeletonComponent = null,
}) => {
    const theme = useTheme();

    // Memoize cell renderers to prevent unnecessary recreation
    const cellRenderers = useMemo(() => {

        return createCellRenderers({
            readOnly,
            handleStatusChange,
            handleDetalhes,
            handleRetiradaChange,
            handlePaymentAction,
            dados,
        });
    }, [
        readOnly,
        handleStatusChange,
        handleDetalhes,
        handleRetiradaChange,
        handlePaymentAction,
        dados
    ]);

    // Memoize row keys for better performance
    const rowKeys = useMemo(() =>
        dados.map((linha) => linha.ID),
        [dados]
    );

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {cabecalhos.map((cabecalho, index) => (
                            <TableCell key={`header-${index}`} sx={STYLES.header}>
                                {cabecalho}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading && skeletonComponent ? (
                        skeletonComponent
                    ) : (
                        dados.map((linha, index) => {
                            const rowKey = rowKeys[index];
                            return (
                                <TableRow
                                    key={rowKey}
                                    sx={{ backgroundColor: getRowBackgroundColor(linha, theme) }}
                                >
                                    {cabecalhos.map((cabecalho, cellIndex) => {
                                        const cellRenderer = cellRenderers[cabecalho] || (() => linha[cabecalho] || '-');
                                        const cellKey = `${rowKey}-${cabecalho}-${cellIndex}`;
                                        return (
                                            <TableCell key={cellKey}>
                                                {cellRenderer(linha)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TabelaReutilizavel.propTypes = {
    cabecalhos: PropTypes.arrayOf(PropTypes.string).isRequired,
    dados: PropTypes.arrayOf(PropTypes.shape({
        ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        Status: PropTypes.string,
        Cliente: PropTypes.string,
        Origem: PropTypes.string,
        DataCadastro: PropTypes.string,
        DataVencimento: PropTypes.string,
        DataEntrega: PropTypes.string,
        Retirada: PropTypes.bool,
        Detalhes: PropTypes.string,
        Total: PropTypes.string,
        Pagamento: PropTypes.string,
        Telefone: PropTypes.string,
        DriveLink: PropTypes.string,
        boolean: PropTypes.bool,
        asaas: PropTypes.shape({
            paymentId: PropTypes.string,
            paymentStatus: PropTypes.string,
        }),
    })).isRequired,
    handleStatusChange: PropTypes.func,
    handleDetalhes: PropTypes.func.isRequired,
    handleRetiradaChange: PropTypes.func,
    handlePaymentAction: PropTypes.func,
    readOnly: PropTypes.bool,
    isLoading: PropTypes.bool,
    skeletonComponent: PropTypes.node,
};

export default React.memo(TabelaReutilizavel);
