import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    useTheme,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const PaymentsFilterBar = ({ filters, onFilterChange, filterOptions }) => {
    const theme = useTheme();
    const [localFilters, setLocalFilters] = useState(filters);

    const handleLocalFilterChange = (name, value) => {
        setLocalFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleApplyFilters = () => {
        onFilterChange(localFilters);
    };

    const handleClearFilters = () => {
        const clearedFilters = {};
        filterOptions.forEach(option => {
            clearedFilters[option.name] = '';
        });
        setLocalFilters(clearedFilters);
        onFilterChange(clearedFilters);
    };

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.mode === 'light'
                    ? theme.palette.background.paper
                    : theme.palette.background.default,
                padding: 2,
                borderRadius: 2,
                boxShadow: theme.shadows[1],
                marginBottom: 3,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: 2,
                    mb: 2
                }}
            >
                {filterOptions.map((option, index) => {
                    if (option.type === 'select') {
                        return (
                            <FormControl key={index} size="small" sx={{ flex: 1, minWidth: "150px" }}>
                                <InputLabel>{option.label}</InputLabel>
                                <Select
                                    value={localFilters[option.name] || ''}
                                    onChange={(e) => handleLocalFilterChange(option.name, e.target.value)}
                                    label={option.label}
                                >
                                    {option.options.map((opt, i) => (
                                        <MenuItem key={i} value={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    }

                    if (option.type === 'text') {
                        return (
                            <TextField
                                key={index}
                                label={option.label}
                                variant="outlined"
                                size="small"
                                value={localFilters[option.name] || ''}
                                onChange={(e) => handleLocalFilterChange(option.name, e.target.value)}
                                sx={{ flex: 1, minWidth: "200px" }}
                            />
                        );
                    }

                    if (option.type === 'date') {
                        return (
                            <TextField
                                key={index}
                                label={option.label}
                                type="date"
                                variant="outlined"
                                size="small"
                                value={localFilters[option.name] || ''}
                                onChange={(e) => {
                                    const date = e.target.value ? new Date(e.target.value).toISOString().split('T')[0] : '';
                                    handleLocalFilterChange(option.name, date);
                                }}
                                InputLabelProps={{ shrink: true }}
                                sx={{ flex: 1, minWidth: "150px" }}
                            />
                        );
                    }

                    return null;
                })}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClearFilters}
                    size="small"
                >
                    Limpar Filtros
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApplyFilters}
                    size="small"
                    startIcon={<SearchIcon />}
                >
                    Filtrar
                </Button>
            </Box>
        </Box>
    );
};

PaymentsFilterBar.propTypes = {
    filters: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    filterOptions: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })),
    })).isRequired,
};

export default React.memo(PaymentsFilterBar);
